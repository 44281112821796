import React from "react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { PayPalScriptOptions } from "@paypal/paypal-js/types/script-options";

interface PayPalButtonProps {
  amount: string;
  onSuccess: (details: any) => void;
  onError: (error: any) => void;
}

const paypalScriptOptions: PayPalScriptOptions = {
  clientId: `${process.env.REACT_APP_PAYPAL_CLIENT_ID}`,
  currency: "GBP",
};

const PayPalButton: React.FC<PayPalButtonProps> = ({
  amount,
  onSuccess,
  onError,
}) => {
  return (
    <PayPalScriptProvider options={paypalScriptOptions}>
      <PayPalButtons
        createOrder={(data, actions) => {
          return actions.order.create({
            intent: "CAPTURE",
            purchase_units: [
              {
                amount: {
                  value: amount,
                  currency_code: "GBP",
                },
              },
            ],
            application_context: {
              shipping_preference: "NO_SHIPPING",
            },
          });
        }}
        onApprove={(data, actions) => {
          if (actions.order) {
            return actions.order
              .capture()
              .then(function (details) {
                onSuccess(details);
              })
              .catch(function (error) {
                console.error("Capture error:", error);
                // Optionally return a rejected promise to propagate the error
                return Promise.reject(error);
              });
          } else {
            console.error("Order is undefined");
            return Promise.reject(new Error("Order is undefined"));
          }
        }}
        onError={onError}
      />
    </PayPalScriptProvider>
  );
};

export default PayPalButton;
