import { Tooltip as ReactTooltip } from "react-tooltip";
import { BiLinkExternal } from "react-icons/bi";

function FullScreenButton({ handleFullScreen }: any) {
  return (
    <div
      onClick={handleFullScreen}
      className="duration-300 ease-in-out cursor-pointer hover:text-red-600 max-sm:hidden"
      data-tooltip-id={`audio-player-fullscreen`}
    >
      <BiLinkExternal />

      <div className="text-sm">
        <ReactTooltip
          id={`audio-player-fullscreen`}
          place="top"
          content="Full Screen"
        />
      </div>
    </div>
  );
}

export default FullScreenButton;
