import { useEffect, useState } from "react";
import HomePage from "./InfoPage";
import VideosPage from "./EpisodePage";
import VideoDetailsButton from "./VideoDetailsButton";
import ButtonSection from "components/skeleton/videoDetailsSkeleton/ButtonSection";
import { useParams } from "react-router-dom";

function VideoDetailButtonSection({ video, isLoading, similarContent }: any) {
  const [isSelected, setIsSelected] = useState<any>(1);
  const [components, setComponents] = useState<any>(pageName[0]);
  const { slug } = useParams();

  // useEffect(() => {
  //   if (
  //     video?.directors?.length === 0 &&
  //     video?.actors?.length === 0 &&
  //     video?.producers?.length === 0
  //   ) {
  //     setComponents(pageName[1]);
  //     setIsSelected(2);
  //   }
  // }, [video]);

  return (
    <div>
      {!isLoading && (
        <div className="flex my-3 ml-6 max-sm:grid max-sm:grid-cols-3 max-sm:mx-0 max-sm:gap-20">
          {/* {video?.seasons ? (
            <>
              {video?.directors?.length === 0 &&
              video?.actors?.length === 0 &&
              video?.producers?.length === 0 ? (
                <>
                  {video?.allowed_to_watch && (
                    <div className="mr-5">
                      <VideoDetailsButton
                        pages={pageName[1]}
                        setIsSelected={setIsSelected}
                        isSelected={isSelected}
                        selected={true}
                        setComponents={setComponents}
                      />
                    </div>
                  )}
                </>
              ) : (
                <>
                  {pageName.map((pages: any, i: number) => (
                    <div key={i} className="mr-5">
                      {pages.name === "INFO" ||
                      (pages.name === "EPISODES" && video?.allowed_to_watch) ? (
                        <VideoDetailsButton
                          pages={pages}
                          setIsSelected={setIsSelected}
                          isSelected={isSelected}
                          selected={pages.id === isSelected ? true : false}
                          setComponents={setComponents}
                        />
                      ) : (
                        <></>
                      )}
                    </div>
                  ))}
                </>
              )}
            </>
          ) : (
            <>
              <div className="mr-5">
                <VideoDetailsButton
                  pages={pageName[0]}
                  setIsSelected={setIsSelected}
                  isSelected={isSelected}
                  selected={(pageName[0].id === isSelected) === true}
                  setComponents={setComponents}
                />
              </div>
            </>
          )} */}
          <>
            {pageName.map((pages: any, i: number) => (
              <div key={i} className="mr-5">
                {pages.name === "INFO" && video?.allowed_to_watch ? (
                  <VideoDetailsButton
                    pages={pages}
                    setIsSelected={setIsSelected}
                    isSelected={isSelected}
                    // selected={pages.id === isSelected ? true : false}
                    selected={true}
                    setComponents={setComponents}
                  />
                ) : (
                  <></>
                )}
              </div>
            ))}
          </>
        </div>
      )}
      {isLoading && <ButtonSection />}

      <div id="section1" className=" text-white mb-[150px] max-sm:-ml-4 ">
        {
          <components.component
            video={video}
            isLoading={isLoading}
            slug={slug}
            similarContent={similarContent}
          />
        }
      </div>
    </div>
  );
}

const pageName = [
  {
    id: 1,
    component: HomePage,
    name: "INFO",
  },
  // {
  //   id: 2,
  //   component: VideosPage,
  //   name: "EPISODES",
  // },
];

export default VideoDetailButtonSection;
