import SubText from 'components/shared/SubText';
import { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import EpisodeCard from './EpisodeCard';

function EpisodeCoursel({ text, season, seriesId, slug }: any) {
  return (
    <div>
      <>
        <div className="flex justify-between mt-10 ml-6">
          <SubText text={text} className="font-bold" />
          {/* <Link to={RouteName.SeeAllPodcastCategoryRoute}>
                  <p className="font-[500] text-[15px] text-[#F50537] cursor-pointer mr-4">
                    See all
                  </p>
                </Link> */}
        </div>
        {/* coursel */}
        <div className="">
          <Swiper
            slidesPerView={5}
            breakpoints={{
              0: {
                slidesPerView: 1,
              },
              370: {
                slidesPerView: 2,
              },
              700: {
                slidesPerView: 3,
              },
              1100: {
                slidesPerView: 3,
              },
              1200: {
                slidesPerView: 4,
              },
              1500: {
                slidesPerView: 5,
              },
              1600: {
                slidesPerView: 5,
              },
            }}
            modules={[Pagination, Navigation]}
            navigation={true}
            className="mt-5 mb-2 mySwiper"
          >
            {season?.episodes?.map((video: any, i: number) => (
              <SwiperSlide className="mt-5" key={i}>
                <div className="mx-3">
                  <EpisodeCard
                    video={video}
                    season={season?.id}
                    id={seriesId}
                    slug={slug}
                  />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </>
    </div>
  );
}

export default EpisodeCoursel;