import LazyLoadImg from "components/LazyLoadImg";
import FollowArtist from "components/shared/FollowArtist";
import { RouteName } from "constant/routeNames";
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

function TrackPageArtistRow({ artist }: any) {
  //   console.log(artist);
  const loggedInUser = useSelector((state: any) => state?.user?.currentUser);
  const favorite = useSelector((state: any) => state?.user?.userFavorites);
  return (
    <div className="w-[80px] min-h-[50px] p-3 pr-8 hover:bg-slate-800 rounded-md text-white duration-300 ease-in-out my-1 flex items-center cursor-pointer justify-between">
      {/* artist details */}
      <Link to={RouteName.MusicArtistPage.replace(":slug", artist?.slug)}>
        <div className="flex w-[200px] items-center justify-start gap-2 cursor-pointer">
          {/* image */}
          <LazyLoadImg
            imgSrc={artist?.artwork_url}
            imgAlt={artist?.slug}
            imgHeight={"60px"}
            imgWidth={"60px"}
            classname={"rounded-full "}
          />
          <div className="ml-3">
            <p className="text-sm text-gray-500">Artist</p>
            <p className="hover:underline">{artist.name}</p>
          </div>
        </div>
      </Link>
      {/* buttons */}
      <div className="flex items-center justify-center ml-3">
        {loggedInUser && (
          <FollowArtist
            user={loggedInUser}
            artistId={artist?.id}
            favorite={favorite}
            Icon={true}
            artist={artist}
          />
        )}
      </div>
    </div>
  );
}

export default TrackPageArtistRow;
