import LazyLoadImg from "components/LazyLoadImg";
import { FaPlay } from "react-icons/fa";

function TableRowImage({
  executionFunc,
  imgSrc,
  imgAlt,
  imgHeight,
  imgWidth,
  hover,
}: any) {
  //   const [hover, setHover] = useState<boolean>(false);
  return (
    <>
      <div
        className={`h-[${imgHeight}] w-[${imgWidth}] relative `}
        onClick={executionFunc}
      >
        <LazyLoadImg
          imgSrc={imgSrc}
          imgAlt={imgAlt}
          imgHeight={imgHeight}
          imgWidth={imgWidth}
        />
        {hover && (
          <div className="absolute inset-0 flex items-center justify-center bg-[#00000074]">
            <FaPlay className="text-red-600" />
          </div>
        )}
      </div>

      {/* <div className="relative w-[30%]" onClick={executionFunc}>
        {hover && (
          <div className="w-[50px] h-[50px] bg-[#00000093] absolute duration-500 ease-in-out"></div>
        )}
       
        <LazyLoadImg
          imgSrc={imgSrc}
          imgAlt={imgAlt}
          imgHeight={imgHeight}
          imgWidth={imgWidth}
        />

        <div
          className={`absolute w-[50px] h-[50px] overflow-hidden !z-40 flex items-center p-2 top-0 ml-2 play-icon ${
            hover ? "visible duration-500 ease-in-out" : "hidden"
          }`}
        >
          {hover && (
            <button className="items-center duration-200 ease-in-out">
              <FaPlay className={`text-md ${hover ? "text-red-600 " : ""}`} />
            </button>
          )}
        </div>
      </div> */}
    </>
  );
}

export default TableRowImage;
