import React, { ReactNode, useEffect } from "react";
import { AiOutlineClose } from "react-icons/ai";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
  width: string;
  showCloseButton?: boolean;
}
const PopUpModel: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  children,
  width,
  showCloseButton = true,
}) => {
  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }

    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, [isOpen]);

  return isOpen ? (
    <div className="fixed inset-0 !z-100 flex items-center justify-center w-full login_modal">
      {/* Backdrop */}
      <div className="fixed inset-0 bg-black opacity-50"></div>

      {/* Modal */}
      <div className="fixed inset-0 flex items-center justify-center backdrop-blur">
        <div
          className={`relative w-[${width}] px-6 py-3 bg-black rounded-lg shadow-xl`}
        >
          {showCloseButton && ( // Render the close button if showCloseButton prop is true
            <button
              className="absolute text-gray-500 top-6 right-6 hover:text-gray-700"
              onClick={onClose}
            >
              <AiOutlineClose className="text-[15px] font-extrabold" />
            </button>
          )}
          <div className="mt-4">{children}</div>
        </div>
      </div>
    </div>
  ) : null;
};

export default PopUpModel;
