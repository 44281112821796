import { HiOutlinePlus } from "react-icons/hi";

function createNewPlaylistCard() {
  return (
    <div>
      <div className="relative z-20 w-card h-[198px] w-[198px] add-new-card rounded-md flex items-center justify-center cursor-pointer max-sm:w-[170px] max-sm:h-[170px]">
        <div className="white-text">
          <div className="flex items-center justify-center w-16 h-16 mx-auto border-4 border-red-100 rounded-full max-sm:scale-50">
            <HiOutlinePlus className="text-5xl max-sm:text-3xl" />
          </div>
          <p className="mx-auto mt-4 text-lg max-sm:text-sm">
            Create new playlist
          </p>
        </div>
      </div>
    </div>
  );
}

export default createNewPlaylistCard;
