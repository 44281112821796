import React from "react";

function NoNotification() {
  return (
    <div>
      <p className="flex items-center justify-center text-base text-center text-white">
        You don't have any notification yet.
      </p>
    </div>
  );
}

export default NoNotification;
