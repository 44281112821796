export const programmes = [
    {
        id: 1,
        name: "BBC Radio 1",
        fm: "FM: 104.8 MHz (London); 96.0 MHz (Belfast); 1215 kHz;",
        img: "https://ichef.bbci.co.uk/images/ic/480xn/p0bwmqrp.jpg",
      },
      {
        id: 2,
        name: "BBC Radio 4",
        fm: "FM: 104.8 MHz (London); 96.0 MHz (Belfast); 1215 kHz;",
        img: "https://cdn.dribbble.com/userupload/2419868/file/still-3c30306cb2b1f98bc1bca56244337614.png",
      },
      {
        id: 3,
        name: "BBC Radio 3",
        fm: "FM: 104.8 MHz (London); 96.0 MHz (Belfast); 1215 kHz;",
        img: "https://ichef.bbci.co.uk/images/ic/480xn/p0bvqm8m.jpg",
      },
      {
        id: 4,
        name: "BBC Radio 1",
        fm: "FM: 104.8 MHz (London); 96.0 MHz (Belfast); 1215 kHz;",
        img: "https://ichef.bbci.co.uk/images/ic/480xn/p0bwmqrp.jpg",
      },
      {
        id: 5,
        name: "BBC Radio 4",
        fm: "FM: 104.8 MHz (London); 96.0 MHz (Belfast); 1215 kHz;",
        img: "https://cdn.dribbble.com/userupload/2419868/file/still-3c30306cb2b1f98bc1bca56244337614.png",
      },
      {
        id: 6,
        name: "BBC Radio 3",
        fm: "FM: 104.8 MHz (London); 96.0 MHz (Belfast); 1215 kHz;",
        img: "https://ichef.bbci.co.uk/images/ic/480xn/p0bvqm8m.jpg",
      },
      {
        id: 7,
        name: "BBC Radio 1",
        fm: "FM: 104.8 MHz (London); 96.0 MHz (Belfast); 1215 kHz;",
        img: "https://ichef.bbci.co.uk/images/ic/480xn/p0bwmqrp.jpg",
      },
      {
        id: 8,
        name: "BBC Radio 4",
        fm: "FM: 104.8 MHz (London); 96.0 MHz (Belfast); 1215 kHz;",
        img: "https://cdn.dribbble.com/userupload/2419868/file/still-3c30306cb2b1f98bc1bca56244337614.png",
      },
      {
        id: 9,
        name: "BBC Radio 3",
        fm: "FM: 104.8 MHz (London); 96.0 MHz (Belfast); 1215 kHz;",
        img: "https://ichef.bbci.co.uk/images/ic/480xn/p0bvqm8m.jpg",
      },
]