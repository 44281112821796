import React from "react";
import PodcastHistoryCardSection from "./shared/PodcastHistoryCardSection";
import { gridCard } from "constant/gridCard";

function PodcastPageFavorite() {
  return (
    <div className="max-sm:mx-3">
      {/* top section */}
      <div className="mb-8 w-[98%]">
        <div>
          <h1 className="text-2xl font-medium text-white lg:text-2xl md:text-2xl">
            Favorite podcasts
          </h1>
        </div>
      </div>
      {/* card section */}
      <PodcastHistoryCardSection gridCard={gridCard} />
    </div>
  );
}

export default PodcastPageFavorite;
