import { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Navigation, Pagination } from 'swiper';

import SubText from 'components/shared/SubText';
import { RouteName } from 'constant/routeNames';
import MovieCarouselCard from 'pages/videoPage/shared/MovieCarouselCard';
import { Link } from 'react-router-dom';
import VideoService from 'services/video/VideoService';
import 'swiper/css';
import 'swiper/css/pagination';
import TrendMovieSkeletion from '../../components/skeleton/TrendMovieSkeletion';
function WelcomeTVSeries({ favorite }: any) {
  const [videos, setVideos] = useState<any>([]);

  const [isLoading, setIsLoading] = useState<any>(false);

  useEffect(() => {
    setIsLoading(true);
    VideoService.getRandomTVSeries()
      .then((res: any) => {
        setIsLoading(false);
        setVideos(res.data);
      })
      .catch((e) => {
        setIsLoading(false);
        // console.log(e);
      });
  }, []);

  return (
    <div>
      {!isLoading && (
        <div className="flex justify-between items-center w-[95%] ">
          <SubText text="Discover TV Series" className="font-bold" />
          <Link to={RouteName.SeeAllSeriesRoute}>
            <p className="text-sm font-bold cursor-pointer red-text place-self-end">
              {''} See all
            </p>
          </Link>
        </div>
      )}

      {!isLoading && (
        <Swiper
          slidesPerView={6}
          breakpoints={{
            0: {
              slidesPerView: 1,
            },
            370: {
              slidesPerView: 2,
            },
            700: {
              slidesPerView: 3,
            },
            1000: {
              slidesPerView: 4,
            },
            1200: {
              slidesPerView: 5,
            },
            1400: {
              slidesPerView: 6,
            },
            1600: {
              slidesPerView: 7,
            },
          }}
          modules={[Pagination, Navigation]}
          navigation={true}
          className="mt-10 mb-10 mySwiper"
        >
          {videos &&
            videos?.length > 0 &&
            videos?.map((card: any, i: number) => (
              <SwiperSlide key={i}>
                <div className="mx-3 max-sm:mx-1">
                  <MovieCarouselCard
                    id={card?.id}
                    // time={card.runtime}
                    title={card?.title}
                    // info={card.description}
                    // year={card.release}
                    // type={card.video_type}
                    img={card?.thumbnail}
                    seasons={card?.no_of_seasons}
                    slug={card?.slug}
                    tv={true}
                    favoriteTvSeries={favorite}
                    tvSeries={card}
                    first_episode={card?.first_episode}
                  />
                </div>
              </SwiperSlide>
            ))}
        </Swiper>
      )}
      {isLoading && <TrendMovieSkeletion />}
    </div>
  );
}

export default WelcomeTVSeries;
