import React, { useEffect, useState } from "react";
import NoSearch from "./NoSearch";
import SearchTrackList from "../SearchTrackList";

function SearchSongsSection({ data, user }: any) {
  const songs = data?.songs || [];
  // const [songs, setSongs] = useState<any>([]);

  // useEffect(() => {
  //   if (data) {
  //     setSongs(data.songs);
  //   }
  // }, [data]);

  return (
    <div>
      {/* top section */}
      <div className="mb-8 w-[98%] ml-2 mt-8 max-sm:w-full max-sm:mx-0">
        <div>
          <h1 className="text-xl font-semibold text-white lg:text-xl md:text-xl max-sm:ml-3">
            {/* search Songs */}
            Songs
          </h1>
        </div>
      </div>
      {/* card section */}

      <SearchTrackList songs={songs} user={user} />
    </div>
  );
}

export default SearchSongsSection;
