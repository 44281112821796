import { FaPlay } from "react-icons/fa";
import { DiscordChat } from "../chat/DiscordChat";
import { useEffect } from "react";

function PPVideoPlayerRightSidePanel({ isActive, videoDetails, star }: any) {
  useEffect(() => {
    console.log(process.env);
    const server = process.env.PAYPERVIEW_SERVER;
    console.log(server);
  }, []);

  console.log(videoDetails);

  return (
    <>
      {/* side bar  hvh shows on isactive variable */}
      <div
        className={`-mt-[88px] z-10 navbar fixed duration-500 p-2 overflow-y-hidden text-center shadow h-[1000px] max-sm:mt-[10px] max-sm:w-full max-sm:h-auto max-sm:relative max-sm:px-0 max-md:w-full max-md:relative max-md:px-0 max-md:h-auto  ${
          isActive
            ? "right-0  md:w-[422px] h-[1000px]"
            : "md:right-[-100px] right-[-60px]  md:w-[100px] max-sm:right-0 max-md:right-0 max-lg:right-0"
        }`}
      >
        {/* icon bar */}
        <div className="flex flex-col text-gray-100 pt-32 px-5  text-left justify-between  max-sm:p-0 max-md:p-0  max-sm:w-[100%] max-md:w-[100%] mx-auto max-sm:justify-center  w-full overflow-hidden max-sm:mt-[-100px] max-md:mt-[-90px] ">
          <div className="flex flex-col">
            <h1 className="text-[20px] max-sm:h-[100px] max-md:h-[50px] absolute font-bold mt-[-10px] bg-[#131313] pb-[38px] px-1 w-[90%] max-sm:w-full max-md:w-full">
              <span className="max-sm:hidden max-md:hidden">
                Chat Section
                <p className="text-gray-500 text-[15px]">
                  {videoDetails?.discord_channel_id}
                </p>
              </span>
            </h1>
            <div className="pt-8 max-sm:w-[99%] max-sm:mx-auto  ">
              <DiscordChat
                channelId={`${videoDetails?.discord_channel_name}`}
                width={"100%"}
                height={"550px"}
                serverId="1181252454935961680"
              />
            </div>
          </div>
          {/* <div className="flex flex-col my-10 max-sm:w-full">
            <button className="bg-[#F50537] py-[16px] text-white flex justify-center items-center text-[19px] font-bold w-[373px] leading-6 rounded-full mx-auto max-sm:w-full">
              <span className="mr-2">
                <FaPlay />
              </span>
              Invite to Chat
            </button>
          </div> */}

          {videoDetails?.isEnableChat === 0 ? (
            <></>
          ) : (
            <>
              <div className="mt-6 ml-2 max-sm:w-full md:hidden">
                <h1 className="text-[14px] my-2 w-[95%] font-normal text-white ">
                  {videoDetails?.description}
                </h1>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default PPVideoPlayerRightSidePanel;
