import BackButton from "components/shared/BackButton";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import LikeService from "services/music/LikeService";
import RadioService from "services/radio/RadioService";
import RadioCard from "./shared/RadioCard";
import RadioSkeleton from "components/skeleton/RadioSkeleton";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { useLocation, useNavigate } from "react-router-dom";
import Pagination from "pages/podcastPage/shared/Pagination";
import GenreSelector from "pages/videoPage/shared/GenreSelector";
import GeneralServices from "services/General/GeneralServices";
import { Adsense } from "@ctrl/react-adsense";
import { RouteName } from "constant/routeNames";

function SeeAllMightLike() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const page = searchParams.get("page") || "1";
  const pageNoInt = parseInt(page, 10);
  const loggedInUser = useSelector((state: any) => state?.user?.currentUser);
  const [stations, setStations] = useState<any>([]);
  const [lastPage, setLastPage] = useState<any>();
  const [isLoading, setIsLoading] = useState<any>(false);
  const [currentPage, setCurrentPage] = useState(pageNoInt);
  const [genres, setGenres] = useState<any>();
  const [selectedGenre, setSelectedGenre] = useState("");
  const [gAdSlot, setgAdSlot] = useState<any>();
  const currentUrl = window.location.pathname;
  const path = currentUrl + `?page=${currentPage}`;

  useEffect(() => {
    const handlePopstate = () => {
      const newPage =
        new URLSearchParams(window.location.search).get("page") || "1";
      setCurrentPage(parseInt(newPage, 10));
    };

    window.addEventListener("popstate", handlePopstate);

    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, []);
  useEffect(() => {
    GeneralServices.getAllConfigData()
      .then((res) => {
        const PageStatus = res?.data?.filter(
          (item: any) => item?.title === "is_video_gAd" && item?.value !== null
        );
        if (PageStatus[0]?.value === "1") {
          const filteredData = res?.data?.filter(
            (item: any) => item?.title === "gAdSlot" && item?.value !== null
          );
          setgAdSlot(filteredData[0]?.value);
          console.log("setgAdStatus", filteredData[0]?.value);
        }
      })
      .catch((e) => {});
  }, []);
  const navigate = useNavigate();

  useEffect(() => {
    RadioService.getAllRadioCategories().then((res) => {
      setGenres(res);
      // console.log(res);
    });
  }, []);

  useEffect(() => {
    // setTimeout(() => {
    setIsLoading(true);
    if (selectedGenre) {
      RadioService.getRadioStationsByGenre(selectedGenre).then((res) => {
        setIsLoading(false);
        setStations(res.stations.data);
        setLastPage(1);
      });
    } else {
      RadioService.getAllRadios(currentPage).then((res) => {
        setIsLoading(false);
        setStations(res.radioStations.data);
        setLastPage(res.radioStations.last_page);
      });
    }
    // console.log(slug);
    // }, 1000);
  }, [selectedGenre, currentPage]);

  // like function
  const [favorite, setFavorite] = useState<any>();
  useEffect(() => {
    navigate(RouteName.SeeAllMightLikeRoute + `?page=${currentPage}`);
  }, [currentPage, navigate]);
  useEffect(() => {
    if (loggedInUser) {
      LikeService.getFavoriteById(loggedInUser?.id, loggedInUser?.id)
        .then((res) => {
          const favoritesIds = res?.profile?.favorite_radio_stations.map(
            (item: any) => item?.id
          );
          setFavorite(favoritesIds);
        })
        .catch(() => {});
    }
  }, [loggedInUser]);

  return (
    <>
      {/* main topic section */}

      <button
        className="w-10 h-10 mb-5 bg-white rounded-full max-sm:ml-[16px]"
        onClick={() => {
          navigate(-1);
        }}
      >
        <AiOutlineArrowLeft className="ml-3" />
      </button>
      {!isLoading && (
        <>
          <div>
            <div className="flex items-center justify-between mb-8 mr-5 max-sm:block max-sm:mr-0">
              <h1 className="text-2xl font-medium text-white lg:text-2xl md:text-2xl max-sm:w-[60%] max-sm:ml-2">
                All Radios
              </h1>
              <GenreSelector
                selectedGenre={selectedGenre}
                setSelectedGenre={setSelectedGenre}
                genres={genres}
              />
            </div>
            {gAdSlot && (
              <Adsense
                client="ca-pub-5773929890067376"
                slot={gAdSlot}
                style={{
                  display: "flex",
                  marginBottom: "20px",
                  marginTop: "20px",
                  height: "90px",
                  justifyContent: "center",
                }}
                format="horizontal"
                data-full-width-responsive="true"
                // format="fluid"
              />
            )}
          </div>

          {stations && (
            <div className="grid w-full grid-cols-2 mt-4 mb-28 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 max-sm:w-full">
              {stations?.map((station: any, index: any) => (
                <div key={index} className="mb-5 mr-4">
                  <RadioCard
                    station={station}
                    user={loggedInUser}
                    favorite={favorite}
                  />
                </div>
              ))}
            </div>
          )}
        </>
      )}
      {isLoading && <RadioSkeleton />}
      <div className="flex justify-center my-5">
        <Pagination
          totalPages={lastPage}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
        {/* {Array.from({ length: 3 }, (_, i) => (
          <button
            key={i}
            onClick={() => setCurrentPage(i + 1)}
            className={`mx-2 px-4 py-2 rounded ${
              currentPage === i + 1 ? "bg-[#f30543] text-white" : "bg-white"
            }`}
          >
            {i + 1}
          </button>
        ))} */}
      </div>
    </>
  );
}

export default SeeAllMightLike;
