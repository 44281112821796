import LoadingSpin from "components/Loading spins/LoadingSpin";
import { useState } from "react";

function ConfirmationPopUp({
  title,
  text,
  executableFunction,
  onClose,
  executableFunctionButtonText,
}: any) {
  const [loading, setLoading] = useState<boolean>(false);
  const handleSubmit = () => {
    setLoading(true);
    executableFunction();
    setLoading(false);
  };
  return (
    <div className="">
      <h1 className="text-lg font-bold text-white">{title}</h1>

      <div className="my-2 ">
        <hr className="cursor-pointer border-1 border-neutral-500" />
      </div>

      <p className="text-base text-slate-500">{text}</p>

      <div className="flex items-center justify-end gap-5">
        <button onClick={onClose} className="text-white hover:text-slate-500">
          Cancel
        </button>
        <button
          className={`flex items-center duration-200 ease-in-out w-auto px-5 py-2 text-center text-white rounded-full button-red max-sm:p-2 max-sm:ml-2 max-sm:text-[10px] max-sm:w-[full] hover:bg-[#f31541b9]`}
          onClick={handleSubmit}
        >
          {loading ? (
            <LoadingSpin fontSize={20} color={"white"} />
          ) : (
            executableFunctionButtonText
          )}
        </button>
      </div>
    </div>
  );
}

export default ConfirmationPopUp;
