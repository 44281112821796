import {
  ProgramBox,
  ProgramContent,
  ProgramImage,
  ProgramStack,
  ProgramText,
  ProgramTitle,
  useProgram,
} from "planby";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { shrinkText } from "utils/helperFuncs";
import { calculateDuration } from "utils/timeFunctions";

function ProgramItem({
  setSelectProgram,
  setProgramViewPopup,
  program,
  ...rest
}: any) {
  const { styles, formatTime, set12HoursTimeFormat, isLive, isMinWidth } =
    useProgram({
      program,
      ...rest,
    });

  const { data } = program;
  const { image, title, since, till } = data;
  //   console.log(data);
  // convert time
  const sinceTime = formatTime(since, set12HoursTimeFormat()).toLowerCase();
  const tillTime = formatTime(till, set12HoursTimeFormat()).toLowerCase();
  const { hours, minutes } = calculateDuration(
    since.toLocaleString(),
    till.toLocaleString()
  );

  // const [selectProgram, setSelectProgram] = useState({});
  // const [programViewPopup, setProgramViewPopup] = useState(false);

  const programClicked = (data: any) => {
    setSelectProgram(data);
    setProgramViewPopup(true);
  };
  return (
    <>
      <ProgramBox
        width={styles.width}
        style={styles.position}
        onClick={() => programClicked(data)}
      >
        <ProgramContent width={styles.width} isLive={isLive}>
          <div className="flex justify-between w-full">
            <div className="flex ">
              {isLive && isMinWidth && (
                <ProgramImage src={image} alt="Preview" width={40} />
              )}
              <ProgramStack>
                <ProgramTitle
                  data-tooltip-id={`program-title-${data.id}-${image}`}
                >
                  {shrinkText(title, 15)}
                </ProgramTitle>
                <ProgramText>
                  {sinceTime} - {tillTime}
                </ProgramText>
              </ProgramStack>
            </div>
            {/* <p className="text-xs text-gray-200">
              {minutes === 0
                ? hours + " h"
                : hours + " h - " + minutes + " min"}
            </p> */}
          </div>
        </ProgramContent>
      </ProgramBox>
      <ReactTooltip
        id={`program-title-${data.id}-${image}`}
        place="bottom"
        content={`${title}`}
        className="z-50"
      />
    </>
  );
}

export default ProgramItem;
