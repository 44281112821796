import { Form, Input } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { CSSProperties, useState } from 'react';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';

const inputStyle: CSSProperties = {
  fontSize: '16px',
  borderRadius: '8px',
  height: '50px',
  alignItems: 'center',
};
function AntDInputField({
  name,
  rules,
  className,
  type,
  value,
  placeholder,
  size,
}: any) {
  // console.log(value);
  const [passwordVisible, setPasswordVisible] = useState<boolean>(false);
  const [inputType, setInputType] = useState<string>('password');

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
    setInputType(passwordVisible ? 'password' : 'text');
  };
  return (
    <div>
      <Form.Item
        name={name}
        rules={rules}
        className={`mt-3 mb-8 ${className ? className : ''}`}
      >
        {name === 'bio' || name === 'description' ? (
          <TextArea
            showCount
            maxLength={100}
            style={{ height: 80 }}
            defaultValue={value}
          />
        ) : type === 'password' ? (
          <Input
            type={inputType}
            placeholder={placeholder}
            style={inputStyle}
            suffix={
              <div
                onClick={togglePasswordVisibility}
                style={{
                  cursor: 'pointer',
                }}
              >
                {passwordVisible ? (
                  <EyeTwoTone className="text-lg" />
                ) : (
                  <EyeInvisibleOutlined className="text-lg" />
                )}
              </div>
            }
          />
        ) : (
          <Input
            style={inputStyle}
            type={'text'}
            defaultValue={value}
            size={size}
            placeholder={placeholder}
          />
        )}
      </Form.Item>
    </div>
  );
}

export default AntDInputField;
