import { RouteName } from "constant/routeNames";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { addMusicToPlayer } from "redux/AudioPlayerRedux";
import CreatePlaylistIcon from "../audioPlayer/AudioPlayer3/CreatePlaylistIcon/CreatePlaylistIcon";
import AddSongToFav from "./AddSongToFav";
import AddSongToQueue from "./AddSongToQueue";
import TableRowImage from "./TableRowImage";
import { formatDuration } from "utils/timeFunctions";

function MusicTableTow({
  id,
  data,
  user,
  favorite,
  userPlaylists,
  setUserPlaylists,
  removeFromPlaylist,
  setRemovedSongId,
}: any) {
  const dispatch = useDispatch<any>();
  const loggedInUser = useSelector((state: any) => state?.user?.currentUser);

  const handleMusicPlayer = () => {
    dispatch(addMusicToPlayer(data));
  };

  const [hover, setHover] = useState<boolean>(false);

  return (
    <>
      <tr
        className={`relative cursor-pointer hover:bg-slate-800 hover:shadow-xl rounded-md text-white hover:text-slate-300 ease-in-out duration-150 max-sm:w-full ${
          hover ? "hovered" : ""
        }`}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <td className="w-[5%] pl-3 max-sm:pl-0">
          <p className="text-[#626A66] text-[15px]">{id}</p>
        </td>
        <td className="w-[35%]">
          <div className="flex items-center w-full gap-4 p-2">
            <TableRowImage
              executionFunc={handleMusicPlayer}
              imgSrc={data?.artwork_url}
              imgAlt={"song"}
              imgHeight={"50px"}
              imgWidth={"50px"}
              hover={hover}
            />
            <div className="flex w-[70%]">
              <Link to={RouteName.MusicTrackRoute.replace(":id", data.id)}>
                <p className="w-full text-[#AFB6B2] text-[15px] max-sm:text-[10px]">
                  {/* { */}
                  {data?.title}
                     {/* ? `${data?.title.slice(0, 25).replace(/_/g, "'")}...`
                      : data?.title.replace(/_/g, "'")} */}
                </p>
              </Link>
            </div>
          </div>
        </td>
        <td className="items-center w-[25%] ml-2 max-sm:text-[10px]">
          <Link
            to={RouteName.MusicArtistPage.replace(
              ":slug",
              data?.artists[0]?.slug
            )}
          >
            <p className="text-white text-[15px] max-sm:text-[10px] hover:text-slate-400 hover:underline">
              {data?.artists[0].name
                ?.replace(/&amp;/g, "&")
                .replace(/&rsquo;/g, "’")
                .replace(/&nbsp;/g, "")}
            </p>
          </Link>
        </td>
        <td className="items-center w-[25%] max-sm:mx-auto max-sm:pl-1 max-sm:w-[10%]">
          <p className="text-[#7B9DB4] text-[12px] max-sm:text-[10px] ">
            {formatDuration(data?.duration)}
          </p>
        </td>
        <td className="items-center justify-between w-[10%] max-sm:ml-[-20px]">
          <div className="flex items-center justify-end gap-5 pr-10 text-xl max-sm:gap-1">
            {loggedInUser &&
              (user ? (
                <>
                  {loggedInUser && user?.id === loggedInUser?.id && (
                    <AddSongToFav song={data} favorite={favorite} />
                  )}
                </>
              ) : (
                <>
                  <AddSongToFav song={data} favorite={favorite} />
                </>
              ))}

            <AddSongToQueue song={data} />

            <div>
              <CreatePlaylistIcon
                userPlaylists={userPlaylists}
                setUserPlaylists={setUserPlaylists}
                mediaId={data.id}
                removeFromPlaylist={removeFromPlaylist}
                setRemovedSongId={setRemovedSongId}
              />
            </div>
          </div>
        </td>
      </tr>
      {/* <tr>
        <td colSpan={5}>
          <hr className="my-2 border-none" />
        </td>
      </tr> */}
    </>
  );
}

export default MusicTableTow;
