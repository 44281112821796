import FollowCardSkeleton from './FollowCardSkeleton';

const FollowPageSkeleton = () => {
  const arr = [1, 2, 3, 4, 5];

  return (
    <div className="flex flex-col gap-2">
      <div className="w-[50px] h-[50px] rounded-full bg-[#1a1919] animate-pulse mt-2"></div>
      <div className="w-[200px] h-[40px] bg-[#1a1919] animate-pulse mt-2"></div>
      <div className="w-[300px] h-[40px] bg-[#1a1919] animate-pulse mt-2"></div>

      <div className="grid lg:grid-cols-7 md:grid-cols-3 sm:grid-cols-1 lg:gap-5 md:gap-2">
        {arr.map((item: any) => (
          <div key={item}>
            <FollowCardSkeleton />
          </div>
        ))}
      </div>
    </div>
  );
};

export default FollowPageSkeleton;
