import { RouteName } from "constant/routeNames";
import React from "react";
import { useNavigate } from "react-router-dom";

function NoTransaction() {
  const navigate = useNavigate();

  const handleExplore = () => {
    navigate(RouteName.PayPerView.replace("/*", ""));
  };
  return (
    <div className="items-center h-full mt-20">
      <p className="flex items-center justify-center text-base text-gray-500 max-sm:text-center">
        No transactions found. Click below to explore events
      </p>
      <div className="flex justify-center mt-5">
        <button
          className="rounded-full w-auto font-semibold px-6 py-2 text-white button-red max-sm:w-[50%] md:w-[25%] lg:w-[10%]"
          onClick={handleExplore}
        >
          Explore
        </button>
      </div>
    </div>
  );
}

export default NoTransaction;
