import React from "react";
import TransectionRow from "./TransectionRow";

function PPVTranscetionTable({ transection }: any) {
  
  return (
    <div className="w-full text-white max-sm:overflow-x-hidden mb-20">
      <table className="w-full px-2">
        <thead className="text-left bg-slate-800">
          <tr>
            <th className="text-[12px] text-[#7B9DB4] py-3 w-[5%] pl-3 max-sm:pl-0">
              #
            </th>
            <th className=" text-[12px] text-[#7B9DB4] w-[35%] max-sm:pl-5">
              Event Name
            </th>
            <th className="text-[12px] text-[#7B9DB4] w-[20%] ">
              Order Number
            </th>
            <th className="text-[12px] text-[#7B9DB4] w-[13%] max-sm:ml-[10px] ">
              Status
            </th>
            <th className="text-[12px] text-[#7B9DB4] w-[12%] max-sm:ml-[10px] ">
              Amount
            </th>
            <th className="text-[12px] text-[#7B9DB4] w-[15%] max-sm:ml-[10px] ">
              Time
            </th>
          </tr>
        </thead>
        <tr>
          <td colSpan={6} className="pr-2 max-sm:pr-3">
            <hr className="mt-2 mb-5 border-[#7B9DB4] pr-4" />
          </td>
        </tr>
        <tbody className="my-5">
          {transection?.map((data: any, i: number) => (
            <TransectionRow id={i + 1} transection={data} key={i} />
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default PPVTranscetionTable;
