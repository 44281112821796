import { Notification } from "constant/notifications";
import { RouteName } from "constant/routeNames";
import { useState } from "react";
import { IoMdShareAlt } from "react-icons/io";
import {
  MdAddCircleOutline,
  MdArrowForwardIos,
  MdRemoveCircleOutline,
} from "react-icons/md";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import PlaylistService from "services/music/PlaylistService";
import { triggerSuccessNotification } from "utils/notification";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { IoArrowBack } from "react-icons/io5";

function MusicPlayListPopUpMobile({
  className,
  list,
  openModal,
  closeModal,
  userPlaylists,
  setUserPlaylists,
  mediaId,
  removeFromPlaylist,
  setRemovedSongId,
  setOpenedComponent,
}: any) {
  const loggedInUser = useSelector((state: any) => state?.user?.currentUser);
  const { id } = useParams();
  const [hover, setHover] = useState<boolean>(false);
  const baseUrl = window.location.origin;

  const addSongToPlaylist = async (playlistId: any, playlistName: any) => {
    const data = {
      mediaId: mediaId,
      mediaType: "song",
      playlist_id: playlistId,
    };
    // console.log(data);
    await PlaylistService.addSongToPlaylist(data)
      .then((response: any) => {
        // console.log(response);
        if (response.data.success === true) {
          toast.success(`Add to ${playlistName}`);
          PlaylistService.getUserPlaylists().then((res) => {
            setUserPlaylists(res.data.data);
          });
        } else {
          if (response.data.message === "Duplicated row") {
            toast.warning(`Already in the ${playlistName}`);
          } else {
            toast.error(`There is an issue when adding to ${playlistName}`);
          }
        }
        closeModal();
      })
      .catch((e: any) => {
        toast.error("An error occurred. Please try again.");
      });
  };

  const handleRemoveSongFromPlaylist = async (playlistId: any) => {
    const data = {
      song_id: mediaId,
      playlist_id: playlistId,
    };

    await PlaylistService.removeSongFromPlaylist(data)
      .then((response: any) => {
        // console.log(response);
        if (response.data.success === true) {
          toast.success(`Removed from the playlist`);
          setRemovedSongId(mediaId);
        } else {
          toast.error(`There is an issue when removing from playlist`);
        }
        closeModal();
      })
      .catch((e: any) => {
        toast.error("An error occurred. Please try again.");
      });
  };

  const copyToClipboard = async () => {
    const currentUrl =
      baseUrl +
      RouteName.Music.replace(
        "*",
        RouteName.MusicTrack.replace(":id", mediaId)
      );
    await navigator.clipboard.writeText(currentUrl);
    triggerSuccessNotification(Notification.CopyToClipboard);
    closeModal();
  };

  const handleOpenPlaylist = () => {
    setOpenedComponent("createPlaylist");
    openModal();
  };

  //don't delete. this is the function of social media sharing
  // const handleOpenShareModel = () => {
  //   setOpenedComponent("shareDetails");
  //   copyToClipboard();
  //   openModal();
  // };

  return (
    <div
      className={`watchlist-dropdown px-4 py-2 rounded-md text-sm absolute white-text duration-500 border-2 border-1 border-neutral-500 overflow-y-scroll overflow-x-visible scrollbar-hide max-h-[200px] ${
        className && className
      }`}
    >
      <div className="my-2">
        {hover ? (
          <>
            <li
              className="flex items-center justify-start gap-2 music-playlist-popup-li pop-up-animation"
              onClick={() => setHover(false)}
            >
              <span className="p-1 text-black bg-white rounded-full">
                <IoArrowBack />
              </span>
              Back
            </li>

            {userPlaylists && userPlaylists?.length > 0 && (
              <hr className="my-1 cursor-pointer border-1 border-neutral-500" />
            )}

            {userPlaylists?.map((item: any, i: number) => (
              <li
                className="mb-2 cursor-pointer hover:text-[#828282] ease-in-out duration-100 list-none"
                key={i}
                onClick={() => addSongToPlaylist(item?.id, item?.title)}
              >
                {/* {item.title} */}

                {item?.title?.length > 20 ? (
                  <>
                    <span data-tooltip-id={item?.id}>{`${item?.title.slice(
                      0,
                      20
                    )}...`}</span>
                    <ReactTooltip
                      className="absolute w-full text-sm"
                      id={item?.id}
                      place="top"
                      content={item?.title}
                    />
                  </>
                ) : (
                  item.title
                )}
              </li>
            ))}

            {/* remove from play list */}
            {removeFromPlaylist && (
              <>
                {userPlaylists?.length > 0 && (
                  <hr className="cursor-pointer border-1 border-neutral-500 " />
                )}
                <li
                  className="flex items-center cursor-pointer hover:text-[#828282] ease-in-out duration-100 my-2"
                  onClick={() => handleRemoveSongFromPlaylist(id)}
                  onMouseEnter={() => setHover(true)}
                >
                  <MdRemoveCircleOutline />
                  <p className="ml-1 ">Remove from the {list}</p>
                </li>
              </>
            )}

            {/* Create new playlist */}
            {/* {userPlaylists?.length > 0 && ( */}
            <hr className="cursor-pointer border-1 border-neutral-500 " />
            {/* )} */}
            <li
              className="flex items-center cursor-pointer hover:text-[#828282] ease-in-out duration-100 my-2"
              onClick={handleOpenPlaylist}
              onMouseEnter={() => setHover(true)}
            >
              <MdAddCircleOutline />
              <p className="ml-1 ">Create new {list}</p>
            </li>
          </>
        ) : (
          <>
            <li
              className="flex items-center justify-between music-playlist-popup-li"
              onMouseEnter={() => setHover(false)}
              onClick={copyToClipboard}
            >
              Copy Song Link
              <span className="">
                <IoMdShareAlt />
              </span>
            </li>

            {loggedInUser && (
              <li
                className="flex items-center justify-between music-playlist-popup-li"
                // onClick={() => setHover(!hover)}
                onClick={() => setHover(true)}
                // onMouseLeave={() => setHover(false)}
              >
                Add to playlist
                <span className="">
                  <MdArrowForwardIos />
                </span>
              </li>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default MusicPlayListPopUpMobile;
