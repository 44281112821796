import React, { useEffect, useState } from "react";

function Pagination({ totalPages, currentPage, setCurrentPage }: any) {
  // let pagesToShow = 2; // Number of pages to show around the current page
  const [pagesToShow, setPagesToShow] = useState(2);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // Create an event listener to update the innerWidth when the window is resized
  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  // Add the event listener when the component mounts
  useEffect(() => {
    window.addEventListener("resize", handleResize);

    // Remove the event listener when the component unmounts to prevent memory leaks
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    // console.log(windowWidth);
    if (windowWidth < 640) {
      setPagesToShow(1);
    } else {
      setPagesToShow(2);
    }
  }, [windowWidth]);

  // Create an array of page numbers to display
  const pageNumbers = [];

  if (totalPages <= pagesToShow * 2 + 1) {
    // If total pages are less than or equal to pagesToShow * 2 + 1, display all pages
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(i);
    }
  } else if (currentPage <= pagesToShow + 2) {
    // If on one of the first few pages, show the first pagesToShow * 2 + 1 pages
    for (
      let i = 1;
      i <= pagesToShow * 2 + (pagesToShow % 2 === 0 ? 1 : 2);
      i++
    ) {
      pageNumbers.push(i);
    }
    pageNumbers.push("..."); // Add an ellipsis
    pageNumbers.push(totalPages); // Show the last page
  } else if (currentPage >= totalPages - pagesToShow - 1) {
    // If on one of the last few pages, show the last pagesToShow * 2 + 1 pages
    pageNumbers.push(1); // Show the first page
    pageNumbers.push("..."); // Add an ellipsis
    for (let i = totalPages - (pagesToShow * 2 + 1); i <= totalPages; i++) {
      pageNumbers.push(i);
    }
  } else {
    // If in the middle pages, show pages around the current page
    pageNumbers.push(1); // Show the first page
    pageNumbers.push("..."); // Add an ellipsis
    for (
      let i = currentPage - pagesToShow;
      i <= currentPage + pagesToShow;
      i++
    ) {
      pageNumbers.push(i);
    }
    pageNumbers.push("..."); // Add an ellipsis
    pageNumbers.push(totalPages); // Show the last page
  }

  return (
    <div className="">
      {pageNumbers.map((pageNumber, index) => (
        <button
          key={index}
          disabled={pageNumber === "..."}
          onClick={() => {
            if (typeof pageNumber === "number") {
              setCurrentPage(pageNumber);
            }
          }}
          className={`mx-2 w-[30px] h-[30px] rounded-full ${
            pageNumber === currentPage
              ? "bg-red-600 text-white"
              : pageNumber === "..."
              ? "text-red-600 font-bold"
              : "bg-white"
          }`}
        >
          {pageNumber}
        </button>
      ))}
    </div>
  );
}

export default Pagination;
