import { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import SubText from "components/shared/SubText";
import { useSelector } from "react-redux";
import Podcastcard from "pages/podcastPage/shared/Podcastcard";
import { useEffect, useState } from "react";
import PodcastService from "services/podcast/PodcastService";
import { Link } from "react-router-dom";
import { RouteName } from "constant/routeNames";
import PodcastcardSkeleton from "components/skeleton/PodcardSkeleton";

function WelcomePagePodcast() {
  const [podcasts, setPodcasts] = useState<any[]>([]);
  const loggedInUser = useSelector((state: any) => state?.user?.currentUser);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    PodcastService.getLatestPodcasts().then((res) => {
      setIsLoading(false);
      setPodcasts(res.data);
      // console.log(res.data);
    });
  }, []);
  return (
    <>
      {!isLoading && (
        <div className="mb-10">
          {podcasts && (
            <>
              {/* top section */}
              <div className="flex justify-between mt-10 mb-5 w-[95%]">
                <div>
                  <SubText text="Discover Podcasts" className="font-bold" />
                </div>
                <Link to={RouteName.SeeAllNewPodcastRoute}>
                  <p className="font-[500] text-[15px] text-[#F50537] cursor-pointer place-self-end">
                    See all
                  </p>
                </Link>
              </div>
              {/* coursel */}
              <div className="">
                <Swiper
                  slidesPerView={6}
                  breakpoints={{
                    0: {
                      slidesPerView: 1,
                    },
                    370: {
                      slidesPerView: 2,
                    },
                    700: {
                      slidesPerView: 3,
                    },
                    1000: {
                      slidesPerView: 4,
                    },
                    1200: {
                      slidesPerView: 5,
                    },
                    1400: {
                      slidesPerView: 6,
                    },
                    1600: {
                      slidesPerView: 7,
                    },
                  }}
                  modules={[Pagination, Navigation]}
                  navigation={true}
                  className="mt-10 mb-10 mySwiper"
                >
                  {podcasts?.map((podcast: any, i: number) => (
                    <div key={i}>
                      <SwiperSlide className="">
                        <div className="mx-3 max-sm:mx-1">
                          <Podcastcard podcast={podcast} user={loggedInUser} />
                        </div>
                      </SwiperSlide>
                    </div>
                  ))}
                </Swiper>
              </div>
            </>
          )}
        </div>
      )}
      {isLoading && <PodcastcardSkeleton />}
    </>
  );
}

export default WelcomePagePodcast;
