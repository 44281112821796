import { useEffect, useState } from "react";
import UserProfileService from "services/UserProfileService";
import { useSelector } from "react-redux";
import NotificationText from "./NotificationText";
import NotificationSkeleton from "components/skeleton/NotificationSkeleton";
import NoNotification from "./NoNotification";

function NotificationContent() {
  const loggedInUser = useSelector((state: any) => state?.user?.currentUser);
  const [notification, setNotification] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<any>(false);

  useEffect(() => {
    setIsLoading(true);
    if (loggedInUser) {
      UserProfileService.getNotificationByUserId(loggedInUser?.id)
        .then((res) => {
          // console.log(Object.values(res.profile?.notifications));
          setIsLoading(false);
          setNotification(Object.values(res.profile?.notifications));
        })
        .catch((e: any) => {
          // console.log(e);
        });
    }
  }, [loggedInUser]);

  // useEffect(() => {
  //   window.scrollTo({
  //     top: 0,
  //     behavior: "smooth",
  //   });
  // }, []);
  return (
    <div
      className={`md:w-[300px] h-[300px] !overflow-y-scroll custom-scrollbar navbar max-sm:w-[320px] max-sm:top-[20px] max-sm:h-[240px] submenu-main`}
    >
      {!isLoading && (
        <>
          {notification.length === 0 ? (
            <>
              <NoNotification />
            </>
          ) : (
            <>
              {notification &&
                notification.length > 0 &&
                notification
                  .slice()
                  .sort((a: any, b: any) => b.id - a.id)
                  .map((notificationT: any, i: any) => (
                    <div key={i} className="mb-3">
                      <NotificationText
                        notification={notificationT}
                        isLoading={isLoading}
                      />
                    </div>
                  ))}
            </>
          )}
        </>
      )}
      {isLoading && <NotificationSkeleton />}
      {/* <div className="flex justify-center mt-5">
        <button className="text-xs font-normal text-white">
          clear Notifications
        </button>
      </div> */}
    </div>
  );
}

export default NotificationContent;
