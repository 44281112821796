import RadioCard from "pages/radioPage/shared/RadioCard";
import NoFavorite from "./NoFavorite";
import { useEffect, useState } from "react";
import { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";

function RadiosByUser({ favoriteData, user, favorite }: any) {
  const [radios, setRadio] = useState<any>([]);
  const allRadios = favoriteData?.favorite_radio_stations || [];

  useEffect(() => {
    setRadio(allRadios);
  }, []);
  return (
    <div>
      {/* top section */}
      <div className="mb-8 w-[98%] ml-6 mt-8 max-sm:w-full max-sm:mx-0">
        <div>
          <h2 className="text-xl font-medium text-white lg:text-xl md:text-xl">
            {/* Radios by User */}
            Favourite Radios
          </h2>
        </div>
      </div>
      {/* card section */}
      {allRadios.length === 0 && allRadios ? (
        <div className="mb-10">
          <NoFavorite text="Stations" />
        </div>
      ) : (
        // <div>
        //   <div className="mt-4 mb-5  w-[95%] grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 2xl:grid-cols-7 max-sm:mx-0 max-sm:w-full max-sm:gap-3 ">
        //     {radios.length > 0 &&
        //       radios.map((radios: any, i: number) => (
        //         <div key={i}>
        //           <RadioCard station={radios} user={user} favorite={favorite} />
        //         </div>
        //       ))}
        //   </div>
        // </div>

        <>
          {/* coursel */}
          <div className="">
            <Swiper
              slidesPerView={6}
              breakpoints={{
                0: {
                  slidesPerView: 1,
                },
                370: {
                  slidesPerView: 2,
                },
                700: {
                  slidesPerView: 3,
                },
                1000: {
                  slidesPerView: 4,
                },
                1200: {
                  slidesPerView: 5,
                },
                1400: {
                  slidesPerView: 6,
                },
                1600: {
                  slidesPerView: 7,
                },
              }}
              modules={[Pagination, Navigation]}
              navigation={true}
              className="mt-4 mb-10 mySwiper"
            >
              {allRadios.length > 0 &&
                allRadios.map((radios: any, i: number) => (
                  <SwiperSlide className="" key={i}>
                    <RadioCard
                      station={radios}
                      user={user}
                      favorite={favorite}
                    />
                  </SwiperSlide>
                ))}
            </Swiper>
          </div>
        </>
      )}
    </div>
  );
}

export default RadiosByUser;
