/* eslint-disable react-hooks/exhaustive-deps */
import { RouteName } from 'constant/routeNames';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import VideoService from 'services/video/VideoService';

function GenreSelector({ slug }: any) {
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);
  const [selected, setSelected] = useState(slug);
  useEffect(() => {
    VideoService.getAllGenres()
      .then((res) => {
        setCategories(res.genres);
      })
      .catch((e) => {
        // console.log(e);
      });
  }, []);

  useEffect(() => {
    navigate(`${RouteName.VideoDiscoverRoute}/${selected}`);
  }, [selected]);

  const onChange = (e: any) => {
    setSelected(e.target.value);
  };

  return (
    <div>
      <label
        htmlFor="watched"
        className="text-white max-sm:hidden max-md:hidden"
      >
        Genres{' '}
      </label>
      <select
        name="cars"
        id="cars"
        className="p-1 ml-2 text-white rounded-md outline-none searchbar"
        onChange={onChange}
        value={selected}
      >
        {categories?.map((c: any, i) => (
          <option value={c.alt_name} key={i}>
            {c.name}
          </option>
        ))}
      </select>
    </div>
  );
}

export default GenreSelector;
