//import logo
import { Route, Routes } from "react-router-dom";
import FeaturePages from "layout/FeaturePages";
import BecomeACreatorHome from "./BecomeACreator/BecomeACreatorHome";
import { RouteName } from "constant/routeNames";

import VideoCreator from "./BecomeACreator/VideoCreator";

function BecomeACreator({
  handleClick,
  isActive,
  setIsActive,
  anyPlayerActive,
}: any) {
  // let navigate = useNavigate();
  // const dispatch = useDispatch<any>();

  // const [creatorModel, setCreatorModel] = useState(false);

  // const clickLogin = () => {
  //   console.log("login");
  //   setCreatorModel((current) => !current);
  // };

  return (
    <div className={`${anyPlayerActive ? `mb-[140px]` : `mb-30`}`}>
      <Routes>
        <Route
          path={RouteName.BecomeACreatorHome}
          element={
            <FeaturePages
              Content={BecomeACreatorHome}
              handleClick={handleClick}
              isActive={isActive}
              setIsActive={setIsActive}
              title={""}
            />
          }
        />
        <Route
          path={RouteName.VideoCreator}
          element={
            <FeaturePages
              Content={VideoCreator}
              handleClick={handleClick}
              isActive={isActive}
              setIsActive={setIsActive}
              title={""}
            />
          }
        />
      </Routes>
    </div>
  );
}

export default BecomeACreator;
