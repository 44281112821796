import SubText from "components/shared/SubText";
import PlayListCardCoursel from "./home/PlayListCardCoursel";
import MusicGenreSlider from "./shared/MusicGenreSlider";
import MusicDiscoverFeature from "./shared/MusicDiscoverFeature";

function MusicDiscoverPage() {
  const feature = [
    {
      headText: "Most played this week",
      title: "Keep Ya Head Up",
      artist: "Strictly 4 My N.I.G.G.A.Z.",
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRDl9d128uXIUfVMvJSRtsJKhHkNOwYixx4E-iABrl247EvVtD0dlRokr1NJZxz5ejMVZk&usqp=CAU",
    },
    {
      headText: "Newst Song",
      title: "Whole Lotta Love",
      artist: "Led Zeppelin",
      image:
        "https://i0.wp.com/auralcrave.com/wp-content/uploads/2020/07/nr006152-6_1.jpg?fit=1200%2C675&ssl=1",
    },
    {
      headText: "Most played this month",
      title: "Scream and Shout",
      artist: "Will.I.Am & Brittney Spears",
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRgGvcOeS0XUTbQzmb2mZ0n5TD0_ibt1vhruA&usqp=CAU",
    },
  ];

  return (
    <div className="mb-56 max-sm:mx-2">
      <h1 className="text-2xl font-medium text-white lg:text-3xl md:text-3xl">
        Discover your music
      </h1>

      <div className="flex justify-between max-sm:block">
        {feature.map((value, i) => (
          <MusicDiscoverFeature {...value} key={i} />
        ))}
      </div>

      <div className="flex flex-row items-center justify-between mt-5">
        <SubText text="Trending Playlist Today" className="font-light" />
        <p className="font-[500] text-[15px] text-[#F50537] cursor-pointer">
          see all
        </p>
      </div>

      <PlayListCardCoursel />

      <div className="flex flex-row items-center justify-between mt-5">
        <SubText text="Music By genre" className="font-light" />
        <p className="font-[500] text-[15px] text-[#F50537] cursor-pointer">
          see all
        </p>
      </div>

      <MusicGenreSlider />
    </div>
  );
}

export default MusicDiscoverPage;
