import { useEffect, useRef, useState } from "react";
import ContentSideBar from "./ContentSideBar";
import { useParams } from "react-router-dom";
import PayperviewService from "services/payperview/PayperviewServie";

function PPVideoPlayerLeftSidePanel({
  isActive,
  videoDetails,
  openModal,
}: any) {
  const { slug } = useParams();
  const [event, setEvent] = useState<any>([]);
  const [selectedTab, setSelectedTab] = useState("Info");

  const scrollableRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (scrollableRef.current) {
      scrollableRef.current.scrollTop = 0; // Reset scroll position
    }
  }, [selectedTab]);

  useEffect(() => {
    if (slug) {
      PayperviewService.getEventByEventSlug(slug)
        .then((res) => {
          console.log("event", res);
          setEvent(res.data);
        })
        .catch((e: any) => {
          console.log(e);
        });
    }
  }, []);

  return (
    <>
      <div
        className={`sidebar !h-[150vh] max-sm:!h-auto overflow-y-hidden  max-sm:relative -mt-[88px] duration-500 fixed max-sm:mt-0 max-md:mt-0 max-sm:w-full ${
          isActive
            ? "left-[-8px] md:w-[600px]  "
            : "md:left-[-100px] left-[-60px] md:w-[100px] "
        }`}
        style={{
          backdropFilter: isActive ? "blur(10px)" : "none",
        }}
      >
        {/* icon bar */}
        <div className="items-center bg-gradient-to-r from-black/100 text-gray-100 pt-32 pl-[80px] px-5 text-left !h-[150vh] max-sm:!h-auto justify-center max-sm:pt-0 max-sm:bg-none max-sm:mt-[-50px] max-md:bg-none max-md:pt-0 max-md:mt-[-50px] max-sm:w-full max-md:w-full">
          <div
            className="w-full pb-10 my-14 scrollbar-hide max-md:my-3 max-md:pb-0"
            ref={scrollableRef}
          >
            <ContentSideBar event={event} />
          </div>
        </div>
      </div>
    </>
  );
}

export default PPVideoPlayerLeftSidePanel;
