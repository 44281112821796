import React, { useState } from "react";

function ContentType({ setContentType, contentType }: any) {
  const onChange = (e: any) => {
    setContentType(e.target.value);
  };

  return (
    <div>
      <label
        htmlFor="watched"
        className="text-white max-sm:hidden max-md:hidden"
      >
        Content Type{" "}
      </label>
      <select
        name="content"
        id="content"
        className="p-1 ml-2 text-white rounded-md outline-none searchbar"
        onChange={onChange}
        value={contentType}
      >
        <option>Movies</option>
        <option>Tv Series</option>
      </select>
    </div>
  );
}

export default ContentType;
