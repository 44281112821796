import React, { useEffect, useState } from "react";
import { HiArrowLeft } from "react-icons/hi";
import PaymentService from "services/PaymentService";
import { useLocation, useNavigate } from "react-router-dom";
import PpvCheckoutImage from "components/skeleton/PpvCheckoutImage";
import { Helmet } from "react-helmet-async";
import PayPalCheckoutForm from "./PayPalCheckoutForm";

const PayPerViewPaypalCheckout = ({ setIsActive }: any) => {
  const location = useLocation();
  const navigate = useNavigate();
  const ppv_event = location.state.ppv_event;
  const [isLoading, setIsLoading] = useState<any>(false);

  const finalServiceFee =
    parseFloat(ppv_event?.price) * (parseFloat(ppv_event?.service_fee) / 100);

  const finalDiscount =
    parseFloat(ppv_event?.price) * (parseFloat(ppv_event?.discount) / 100);

  const totalFee =
    parseFloat(ppv_event?.price) + finalServiceFee - finalDiscount;

  return (
    <>
      <Helmet>
        <title>
          Check Out -{" "}
          {ppv_event?.title ? `${ppv_event?.title}` : "Ayozat Payperview"}
        </title>
        <meta name="description" content={ppv_event?.meta_description} />

        <meta name="keywords" content={ppv_event?.focus_keyword} />
        <meta property="og:title" content={ppv_event?.title} />
        <meta property="og:description" content={ppv_event?.meta_description} />

        <meta property="og:image" content={ppv_event?.bannerImage} />
        <meta property="og:type" content="website" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={ppv_event?.title} />
        <meta
          name="twitter:description"
          content={ppv_event?.meta_description}
        />
        <meta name="twitter:image" content={ppv_event?.bannerImage} />
        <meta name="twitter:site" content="AYOZAT Payperview" />
      </Helmet>
      <div className="flex !items-center justify-center h-[90vh] mb-40">
        <div className="w-10/12 h-auto px-8 py-10 mx-auto bg-gray-900 rounded max-sm:w-full max-lg:h-[90vh] max-lg:w-[97%] max-lg:m-0 max-sm:h-auto max-sm:pt-4">
          <button
            className="flex items-center justify-center w-10 h-10 text-2xl text-gray-500 bg-white rounded-full cursor-pointer top-6 z-100 left-6 hover:text-gray-700 mb-7 "
            onClick={() => {
              navigate(-1);
            }}
          >
            <HiArrowLeft className="text-black" />
          </button>

          <div className="grid grid-cols-2 gap-10 max-sm:grid-cols-1 max-lg:grid-cols-1">
            <div>
              <h1 className="text-[21px] font-bold text-gray-400">
                {ppv_event?.title}
              </h1>
              <div>
                <h1 className="text-[27px] font-bold text-gray-300">
                  £{totalFee.toFixed(2)}
                </h1>
              </div>
              <div className="mt-4">
                <img
                  src={ppv_event?.bannerImage}
                  alt={ppv_event?.title}
                  className="object-cover w-11/12 mt-4 rounded-lg max-lg:w-full"
                />
              </div>
            </div>

            {!isLoading && (
              <div className="bg-gray-200 p-3 rounded-md">
                <PayPalCheckoutForm ppv_event={ppv_event} />
              </div>
            )}
            {isLoading && <PpvCheckoutImage />}
          </div>
        </div>
      </div>
    </>
  );
};

export default PayPerViewPaypalCheckout;
