import React, { useEffect, useState, useCallback } from "react";
import CreatorService from "services/creators/CreatorService";
import BackButton from "components/shared/BackButton";
import BackAndTextSkeleton from "components/skeleton/BackAndTextSkeleton";
import VideoListCreator from "./shared/VideoListCreator";
import CreatorAllVideosSkeleton from "components/skeleton/CreatorAllVideosSkeleton";

function SeeAllCreatorsVideos() {
  const [videos, setVideos] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState<number>(1);

  const fetchVideos = useCallback((page: number) => {
    setIsLoading(true);
    CreatorService.getCreatorsAllVideo(page)
      .then((res: any) => {
        setIsLoading(false);
        setLastPage(res.data.last_page);
        if (page === 1) {
          setVideos(res.data.data);
        } else {
          setVideos((prevVideos: any) => {
            return [...prevVideos, ...res.data.data];
          });
        }
      })
      .catch((err: any) => {
        console.log(err);
        setIsLoading(false);
      });
  }, []);

  const handleScroll = useCallback(() => {
    const windowHeight =
      "innerHeight" in window
        ? window.innerHeight
        : document.documentElement.offsetHeight;
    const body = document.body;
    const html = document.documentElement;
    const docHeight = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    );
    const windowBottom = windowHeight + window.scrollY;
    if (
      windowBottom + document.documentElement.scrollTop <= docHeight ||
      isLoading
    ) {
      return;
    }
    if (currentPage < lastPage) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  }, [isLoading, currentPage, lastPage]);

  // Reset currentPage when the component mounts or reloads
  useEffect(() => {
    setCurrentPage(1);
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  useEffect(() => {
    fetchVideos(currentPage);
  }, [currentPage, fetchVideos]);

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchVideos(1);
  }, []);

  return (
    <>
      <div className="mt-5 ">
        {!isLoading && (
          <>
            <div className="ml-3">
              <BackButton />
              <div className="mb-8">
                <h1 className="text-2xl font-medium text-white lg:text-2xl md:text-2xl max-sm:w-[60%] max-sm:ml-2">
                  All Creator Videos
                </h1>
              </div>
            </div>
          </>
        )}
        {isLoading && <BackAndTextSkeleton />}
        {/* ... other UI components ... */}

        <>
          <div className="video-grid">
            <div className="mt-4 mb-5 w-[95%] grid grid-cols-5 gap-10 max-sm:grid-cols-1 max-md:grid-cols-2 max-lg:grid-cols-2 max-xl:grid-cols-4 max-2xl:grid-cols-4 max-[1700px]:grid-cols-4 max-sm:mx-auto max-sm:w-full max-sm:gap-4">
              <VideoListCreator videos={videos} />
            </div>
          </div>
        </>
        {isLoading && <CreatorAllVideosSkeleton />}
      </div>
    </>
  );
}

export default SeeAllCreatorsVideos;
