import React from "react";
import { BiLockAlt } from "react-icons/bi";

function PrivateAccount() {
  return (
    <div className="flex items-center mt-4 max-sm:mt-0">
      <div>
        <BiLockAlt className="w-5 h-5 mr-2 text-white" />
      </div>
      <p className="text-lg font-bold text-white max-sm:text-sm">
        This is a Private Account
      </p>
    </div>
  );
}

export default PrivateAccount;
