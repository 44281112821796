function SubText({ text, className }: any) {
  return (
    <p
      className={`font-bold text-white md:text-xl text-md max-sm:ml-1 max-sm:text-lg ${
        className && className
      }`}
    >
      {text}
    </p>
  );
}

export default SubText;
