import { useEffect, useState } from 'react';
import AntDInputField from './AntDInputField';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useForm } from 'antd/lib/form/Form';
// import IEditProfile from "types/IEditProfile";
import { Form } from 'antd';
import UserProfileService from 'services/UserProfileService';
import { updatePrfile } from 'redux/userRedux';
import LoadingSpin from 'components/Loading spins/LoadingSpin';

const onFinishFailed = (errorInfo: any) => {
  // console.log("Failed:", errorInfo);
  toast.error(errorInfo.errorFields[0].errors[0]);
};

const options: any[] | undefined = [];
for (let i = 10; i < 36; i++) {
  options.push({
    value: i.toString(36) + i,
    label: i.toString(36) + i,
  });
}
// const onChange = (checked: boolean) => {
//   console.log(`switch to ${checked}`);
// };

function ChangeUserName() {
  const dispatch = useDispatch<any>();
  const loggedInUser = useSelector((state: any) => state?.user?.currentUser);
  const [form] = useForm<any>();
  // const [password, setPassword] = useState<String>("");
  const [loadingSpin, setLoadingSpin] = useState<boolean>(false);
  const [, setLoading] = useState<boolean>(false);

  const onFinish = async (value: any) => {
    setLoadingSpin(true);
    const finalObj: any = {
      ...value,
      // password: password,
    };
    const formDataObj = new FormData();

    for (const key in finalObj) {
      formDataObj.append(key, finalObj[key]);
    }
    // console.log(finalObj);
    await UserProfileService.editUserName(formDataObj)

      .then((response: any) => {
        setLoadingSpin(false);
        if (response.status === 200) {
          toast.success('Username Updated');

          dispatch(updatePrfile(response.data.data));
        } else {
          toast.error('There is an issue when updating username');
        }
      })
      .catch((error) => {
        setLoadingSpin(false);
        if (error.response && error.response.status === 422) {
          const errorData = error.response.data.errors.new_username[0];
          setErrors(errorData);
          toast.warning(errorData);
          // console.log("first", errors);
        } else {
          // Handle other types of errors (e.g., server errors)
          toast.error('An error occurred. Please try again.');
        }
      });
  };

  const [errors, setErrors] = useState({
    username: [],
  });

  useEffect(() => {
    setLoading(true);
    const initialValue = {
      new_username: loggedInUser?.username,
    };
    form.setFieldsValue(initialValue);
    setLoading(false);
  }, [loggedInUser, form]);

  return (
    <div className="ml-[20px] mx-auto h-screen w-full rounded-md max-sm:w-full max-md:w-full max-lg:w-full max-sm:px-3 max-sm:mx-0 ">
      <Form
        form={form}
        onFinishFailed={onFinishFailed}
        onFinish={onFinish}
        autoComplete="on"
      >
        <div className="w-full">
          <div className="text-white text-[25px] mt-20 font-bold my-3">
            <h1>Change Username</h1>
          </div>
        </div>
        <div className="items-center w-full">
          <div className="mt-8 mr-5 flex-3 grow">
            <span className="text-[15px] text-white my-4">Username</span>
            <AntDInputField
              name="new_username"
              rules={[
                {
                  required: true,
                  message: 'Please enter your username!',
                },
              ]}
            />
            {errors.username?.length > 0 && (
              <div className="mb-5 text-left text-red-400">
                <span className="error">{errors.username[0]}</span>
              </div>
            )}
          </div>
          <div className="mt-8 mr-5 flex-3 grow">
            <span className="text-[15px] text-white my-4">Password</span>
            <AntDInputField
              name="current_password"
              placeholder="Enter your password"
              type="password"
              // onChange={(event: any) => setPassword(event.target.value)}
              rules={[
                {
                  required: true,
                  message: 'Please enter your password!',
                },
              ]}
            />
          </div>
          {/* <div>
          <BsCheckCircleFill className="w-8 h-8 text-green-600 mt-9" />/
        </div> */}
        </div>
        <Form.Item wrapperCol={{ offset: 0, span: 10 }}>
          <button
            className="px-4 py-2 mt-8 text-white min-w-[130px] rounded-full button-red max-sm:w-full"
            type="submit"
          >
            {loadingSpin ? (
              <LoadingSpin fontSize={20} color={'white'} />
            ) : (
              `Save Changes`
            )}
          </button>
        </Form.Item>
      </Form>
    </div>
  );
}

export default ChangeUserName;
