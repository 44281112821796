import React from "react";

function DescriptionSkeletion() {
  const arr = [1, 2, 3];

  return (
    <div>
      <div className="flex flex-col">
        {/* back button */}
        <div className="w-[50px] h-[50px] rounded-full bg-[#1a1919] animate-pulse"></div>
        {/* details */}
        <div className="flex flex-row gap-2 mt-[10px] gap-x-[30px]">
          <div className="w-[300px] h-[300px] bg-[#1a1919] animate-pulse"></div>
          <div className="flex flex-col gap-2 mt-[80px]">
            <div className="w-[400px] h-[60px] bg-[#1a1919] animate-pulse"></div>
            <div className="w-[100px] h-[20px] bg-[#1a1919] animate-pulse"></div>
            <div className="w-[150px] h-[30px] bg-[#1a1919] animate-pulse"></div>
          </div>
        </div>
        {/* play buttn */}
        <div className="flex flex-row gap-[40px] mt-[40px]">
          <div className="w-[200px] h-[50px] rounded-[30px] bg-[#1a1919] animate-pulse"></div>
          {/* <div className='w-[50px] h-[50px] rounded-full bg-[#1a1919] animate-pulse'></div> */}
          <div className="w-[50px] h-[50px]  bg-[#1a1919] animate-pulse"></div>
        </div>
        {/* hr */}
        <div className="w-full h-1 bg-[#1a1919] animate-pulse mt-[25px]"></div>

        {/* below hr */}
        <div className="flex justify-between w-full max-sm:block max-sm:pl-4">
          {/* description */}
          <div className="items-start w-1/2 sm:px-4 max-sm:w-full max-sm:mb-5">
            <div className="w-[80%] max-sm:w-full h-5 bg-[#1a1919] animate-pulse mt-[25px]"></div>
            <div className="w-[80%] max-sm:w-full h-5 bg-[#1a1919] animate-pulse mt-[5px]"></div>
            <div className="w-[80%] max-sm:w-full h-5 bg-[#1a1919] animate-pulse mt-[5px]"></div>
            <div className="w-[80%] max-sm:w-full h-5 bg-[#1a1919] animate-pulse mt-[5px]"></div>
          </div>
          {/* you might like */}
          <div className="w-1/2 max-sm:w-full max-sm:mt-5">
            <div className="w-[40%] max-sm:w-[80%] h-7 bg-[#1a1919] animate-pulse my-[25px]"></div>

            <div className={`grid grid-cols-3 gap-2 max-sm:grid-cols-2`}>
              {arr.map((item: any) => (
                <div
                  key={item}
                  className="w-[180px] h-[250px] bg-[#1a1919] animate-pulse"
                ></div>
              ))}
            </div>
          </div>
        </div>

        {/* <div className="flex flex-col gap-2 mt-[20px]">
          <div className="w-[400px] h-[60px] bg-[#1a1919] animate-pulse"></div>
        </div>
        <div className="mt-[40px]">
          <div className="w-[400px] h-[30px] bg-[#1a1919] animate-pulse"></div>
          <div className="grid mt-5 lg:grid-cols-6 max-md:grid-cols-3 max-sm:grid-cols-1">
            {arr.map((item: any) => (
              <div className="flex flex-row lg:gap-[60px] sm:gap-[20px] gap-3 mt-10 key={item}">
                {arr.map((item: any) => (
                  <div className="flex flex-col items-start justify-center">
                    <div className="lg:w-[160px] h-[160px] bg-[#1a1919] animate-pulse mt-2"></div>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default DescriptionSkeletion;
