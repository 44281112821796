import MovieCarouselCard from "pages/videoPage/shared/MovieCarouselCard";
import React, { useEffect, useState } from "react";
import NoFavorite from "./NoFavorite";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";

function TvSeriesByUser({ user, favorite, favoriteData }: any) {
  const [tvSeries, setTvSeries] = useState<any>([]);
  const allTvSeries = favoriteData?.favorite_tv_series || [];

  useEffect(() => {
    setTvSeries(allTvSeries);
  }, []);
  return (
    <div>
      {/* top section */}
      <div className="mb-8 w-[98%] ml-6 mt-8 max-sm:w-full max-sm:mx-0">
        <div>
          <h2 className="text-xl font-medium text-white lg:text-xl md:text-xl">
            {/* Videos by User */}
            Favourite TV Series
          </h2>
        </div>
      </div>
      {/* card section */}
      {allTvSeries?.length === 0 && allTvSeries ? (
        <NoFavorite text="TV Series" />
      ) : (
        // <div>
        //   <div className="mt-4 mb-5 w-[95%] grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 2xl:grid-cols-7 max-sm:mx-auto max-sm:w-full max-sm:gap-4">
        //     {tvSeries.length > 0 &&
        //       tvSeries.map((card: any, i: any) => (
        //         <div key={i}>
        //           <div className="mx-3 my-1 max-sm:mx-0">
        //             <MovieCarouselCard
        //               id={card.id}
        //               // time={card.runtime}
        //               title={card.title}
        //               // info={card.description}
        //               // year={card.release}
        //               // type={card.video_type}
        //               img={card.thumbnail_url}
        //               seasons={card.no_of_seasons}
        //               slug={card.slug}
        //               tv={true}
        //               user={user}
        //               tvSeries={card}
        //               favoriteTvSeries={favorite}
        //             />
        //           </div>
        //         </div>
        //       ))}
        //   </div>
        // </div>
        <div className="">
          <Swiper
            slidesPerView={7}
            breakpoints={{
              0: {
                slidesPerView: 1,
              },
              380: {
                slidesPerView: 2,
              },
              700: {
                slidesPerView: 3,
              },
              1000: {
                slidesPerView: 4,
              },
              1200: {
                slidesPerView: 5,
              },
              1400: {
                slidesPerView: 6,
              },
              1600: {
                slidesPerView: 7,
              },
            }}
            modules={[Pagination, Navigation]}
            navigation={true}
            className="mt-8 mb-10 mySwiper "
          >
            {allTvSeries?.length > 0 &&
              allTvSeries?.map((card: any, i: any) => (
                <SwiperSlide key={i}>
                  <div className="mx-3 max-sm:mx-1">
                    <MovieCarouselCard
                      id={card?.id}
                      // time={card.runtime}
                      title={card?.title}
                      // info={card.description}
                      // year={card.release}
                      // type={card.video_type}
                      img={card?.thumbnail_url}
                      thumbnail_url={card?.thumbnail}
                      seasons={card?.no_of_seasons}
                      slug={card?.slug}
                      tv={true}
                      user={user}
                      tvSeries={card}
                      favoriteTvSeries={favorite}
                      first_episode={card?.first_episode}
                    />
                  </div>
                </SwiperSlide>
              ))}
          </Swiper>
        </div>
      )}
    </div>
  );
}

export default TvSeriesByUser;
