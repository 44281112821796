import axios from "axios";
import authHeader from "utils/auth-header";

const baseURL = process.env.REACT_APP_BACKEND_SERVER;

const getLatestMusic = async () => {
  const response = await axios({
    method: "get",
    url: `${baseURL}/latest/songs`,
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
  });
  // alert("Favourite created --- "+ response);
  return response.data;
};

const getMostPlayed = async () => {
  const response = await axios({
    method: "get",
    url: `${baseURL}/most-played/songs`,
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
  });
  // alert("Favourite created --- "+ response);
  return response.data;
};
const getRandomSongs = async () => {
  const response = await axios({
    method: "get",
    url: `${baseURL}/random/songs`,
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
  });
  // alert("Favourite created --- "+ response);
  return response.data;
};

const getSongsByGenre = async (slug: any) => {
  const response = await axios({
    method: "get",
    url: `${baseURL}/genre/${slug}/songs`,
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
  });
  // alert("Favourite created --- "+ response);
  return response.data;
};

const trackSongPlay = async (songId: number) => {
  const data = {
    id: songId,
    type: "song",
  };
  const response = await axios({
    method: "post",
    url: `${baseURL}/song/on-track-played`,
    data,
    headers: authHeader(),
  });

  return response.data;
};

const getTrackDataByID = async (id: any) => {
  const response = await axios({
    method: "get",
    url: `${baseURL}/song/${id}`,
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
  });

  return response.data;
};

const MusicService = {
  getLatestMusic,
  getMostPlayed,
  getRandomSongs,
  getSongsByGenre,
  trackSongPlay,
  getTrackDataByID,
};

export default MusicService;
