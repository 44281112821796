import axios from "axios";
import authHeader from "utils/auth-header";

axios.defaults.baseURL = process.env.REACT_APP_BACKEND_SERVER;
// const VIUZAT_URL = process.env.REACT_APP_VIUZAT_SERVER;
const BASE_URL = process.env.REACT_APP_BACKEND_SERVER;
const getPodcastById = async (id: any) => {
  const response = await axios({
    method: "get",
    url: `${BASE_URL}/podcast/${id}`,
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
  });
  return response.data;
};

const getAllCategories = async () => {
  const response = await axios({
    method: "get",
    url: `${BASE_URL}/podcast-categories`,
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
  });
  return response.data;
};

const getLatestPodcasts = async () => {
  const response = await axios({
    method: "get",
    url: `${BASE_URL}/latest-podscasts`,
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
  });
  return response.data;
};
const getPodcastByGenreSlug = async (slug: any) => {
  const response = await axios({
    method: "get",
    url: `${BASE_URL}/podcasts/category/${slug}`,
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
  });
  return response.data;
};

const getAllPodcastsWithSlidesAndChannels = async () => {
  const response = await axios({
    method: "get",
    url: `${BASE_URL}/podcasts`,
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
  });
  return response.data;
};

const getAllPodcasts = async (pageNo:any) => {
  const response = await axios({
    method: "get",
    url: `${BASE_URL}/all-spodcasts?page=${pageNo}`,
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
  });
  return response.data;
};

const likePodcast = async (data: any) => {
  const response = await axios({
    method: "post",
    data: data,
    url: `${BASE_URL}/podcast/like`,
    headers: authHeader(),
  });

  return response.data;
};

const unlikePodcast = async (data: any) => {
  const response = await axios({
    method: "post",
    data: data,
    url: `${BASE_URL}/podcast/unlike`,
    headers: authHeader(),
  });

  return response.data;
};

const getEpisodesByPageNumber = async (podcastId: any,pageNo:any) => {
  const response = await axios({
    method: "get",
    url: `${BASE_URL}/podcast/${podcastId}?page=${pageNo}`,
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
  });

  return response.data;
};

const PodcastService = {
  getPodcastById,
  getAllCategories,
  getLatestPodcasts,
  getPodcastByGenreSlug,
  getAllPodcastsWithSlidesAndChannels,
  getAllPodcasts,
  likePodcast,
  unlikePodcast,
  getEpisodesByPageNumber,
};

export default PodcastService;
