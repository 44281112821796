import axios from "axios";
import authHeader from "utils/auth-header";

const baseURL = process.env.REACT_APP_BACKEND_SERVER;

const startPayment = async (data: any) => {
  try {
    const response = await axios({
      method: "post",
      data: data,
      url: `${baseURL}/payment/process`,
      headers: authHeader(),
    });
    return response.data;
  } catch (error) {
    // Handle the error here
    console.error("startPayment - Error occurred during payment:", error);
    throw error; // You can rethrow the error or handle it as needed
  }
};

const getPaymentIntense = async (data: any) => {
  try {
    const response = await axios({
      method: "post",
      data: data,
      url: `${baseURL}/payment/intents`,
      headers: authHeader(),
    });
    return response.data;
  } catch (error) {
    // Handle the error here
    console.error(
      "getPaymentIntense - Error occurred during the payment intense:",
      error
    );
    throw error; // You can rethrow the error or handle it as needed
  }
};

const paypalPaymentIntense = async (
  ppv_event_id: any,
  transactionData: any
) => {
  try {
    const response = await axios({
      method: "post",
      data: {
        ppv_event_id,
        transactionData,
      },
      url: `${baseURL}/paypal/intents`,
      headers: authHeader(),
    });
    return response.data;
  } catch (error) {
    // Handle the error here
    console.error(
      "getPaymentIntense - Error occurred during the payment intense:",
      error
    );
    throw error; // You can rethrow the error or handle it as needed
  }
};

const getEventByIntense = async (intent_id: any) => {
  try {
    const response = await axios({
      method: "get",
      url: `${baseURL}/ppv-event/payment/${intent_id}`,
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
    });
    return response.data;
  } catch (error) {
    // Handle the error here
    console.error(
      "getPaymentIntense - Error occurred during the payment intense:",
      error
    );
    throw error; // You can rethrow the error or handle it as needed
  }
};

const PaymentService = {
  startPayment,
  getPaymentIntense,
  getEventByIntense,
  paypalPaymentIntense,
};

export default PaymentService;
