import MusicTable from "pages/musicPage/shared/MusicTable";
import { useEffect, useState } from "react";
import NoFavorite from "./NoFavorite";

function MusicByUser({
  favoriteData,
  user,
  favorite,
  userPlaylists,
  setUserPlaylists,
}: any) {
  const [songs, setSongs] = useState<any>([]);
  const allSongs = favoriteData?.loved.data || [];

  useEffect(() => {
    setSongs(allSongs);
  }, []);
  return (
    <div>
      {/* top section */}
      <div className="mb-8 w-[98%] ml-6 mt-8  max-sm:mx-0">
        <div>
          <h2 className="text-xl font-medium text-white lg:text-xl md:text-xl">
            {/* Music by User */}
            Favourite Music
          </h2>
        </div>
      </div>
      {/* card section */}
      {/* <div>
        <div className="mt-4 ml-6 mb-5 w-[95%] grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 2xl:grid-cols-7 max-sm:mx-0 max-sm:w-full max-sm:gap-3">
          {audios.map((audio: any, i: number) => (
            <div key={i}>
              <UserMusicCard song={audio} user={user} />
            </div>
          ))}
        </div>
      </div> */}
      {/* <div>
        <UserTrackList
          songs={songs}
          user={user}
          favorite={favorite}
          userPlaylists={userPlaylists}
          setUserPlaylists={setUserPlaylists}
        />
      </div> */}
      {songs.length === 0 && songs ? (
        <div>
          <NoFavorite text="Songs" user={user} />
        </div>
      ) : (
        <div className="pr-3 my-5">
          <MusicTable
            songs={songs}
            userPlaylists={userPlaylists}
            setUserPlaylists={setUserPlaylists}
            favorite={favorite}
            user={user}
          />
        </div>
      )}
    </div>
  );
}

export default MusicByUser;
