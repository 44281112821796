import ActorImage from "components/ActorImage";
import React from "react";

function SourceInfo({ videoDetails, loading }: any) {
  return (
    <>
      {/* booster icon */}
      {/* <div className="z-10  flex rounded-full items-center bottom-8 bg-white w-[170px] ml-1 mb-1 cursor-pointer scale-[1.1] my-2">
<button className="p-1 bg-yellow-400 rounded-full cursor-pointer">
  <HiLightningBolt className="text-4xl " />
</button>
<div className="items-center ml-2">
  <p className="text-xs leading-none text-black cursor-pointer">
    Uploaded By
  </p>
  <p className="text-lg font-bold text-black cursor-pointer">
    Booster
  </p>
</div>
</div> */}
      <div className="overflow-y-scroll scrollbar-hide ">
        {!loading && videoDetails ? (
          <>
            <h1 className="text-[30px] font-[700]">{videoDetails.title}</h1>
            <div
              dangerouslySetInnerHTML={{ __html: videoDetails.description }}
              className="my-3"
            ></div>
            {videoDetails?.actors?.length > 0 && (
              <p className="my-2 text-base">Cast</p>
            )}

            <div className="grid grid-cols-3 gap-y-2">
              {videoDetails?.actors?.map((a: any, i: number) => (
                <ActorImage actor={a} key={i} />
              ))}
            </div>

            {videoDetails?.directors?.length > 0 && (
              <p className="my-2 text-base">Director</p>
            )}

            <div className="grid grid-cols-3 gap-y-2">
              {videoDetails?.directors?.map((a: any, i: number) => (
                <ActorImage actor={a} key={i} />
              ))}
            </div>

            {videoDetails?.producers?.length > 0 && (
              <p className="my-2 text-base">Producers</p>
            )}

            <div className="grid grid-cols-3 gap-y-2">
              {videoDetails?.producers?.map((a: any, i: number) => (
                <ActorImage actor={a} key={i} />
              ))}
            </div>
          </>
        ) : (
          <>
            <div className="w-[400px]  h-[30px] bg-[#1a1919] animate-pulse max-sm:w-full"></div>
            <div className="my-3">
              {Array.from({ length: 5 }, (_, i) => (
                <div
                  className="w-[400px]  h-[15px] mt-1 bg-[#1a1919] animate-pulse "
                  key={i}
                ></div>
              ))}
            </div>
            {Array.from({ length: 3 }, (_, i) => (
              <div className="my-3" key={i}>
                <div className="w-[200px] h-[20px] mt-4 bg-[#1a1919] animate-pulse "></div>
                <div className="grid grid-cols-3">
                  <div className="w-[100px] h-[150px] md:w-[150px] md:h-[200px] mt-4 bg-[#1a1919] animate-pulse"></div>
                  <div className="md:w-[150px] w-[100px] h-[150px]  md:h-[200px] mt-4 bg-[#1a1919] animate-pulse"></div>
                  <div className="md:w-[150px] md:h-[200px] mt-4 w-[100px] h-[150px]  bg-[#1a1919] animate-pulse"></div>
                </div>
              </div>
            ))}
          </>
        )}
      </div>
    </>
  );
}

export default SourceInfo;
