import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import UserProfileService from 'services/UserProfileService';
import UserProfileHeroSection from './shared/UserProfileHeroSection';
import UserProfileButtonSection from './shared/UserProfileButtonSection';
import { RouteName } from 'constant/routeNames';

function AnotherUserProfile() {
  const { id } = useParams();
  // const [id, setId] = useState<any>();
  const navigate = useNavigate();
  const [user, setUser] = useState<any>();

  useEffect(() => {
    UserProfileService.getUserById(id).then((res) => {
      // console.log("AAA>>", res.profile);
      setUser(res.profile);
      // setId(user.id);
    });
  }, [id]);

  // useEffect(() => {
  //   navigate(
  //     RouteName.UserProfile.replace(
  //       "/*",
  //       RouteName.OtherUserProfileRoute.replace(":id", id)
  //     )
  //   );
  // }, [id]);

  const userDetails = [
    {
      id: 1,
      name: 'Flix Magen',
      username: 'flix2023',
      bio: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
      // img: Artist,
      followers: '273 followers',
      followings: '273 followings',
    },
  ];
  return (
    <div>
      <div>
        {/* {userDetails &&
            userDetails.map((user: any, i: number) => ( */}
        {/* <div key={i}> */}
        <UserProfileHeroSection user={user} />
        {/* </div> */}
        {/* ))} */}
      </div>
      <UserProfileButtonSection />
    </div>
  );
}

export default AnotherUserProfile;
