import { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import { Navigation, Pagination } from "swiper";

import SubText from "components/shared/SubText";
import { RouteName } from "constant/routeNames";
import MovieCarouselCard from "pages/videoPage/shared/MovieCarouselCard";
import { Link } from "react-router-dom";
import VideoService from "services/video/VideoService";
import "swiper/css";
import "swiper/css/pagination";
import TrendMovieSkeletion from "../../components/skeleton/TrendMovieSkeletion";
function TrendMovies({ favorite }: any) {
  const [videos, setVideos] = useState<any>([]);

  const [isLoading, setIsLoading] = useState<any>(false);

  useEffect(() => {
    setIsLoading(true);
    VideoService.getRandomMovies()
      .then((res: any) => {
        setIsLoading(false);
        setVideos(res.data);
      })
      .catch((e) => {
        setIsLoading(false);
        // console.log(e);
      });
  }, []);

  return (
    <div>
      {!isLoading && (
        <div className="flex justify-between items-center w-[95%]">
          <SubText text="Discover Movies" className="font-bold" />
          <Link to={RouteName.SeeAllMoviesRoute}>
            <p className="text-sm font-bold cursor-pointer red-text place-self-end">
              {""} See all
            </p>
          </Link>
        </div>
      )}

      {!isLoading && (
        <Swiper
          slidesPerView={6}
          breakpoints={{
            0: {
              slidesPerView: 1,
            },
            370: {
              slidesPerView: 2,
            },
            700: {
              slidesPerView: 3,
            },
            1000: {
              slidesPerView: 4,
            },
            1200: {
              slidesPerView: 5,
            },
            1400: {
              slidesPerView: 6,
            },
            1600: {
              slidesPerView: 7,
            },
          }}
          modules={[Pagination, Navigation]}
          navigation={true}
          className="mt-10 mb-10 mySwiper"
        >
          {videos?.map((card: any, i: number) => (
            <SwiperSlide key={i}>
              <div className="mx-3 max-sm:mx-1">
                <MovieCarouselCard
                  id={card?.id}
                  time={card?.runtime}
                  title={card?.title}
                  info={card?.title}
                  year={card?.released_at}
                  type={card?.genre}
                  img={card?.thumbnail}
                  thumbnail_url={card?.thumbnail_url}
                  slug={card?.slug}
                  favoriteMovies={favorite}
                  movie={card}
                />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      )}
      {isLoading && <TrendMovieSkeletion />}
    </div>
  );
}

export default TrendMovies;
