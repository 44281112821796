import { RouteName } from "constant/routeNames";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import PayperviewLazyLoading from "./PayperviewLazyLoading";
import AddToFavPayperview from "./AddToFavPayperview";
import { useSelector } from "react-redux";
import ExpiredStatus from "./ExpiredStatus";
import PayperviewService from "services/payperview/PayperviewServie";
import LiveButton from "./LiveButton";

const PayPerViewCard = ({ event, isPurchased, user }: any) => {
  // console.log(event);
  const [hasPurchasedMovie, setHasPurchasedMovie] = useState<boolean>(false);
  const [remainingDays, setRemainingDays] = useState();

  useEffect(() => {
    if (isPurchased) {
      if (event && event?.rentable) {
        hasEventPurchasedMovie(event?.id);
      }
    }
  }, [isPurchased]);

  const hasEventPurchasedMovie = async (event_id: any) => {
    const response = await PayperviewService.hasPurchasedMovie(event_id);
    console.log(response);
    setHasPurchasedMovie(response?.isRentalValid);
    setRemainingDays(response?.remaining_days);
  };

  const loggedInUser = useSelector((state: any) => state?.user?.currentUser);
  return (
    <>
      <Link to={RouteName.PayperviewDetailsRoute.replace(":slug", event?.slug)}>
        {/* <Link to={`/payperview/${event?.slug}`}> */}
        <div className="mx-[10px] inline-block w-[181px] h-[290px] p-3 bg-[#1a1919]  hover:bg-slate-800 hover:shadow-xl rounded-md cursor-pointer text-white hover:text-slate-300 ease-in-out duration-150 max-sm:mx-auto">
          {/* image */}
          <div className=" w-full h-[181px] items-center justify-center relative ">
            {/* <img
            src={event?.thumbnail}
            alt="ppv"
            className="w-[181px] h-full  object-cover overflow-hidden rounded-md"
          /> */}
            <PayperviewLazyLoading
              imgSrc={event?.thumbnail || event?.thumbnail_url}
              imgAlt={"ppv"}
              imgHeight={"181px"}
              imgWidth={"100%"}
              style={{
                backgroundSize: "cover",
                backgroundPosition: "center",
                width: "100%",
                height: "181px",
                borderRadius: "5px",
              }}
            />
            {isPurchased || event?.isBuy === 0 ? (
              <>
                {event.category === "Movie" ? (
                  <>
                    <ExpiredStatus days={remainingDays} />
                  </>
                ) : (
                  <>{event?.event_status === "streaming" && <LiveButton />}</>
                )}
              </>
            ) : (
              <></>
            )}
            <div className="absolute -mt-[28px] flex w-full items-center ">
              <>
                {isPurchased && (
                  <div className="bg-[#f30543] w-auto h-5 px-2 rounded-sm">
                    <p className="text-sm font-normal text-white">Purchased</p>
                  </div>
                )}
              </>
              <div className="flex justify-end w-full">
                {loggedInUser && user?.id === loggedInUser?.id && (
                  <AddToFavPayperview
                    PayperviewCard={true}
                    payperview={event}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="relative top-1">
            <div className="my-[4px] ">
              {/* <div className="flex justify-between"> */}
              <p className="text-[15px] leading-tight font-[600] h-[60px]">
                {event?.title?.length > 50
                  ? `${event?.title
                      ?.slice(0, 22)
                      .replace(/&amp;/g, "&")
                      .replace(/&rsquo;/g, "’")
                      .replace(/&nbsp;/g, "")}...`
                  : event?.title
                      ?.replace(/&amp;/g, "&")
                      .replace(/&rsquo;/g, "’")
                      .replace(/&nbsp;/g, "")}{" "}
                {isPurchased}
              </p>
              {/* </div> */}

              <div className="flex justify-between">
                <p className="text-[11px] font-[400] leading-tight text-[#648194] mt-[5px]">
                  {event?.host_name?.length > 14
                    ? `${event?.host_name
                        ?.slice(0, 12)
                        ?.replace(/&amp;/g, "&")
                        .replace(/&rsquo;/g, "’")
                        .replace(/&nbsp;/g, "")}...`
                    : event?.host_name
                        ?.replace(/&amp;/g, "&")
                        .replace(/&rsquo;/g, "’")
                        .replace(/&nbsp;/g, "")}
                </p>
                <p className="text-[11px] text-[#f30543] font-[500] leading-tight mt-[5px]">
                  {event?.category}
                </p>
              </div>
            </div>
          </div>
        </div>
      </Link>
    </>
  );
};

export default PayPerViewCard;
