import PopUpModel from "components/PopUpModel";
import SocialMediaPopUp from "components/SocialMediaPopUp/SocialMediaPopUp";
import { RouteName } from "constant/routeNames";
import CreatePlayList from "pages/musicPage/shared/CreatePlayList";
import MusicPlayListPopUpDesktop from "pages/musicPage/shared/MusicPlayListPopUpDesktop";
import MusicPlayListPopUpMobile from "pages/musicPage/shared/MusicPlayListPopUpMobile";
import { useEffect, useRef, useState } from "react";
import { FiMoreHorizontal } from "react-icons/fi";

function CreatePlaylistIcon({
  userPlaylists,
  setUserPlaylists,
  mediaId,
  // remove from playlist
  removeFromPlaylist,
  setRemovedSongId,
  Icon,
}: any) {
  const popRef = useRef<HTMLDivElement | null>(null);
  const [isActivePlaylist, setIsActivePlaylist] = useState(false);
  const [openedComponent, setOpenedComponent] = useState<any>(null);
  // shareble Link
  const baseUrl = window.location.origin;
  const currentUrl =
    baseUrl +
    RouteName.Music.replace("*", RouteName.MusicTrack.replace(":id", mediaId));

  // playlist popup
  const dropDownPlaylist = () => {
    // setIsActivePlaylist(!isActivePlaylist);
    setIsActivePlaylist(true);
  };

  const closeDownPlaylist = () => {
    setIsActivePlaylist(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (popRef.current && !popRef.current.contains(event.target as Node)) {
        setIsActivePlaylist(false);
      }
    };
    if (isActivePlaylist) {
      window.addEventListener("mousedown", handleClickOutside);
    }
    return () => {
      window.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isActivePlaylist]);

  const [showModal, setShowModal] = useState(false);

  const openModal = () => {
    // setIsActivePlaylist(false);
    closeDownPlaylist();
    setShowModal(true);
  };

  const closeModal = () => {
    closeDownPlaylist();
    setShowModal(false);
  };
  return (
    <>
      <div
        onClick={dropDownPlaylist}
        ref={popRef}
        className="relative duration-300 ease-in-out cursor-pointer hover:text-red-600"
      >
        {Icon ? (
          <Icon className="w-10 h-10 mx-4 text-white cursor-pointer" />
        ) : (
          <FiMoreHorizontal />
        )}

        {isActivePlaylist && (
          <>
            {/* desktop view */}
            <div className="max-sm:hidden">
              <MusicPlayListPopUpDesktop
                className={`bottom-[-5px] absolute !min-w-[250px] z-50 ${
                  Icon ? `left-[60px] bottom-[20px]` : `right-[20px]`
                } !z-100`}
                list="playlist"
                openModal={openModal}
                userPlaylists={userPlaylists}
                setUserPlaylists={setUserPlaylists}
                mediaId={mediaId}
                removeFromPlaylist={removeFromPlaylist}
                setRemovedSongId={setRemovedSongId}
                closeModal={closeModal}
                setOpenedComponent={setOpenedComponent}
              />
            </div>

            {/* mobile view */}
            <div className="sm:hidden">
              <MusicPlayListPopUpMobile
                className={`bottom-[-5px] absolute !min-w-[200px] ${
                  Icon ? `right-[0px] bottom-[20px]` : `right-[20px]`
                } !z-50`}
                list="playlist"
                openModal={openModal}
                userPlaylists={userPlaylists}
                setUserPlaylists={setUserPlaylists}
                mediaId={mediaId}
                removeFromPlaylist={removeFromPlaylist}
                setRemovedSongId={setRemovedSongId}
                closeModal={closeModal}
                setOpenedComponent={setOpenedComponent}
              />
            </div>
          </>
        )}
      </div>

      {showModal && (
        <div className="z-50">
          <PopUpModel isOpen={showModal} onClose={closeModal} width={"800px"}>
            {openedComponent === "createPlaylist" ? (
              <CreatePlayList
                closeModal={closeModal}
                setUserPlaylists={setUserPlaylists}
              />
            ) : (
              <SocialMediaPopUp shareUrl={currentUrl} />
            )}
          </PopUpModel>
        </div>
      )}
    </>
  );
}

export default CreatePlaylistIcon;
