import PodcastHeroSection from "./home/PodcastHeroSection";
import PodcastcategoriesCoursel from "./shared/PodcastcategoriesCoursel";
import NewestPodcastCoursel from "./shared/NewestPodcastCoursel";
import PodcastService from "services/podcast/PodcastService";
import { useState, useEffect } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import GeneralServices from "services/General/GeneralServices";
import { Adsense } from "@ctrl/react-adsense";

function PodcastPageHome() {
  const [podcasts, setPodcasts] = useState([]);
  const [isLoading, setIsLoading] = useState<any>(false);
  const [gAdSlot, setgAdSlot] = useState<any>();

  useEffect(() => {
    GeneralServices.getAllConfigData()
      .then((res) => {
        const PageStatus = res?.data?.filter(
          (item: any) =>
            item?.title === "is_podcast_gAd" && item?.value !== null
        );
        if (PageStatus[0]?.value === "1") {
          const filteredData = res?.data?.filter(
            (item: any) => item?.title === "gAdSlot" && item?.value !== null
          );
          setgAdSlot(filteredData[0]?.value);
          console.log("setgAdStatus", filteredData[0]?.value);
        }
      })
      .catch((e) => {});
  }, []);

  useEffect(() => {
    setIsLoading(true);
    PodcastService.getLatestPodcasts().then((res) => {
      setIsLoading(false);
      setPodcasts(res.data);
      // console.log(res.data);
    });
  }, []);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const FE_URL = process.env.REACT_APP_FRONTEND_URL;

  return (
    <>
      <Helmet>
        <title>AYOZAT Podcast</title>

        <meta
          name="description"
          content="AYOZAT Streaming Platform - Watch free movies and TV shows online in HD on any device anytime. Free movie website search is over."
        />
        <link rel="canonical" href={`${FE_URL}/podcast`} />
        <meta property="og:title" content="AYOZAT Podcast" />
        <meta
          property="og:description"
          content="AYOZAT Streaming Platform - Watch free movies and TV shows online in HD on any device anytime. Free movie website search is over."
        />
        <meta property="og:image" content="https://ayozat.co.uk/logo192.png" />
        <meta property="og:url" content={`${FE_URL}/podcast`} />
        <meta property="og:type" content="website" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="AYOZAT Podcast" />
        <meta
          name="twitter:description"
          content="AYOZAT Streaming Platform - Watch free movies and TV shows online in HD on any device anytime. Free movie website search is over."
        />
        <meta name="twitter:image" content="https://ayozat.co.uk/logo192.png" />
        <meta name="twitter:site" content="AYOZAT Podcast" />
        <meta name="twitter:url" content={`${FE_URL}/podcast`} />
      </Helmet>

      <div className={``}>
        <h1 className="hidden">AYOZAT Podcasts</h1>
        <PodcastHeroSection latest={podcasts && podcasts[0]} />
        {gAdSlot && (
          <Adsense
            client="ca-pub-5773929890067376"
            slot={gAdSlot}
            style={{
              display: "flex",
              marginBottom: "20px",
              marginTop: "20px",
              height: "90px",
              justifyContent: "center",
            }}
            format="horizontal"
            data-full-width-responsive="true"
            // format="fluid"
          />
        )}
        {/* <PickUpPodcastCoursel podcastList={podcast} /> */}
        <PodcastcategoriesCoursel />
        <NewestPodcastCoursel podcasts={podcasts} isLoading={isLoading} />
      </div>
    </>
  );
}

export default PodcastPageHome;
