import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PayperviewService from "services/payperview/PayperviewServie";
import PurchesedCoursel from "./PurchesedCoursel";
import PayperviewPickedout from "components/skeleton/PayperviewPickedout";
import PayPerViewCard from "./PayPerViewCard";
import SubText from "components/shared/SubText";
import ConditionalSeeAll from "./ConditionalSeeAll";
import { RouteName } from "constant/routeNames";
import GridView from "./GridView";

function Purchased({ purchased, isLoading, user }: any) {
  const loggedInUser = useSelector((state: any) => state?.user?.currentUser);

  return (
    <div>
      {purchased?.length === 0 ? (
        <></>
      ) : (
        <>
          {/* {purchased?.length >= 4 ? (
            <> */}
              {!isLoading && (
                <PurchesedCoursel events={purchased} user={user} />
              )}
              {isLoading && <PayperviewPickedout />}
            </>
          // ) : (
          //   <>
          //     {!isLoading && (
          //       <GridView
          //         events={purchased}
          //         text={"Purchased Events"}
          //         route={RouteName.purchasedSeeAllRoute}
          //         purchased={purchased}
          //       />
          //     )}
          //     {isLoading && <PayperviewPickedout />}
          //   </>
          // )}
        // </>
      )}
    </div>
  );
}
export default Purchased;
