import React, { useEffect, useState } from 'react';
import StarDetails from './shared/StarDetails';
import VideoPageMovieCoursel from 'pages/videoPage/home/VideoPageMovieCoursel';
import VideoPageSeriesCoursel from 'pages/videoPage/home/VideoPageSeriesCoursel';
import { useSelector } from 'react-redux';
import ArtistDetailsSkeleton from './Skeleton/ArtistDetailsSkeleton';
import ArtistService from 'services/video/ArtistService';
import { useParams } from 'react-router-dom';

function StarPageHome() {
  const { slug } = useParams();
  const [isLoading, setIsLoading] = useState<any>(false);
  const loggedInUser = useSelector((state: any) => state?.user?.currentUser);
  const [star, setStar] = useState<any>([]);

  useEffect(() => {
    setIsLoading(true);
    ArtistService.getArtistBySlug(slug)
      .then((res: any) => {
        setIsLoading(false);
        setStar(res.data);
        // console.log(res.data);
      })
      .catch((e) => {
        // console.log(e);
      });
  }, [slug]);

  return (
    <>
      {/* {!isLoading && ( */}
      <div className="mb-16 text-white">
        {!isLoading && star ? (
          <>
            <StarDetails star={star} />

            {/* description */}
            {star?.bio && (
              <div className="max-sm:px-3 sm:px-2">
                <h1 className="my-3 font-bold max-sm:text-lg sm:text-xl">
                  Description
                </h1>

                {/* description text */}
                <p className="text-justify">{star?.bio}</p>
              </div>
            )}
          </>
        ) : (
          <ArtistDetailsSkeleton />
        )}

        <div className="sm:my-3 max-sm:my-1">
          {/* assets */}
          {star && star?.movies?.data && star?.movies?.data?.length > 0 && (
            <VideoPageMovieCoursel
              movies={star?.movies?.data}
              user={loggedInUser}
              title={star?.name && `Movies by ${star?.name}`}
              isLoading={isLoading}
              dontShowSeeAll={true}
            />
          )}
          {star &&
            star?.tv_series?.data &&
            star?.tv_series?.data?.length > 0 && (
              <VideoPageSeriesCoursel
                user={loggedInUser}
                title={star?.name && `Series by ${star?.name}`}
                series={star?.tv_series?.data}
                isLoading={isLoading}
                dontShowSeeAll={true}
              />
            )}
        </div>
      </div>
      {/* )} */}
    </>
  );
}

export default StarPageHome;
