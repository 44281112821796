import { HiArrowLeft } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import EditProfileButtonSection from "./shared/EditProfileButtonSection";
import { RouteName } from "constant/routeNames";
import { useSelector } from "react-redux";

function EditProfileLeftSection() {
  const loggedInUser = useSelector((state: any) => state?.user?.currentUser);
  const navigate = useNavigate();

  return (
    <div className="flex">
      {/* back arrow icon */}
      <div
        className={`!z-20 flex items-center justify-center w-10 ml-4 mt-10 h-10 text-2xl bg-white text-black rounded-full cursor-pointer max-sm:absolute max-sm:top-[50px]`}
        onClick={() => {
          navigate(RouteName.UserProfile.replace("*", loggedInUser.username));
        }}
      >
        <HiArrowLeft />
      </div>
      <div>
        <EditProfileButtonSection />
      </div>

      {/* <ProfileDetails /> */}
    </div>
  );
}

export default EditProfileLeftSection;
