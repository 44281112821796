import SubText from "components/shared/SubText";
import { Cards } from "constant/PayPerViewData";
import React, { useEffect, useState } from "react";
import { Pagination, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import PayPerViewCard from "./PayPerViewCard";
import PayperviewPickedout from "components/skeleton/PayperviewPickedout";
import "swiper/css";
import "swiper/css/pagination";
import "styles/card.css";
import "swiper/css/navigation";
import { Link } from "react-router-dom";
import ConditionalSeeAll from "./ConditionalSeeAll";
import { RouteName } from "constant/routeNames";

function UpcomingCoursel({ purchased, events, isLoading, user }: any) {
  // console.log(events);
  const [upComingEvents, setUpComingEvents] = useState<any>([]);
  useEffect(() => {
    if (events) {
      const upComingEvent = events.filter(
        (event: any) =>
          event?.event_status === "not started" ||
          event?.event_status === "streaming"
      );
      setUpComingEvents(upComingEvent);
    }
  }, [events]);

  return (
    <>
      {!isLoading && (
        <>
          {upComingEvents?.length > 0 && (
            <div>
              {/* top section */}
              <div className="flex justify-between w-[98%] mt-10 mb-8">
                <SubText text={"Upcoming Events"} className="font-bold" />
                <ConditionalSeeAll
                  length={upComingEvents?.length}
                  route={RouteName.upcomingSeeAllRoute}
                />
              </div>
              <div>
                <Swiper
                  slidesPerView={7}
                  breakpoints={{
                    0: {
                      slidesPerView: 1,
                    },
                    350: {
                      slidesPerView: 2,
                    },
                    550: {
                      slidesPerView: 3,
                    },
                    1000: {
                      slidesPerView: 4,
                    },
                    1100: {
                      slidesPerView: 5,
                    },
                    1200: {
                      slidesPerView: 6,
                    },
                    1400: {
                      slidesPerView: 7,
                    },
                  }}
                  modules={[Pagination, Navigation]}
                  navigation={true}
                  className="mt-4 mb-5 mySwiper"
                >
                  {upComingEvents &&
                    upComingEvents?.map((event: any, i: number) => (
                      <SwiperSlide className="" key={i}>
                        <PayPerViewCard
                          event={event}
                          user={user}
                          isPurchased={
                            purchased.some(
                              (purchasedEvent: any) =>
                                purchasedEvent?.id === event?.id
                            )
                              ? true
                              : false
                          }
                        />
                      </SwiperSlide>
                    ))}
                </Swiper>
              </div>
            </div>
          )}
        </>
      )}
      {isLoading && <PayperviewPickedout />}
    </>
  );
}

export default UpcomingCoursel;
