import { Link } from "react-router-dom";

function HeroSection({ Logo, link }: any) {
  return (
    <div className="music-heroimage">
      <div className=" mt-[150px] mx-auto">
        <div className="text-center w-[800px] mx-auto mb-5">
          <img src={Logo} className="landing-header-img"/>
        </div>
      
        <p className="font-Plus Jakarta Sans white-text text-[40px] font-[500] w-[800px] mx-auto leading-10 text-center fw-100">
          Watch movies and shows anytime anywhere personalized for you
        </p>
        <div className="flex justify-center w-full">
          <Link to={link}>
            <button className="white-text  button-red px-8 py-1.5 mt-[25px] font-medium rounded-full xl:text-lg lg:text-lg md:text-md sm:text-sm">
              Explore content
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default HeroSection;
