import React from "react";
import PodcastPlayList from "./PodcastPlayList";
import { onePodcast } from "constant/onePodcast";

function PodcastMusicLeftSide() {
  return (
    <div>
      <PodcastPlayList podcastList={onePodcast} />
    </div>
  );
}

export default PodcastMusicLeftSide;
