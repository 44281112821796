import { ChannelCard } from "pages/liveTvPage/shared/ChannelCard";
import { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";
import { Link } from "react-router-dom";
import { RouteName } from "constant/routeNames";
import SubText from "components/shared/SubText";
import TVChanneSkeleton from "components/skeleton/TVChanneSkeleton";

function ChannelCoursel({ data, isLoading }: any) {
  return (
    <div>
      {!isLoading && (
        <div className="flex justify-between items-center w-[95%]">
          <SubText text="Featured Live TV Channels" className="font-bold" />
          <Link to={RouteName.SeeAllChannelsRoute.replace(":slug", "all")}>
            <p className="text-sm font-bold cursor-pointer red-text place-self-end">
              {""} See all
            </p>
          </Link>
        </div>
      )}
      {!isLoading && (
        <div>
          <Swiper
            slidesPerView={5}
            breakpoints={{
              0: {
                slidesPerView: 2,
              },
              650: {
                slidesPerView: 3,
              },
              1000: {
                slidesPerView: 4,
              },
              1100: {
                slidesPerView: 5,
              },
              1200: {
                slidesPerView: 6,
              },
              1500: {
                slidesPerView: 7,
              },
              1600: {
                slidesPerView: 8,
              },
            }}
            modules={[Pagination, Navigation]}
            navigation={true}
            className="justify-center mx-auto mt-10 mb-10 mySwiper"
          >
            {data?.map((channel: any, i: number) => (
              <SwiperSlide className="!w-[full]" key={i}>
                <ChannelCard data={channel} img={channel.logo} className="" />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      )}
      {isLoading && <TVChanneSkeleton />}
    </div>
  );
}

export default ChannelCoursel;
