import { BsCameraVideo } from "react-icons/bs";
import { FaPodcast } from "react-icons/fa";
import { HiMusicNote } from "react-icons/hi";
import { MdLiveTv, MdOutlineRadio } from "react-icons/md";
import { AssetTypes } from "./AssetTypes";
import { RouteName } from "./routeNames";
import { ReactComponent as PaperView } from "../Assets/svg/ppv.svg";

export const contentNavData = [
  {
    title: AssetTypes.LiveTV,
    link: "/live-tv/ayozat-tv",
    icon: MdLiveTv,
    // subroutes: [
    //   {
    //     subRouteName: "Home",
    //     icon: RiHomeLine,
    //     subRouteLink: "/livetv",
    //   },
    //   {
    //     subRouteName: "Discover",
    //     icon: BiCompass,
    //     subRouteLink: RouteName.LiveTVDiscoverRoute,
    //   },
    //   {
    //     subRouteName: "History",
    //     icon: RiHistoryFill,
    //     subRouteLink: RouteName.LiveTVHistoryRoute,
    //   },
    //   {
    //     subRouteName: "Channels",
    //     icon: HiOutlineStar,
    //     subRouteLink: RouteName.LiveTVChannelRoute,
    //   },
    // ],
  },
  {
    title: AssetTypes.Video,
    link: RouteName.Video.replace("/*", ""),
    icon: BsCameraVideo,
    // subroutes: [
    //   {
    //     subRouteName: "Home",
    //     icon: RiHomeLine,
    //     subRouteLink: "/video",
    //   },
    //   {
    //     subRouteName: "Discover",
    //     icon: BiCompass,
    //     subRouteLink: RouteName.VideoDiscoverRoute,
    //   },
    //   {
    //     subRouteName: "History",
    //     icon: RiHistoryFill,
    //     subRouteLink: RouteName.VideoHistoryRoute,
    //   },
    //   {
    //     subRouteName: "Watchlist",
    //     icon: RiPlayList2Fill,
    //     subRouteLink: RouteName.VideoWatchlistRoute,
    //   },
    //   {
    //     subRouteName: "Favorite",
    //     icon: HiOutlineStar,
    //     subRouteLink: RouteName.VideoFavouriteRoute,
    //   },
    // ],
  },
  {
    title: AssetTypes.Music,
    link: RouteName.Music.replace("/*", ""),
    icon: HiMusicNote,
    // subroutes: [
    //   {
    //     subRouteName: "Home",
    //     icon: RiHomeLine,
    //     subRouteLink: "/music",
    //   },
    //   {
    //     subRouteName: "Discover",
    //     icon: BiCompass,
    //     subRouteLink: RouteName.MusicDiscoverRoute,
    //   },
    //   {
    //     subRouteName: "History",
    //     icon: RiHistoryFill,
    //     subRouteLink: RouteName.MusicHistoryRoute,
    //   },
    //   {
    //     subRouteName: "Collection",
    //     icon: HiOutlineStar,
    //     subRouteLink: RouteName.MusicColletionRoute,
    //   },
    // ],
  },

  {
    title: AssetTypes.Podcast,
    link: RouteName.Podcast.replace("/*", ""),
    icon: FaPodcast,
    // subroutes: [
    //   {
    //     subRouteName: "Home",
    //     icon: RiHomeLine,
    //     subRouteLink: "/podcast",
    //   },
    //   {
    //     subRouteName: "Discover",
    //     icon: BiCompass,
    //     subRouteLink: RouteName.PodcastDiscoverRoute,
    //   },
    //   {
    //     subRouteName: "History",
    //     icon: RiHistoryFill,
    //     subRouteLink: RouteName.PodcastHistoryRoute,
    //   },
    //   {
    //     subRouteName: "Favorite",
    //     icon: HiOutlineStar,
    //     subRouteLink: RouteName.PodcastFavouriteRoute,
    //   },
    // ],
  },
  {
    title: AssetTypes.Radio,
    link: RouteName.Radio.replace("/*", ""),
    icon: MdOutlineRadio,
    // subroutes: [
    //   {
    //     subRouteName: "Home",
    //     icon: RiHomeLine,
    //     subRouteLink: "/radio",
    //   },
    //   {
    //     subRouteName: "Discover",
    //     icon: BiCompass,
    //     subRouteLink: RouteName.RadioDiscoverRoute,
    //   },
    //   {
    //     subRouteName: "History",
    //     icon: RiHistoryFill,
    //     subRouteLink: RouteName.RadioHistoryRoute,
    //   },
    //   {
    //     subRouteName: "Favorite",
    //     icon: HiOutlineStar,
    //     subRouteLink: RouteName.RadioFavouriteRoute,
    //   },
    // ],
  },
  {
    title: AssetTypes.PayPerView,
    link: RouteName.PayPerView.replace("/*", ""),
    icon: PaperView,
    //   // subroutes: [
    //   //   {
    //   //     subRouteName: "Home",
    //   //     icon: RiHomeLine,
    //   //     subRouteLink: "/video",
    //   //   },
    //   //   {
    //   //     subRouteName: "Discover",
    //   //     icon: BiCompass,
    //   //     subRouteLink: RouteName.VideoDiscoverRoute,
    //   //   },
    //   //   {
    //   //     subRouteName: "History",
    //   //     icon: RiHistoryFill,
    //   //     subRouteLink: RouteName.VideoHistoryRoute,
    //   //   },
    //   //   {
    //   //     subRouteName: "Watchlist",
    //   //     icon: RiPlayList2Fill,
    //   //     subRouteLink: RouteName.VideoWatchlistRoute,
    //   //   },
    //   //   {
    //   //     subRouteName: "Favorite",
    //   //     icon: HiOutlineStar,
    //   //     subRouteLink: RouteName.VideoFavouriteRoute,
    //   //   },
    //   // ],
  },
];
