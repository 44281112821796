import { RouteName } from "constant/routeNames";
import LazyLoading from "pages/videoPage/shared/LazyLoading";
import React, { useEffect, useState } from "react";
import { FaPlay } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { setVideoStart, setVideoSuccess } from "redux/VideoPlayerRedux";
import AddToFavCreator from "./AddToFavCreator";
import { Tooltip as ReactTooltip } from "react-tooltip";

function CreatorVideoCard({ video, slug, id, season }: any) {
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const loggedInUser = useSelector((state: any) => state?.user?.currentUser);

  // console.log(slug);

  const episodeOnClick = () => {
    setTimeout(async () => {
      const value = {
        ...video,
      };

      navigate(
        RouteName.CreatorPlaylistRoute.replace(":slug", slug)
          .replace(":id", video?.season_id)
          .replace(":stream", video?.stream_key)
      );
      dispatch(setVideoStart());
      dispatch(setVideoSuccess(value));
    }, 0);
  };
  return (
    <div
      className="mb-[10px] mx-[10px] inline-block h-[240px] w-[300px] rounded-md  px-2 py-2 bg-[#1a1919] hover:bg-slate-800 hover:shadow-xl place-items-center shadow-lg max-sm:w-full"
      //   onMouseEnter={() => setHover(true)}
      //   onMouseLeave={() => setHover(false)}
    >
      {/* image */}
      <div className="relative w-full rounded-lg h-[160px] items-center justify-center bg-img white-text max-sm:w-[98%] max-sm:h-[180px]">
        <div className="absolute h-[160px] bottom-0 !z-50 flex items-center justify-center w-full play-button">
          <button className="p-3 rounded-full" onClick={episodeOnClick}>
            <FaPlay />
          </button>
          <div className="absolute right-2 bottom-2">
            <AddToFavCreator
              loggedInUser={loggedInUser}
              video={video}
              icon={true}
            />
          </div>
        </div>

        <LazyLoading
          imgSrc={video?.thumbnail_url}
          imgAlt={"Card pic"}
          imgHeight={"160px"}
          imgWidth={"100%"}
          style={{
            backgroundSize: "cover",
            backgroundPosition: "center",
            width: "100%",
            height: "100%",
            borderRadius: "5px",
            objectFit: "cover",
          }}
        />
      </div>
      <div className="relative h-[60px] top-1 mb-2">
        <div className="my-[4px] ml-1">
          <h2
            className="mb-1 font-bold text-slate-200 text-[14px] text-md w-[90%] h-[38px]"
            data-tooltip-id={`add-song-to-favorite-${video?.title}`}
          >
            {video?.title?.length > 40
              ? `${video?.title.slice(0, 40)}...`
              : video?.title}
          </h2>
          <div className="z-50 text-sm ">
            <ReactTooltip
              place="top"
              id={`add-song-to-favorite-${video?.title}`}
              content={video?.title}
              style={{ width: "180px" }}
            />
          </div>
          <div className="absolute mb-0">
            {/* <div className="flex mt-1 creator-card-bottom-details">
              <p className="text-white">1.3K views</p>
              <p className="ml-3 text-white">2 days ago</p>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreatorVideoCard;
