import SubText from "components/shared/SubText";
import RadioSkeleton from "components/skeleton/RadioSkeleton";
import { RouteName } from "constant/routeNames";
import RadioCard from "pages/radioPage/shared/RadioCard";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import LikeService from "services/music/LikeService";
import RadioService from "services/radio/RadioService";
import { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

function WelcomePageRadioSection({ text, user }: any) {
  const [stations, setStations] = useState([]);
  const [isLoading, setIsLoading] = useState<any>(false);
  const loggedInUser = useSelector((state: any) => state?.user?.currentUser);

  useEffect(() => {
    setIsLoading(true);
    RadioService.getRandomRadioStations().then((res) => {
      // console.log(res.slides);
      setIsLoading(false);
      setStations(res.randomRadioStations);
    });
  }, []);

  //Like function
  const [favorite, setFavorite] = useState<any>();

  useEffect(() => {
    if (user && loggedInUser) {
      LikeService.getFavoriteById(loggedInUser?.id, user?.id)
        .then((res) => {
          const favoritesIds = res?.profile?.favorite_radio_stations?.map(
            (item: any) => item.id
          );
          setFavorite(favoritesIds);
        })
        .catch(() => {});
    }
  }, [user]);
  return (
    <div className="mb-10">
      {/* top section */}
      {!isLoading && (
        <div className="flex justify-between w-[95%] mt-5 mb-8 ">
          <div>
            <SubText text={text} className="font-bold" />
          </div>
          <Link to={RouteName.SeeAllMightLikeRoute}>
            <p className="font-[500] text-[15px] text-[#F50537] cursor-pointer place-self-end">
              See all
            </p>
          </Link>
        </div>
      )}
      {/* coursel */}
      {!isLoading && (
        <div className="">
          <Swiper
            slidesPerView={6}
            breakpoints={{
              0: {
                slidesPerView: 1,
              },
              370: {
                slidesPerView: 2,
              },
              700: {
                slidesPerView: 3,
              },
              1000: {
                slidesPerView: 4,
              },
              1200: {
                slidesPerView: 5,
              },
              1400: {
                slidesPerView: 6,
              },
              1600: {
                slidesPerView: 7,
              },
            }}
            modules={[Pagination, Navigation]}
            navigation={true}
            className="mt-10 mb-10 mySwiper"
          >
            {stations?.map((station: any, i: number) => (
              <div key={i}>
                <SwiperSlide className="">
                  <RadioCard
                    station={station}
                    user={user}
                    favorite={favorite}
                  />
                </SwiperSlide>
              </div>
            ))}
          </Swiper>
        </div>
      )}
      {isLoading && <RadioSkeleton />}
    </div>
  );
}

export default WelcomePageRadioSection;
