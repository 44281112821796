import { AssetTypes } from "constant/AssetTypes";
import { VideoLastWatch } from "pages/videoPage/home/shared/VideoPlayer";

interface Duration {
  hours: number;
  minutes: number;
}

export function calculateDuration(
  startDate: string,
  endDate: string
): Duration {
  const start = new Date(startDate);
  const end = new Date(endDate);

  // Calculate the difference in milliseconds
  const durationInMillis = end.getTime() - start.getTime();

  // Calculate the hours and minutes
  const hours = Math.floor(durationInMillis / (1000 * 60 * 60));
  const minutes = Math.floor(
    (durationInMillis % (1000 * 60 * 60)) / (1000 * 60)
  );

  return { hours, minutes };
}

//convert duration to mins and seconds
export function convertTime(totalMins: number) {
  if (totalMins < 60) {
    return { hours: 0, minutes: totalMins };
  } else if (totalMins % 60 === 0) {
    return { hours: 1, minutes: 0 };
  } else {
    const hours = Math.floor(totalMins / 60);
    const mins = totalMins % 60;
    return { hours: hours, minutes: mins };
  }
}

// get time ago
export function getTimeAgo(timestamp: any) {
  const currentDate = new Date();
  const previousDate = new Date(timestamp);

  const timeDiff = Math.abs(currentDate.getTime() - previousDate.getTime());
  const minutes = Math.floor(timeDiff / (1000 * 60));
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const months = Math.floor(days / 30);
  const years = Math.floor(months / 12);

  if (years > 0) {
    return years === 1 ? "1 year ago" : `${years} years ago`;
  } else if (months > 0) {
    return months === 1 ? "1 month ago" : `${months} months ago`;
  } else if (days > 0) {
    return days === 1 ? "1 day ago" : `${days} days ago`;
  } else if (hours > 0) {
    return hours === 1 ? "1 hour ago" : `${hours} hours ago`;
  } else if (minutes > 0) {
    return minutes === 1 ? "1 minute ago" : `${minutes} minutes ago`;
  } else {
    return "Just now";
  }
}

export function formatDuration(durationInMs: number) {
  const totalSeconds = Math.floor(durationInMs);
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = totalSeconds % 60;

  // const formattedMinutes = String(minutes).padStart(2, "0");
  const formattedMinutes = minutes;
  const formattedSeconds = String(seconds).padStart(2, "0");

  // return totalSeconds;
  return `${formattedMinutes}:${formattedSeconds}`;
}

export function formatDurationHr_Min_Sec(durationInMs: number) {
  const totalSeconds = Math.floor(durationInMs);
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = totalSeconds % 60;

  const hours = Math.floor(minutes / 60);
  const remainMins = minutes % 60;

  const formattedHours = String(hours).padStart(2, "0");
  const formattedMinutes = String(remainMins).padStart(2, "0");
  // const formattedMinutes = minutes;
  const formattedSeconds = String(seconds).padStart(2, "0");

  // return totalSeconds;
  if (hours > 0) {
    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  } else {
    return `${formattedMinutes}:${formattedSeconds}`;
  }
}

export function formatDurationHrAndMins(seconds: number) {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);

  const formattedHours = String(hours).padStart(2, "0");
  const formattedMins = String(minutes).padStart(2, "0");

  // return totalSeconds;
  return `${
    formattedHours === "00" ? "" : `${formattedHours} hr `
  }${formattedMins} mins`;
}

export function convertTimeWithDashes(input: string) {
  const year = input.substring(0, 4);
  const month = input.substring(4, 6);
  const day = input.substring(6, 8);
  const hour = input.substring(8, 10);
  const minute = input.substring(10, 12);

  return `${year}-${month}-${day}T${hour}:${minute}:00`;
}

export function formatDateTo12HourTime(dateString: string) {
  const date = new Date(dateString);
  let hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? "pm" : "am";

  // Convert hours to 12-hour format
  if (hours > 12) {
    hours -= 12;
  } else if (hours === 0) {
    hours = 12;
  }

  // Add leading zero to minutes if necessary
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

  return `${hours}.${formattedMinutes} ${ampm}`;
}

export function countdownToTargetDate(targetDateStr: string) {
  const targetDate: Date = new Date(targetDateStr);
  const now: Date = new Date();

  if (targetDate <= now) {
    return "ended";
  }

  const timeDifference: number = targetDate.getTime() - now.getTime();
  const days: number = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
  const hours: number = Math.floor(
    (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutes: number = Math.floor(
    (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
  );
  const seconds: number = Math.floor((timeDifference % (1000 * 60)) / 1000);

  return {
    days,
    hours,
    minutes,
    seconds,
  };
}

export function formatToLocalDateString(dateString: string) {
  if (typeof dateString !== "undefined") {
    const options = {
      weekday: "short",
      day: "numeric",
      month: "short",
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    };

    const localDate = new Date(dateString);
    const formattedDate = new Intl.DateTimeFormat(
      undefined,
      options as any
    ).format(localDate);

    return formattedDate.replace(/\bam\b/i, "AM").replace(/\bpm\b/i, "PM");
  }
}

export function getFormattedLocalDate(utcTimeString: string) {
  const utcDate = new Date(utcTimeString);

  const date = utcDate.getDate();
  const month = utcDate.getMonth() +1;
  const year = utcDate.getFullYear();
  const hours = utcDate.getHours();
  const minutes = utcDate.getMinutes();

  return { year,month,date, hours, minutes };

}
export const getLastWatchTime = (
  id: number,
  type: AssetTypes.TVSeries | AssetTypes.Movie,
  lastWatch: any
) => {
  // console.log("Searching for : ", id);
  const existingItemIndex =
    lastWatch?.length > 0 &&
    lastWatch?.findIndex(
      (item: VideoLastWatch) => item?.id === id && item?.type === type
    );

  if (existingItemIndex !== -1) {
    return lastWatch[existingItemIndex]?.currentTime;
  } else {
    return 0;
  }
};
