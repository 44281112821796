import { useEffect, useState } from "react";
import SingleEpisode from "./shared/SingleEpisode";
import { useSelector } from "react-redux";

function Episodes({ videoDetails, loading }: any) {
  const { currentVideo } = useSelector((state: any) => state?.video);

  const [selectedSeason, setSelectedSeason] = useState<any | null>(null);
  useEffect(() => {
    if (currentVideo?.id) {
      setSelectedSeason(
        videoDetails?.seasons?.find((s: any) => s.id === currentVideo.season)
      );
    } else {
      if (videoDetails?.no_of_seasons !== 0 && videoDetails?.seasons) {
        setSelectedSeason(videoDetails?.seasons[0] && videoDetails?.seasons[0]);
      }
    }
  }, [currentVideo, videoDetails]);

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedIndex = event.target.selectedIndex;
    if (selectedIndex >= 0) {
      const selectedOption = event.target.options[selectedIndex];
      const selectedSeasonJSON = selectedOption.getAttribute("data-object");
      if (selectedSeasonJSON) {
        const parsedObject = JSON.parse(selectedSeasonJSON) as any;
        setSelectedSeason(parsedObject);
      }
    }
  };

  return (
    <>
      {!loading && videoDetails ? (
        <>
          <div className="flex items-center my-3 max-sm:w-full">
            <p className="text-3xl font-bold">Seasons</p>
            <select
              name="season"
              id="season"
              className="ml-4 p-2 text-white rounded-md outline-none searchbar max-h-[60px] overflow-y-auto max-sm:w-full w-[270px]"
              value={selectedSeason ? JSON.stringify(selectedSeason) : ""}
              onChange={handleChange}
            >
              {videoDetails &&
                videoDetails?.seasons?.map((s: any, i: number) => (
                  <option
                    key={i}
                    value={JSON.stringify(s)}
                    data-object={JSON.stringify(s)}
                  >
                    {s.name}
                  </option>
                ))}
            </select>
          </div>
          {/* <div className="flex items-center my-3">
            {videoDetails?.seasons?.map((s: any, i: number) => (
              <p
                key={i}
                className={`flex items-center justify-center mr-4 rounded-md cursor-pointer p-1 backdrop:blur-sm font-bold ${
                  season?.id === s.id ? 'button-red' : 'button-gray'
                }`}
                onClick={() => setSeason(s)}
              >
                {s.name}
              </p>
            ))}
          </div> */}

          <div className="overflow-y-scroll scrollbar-hide ">
            {selectedSeason?.episodes?.map((e: any, i: number) => (
              <SingleEpisode
                data={e}
                key={i}
                id={videoDetails.id}
                season={selectedSeason.id}
              />
            ))}
          </div>
        </>
      ) : (
        <>
          <div className="w-[60px]  h-[20px] mr-2 bg-[#1a1919] animate-pulse max-sm:w-full"></div>
          <div className="flex items-center my-8">
            {Array.from({ length: 3 }, (_, i) => (
              <div
                className="w-[80px]  h-[40px] mr-2 bg-[#1a1919] animate-pulse max-sm:w-full"
                key={i}
              ></div>
            ))}
          </div>
          {Array.from({ length: 5 }, (_, i) => (
            <div
              className="w-[250px]  h-[45px] my-2 bg-[#1a1919] animate-pulse max-sm:w-full"
              key={i}
            ></div>
          ))}
        </>
      )}
    </>
  );
}

export default Episodes;
