import React, { useRef } from "react";
import { addToQueue } from "redux/AudioPlayerRedux";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { useDispatch } from "react-redux";
import { triggerWarningNotification } from "utils/notification";
import { Notification } from "constant/notifications";
import { RiPlayListAddLine } from "react-icons/ri";

function AddSongToQueue({ song, toolTiId }: any) {
  const dispatch = useDispatch<any>();
  const popRef = useRef<HTMLDivElement | null>(null);

  const addToQueueSong = () => {
    if (song) {
      dispatch(addToQueue(song));
    } else {
      triggerWarningNotification(Notification.NoSongs);
    }
  };
  return (
    <div
      onClick={addToQueueSong}
      ref={popRef}
      className="duration-300 ease-in-out cursor-pointer hover:text-red-600"
      data-tooltip-id={`${toolTiId}-${song?.id}`}
    >
      <RiPlayListAddLine />

      <div className="text-sm">
        <ReactTooltip
          // id={`song-card-${song?.id}`}
          id={`${toolTiId}-${song?.id}`}
          place="top"
          content="Add to queue"
        />
      </div>
    </div>
  );
}

export default AddSongToQueue;
