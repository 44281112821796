/* eslint-disable react-hooks/exhaustive-deps */
import { RouteName } from 'constant/routeNames';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setChannelCategory } from 'redux/VideoPlayerRedux';
import LiveTVService from 'services/liveTv/LiveTVService';

function LiveTVGenreSelector({ category, setCategory, page }: any) {
  const [categories, setCategories] = useState<any[]>([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    if (page) {
      navigate(RouteName.SeeAllChannelsRoute.replace(':slug', category));
    }
    // setGenre(selected);
  }, [category]);

  useEffect(() => {
    LiveTVService.getLiveTvCategories()
      .then((res) => {
        setCategories(res.tv_channel_categories);
      })
      .catch((e) => {
        // console.log(e);
      });
  }, []);

  const onChange = (e: any) => {
    setCategory(e.target.value);
    dispatch(setChannelCategory(e.target.value));
  };

  return (
    <div className="max-sm:w-[200px]">
      <label
        htmlFor="watched"
        className="text-white max-sm:hidden max-md:hidden"
      >
        Category{' '}
      </label>
      <select
        name="cars"
        id="cars"
        className="p-1 ml-2 text-white rounded-md outline-none searchbar"
        onChange={onChange}
        value={category}
      >
        <option defaultValue="all" value={'all'}>
          All
        </option>
        {categories?.map((c: any, i) => (
          <option value={c.slug} key={i}>
            {c.name}
          </option>
        ))}
      </select>
    </div>
  );
}

export default LiveTVGenreSelector;
