import React from "react";

const TopTitle = ({ pageName }: any) => {
  return (
    <h1 className="text-gray-400 my-10 uppercase text-[20px] font-bold">
      {pageName}
    </h1>
  );
};

export default TopTitle;
