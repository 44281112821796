export const radios = [
    {
        id: 1,
        name: "BBC Radio 1",
        fm: "FM: 104.8 MHz (London); 96.0 MHz (Belfast); 1215 kHz;",
        img: "https://ichef.bbci.co.uk/images/ic/1920x1080/p0cbj45j.jpg",
      },
      {
        id: 2,
        name: "BBC Radio 4",
        fm: "FM: 104.8 MHz (London); 96.0 MHz (Belfast); 1215 kHz;",
        img: "https://ichef.bbci.co.uk/images/ic/1920x1080/p0cbjc9r.jpg",
      },
      {
        id: 3,
        name: "BBC Radio 3",
        fm: "FM: 104.8 MHz (London); 96.0 MHz (Belfast); 1215 kHz;",
        img: "https://ichef.bbci.co.uk/images/ic/1200x675/p07ptn5l.jpg",
      },
      {
        id: 4,
        name: "BBC Radio 6",
        fm: "FM: 104.8 MHz (London); 96.0 MHz (Belfast); 1215 kHz;",
        img: "https://support.musicgateway.com/wp-content/uploads/2020/06/BBC-Radio-6-music-logo.jpg",
      },
      {
        id: 5,
        name: "BBC Radio 5",
        fm: "FM: 104.8 MHz (London); 96.0 MHz (Belfast); 1215 kHz;",
        img: "https://ichef.bbci.co.uk/images/ic/1200x675/p01l80mb.jpg",
      },
    {
        id: 6,
        name: "BBC Radio 1",
        fm: "FM: 104.8 MHz (London); 96.0 MHz (Belfast); 1215 kHz;",
        img: "https://ichef.bbci.co.uk/images/ic/1920x1080/p0cbj45j.jpg",
      },
      {
        id: 7,
        name: "BBC Radio 4",
        fm: "FM: 104.8 MHz (London); 96.0 MHz (Belfast); 1215 kHz;",
        img: "https://ichef.bbci.co.uk/images/ic/1920x1080/p0cbjc9r.jpg",
      },
      {
        id: 8,
        name: "BBC Radio 3",
        fm: "FM: 104.8 MHz (London); 96.0 MHz (Belfast); 1215 kHz;",
        img: "https://ichef.bbci.co.uk/images/ic/1200x675/p07ptn5l.jpg",
      },
      {
        id: 9,
        name: "BBC Radio 6",
        fm: "FM: 104.8 MHz (London); 96.0 MHz (Belfast); 1215 kHz;",
        img: "https://support.musicgateway.com/wp-content/uploads/2020/06/BBC-Radio-6-music-logo.jpg",
      },
      {
        id: 10,
        name: "BBC Radio 5",
        fm: "FM: 104.8 MHz (London); 96.0 MHz (Belfast); 1215 kHz;",
        img: "https://ichef.bbci.co.uk/images/ic/1200x675/p01l80mb.jpg",
      },
]