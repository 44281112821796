import SubText from "components/shared/SubText";
import { RouteName } from "constant/routeNames";
import { useState } from "react";
import { FaPlay } from "react-icons/fa";
import { Link } from "react-router-dom";

function PodcastHeroCard({ latestPodcast, isLoading }: any) {
  const [hover, setHover] = useState<boolean>(false);
  return (
    <>
      <Link
        to={RouteName.PodcastOpenEpisodeRoute.replace(":id", latestPodcast?.id)}
      >
        <div
          className="w-full max-sm:my-3 max-sm:block"
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
        >
          <SubText text="Trending this week" className="font-bold" />

          <p className="text-[10px] font-[400] text-[#7B9DB4] my-[5px] max-sm:text-[10px]">
            {latestPodcast?.title}
          </p>

          <div
            className="relative w-[100%] rounded-lg h-[310px] bg-img white-text flex max-sm:w-full"
            style={{
              background: `url(${latestPodcast?.artwork_url}) no-repeat center`,
              backgroundSize: "100%",
              backgroundPosition: "center",
            }}
          >
            {/* card inside content */}
            <div className="absolute inset-x-0 bottom-0 flex justify-between p-2 bg-gradient-to-t from-black to-black/50 h-[100px]">
              <div className="flex h-full overflow-hidden">
                <div>
                  <p className="text-[15px] text-white ml-2 font-[700]">
                    {latestPodcast?.title}
                  </p>
                  <p className="text-[12px] text-[#b0b0b0] ml-2 font-[500]">
                    {latestPodcast?.artist?.name}
                  </p>
                  <p className="text-[10px] text-white ml-2 font-[500]">
                    {latestPodcast?.description.length > 200
                      ? `${latestPodcast?.description.slice(0, 200)}...`
                      : latestPodcast?.description}
                  </p>
                </div>
              </div>
              <div className="flex items-center">
                <div
                  className={`flex items-center justify-center w-12 h-12 rounded-full shadow-lg cursor-pointer ${
                    hover ? "bg-red-600" : `bg-stone-400`
                  } duration-300 ease-in-out`}
                >
                  <FaPlay className="w-5 h-5 text-white " />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Link>
    </>
  );
}

export default PodcastHeroCard;
