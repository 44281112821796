import { AssetTypes } from 'constant/AssetTypes';
import { RouteName } from 'constant/routeNames';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { removeAllPlayer } from 'redux/AudioPlayerRedux';
import {
  removeLiveTVData,
  setVideoStart,
  setVideoSuccess,
  setVideoLoaded,
} from 'redux/VideoPlayerRedux';
import VideoService from 'services/video/VideoService';
import { getLastWatchTime } from 'utils/timeFunctions';
import CommonPlayerSection from './shared/CommonPlayerSection';

function VideoTVSeriesPlayerPage() {
  const { slug, stream } = useParams();
  const navigate = useNavigate();
  const [openGeoLoactionModel, setOpenGeoLoactionModel] = useState(false);

  const dispatch = useDispatch<any>();
  const [videoDetails, setVideoDetails] = useState<any>({});
  const [error, setError] = useState('');
  const { lastWatched } = useSelector((state: any) => state?.video);

  useEffect(() => {
    let cleanedSlug = slug && slug.endsWith('.html') ? slug.slice(0, -5) : slug;
    dispatch(removeLiveTVData());
    dispatch(removeAllPlayer());
    const loadData = async () => {
      dispatch(setVideoStart());
      setTimeout(async () => {
        await VideoService.getTVSeriesByStreamKey(stream)
          .then(async (res) => {
            //   dispatch(setVideoSuccess(res.data?.seasons[0].episodes[0]));
            const lastWatchTime = getLastWatchTime(
              res.data?.id,
              AssetTypes.TVSeries,
              lastWatched
            );
            const value = await {
              ...res.data,
              tv_series_id: res.data?.tv_show_id,
              season: res.data?.season_id,
              userCurrentTime: lastWatchTime,
              poster: res.data?.thumbnail,
            };
            setVideoDetails(value);

            dispatch(setVideoSuccess(value));
            setError('');

            dispatch(setVideoLoaded());
          })
          .catch((err) => {
            toast.error('Content Not Found');
            setError('Content Not Found, Please try again');
            navigate(RouteName.pageNotFound);
            dispatch(setVideoLoaded());
          });
      }, 1000);

      setTimeout(async () => {
        await VideoService.getTVSeriesDetailsBySlug(cleanedSlug)
          .then(async (res) => {
            if (res?.data?.allowed_to_watch === false) {
              setOpenGeoLoactionModel(true);
            }
            setVideoDetails(res.data);
          })
          .catch((err) => {
            toast.error('Content Not Found');
            setError('Content Not Found, Please try again');
            navigate(RouteName.pageNotFound);
            dispatch(setVideoLoaded());
          });
      }, 1000);
    };
    loadData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stream]);
  return (
    <>
      <CommonPlayerSection
        videoDetails={videoDetails}
        contentType="series"
        openGeoLoactionModel={openGeoLoactionModel}
        setOpenGeoLoactionModel={setOpenGeoLoactionModel}
        error={error}
      />
    </>
  );
}

export default VideoTVSeriesPlayerPage;
