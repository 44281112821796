export const audios = [
  {
    name: "Smells Like Teen Spirit",
    artist: "Nirvana",
    thumbnail:
      "https://m.media-amazon.com/images/M/MV5BMzBjNzIzODEtMzdiOC00ZmE0LTgyY2UtOGY0NTRiOTViY2JlXkEyXkFqcGdeQXVyNDE4OTY5NzI@._V1_FMjpg_UX1000_.jpg",
    audioUrl:
      //   "https://cph-p2p-msl.akamaized.net/hls/live/2000341/test/master.m3u8",
      // "https://cdn.simplecast.com/audio/cae8b0eb-d9a9-480d-a652-0defcbe047f4/episodes/af52a99b-88c0-4638-b120-d46e142d06d3/audio/500344fb-2e2b-48af-be86-af6ac341a6da/default_tc.mp3",
      "https://iframe.mediadelivery.net/embed/57964/a3339ea1-46ef-4434-9ae8-dbcc6cf5c951",
  },
  {
    name: "Billie Jean",
    artist: "Michael Jackson",
    thumbnail: "https://images.thebrag.com/tb/uploads/2017/02/billiejean.jpg",
    audioUrl:
      "https://cdn.simplecast.com/audio/cae8b0eb-d9a9-480d-a652-0defcbe047f4/episodes/af52a99b-88c0-4638-b120-d46e142d06d3/audio/500344fb-2e2b-48af-be86-af6ac341a6da/default_tc.mp3",
  },
  {
    name: "Stayin' Alive",
    artist: "Bee Gees",
    thumbnail:
      "https://i1.sndcdn.com/artworks-ORkYNQrXv0U4LEde-GY4JyQ-t500x500.jpg",
    audioUrl:
      "https://cdn.simplecast.com/audio/cae8b0eb-d9a9-480d-a652-0defcbe047f4/episodes/af52a99b-88c0-4638-b120-d46e142d06d3/audio/500344fb-2e2b-48af-be86-af6ac341a6da/default_tc.mp3",
  },
  {
    name: "I Will Survive",
    artist: "Gloria Gaynor",
    thumbnail:
      "https://i1.sndcdn.com/artworks-sDVpGOcBgHqWln7f-vKnJkA-t500x500.png",
    audioUrl:
      "https://cdn.simplecast.com/audio/cae8b0eb-d9a9-480d-a652-0defcbe047f4/episodes/af52a99b-88c0-4638-b120-d46e142d06d3/audio/500344fb-2e2b-48af-be86-af6ac341a6da/default_tc.mp3",
  },
  {
    name: "Whole Lotta Love",
    artist: "Led Zeppelin",
    thumbnail:
      "https://i.discogs.com/32RD2WkZcDjwe4FpuMfDw1-8QW8Ea3us90yNsEHK9Dc/rs:fit/g:sm/q:90/h:600/w:593/czM6Ly9kaXNjb2dz/LWRhdGFiYXNlLWlt/YWdlcy9SLTE4MTAw/NDUtMTM3NzYyNjY1/MS04NjYwLmpwZWc.jpeg",
    audioUrl:
      "https://cdn.simplecast.com/audio/cae8b0eb-d9a9-480d-a652-0defcbe047f4/episodes/af52a99b-88c0-4638-b120-d46e142d06d3/audio/500344fb-2e2b-48af-be86-af6ac341a6da/default_tc.mp3",
  },
  {
    name: "Sweet Child O'Mine",
    artist: "Guns N' Roses",
    thumbnail:
      "https://i1.sndcdn.com/artworks-000068784359-ji49u4-t500x500.jpg",
    audioUrl:
      "https://cdn.simplecast.com/audio/cae8b0eb-d9a9-480d-a652-0defcbe047f4/episodes/af52a99b-88c0-4638-b120-d46e142d06d3/audio/500344fb-2e2b-48af-be86-af6ac341a6da/default_tc.mp3",
  },
  {
    name: "Scream and Shout",
    artist: "Will.I.Am & Brittney Spears",
    thumbnail:
      "https://i1.sndcdn.com/artworks-000554596227-58lxvw-t500x500.jpg",
    audioUrl:
      "https://cdn.simplecast.com/audio/cae8b0eb-d9a9-480d-a652-0defcbe047f4/episodes/af52a99b-88c0-4638-b120-d46e142d06d3/audio/500344fb-2e2b-48af-be86-af6ac341a6da/default_tc.mp3",
  },
  {
    name: "Santeria",
    artist: "Sublime",
    thumbnail: "https://i.ytimg.com/vi/NPsAuxShNhQ/maxresdefault.jpg",
    audioUrl:
      "https://cdn.simplecast.com/audio/cae8b0eb-d9a9-480d-a652-0defcbe047f4/episodes/af52a99b-88c0-4638-b120-d46e142d06d3/audio/500344fb-2e2b-48af-be86-af6ac341a6da/default_tc.mp3",
  },
];
