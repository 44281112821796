import SubText from "components/shared/SubText";
import React from "react";

function AboutPage({ creatorData }: any) {
  // Date conversion
  const dateString = `${creatorData?.released_at}`;

  const joinDate = new Date(dateString);

  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "short",
    day: "numeric",
  };
  const formattedDate = joinDate.toLocaleDateString("en-US", options);

  return (
    <div className="mt-10 ml-6">
      {/* top section */}
      <div className="flex">
        <div className="w-[70%] max-sm:w-full">
          {/* Description */}
          <div>
            <SubText text="Description" />
            {/* <div className="text-base font-normal text-white w-[70%] text-justify mt-5">
              {creatorData?.general_info.description
                .replace(/&amp;/g, "&")
                .replace(/&rsquo;/g, "’")
                .replace(/&ldquo;/g, '"')
                .replace(/&rdquo;/g, '"')
                .replace(/&nbsp;/g, "")}
            </div> */}

            <p
              dangerouslySetInnerHTML={{
                __html:
                  creatorData?.general_info.description
                    .replace(/&amp;/g, "&")
                    .replace(/&rsquo;/g, "’")
                    .replace(/&ldquo;/g, '"')
                    .replace(/&rdquo;/g, '"')
                    .replace(/&nbsp;/g, "") || "",
              }}
              className="text-base font-normal text-white w-[70%] text-justify mt-5 max-sm:w-full"
            />
          </div>
          {/* channel name and cantact number */}
          {/* <div className="mt-10">
            <SubText text="Pet Talk" />
            <div className="flex">
              <SubText text="Call : " className="mr-3" />
              <p className="text-lg font-normal text-white">0775 987 687</p>
            </div>
          </div> */}
          {/* official partners */}
          {/* <div className="mt-10">
            <div className="flex">
              <SubText
                text="Official Camera partner Nikon - "
                className="mr-3"
              />
              <p className="text-lg font-normal text-white">Photo Technika</p>
            </div>
            <div className="flex">
              <SubText
                text="Official Energy food partner - "
                className="mr-3"
              />
              <p className="text-lg font-normal text-white">Jars by ShaRu</p>
            </div>
            <hr className="w-[70%] mt-20" />
          </div> */}
          {/* Details */}
          <div className="mt-10">
            <SubText text="Details" />
            {/* <div className="flex items-center mt-5">
              <p className="mr-3">For business inquiries:</p>
              <button className="flex items-center text-base font-bold text-white cursor-pointer hover:bg-[#48484895] py-3 px-5 border-[#f30543] border-2 rounded-full duration-200 ease-in-out">
                View Email Address
              </button>
            </div> */}
            <div className="flex items-center mt-5">
              <p className="mr-3">Location:</p>
              <p className="ml-28">{creatorData?.general_info.country_name}</p>
            </div>
            {/* <hr className="w-[70%] mt-20" /> */}
          </div>
          {/* Links */}
          {/* <div className="mt-10">
            <SubText text="Links" />
            <div className="flex justify-between w-[50%]">
              <div className="mt-5 ">
                <p className="font-semibold">Facebook</p>
                <a href="web.facebook.com/pettalksl" className="text-blue-300">
                  web.facebook.com/pettalksl
                </a>
              </div>
              <div className="mt-5">
                <p className="font-semibold">Instagram</p>
                <a href="instagram.com/pettalksl" className="text-blue-300">
                  instagram.com/pettalksl
                </a>
              </div>
            </div>
            <div className="mt-8">
              <p className="font-semibold">email</p>
              <a href="pettalksl@gmail.com" className="text-blue-300">
                pettalksl@gmail.com
              </a>
            </div>
            <hr className="w-[70%] mt-20" />
          </div> */}
        </div>
        {/* top right section */}
        {/* <div className="w-[30%]">
          <div>
            <SubText text="Stats" className="text-[15px]" />
            <hr className="w-[95%] mt-3 mb-3" />
          </div>
          <div>
            <div className="flex">
              <p className="font-normal">Joined {formattedDate}</p>
            </div>
            <hr className="w-[95%] mt-3 mb-3" />
          </div>
          <div>
            <div className="flex">
              <p className="font-normal">39,400,586 views</p>
            </div>
            <hr className="w-[95%] mt-3 mb-3" />
          </div>
          <div>
            <div className="flex">
              <MdOutlinedFlag className="w-8 h-8 mr-5" />
              <TbShare3 className="w-8 h-8" />
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default AboutPage;
