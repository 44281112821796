import LazyLoadImg from "components/LazyLoadImg";
import { RouteName } from "constant/routeNames";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { setLiveTVSuccess, setVideoStart } from "redux/VideoPlayerRedux";
import { convertFromGetAllChannels } from "utils/convertLiveTVData";
export const ChannelCard = ({ data, img }: any) => {
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();

  const channelClicked = async () => {
    let video: any = convertFromGetAllChannels(data);
    await dispatch(setVideoStart());
    await dispatch(setLiveTVSuccess(video));
    navigate(RouteName.LiveTVPlayerLinkWithSlug.replace(":slug", data.slug));
  };
  return (
    <div
      className="inline-block h-[200px] hover:-translate-y-1 hover:scale-105 motion-reduce:transition-none motion-reduce:hover:transform-none p-3 mx-auto text-center rounded-xl cursor-pointer  hover:bg-[#1c1b1b] hover:shadow-xl"
      onClick={channelClicked}
    >
      <div className="flex mt-2 justify-center mx-auto w-[120px] h-[120px] bg-[#363636] rounded-full items-center cover overflow-hidden">
        <LazyLoadImg
          imgSrc={img}
          imgAlt={"Channel Icon"}
          // imgHeight={"200px"}
          // imgWidth={"300px"}
          className="object-none hover:shadow-2xl"
          style={{ objectFit: "fill", width: "100%", height: "120px" }}
        />
      </div>

      <p className="inline-block w-[150px] mt-2 text-center text-white">
        {data.name.replace(/&amp;/g, '&')
                  .replace(/&rsquo;/g, '’')
                  .replace(/&nbsp;/g, '')
                  .replace(/&ldquo;/g, '"')
                  .replace(/&rdquo;/g, '"')}
      </p>
    </div>
  );
};
