import { gridCard } from "constant/gridCard";
import { podcast } from "constant/podcast";
import { podcastNew } from "constant/podcastNew";
import NewestPodcastCoursel from "./shared/NewestPodcastCoursel";
import PickUpPodcastCoursel from "./shared/PickUpPodcastCoursel";
import PodcastCardSection from "./shared/PodcastCardSection";
import PodcastcategoriesCoursel from "./shared/PodcastcategoriesCoursel";

function PodcastPageDiscover() {
  return (
    <div className="max-sm:mx-3">
      {/* main topic section */}
      <div className="mb-8">
        <h1 className="text-2xl font-bold text-white lg:text-2xl md:text-2xl">
          Discover podcasts
        </h1>
      </div>
      <PodcastCardSection gridCard={gridCard} />
      <PickUpPodcastCoursel podcastList={podcast} />
      <PodcastcategoriesCoursel />
      {/* <NewestPodcastCoursel podcastNew={podcastNew} /> */}
    </div>
  );
}

export default PodcastPageDiscover;
