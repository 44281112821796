import React, { useEffect, useState } from "react";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import PlaylistService from "services/music/PlaylistService";
import PlaylistCard from "./playlist/PlaylistCard";
import MusicSeeAllSkeleton from "components/skeleton/MusicSeeAllSkeleton";

function DiscoverPlaylists() {
  const navigate = useNavigate();
  // const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState<any>(false);
  const [publicPlaylists, setPublicPlaylists] = useState<any>([]);

  useEffect(() => {
    // setLoading(true);
    setIsLoading(true);
    PlaylistService.getPublicPlaylists()
      .then((res: any) => {
        setIsLoading(false);
        // console.log("first", res.data.data);
        setPublicPlaylists(res.data.data);
      })
      .catch((e) => {
        setIsLoading(false);
        // console.log(e);
      });
  }, []);
  return (
    <>
      {!isLoading && (
        <div>
          {/* main topic section */}
          <div>
            <button
              className="w-10 h-10 mb-5 bg-white rounded-full ml"
              onClick={() => {
                navigate(-1);
              }}
            >
              <AiOutlineArrowLeft className="ml-3" />
            </button>
          </div>
          <div className="mb-8">
            <h1 className="text-2xl font-medium text-white lg:text-2xl md:text-2xl max-sm:w-[60%] max-sm:ml-2">
              Discover Playlists
            </h1>
          </div>
          {publicPlaylists && (
            <div className="grid w-full grid-cols-2 mt-4 mb-28 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 2xl:grid-cols-7 max-sm:w-full">
              {publicPlaylists.map((playlist: any, index: number) => (
                <div key={index} className="mb-5 mr-4">
                  <PlaylistCard playlist={playlist} discoverPlayist={true} />
                </div>
              ))}
            </div>
          )}
        </div>
      )}
      {isLoading && <MusicSeeAllSkeleton />}
    </>
  );
}

export default DiscoverPlaylists;
