import { RouteName } from "constant/routeNames";
import { Link, useLocation, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import GeneralServices from "services/General/GeneralServices";

const WaringPage = () => {
  const location = useLocation();
  const path = location.pathname.substring(1);
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    GeneralServices.getRedirectUrl()
      .then((response: any) => {
        const filteredData = response.data.filter((item: any) => {
          if (item.old_url.endsWith("*")) {
            const pathSegements = location.pathname.split("/");
            const lastSegment = pathSegements[pathSegements.length - 1];

            item.old_url = item.old_url.replace("*", lastSegment);
          }

          return item.old_url === path;
        });

        if (filteredData.length > 0) {
          navigate(filteredData[0].new_url);
        } else {
          setIsLoading(false);
        }
      })
      .catch((error) => {
        // console.log("Error getRedirectUrl ==> ", error);
        setIsLoading(false);
      });
  }, [path, navigate]);

  if (isLoading) {
    return (
      <div className="flex justify-center mt-[180px] w-full mx-auto  text-center max-sm:mt-[200px]">
        <div className="block"></div>
      </div>
    );
  }

  return (
    <div className="flex justify-center mt-[180px] w-full mx-auto  text-center max-sm:mt-[200px]">
      <div className="block">
        <h1 className="text-[#f30543] text-[150px] max-sm:text-[80px] ">404</h1>
        <p className="text-[30px] font-extrabold text-white max-sm:text-[20px]">
          Page Not Found
        </p>
        <h6 className="mt-5 text-[10px] font-thin text-gray-600">
          Oops! The page you are looking for does not exist. <br />
          It might have been moved or delete
        </h6>
        <Link to={RouteName.Home}>
          <button className="px-3 py-2 mt-8 rounded-full button-red">
            Home
          </button>
        </Link>
      </div>
    </div>
  );
};

export default WaringPage;
