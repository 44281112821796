import { Notification } from "constant/notifications";
import React, { useEffect, useState } from "react";
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import LikeService from "services/music/LikeService";
import {
  triggerErrorNotification,
  triggerSuccessNotification,
} from "utils/notification";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { updateFavorites } from "redux/userRedux";

function AddSongToFav({ song, Icon, musicCard, hover }: any) {
  // console.log(song);
  // console.log(favorite);
  const dispatch = useDispatch<any>();
  const loggedInUser = useSelector((state: any) => state?.user?.currentUser);
  const favorite = useSelector((state: any) => state?.user?.userFavorites);
  // console.log(favorite?.loved?.data);
  const [addFav, setAddFav] = useState<boolean>(false);

  useEffect(() => {
    const favoriteMusicIds = favorite?.loved?.data.map((item: any) => item.id);
    if (song && favoriteMusicIds) {
      if (favoriteMusicIds?.includes(song?.id)) {
        setAddFav(true);
      } else {
        setAddFav(false);
      }
    }
  }, [favorite, song]);

  const addFavToRedux = () => {
    const updatedLovedData = [...favorite?.loved?.data, song];
    const updatedFavorite = {
      ...favorite,
      loved: {
        ...favorite.loved,
        data: updatedLovedData,
      },
    };
    // Dispatch the action to update the 'favorite' object in the Redux store
    dispatch(updateFavorites(updatedFavorite));
  };

  const removeFavFromRedux = () => {
    // const updatedLovedData = [...favorite?.loved?.data, song];
    const updatedLovedData = favorite?.loved?.data.filter(
      (favSong: any) => favSong.id !== song.id
    );
    const updatedFavorite = {
      ...favorite,
      loved: {
        ...favorite.loved,
        data: updatedLovedData,
      },
    };
    // Dispatch the action to update the 'favorite' object in the Redux store
    dispatch(updateFavorites(updatedFavorite));
  };

  const handleFavorite = (e: any) => {
    e.preventDefault();
    const favoriteSong = {
      id: song.id.toString(),
      object_type: "song",
    };

    if (!addFav) {
      setAddFav(true);
      LikeService.likeByUserId(favoriteSong).then((res) => {
        if (res.status) {
          setAddFav(true);
          // updateFav();
          addFavToRedux();
          triggerSuccessNotification(Notification.AddToFav);
        } else {
          setAddFav(false);
          triggerErrorNotification(Notification.FavError);
        }
      })
      .catch((e) => {
        setAddFav(true);
        triggerErrorNotification(Notification.FavError);
      });
    } else {
      setAddFav(false);
      LikeService.unLikeByUserId(favoriteSong).then((res) => {
        if (res.status) {
          triggerSuccessNotification(Notification.RemovedFromFav);
          // updateFav();
          removeFavFromRedux();
          setAddFav(false);
        } else {
          setAddFav(true);
          triggerErrorNotification(Notification.FavError);
        }
      })
      .catch((e) => {
        setAddFav(true);
        triggerErrorNotification(Notification.FavError);
      });
    }
  };
  return (
    <div>
      {loggedInUser ? (
        <div
          onClick={handleFavorite}
          className="cursor-pointer"
          data-tooltip-id={`add-song-to-favourite-${song?.id}`}
        >
          {addFav ? (
            <div className="text-red-600">
              {Icon ? (
                <AiFillHeart className="w-10 h-10 cursor-pointer" />
              ) : (
                <AiFillHeart />
              )}
            </div>
          ) : (
            <div
              className={
                musicCard
                  ? `${
                      hover
                        ? `text-red-600 duration-500 ease-in-out`
                        : `text-white duration-500 ease-in-out`
                    }`
                  : "text-white"
              }
            >
              {Icon ? (
                <AiOutlineHeart className="w-10 h-10 cursor-pointer" />
              ) : (
                <AiOutlineHeart />
              )}
            </div>
          )}

          <div className="text-sm">
            <ReactTooltip
              id={`add-song-to-favourite-${song?.id}`}
              place="top"
              content={addFav ? "Remove from favourite" : "Add to favourites"}
            />
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default AddSongToFav;
