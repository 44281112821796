export const gridCard = [
    {
      id: 1,
      name: "On Purpose with Jay Shetty",
      description: "Jay Shetty",
      img: "https://i.scdn.co/image/ab6765630000ba8a882986685255a2e620b489f9",
    },
    {
      id: 2,
      name: "Planet Money",
      description: "NPR",
      img: "https://m.media-amazon.com/images/M/MV5BMzhmYTQxYTItNzg0NC00MmU2LTgxNDAtNzMxMTAyOTk5NWYzXkEyXkFqcGdeQXVyMDY3OTcyOQ@@._V1_.jpg",
    },
    {
      id: 3,
      name: "The Indicator from Planet Money",
      description: "NPR",
      img: "https://media.npr.org/assets/img/2022/09/23/the-indicator_tile_npr-network-01_sq-cdaf29c3fae4825d9aed15df5a2ee4bdfcd95a3e-s1400-c100.jpg",
    },
    {
      id: 4,
      name: "The Tim Ferriss Show",
      description: "Tim Ferriss: Bestselling...",
      img: "https://i0.wp.com/tim.blog/wp-content/uploads/2014/04/timferrissshowart-1400x1400.jpg?fit=1400%2C1400&ssl=1",
    },
    // {
    //   id: 5,
    //   name: "On Purpose with Jay Shetty",
    //   description: "Jay Shetty",
    //   img: "https://i.scdn.co/image/ab6765630000ba8a882986685255a2e620b489f9",
    // },
    // {
    //   id: 6,
    //   name: "Planet Money",
    //   description: "NPR",
    //   img: "https://m.media-amazon.com/images/M/MV5BMzhmYTQxYTItNzg0NC00MmU2LTgxNDAtNzMxMTAyOTk5NWYzXkEyXkFqcGdeQXVyMDY3OTcyOQ@@._V1_.jpg",
    // },
    // {
    //   id: 7,
    //   name: "The Indicator from Planet Money",
    //   description: "NPR",
    //   img: "https://media.npr.org/assets/img/2022/09/23/the-indicator_tile_npr-network-01_sq-cdaf29c3fae4825d9aed15df5a2ee4bdfcd95a3e-s1400-c100.jpg",
    // },
    // {
    //   id: 8,
    //   name: "The Tim Ferriss Show",
    //   description: "Tim Ferriss: Bestselling...",
    //   img: "https://i0.wp.com/tim.blog/wp-content/uploads/2014/04/timferrissshowart-1400x1400.jpg?fit=1400%2C1400&ssl=1",
    // },
  ];