import React from "react";
import VideoCardGrid from "./shared/VideoCardGrid";

function VideosPage({ creatorData, slug, isLoading }: any) {
  return (
    <div className="mt-5">
      <VideoCardGrid
        creatorData={creatorData}
        slug={slug}
        isLoading={isLoading}
      />
    </div>
  );
}

export default VideosPage;
