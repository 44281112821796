const DiscoverAlbumSkeleton = () => {
  const arr = [1, 2, 3, 4, 5, 6, 7];
  return (
    <div>
      {/* <div className='w-[300px] h-[30px] mt-3 bg-[#1a1919]'></div> */}
      <div className={'flex flex-row justify-between w-[85vw] mt-12 mr-12'}>
        {arr.map((item: any) => (
          <div key={item} className="flex flex-col items-start justify-center">
            <div className="lg:w-[170px] h-[250px] w-[90px] bg-[#1a1919] animate-pulse mt-2"></div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DiscoverAlbumSkeleton;
