import React, { useEffect, useState } from "react";
import NoFavorite from "./NoFavorite";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import { Link } from "react-router-dom";
import { RouteName } from "constant/routeNames";
import PayPerViewCard from "pages/PayPerView/shared/PayPerViewCard";

function PayperviewByUser({ favoriteData, user, favorite, purchased }: any) {
  const [payperview, setPayperview] = useState<any>([]);
  const allPayperview = favoriteData?.favorite_ppv_events || [];

  useEffect(() => {
    setPayperview(allPayperview);
  }, []);
  return (
    <div>
      {/* top section */}
      <div className="mb-8 w-[98%] ml-6 mt-8 max-sm:w-full max-sm:mx-0">
        <div>
          <h1 className="text-xl font-medium text-white lg:text-xl md:text-xl">
            {/* Podcasts by User */}
            Favourite PPV
          </h1>
        </div>
      </div>
      {/* card section */}
      {allPayperview.length === 0 && allPayperview ? (
        <div>
          <NoFavorite text="Payperview Events" />
        </div>
      ) : (
        // <div>
        //   <div className="mt-4 mb-5 w-[95%] grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 2xl:grid-cols-7 max-sm:mx-0 max-sm:w-full max-sm:gap-3 ">
        //     {podcasts.length > 0 &&
        //       podcasts.map((podcasts: any, i: number) => (
        //         <div key={i}>
        //           <Podcastcard
        //             podcast={podcasts}
        //             user={user}
        //             favorite={favorite}
        //           />
        //         </div>
        //       ))}
        //   </div>
        // </div>

        <>
          {/* coursel */}
          <div className="">
            <Swiper
              slidesPerView={6}
              breakpoints={{
                0: {
                  slidesPerView: 1,
                },
                370: {
                  slidesPerView: 2,
                },
                700: {
                  slidesPerView: 3,
                },
                1000: {
                  slidesPerView: 4,
                },
                1200: {
                  slidesPerView: 5,
                },
                1400: {
                  slidesPerView: 6,
                },
                1600: {
                  slidesPerView: 7,
                },
              }}
              modules={[Pagination]}
              className="mt-4 mb-10 mySwiper"
            >
              {allPayperview.length > 0 &&
                allPayperview.map((event: any, i: number) => (
                  <SwiperSlide className="" key={i}>
                    <Link
                      to={RouteName.PayperviewDetailsRoute.replace(
                        ":slug",
                        payperview?.slug
                      )}
                    >
                      <PayPerViewCard
                        event={event}
                        user={user}
                        favorite={favorite}
                        isPurchased={
                          purchased?.some(
                            (payperviewEvent: any) =>
                              payperviewEvent?.id === event?.id
                          )
                            ? true
                            : false
                        }
                      />
                    </Link>
                  </SwiperSlide>
                ))}
            </Swiper>
          </div>
        </>
      )}
    </div>
  );
}

export default PayperviewByUser;
