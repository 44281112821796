import { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";
import CategoryCard from "pages/videoPage/home/shared/CategoryCard";
import VideoService from "services/video/VideoService";

import "swiper/css";
import "swiper/css/pagination";
import SubText from "components/shared/SubText";
import { Link } from "react-router-dom";
import { RouteName } from "constant/routeNames";
import VideoCategorySkeleton from "components/skeleton/VideoCategorySkeleton";

function CategoriesCoursel() {
  const [categories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState<any>(false);

  useEffect(() => {
    setIsLoading(true);
    VideoService.getAllGenres()
      .then((res) => {
        setIsLoading(false);
        setCategories(res.genres);
      })
      .catch((e) => {
        setIsLoading(false);
        // console.log(e);
      });
  }, []);

  return (
    <>
      <div className="flex justify-between w-[95%]">
        <SubText text="Top Categories" className="font-bold max-sm:ml-4" />
        <Link to={RouteName.SeeAllTopCategoriesRoute}>
          <p className="text-sm font-bold cursor-pointer red-text place-self-end">
            {""} See all
          </p>
        </Link>
      </div>
      {!isLoading && (
        <div className="">
          <Swiper
            slidesPerView={5}
            breakpoints={{
              0: {
                slidesPerView: 2,
              },
              390: {
                slidesPerView: 2,
              },
              740: {
                slidesPerView: 3,
              },
              1000: {
                slidesPerView: 4,
              },
              1200: {
                slidesPerView: 5,
              },
              1400: {
                slidesPerView: 5,
              },
              1600: {
                slidesPerView: 5,
              },
            }}
            modules={[Pagination, Navigation]}
            navigation={true}
            className="my-10 mySwiper"
          >
            {categories?.map((card: any, i) => (
              <SwiperSlide key={i}>
                <CategoryCard data={card} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      )}
      {isLoading && <VideoCategorySkeleton />}
    </>
  );
}

export default CategoriesCoursel;
