import React from "react";

const NormalText = ({ content }: any) => {
  return (
    <div className="mt-6 text-slate-300 text-sm w-[98%] ">
      <p dangerouslySetInnerHTML={{ __html: content }} className="w-[98%] " />
      {/* {content.split("\n").map((paragraph: any, index: number) => (
        <p key={index}>{paragraph}</p>
      ))} */}
    </div>
  );
};

export default NormalText;
