import React, { useEffect, useState } from "react";
import { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import AlbumCard from "../shared/AlbumCard";
import AlbumService from "services/music/AlbumService";
import DiscoverAlbumSkeleton from "components/skeleton/DiscoverAlbumSkeleton";

function AlbumCoursel() {
  const [albums, setAlbums] = useState<any>([]);
  const [isLoading, setIsLoding] = useState<any>(false);

  useEffect(() => {
    setIsLoding(true);
    AlbumService.getRandomAlbums().then((res) => {
      // console.log(res.randomAlbums);
      setIsLoding(false);
      setAlbums(res.randomAlbums);
    });
  }, []);

  // console.log(albums);
  return (
    <div>
      {!isLoading && (
        <div className="">
          <Swiper
            slidesPerView={7}
            breakpoints={{
              0: {
                slidesPerView: 1,
              },
              370: {
                slidesPerView: 2,
              },
              700: {
                slidesPerView: 3,
              },
              1000: {
                slidesPerView: 4,
              },
              1200: {
                slidesPerView: 5,
              },
              1400: {
                slidesPerView: 6,
              },
              1600: {
                slidesPerView: 7,
              },
            }}
            modules={[Pagination, Navigation]}
            className="mt-4 mb-5 mySwiper"
            navigation={true}
          >
            {albums &&
              albums?.map((album: any, i: number) => (
                <SwiperSlide className="" key={i}>
                  <AlbumCard {...album} />
                </SwiperSlide>
              ))}
          </Swiper>
        </div>
      )}
      {isLoading && <DiscoverAlbumSkeleton />}
    </div>
  );
}

export default AlbumCoursel;
