import { RouteName } from "constant/routeNames";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { FaCircle } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { formatDuration } from "utils/timeFunctions";
import LazyLoadImg from "components/LazyLoadImg";

const TrackDetails = ({ data }: any) => {
  const navigate = useNavigate();
  // console.log(data);
  return (
    <>
      <div className="relative h-auto ">
        <div
          className="relative h-auto mb-30"
          style={{
            backgroundImage: `url(${data?.artwork_url})`,
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        >
          <div className="p-3 full-backdrop-bottom">
            <div>
              <button
                className="w-10 h-10 mt-5 bg-white rounded-full max-sm:ml-[7px]"
                onClick={() => {
                  navigate(-1);
                }}
              >
                <AiOutlineArrowLeft className="ml-3" />
              </button>
            </div>
            <div className="flex items-end max-sm:h-[250px] ">
              {/* <img
                  src={data?.artwork_url}
                  alt=""
                  className="rounded-md album-page-image max-sm:h-[20%]"
                /> */}
              <div className="mt-5 max-sm:hidden">
                <LazyLoadImg
                  imgSrc={data?.artwork_url}
                  imgAlt={"Card pic"}
                  imgHeight={"280px"}
                  imgWidth={"280px"}
                  className="rounded-md album-page-image max-sm:h-[20%]"
                  
                />
              </div>

              <div className="ml-10 max-sm:ml-5 ">
                <h1
                  className="text-6xl font-bold font-secular fafafa max-sm:text-[30px] cursor-default"
                  dangerouslySetInnerHTML={{ __html: data?.title.replace(/_/g, "'") }}
                ></h1>

                {/* display artsits and hi image */}

                <div className="flex items-center gap-3 mt-5 text-sm text-white max-sm:mt-8">
                  <div className="flex items-center justify-start">
                    <LazyLoadImg
                      imgSrc={data?.artists[0]?.artwork_url}
                      imgAlt={"first artist"}
                      // imgHeight={"90%"}
                      // imgWidth={"90%"}
                      className="mr-2 bg-cover rounded-full"
                      style={{
                        borderRadius: "100%",
                        width: "24px",
                        height: "24px",
                        backgroundSize: "cover",
                        marginRight: "8px",
                      }}
                    />
                    {/* {data?.artists?.map((d: any, i: number) => ( */}
                    <>
                      <Link
                        to={RouteName.MusicArtistPage.replace(
                          ":slug",
                          data?.artists[0]?.slug
                        )}
                      >
                        <p
                          // key={i}
                          className={`ml-1 text-white font-bold hover:underline`}
                        >
                          {/* {d?.name} */}
                          {data?.artists[0]?.name}
                        </p>
                      </Link>
                    </>
                  </div>

                  <div className="flex items-center justify-start gap-3">
                    <div className="flex items-center justify-start">
                      <FaCircle className="ml-1 text-[6px] text-white" />
                      <p className="ml-1"> {data?.released_at?.split("/")[2]}</p>
                    </div>
                    <div className="flex items-center justify-start">
                      <FaCircle className="ml-1 text-[6px] text-white" />
                      <p className="ml-1">
                        {data?.duration
                          ? formatDuration(data.duration)
                          : "00:00"}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TrackDetails;
