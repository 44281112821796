import { TabNames } from "constant/tabNames";

const Tabs: string[] = [TabNames.Episodes, TabNames.Info];

function TVSeriesTabs({ selectedTab, setSelectedTab }: any) {
  return (
    <>
      {Tabs.map((tab: string, i: number) => (
        <p
          className={`mr-5 text-white cursor-pointer ${
            selectedTab === tab ? "opacity-100 border-b" : "opacity-60 "
          }`}
          key={i}
          onClick={() => setSelectedTab(tab)}
        >
          {tab}
        </p>
      ))}
    </>
  );
}

export default TVSeriesTabs;
