import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";


const RadioSkeleton = () => {
  const arr = [1, 2, 3, 4, 5, 6];
  return (
    <div className='mt-20'>
      <div className="flex flex-row justify-between">
        <div className="w-[250px] h-[30px] bg-[#1a1919]"></div>
        <div className="w-[100px] h-[30px] mr-[50px] bg-[#1a1919]"></div>
      </div>
      <div className="">
        <Swiper
          slidesPerView={5}
          breakpoints={{
            0: {
              slidesPerView: 1,
            },
            370: {
              slidesPerView: 2,
            },
            700: {
              slidesPerView: 3,
            },
            1100: {
              slidesPerView: 3,
            },
            1200: {
              slidesPerView: 4,
            },
            1500: {
              slidesPerView: 5,
            },
            1600: {
              slidesPerView: 6,
            },
          }}
          className="mt-4 mb-5 mySwiper"
        >
          <div className='flex flex-row lg:gap-[60px] sm:gap-[20px] gap-3 mt-10'>
            {arr.map((item: any) => (
              <div key={item} className="flex flex-col justify-center items-start">
                <div className='lg:w-[160px] h-[160px] bg-[#1a1919] animate-pulse mt-2'></div>
              </div>
            ))
            }
          </div>
        </Swiper>
      </div>
    </div>
  );
};

export default RadioSkeleton;
