import { Notification } from "constant/notifications";
import React, { useEffect, useState } from "react";
import {
  AiFillHeart,
  AiFillStar,
  AiOutlineHeart,
  AiOutlineStar,
} from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import LikeService from "services/music/LikeService";
import {
  triggerErrorNotification,
  triggerSuccessNotification,
} from "utils/notification";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { updateFavorites } from "redux/userRedux";
import PayperviewService from "services/payperview/PayperviewServie";

function AddToFavPayperview({
  payperview,
  Icon,
  PayperviewCard,
}: //   hover,
//   addFav,
//   setAddFav,
any) {
  const dispatch = useDispatch<any>();
  const loggedInUser = useSelector((state: any) => state?.user?.currentUser);
  const favorite = useSelector((state: any) => state?.user?.userFavorites);
  console.log(favorite);
  const [addFav, setAddFav] = useState<boolean>(false);
  const [hover, setHover] = useState(false);

  useEffect(() => {
    const favoritePpvIds = favorite?.favorite_ppv_events?.map(
      (item: any) => item.id
    );
    if (payperview && favoritePpvIds) {
      if (favoritePpvIds?.includes(payperview?.id)) {
        setAddFav(true);
      } else {
        setAddFav(false);
      }
    }
  }, [favorite, payperview]);

  const addFavToRedux = () => {
    console.log(favorite);
    const updatedPayperviewData = [
      ...favorite?.favorite_ppv_events,
      payperview,
    ];
    const updatedFavorite = {
      ...favorite,
      favorite_ppv_events: updatedPayperviewData,
    };
    // Dispatch the action to update the 'favorite' object in the Redux store
    dispatch(updateFavorites(updatedFavorite));
  };

  const removeFavFromRedux = () => {
    // const updatedPodcastData = [...favorite?.favorite_podcasts, podcast];
    const updatedPayperviewData = favorite?.favorite_ppv_events?.filter(
      (favPpv: any) => favPpv.id !== payperview.id
    );
    const updatedFavorite = {
      ...favorite,
      favorite_ppv_events: updatedPayperviewData,
    };
    // Dispatch the action to update the 'favorite' object in the Redux store
    dispatch(updateFavorites(updatedFavorite));
  };

  const handleFavorite = (e: any) => {
    e.preventDefault();
    // console.log(addFav);
    const favoritePpv = {
      id: payperview.id.toString(),
      object_type: "ppv",
    };

    if (!addFav) {
      setAddFav(true);
      PayperviewService.likePpvEvent(favoritePpv).then((res) => {
        if (res.status) {
          setAddFav(true);
          // updateFav();
          addFavToRedux();
          triggerSuccessNotification(Notification.AddToFav);
        } else {
          setAddFav(false);
          triggerErrorNotification(Notification.FavError);
        }
      })
      .catch((e) => {
        setAddFav(true);
        triggerErrorNotification(Notification.FavError);
      });
    } else {
      setAddFav(false);
      PayperviewService.unLikePpvEvent(favoritePpv).then((res) => {
        if (res.status) {
          triggerSuccessNotification(Notification.RemovedFromFav);
          // updateFav();
          removeFavFromRedux();
          setAddFav(false);
        } else {
          setAddFav(true);
          triggerErrorNotification(Notification.FavError);
        }
      })
      .catch((e) => {
        setAddFav(true);
        triggerErrorNotification(Notification.FavError);
      });
    }
  };

  return (
    <div>
      {loggedInUser ? (
        <div
          onClick={handleFavorite}
          className={`cursor-pointer items-center p-1 rounded-full add-button-payperview ${
            !Icon && addFav && `bg-red-600`
          }`}
          data-tooltip-id={`add-payperview-to-favorite-${payperview.id}`}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
        >
          {addFav ? (
            <div className="">
              {Icon ? (
                <AiFillHeart className="text-red-600 cursor-pointer" />
              ) : (
                <div className="">
                  {hover ? (
                    <AiFillStar className="text-red-600 cursor-pointer" />
                  ) : (
                    <AiOutlineStar />
                  )}
                </div>
              )}
            </div>
          ) : (
            <div
              className={
                PayperviewCard
                  ? `${
                      hover
                        ? `text-red-600 duration-500 ease-in-out`
                        : `text-white duration-500 ease-in-out`
                    }`
                  : "text-white"
              }
            >
              {Icon ? (
                <AiOutlineHeart className="cursor-pointer" />
              ) : (
                <AiOutlineStar />
              )}
            </div>
          )}

          <div className="text-sm">
            <ReactTooltip
              id={`add-payperview-to-favorite-${payperview.id}`}
              place="top"
              content={addFav ? "Remove from favourites" : "Add to favourites"}
            />
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default AddToFavPayperview;
