import FilterSection from "components/shared/FilterSection";
import CardGridSection from "pages/videoPage/shared/CardGridSection";
import { useEffect } from "react";

function VideoPageFavorite() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      {/* main topic section */}
      <div className="mb-8 flex items-center justify-between w-[98%] max-sm:block ">
        {/* left part */}
        <div>
          <h1 className="text-2xl font-medium text-white lg:text-3xl md:text-3xl max-sm:text-3xl max-sm:ml-6">
            User's Favorite
          </h1>
        </div>
        {/* right part */}

        <FilterSection />
      </div>
      {/* card section */}
      <CardGridSection />
    </div>
  );
}

export default VideoPageFavorite;
