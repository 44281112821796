import LazyLoadImg from "components/LazyLoadImg";
import { AssetTypes } from "constant/AssetTypes";
import { HiArrowRight } from "react-icons/hi";
import { Link } from "react-router-dom";

function GridCard({ theme, title, info, img, link }: any) {
  const enabled = localStorage.getItem("ppv_enabled");
  return (
    <div
      className={`!overflow-hidden ${
        theme === "light"
          ? "button-white pt-4 gridCard-hover"
          : "background-gray pt-4 gridCard-hover"
      }`}
    >
      <div className="w-[90%] mx-auto overflow-hidden">
        <div className="w-[65%] max-sm:w-full">
          <h2
            className={
              theme === "light" ? "text-black mb-4" : "text-white mb-4"
            }
          >
            <span className="mr-2 font-extrabold red-text lg:text-xl md:text-lg sm:text-md ">
              AYOZAT
            </span>{" "}
            {title}
          </h2>
          <p
            className={
              theme === "light"
                ? "text-black font-medium mb-4 "
                : "text-white font-medium mb-4 "
            }
          >
            {info}
          </p>
          {title === AssetTypes.PayPerView ? (
            <>
              {enabled === "1" ? (
                <>
                  <Link to={link}>
                    <p
                      className={
                        theme === "light"
                          ? "text-black font-medium flex items-center cursor-pointer"
                          : "text-white font-medium flex items-center cursor-pointer"
                      }
                    >
                      Explore <HiArrowRight className="ml-2" />
                    </p>
                  </Link>
                </>
              ) : (
                <>
                  <p
                    className={
                      theme === "light"
                        ? "text-black font-medium flex items-center cursor-pointer"
                        : "text-white font-medium flex items-center cursor-pointer"
                    }
                  >
                    Coming soon
                  </p>
                </>
              )}
            </>
          ) : (
            <>
              <Link to={link}>
                <p
                  className={
                    theme === "light"
                      ? "text-black font-medium flex items-center cursor-pointer"
                      : "text-white font-medium flex items-center cursor-pointer"
                  }
                >
                  Explore <HiArrowRight className="ml-2" />
                </p>
              </Link>
            </>
          )}
        </div>
        {/* bottom image */}
        <div className="w-full -mb-[7px] overflow-hidden">
          <img
            // src='https://i.pcmag.com/imagery/reviews/05cItXL96l4LE9n02WfDR0h-6..v1575422587.png'
            src={img}
            alt="card figure"
            className="w-full mt-8 overflow-hidden shadow-2xl img-shadow"
            style={{
              backgroundSize: "100%",
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default GridCard;
