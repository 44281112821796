import SongList from "../shared/SongList";

const MostPlayedSongList = ({
  audios,
  user,
  userPlaylists,
  setUserPlaylists,
}: any) => {
  // const loggedInUser = useSelector((state: any) => state?.user?.currentUser);

  return (
    <div className=" w-full  bg-[#1E1E1E] rounded-md max-sm:mt-4 max-md:w-full max-lg:w-full">
      <div>
        <h1 className="mb-4 ml-5 p-1 font-[700] text-[20px] text-white leading-10 ">
          Trending Now
        </h1>
        <div className="px-3 ">
          <table className="w-full px-5 my-5 max-sm:px-0">
            <thead className="text-left">
              <tr>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody className="my-5 max-sm:w-full ">
              {audios.map((value: any, index: number) => (
                <SongList
                  key={index}
                  value={value}
                  index={index + 1}
                  user={user}
                  userPlaylists={userPlaylists}
                  setUserPlaylists={setUserPlaylists}
                />
              ))}
            </tbody>
          </table>
        </div>
        <div className="flex items-center justify-center h-full py-[20px]">
          {/* <button className="text-[#F50537] font-[400] text-[14px]">
            Explore more
          </button> */}
        </div>
      </div>
    </div>
  );
};

export default MostPlayedSongList;
