import { AssetTypes } from "constant/AssetTypes";
import { Notification } from "constant/notifications";
import { RouteName } from "constant/routeNames";
import { useState } from "react";
import { BsFillPlayFill } from "react-icons/bs";
import { CgMediaLive } from "react-icons/cg";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updateFavorites } from "redux/userRedux";
import LikeService from "services/music/LikeService";
import VideoService from "services/video/VideoService";
import {
  triggerErrorNotification,
  triggerSuccessNotification,
} from "utils/notification";

function HeroSliderLeft({ data }: any) {
  console.log("first", data);
  const navigate = useNavigate();
  // const favorite = useSelector((state: any) => state?.user?.userFavorites);
  // const [addFav, setAddFav] = useState<boolean>(false);
  const dispatch = useDispatch<any>();

  const channelClicked = async () => {
    navigate(RouteName.LiveTVPlayerLinkWithSlug.replace(":slug", data.slug));
  };

  const movieClicked = async () => {
    navigate(RouteName.WatchMovies.replace(":slug", data.slug));
  };

  const SeriesClicked = async () => {
    if (data?.first_episode) {
      navigate(
        RouteName.WatchTVSeries.replace(":slug", data.slug).replace(
          ":stream",
          data?.first_episode?.stream_key
        )
      );
    } else {
      navigate(RouteName.Welcome);
    }
  };

  const PodcastClicked = async () => {
    navigate(RouteName.PodcastOpenEpisodeRoute.replace(":id", data.podcast_id));
  };

  const musicClicked = async () => {
    navigate(RouteName.MusicTrackRoute.replace(":id", data.song_id));
  };

  // //Movies
  // const addFavMoviesToRedux = () => {
  //   const updatedMoviesData = [...favorite?.favorite_movies, data];

  //   const updatedFavorite = {
  //     ...favorite,
  //     favorite_movies: updatedMoviesData,
  //   };
  //   // Dispatch the action to update the 'favorite' object in the Redux store
  //   dispatch(updateFavorites(updatedFavorite));
  // };

  // const removeFavMoviesFromRedux = () => {
  //   const updatedMoviesData = favorite?.favorite_movies.filter(
  //     (favMovie: any) => favMovie.id !== data?.movie_id
  //   );
  //   const updatedFavorite = {
  //     ...favorite,
  //     favorite_movies: updatedMoviesData,
  //   };

  // Dispatch the action to update the 'favorite' object in the Redux store
  // dispatch(updateFavorites(updatedFavorite));
  // };

  // //TV Series
  // const addFavTvSeriesToRedux = () => {
  //   const updatedTvSeriesData = [...favorite?.favorite_tv_series, data];
  //   const updatedFavorite = {
  //     ...favorite,
  //     favorite_tv_series: updatedTvSeriesData,
  //   };
  //   // Dispatch the action to update the 'favorite' object in the Redux store
  //   dispatch(updateFavorites(updatedFavorite));
  // };

  // const removeFavTvSeriesFromRedux = () => {
  //   // const updatedLovedData = [...favorite?.loved?.data, song];
  //   const updatedTvSeriesData = favorite?.favorite_tv_series.filter(
  //     (favTvSeries: any) => favTvSeries.id !== data?.tvSeries_id
  //   );
  //   const updatedFavorite = {
  //     ...favorite,
  //     favorite_tv_series: updatedTvSeriesData,
  //   };

  //   // Dispatch the action to update the 'favorite' object in the Redux store
  //   dispatch(updateFavorites(updatedFavorite));
  // };

  // const handleMovieFavorite = () => {
  //   const favoriteTvSeries = {
  //     id: data?.tvSeries_id,
  //     object_type: 'tv_show',
  //   };
  //   const favoriteMovis = {
  //     id: data?.movie_id,
  //     object_type: 'movie',
  //   };
  //   // console.log(favoriteMovis);

  //   if (!addFav) {
  //     setAddFav(true);
  //     if (data.type === AssetTypes.TVSeries) {
  //       VideoService.likeTVSeries(favoriteTvSeries)
  //         .then((res) => {
  //           setAddFav(true);
  //           // toast.info(`You Liked ${title}`);
  //           addFavTvSeriesToRedux();
  //           triggerSuccessNotification(Notification.AddToFav);
  //         })
  //         .catch((e) => {
  //           setAddFav(false);
  //           triggerErrorNotification(Notification.FavError);
  //         });
  //     } else if (data.type === AssetTypes.Movie) {
  //       VideoService.likeMovie(favoriteMovis)
  //         .then((res) => {
  //           setAddFav(true);
  //           // toast.info(`You Liked ${title}`);
  //           addFavMoviesToRedux();
  //           triggerSuccessNotification(Notification.AddToFav);
  //         })
  //         .catch((e) => {
  //           setAddFav(false);
  //           triggerErrorNotification(Notification.FavError);
  //         });
  //     }
  //   } else {
  //     setAddFav(false);
  //     if (data.type === AssetTypes.TVSeries) {
  //       VideoService.unlikeTVSeries(favoriteTvSeries)
  //         .then((res) => {
  //           triggerSuccessNotification(Notification.RemovedFromFav);
  //           removeFavTvSeriesFromRedux();
  //           setAddFav(false);
  //         })
  //         .catch((e) => {
  //           setAddFav(true);
  //           triggerErrorNotification(Notification.FavError);
  //         });
  //     } else if (data.type === AssetTypes.Movie) {
  //       VideoService.unlikeMovie(favoriteMovis)
  //         .then((res) => {
  //           triggerSuccessNotification(Notification.RemovedFromFav);
  //           removeFavMoviesFromRedux();
  //           setAddFav(false);
  //         })
  //         .catch((e) => {
  //           setAddFav(true);
  //           triggerErrorNotification(Notification.FavError);
  //         });
  //     }
  //     // setIsLiked(false);
  //   }
  // };

  // //Music
  // const addFavMusicToRedux = () => {
  //   const updatedLovedData = [...favorite?.loved?.data, data];
  //   const updatedFavorite = {
  //     ...favorite,
  //     loved: {
  //       ...favorite.loved,
  //       data: updatedLovedData,
  //     },
  //   };
  //   // Dispatch the action to update the 'favorite' object in the Redux store
  //   dispatch(updateFavorites(updatedFavorite));
  // };

  // const removeFavMusicFromRedux = () => {
  //   // const updatedLovedData = [...favorite?.loved?.data, song];
  //   const updatedLovedData = favorite?.loved?.data.filter(
  //     (favSong: any) => favSong.id !== data?.song_id
  //   );
  //   const updatedFavorite = {
  //     ...favorite,
  //     loved: {
  //       ...favorite.loved,
  //       data: updatedLovedData,
  //     },
  //   };
  //   // Dispatch the action to update the 'favorite' object in the Redux store
  //   dispatch(updateFavorites(updatedFavorite));
  // };

  // const handleMusicFavorite = () => {
  //   const favoriteSong = {
  //     id: data?.song_id.toString(),
  //     object_type: 'song',
  //   };

  //   if (!addFav) {
  //     setAddFav(true);
  //     LikeService.likeByUserId(favoriteSong).then((res) => {
  //       if (res.status) {
  //         setAddFav(true);
  //         // updateFav();
  //         addFavMusicToRedux();
  //         triggerSuccessNotification(Notification.AddToFav);
  //       } else {
  //         setAddFav(false);
  //         triggerErrorNotification(Notification.FavError);
  //       }
  //     });
  //   } else {
  //     setAddFav(false);
  //     LikeService.unLikeByUserId(favoriteSong).then((res) => {
  //       if (res.status) {
  //         triggerSuccessNotification(Notification.RemovedFromFav);
  //         // updateFav();
  //         removeFavMusicFromRedux();
  //         setAddFav(false);
  //       } else {
  //         setAddFav(true);
  //         triggerErrorNotification(Notification.FavError);
  //       }
  //     });
  //   }
  // };

  // //Podcast
  // const addFavPodcastToRedux = () => {
  //   const updatedPodcastData = [...favorite?.favorite_podcasts, data];
  //   const updatedFavorite = {
  //     ...favorite,
  //     favorite_podcasts: updatedPodcastData,
  //   };
  //   // Dispatch the action to update the 'favorite' object in the Redux store
  //   dispatch(updateFavorites(updatedFavorite));
  // };

  // const removeFavPodcastFromRedux = () => {
  //   // const updatedPodcastData = [...favorite?.favorite_podcasts, podcast];
  //   const updatedPodcastData = favorite?.favorite_podcasts.filter(
  //     (favpodcast: any) => favpodcast.id !== data?.podcast_id
  //   );
  //   const updatedFavorite = {
  //     ...favorite,
  //     favorite_podcasts: updatedPodcastData,
  //   };
  //   // Dispatch the action to update the 'favorite' object in the Redux store
  //   dispatch(updateFavorites(updatedFavorite));
  // };

  // const handlePodcastFavorite = (e: any) => {
  //   e.preventDefault();
  //   console.log(addFav);
  //   const favoritepodcast = {
  //     id: data?.podcast_id.toString(),
  //     object_type: 'podcast',
  //   };

  //   if (!addFav) {
  //     setAddFav(true);
  //     LikeService.likeByUserId(favoritepodcast).then((res) => {
  //       if (res.status) {
  //         setAddFav(true);
  //         // updateFav();
  //         addFavPodcastToRedux();
  //         triggerSuccessNotification(Notification.AddToFav);
  //       } else {
  //         setAddFav(false);
  //         triggerErrorNotification(Notification.FavError);
  //       }
  //     });
  //   } else {
  //     setAddFav(false);
  //     LikeService.unLikeByUserId(favoritepodcast).then((res) => {
  //       if (res.status) {
  //         triggerSuccessNotification(Notification.RemovedFromFav);
  //         // updateFav();
  //         removeFavPodcastFromRedux();
  //         setAddFav(false);
  //       } else {
  //         setAddFav(true);
  //         triggerErrorNotification(Notification.FavError);
  //       }
  //     });
  //   }
  // };

  //Radio
  // const addFavRadioToRedux = () => {
  //   const updatedStationData = [...favorite?.favorite_radio_stations, data];
  //   const updatedFavorite = {
  //     ...favorite,
  //     favorite_radio_stations: updatedStationData,
  //   };
  //   // Dispatch the action to update the 'favorite' object in the Redux store
  //   dispatch(updateFavorites(updatedFavorite));
  // };

  // const removeFavRadioFromRedux = () => {
  //   // const updatedPodcastData = [...favorite?.favorite_podcasts, podcast];
  //   const updatedStationData = favorite?.favorite_radio_stations.filter(
  //     (favRadio: any) => favRadio.id !== station.id
  //   );
  //   const updatedFavorite = {
  //     ...favorite,
  //     favorite_radio_stations: updatedStationData,
  //   };
  //   // Dispatch the action to update the 'favorite' object in the Redux store
  //   dispatch(updateFavorites(updatedFavorite));
  // };

  // const handleRadioFavorite = async () => {
  //   const favoriteRadioStation = {
  //     id: station.id.toString(),
  //     object_type: "radio",
  //   };

  //   if (!addFav) {
  //     setAddFav(true);
  //     RadioService.likeRadio(favoriteRadioStation).then((res) => {
  //       if (res.status) {
  //         setAddFav(true);
  //         // updateFav();
  //         addFavToRedux();
  //         triggerSuccessNotification(Notification.AddToFav);
  //       } else {
  //         setAddFav(false);
  //         triggerErrorNotification(Notification.FavError);
  //       }
  //     });
  //   } else {
  //     setAddFav(false);
  //     RadioService.unlikeRadio(favoriteRadioStation).then((res) => {
  //       if (res.status) {
  //         triggerSuccessNotification(Notification.RemovedFromFav);
  //         // updateFav();
  //         removeFavFromRedux();
  //         setAddFav(false);
  //       } else {
  //         setAddFav(true);
  //         triggerErrorNotification(Notification.FavError);
  //       }
  //     });
  //   }
  // };

  return (
    <div
      className="flex h-[65vh] w-full -left-[400px] -top-[350px]  rounded-lg overflow-hidden"
      style={{
        backgroundImage: `url(${data.banner})`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <div className=" text-white pl-[32px] bottom-0 py-8 absolute w-full bg-gradient-to-t from-black to-transparent cursor-pointer max-sm:w-full overflow-hidden max-sm:pl-[20px]">
        {data.caption ? (
          <p className="bg-[#F50537] inline-block px-3 text-[11px] items-center p-1 text-center">
            {data.caption
              .replace(/&amp;/g, "&")
              .replace(/&rsquo;/g, "’")
              .replace(/&nbsp;/g, "")}
          </p>
        ) : (
          <></>
        )}

        {data.date ? <p className="mt-[10px]">{data.date}</p> : ""}

        <p className="text-4xl font-rubik leading-tight font-bold mt-[10px] max-sm:text-[25px]">
          {data.title
            .replace(/&amp;/g, "&")
            .replace(/&rsquo;/g, "’")
            .replace(/&nbsp;/g, "")}
        </p>
        <p className="w-[630px] mt-[10px] pr-3 leading-normal max-sm:text-[13px] max-sm:w-full max-sm:pr-3 max-sm:mb-10 max-md:w-full">
          {data.description
            .replace(/&amp;/g, "&")
            .replace(/&rsquo;/g, "’")
            .replace(/&nbsp;/g, "")}
        </p>

        <div className="mt-[10px] flex ">
          <div className="flex">
            <button className="w-[162px] h-[42px] bg-white  rounded-3xl hover:scale-105 hover:shadow-xl max-sm:w-[145px] ">
              {data.type === "Live TV" ? (
                <>
                  <p
                    className="flex items-center justify-center"
                    onClick={channelClicked}
                  >
                    <CgMediaLive className="stream-icon-hero" />
                    <span className="ml-2 text-black">{data.cta}</span>
                  </p>
                </>
              ) : data.type === AssetTypes.TVSeries ? (
                <>
                  <p
                    className="flex items-center justify-center"
                    onClick={SeriesClicked}
                  >
                    <BsFillPlayFill className="stream-icon-hero" />
                    <span className="ml-2 text-black">{data.cta}</span>
                  </p>
                </>
              ) : data.type === AssetTypes.Podcast ? (
                <>
                  <p
                    className="flex items-center justify-center"
                    onClick={PodcastClicked}
                  >
                    <BsFillPlayFill className="stream-icon-hero" />
                    <span className="ml-2 text-black">{data.cta}</span>
                  </p>
                </>
              ) : data.type === AssetTypes.Music ? (
                <>
                  <p
                    className="flex items-center justify-center"
                    onClick={musicClicked}
                  >
                    <BsFillPlayFill className="stream-icon-hero" />
                    <span className="ml-2 text-black">{data.cta}</span>
                  </p>
                </>
              ) : data.type === AssetTypes.Movie ? (
                <>
                  <p
                    className="flex items-center justify-center"
                    onClick={movieClicked}
                  >
                    <BsFillPlayFill className="stream-icon-hero" />
                    <span className="ml-2 text-black">{data.cta}</span>
                  </p>
                </>
              ) : (
                <>
                  <a
                    href={data.url}
                    target="_blank"
                    rel="noreferrer"
                    className="flex items-center justify-center"
                  >
                    <BsFillPlayFill className="stream-icon-hero" />
                    <span className="ml-2 text-black">{data.cta}</span>
                  </a>
                </>
              )}
            </button>
          </div>
          {/* {data.type === AssetTypes.LiveTV || data.type === AssetTypes.Other ? (
            <></>
          ) : (
            <>
              {data.type === AssetTypes.TVSeries ? (
                <>
                  <button
                    className="w-[162px] h-[42px] border-white border-solid border-2 text-white rounded-3xl ml-3 hover:scale-105 hover:shadow-xl"
                    onClick={handleMovieFavorite}
                  >
                    {addFav ? 'Added to favorite' : 'Add to favorites'}
                  </button>
                </>
              ) : data.type === AssetTypes.Movie ? (
                <>
                  <button
                    className="w-[162px] h-[42px] border-white border-solid border-2 text-white rounded-3xl ml-3 hover:scale-105 hover:shadow-xl"
                    onClick={handleMovieFavorite}
                  >
                    {addFav ? 'Added to favorite' : 'Add to favorites'}
                  </button>
                </>
              ) : data.type === AssetTypes.Music ? (
                <>
                  <button
                    className="w-[162px] h-[42px] border-white border-solid border-2 text-white rounded-3xl ml-3 hover:scale-105 hover:shadow-xl"
                    onClick={handleMusicFavorite}
                  >
                    {addFav ? 'Added to favorite' : 'Add to favorites'}
                  </button>
                </>
              ) : data.type === AssetTypes.Podcast ? (
                <>
                  <button
                    className="w-[162px] h-[42px] border-white border-solid border-2 text-white rounded-3xl ml-3 hover:scale-105 hover:shadow-xl"
                    onClick={handlePodcastFavorite}
                  >
                    {addFav ? 'Added to favorite' : 'Add to favorites'}
                  </button>
                </>
              ) : ( */}
          {/* data.type === AssetTypes.Radio ? ( */}
          {/* <> */}
          {/* <button */}
          {/* className="w-[162px] h-[42px] border-white border-solid border-2 text-white rounded-3xl ml-3 hover:scale-105 hover:shadow-xl"
                       onClick={handlePodcastFavorite}
                     >
                       {addFav ? "Added to favorite" : "Add to favorite"}
                     </button>
                   </>
                 )
                 :
                 <></> */}
          {/* )} */}
          {/* </> */}
          {/* )} */}
        </div>
      </div>
    </div>
  );
}

export default HeroSliderLeft;
