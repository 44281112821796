import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import PlaylistCard from "./PlaylistCard";

function SongQueue({ musics, currentSong, music, radio, podcast }: any) {
  return (
    <>
      <div
        className={`h-[100px] py-2 gap-5 bg-[#343434] text-white max-sm:hidden`}
      >
        <Swiper
          slidesPerView={5}
          breakpoints={{
            0: {
              slidesPerView: 1,
            },
            650: {
              slidesPerView: 2,
            },
            1000: {
              slidesPerView: 3,
            },
            1100: {
              slidesPerView: 3,
            },
            1200: {
              slidesPerView: 4,
            },
            1500: {
              slidesPerView: 5,
            },
            1600: {
              slidesPerView: 6,
            },
          }}
          modules={[Pagination]}
          className="mySwiper"
        >
          {musics?.map((song: any, i: number) => (
            <div key={i}>
              <SwiperSlide key={song.id}>
                <PlaylistCard
                  index={i}
                  song={song}
                  currentSong={currentSong}
                  QueueCount={musics.length}
                  music={music}
                  radio={radio}
                  podcast={podcast}
                />
              </SwiperSlide>
            </div>
          ))}
        </Swiper>
      </div>
      <div className="sm:hidden bg-[#343434]">
        {musics.map((song: any, i: number) => (
          <PlaylistCard
            index={i}
            song={song}
            currentSong={currentSong}
            key={i}
            QueueCount={musics.length}
            music={music}
            radio={radio}
            podcast={podcast}
          />
        ))}
      </div>
    </>
  );
}

export default SongQueue;
