import axios from "axios";
import authHeader from "utils/auth-header";

axios.defaults.baseURL = process.env.REACT_APP_BACKEND_SERVER;
const BASE_URL = process.env.REACT_APP_BACKEND_SERVER;

const getAllPayperviewCategories = async () => {
  const response = await axios({
    method: "get",
    url: `${BASE_URL}/pay-per-view/categories`,
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
  });
  return response.data;
};

const getAllPayperviewFeatured = async () => {
  const response = await axios({
    method: "get",
    url: `${BASE_URL}/ppv-events/featured`,
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
  });
  return response.data;
};

const getCategoryBySlug = async (slug: any) => {
  const response = await axios({
    method: "get",
    url: `${BASE_URL}/ppv-events/category/${slug}`,
    headers: {
      "Content-Type": "application/jason; charset =utf-8",
    },
  });
  return response.data;
};

const getEventByEventSlug = async (event_slug: any) => {
  const response = await axios({
    method: "get",
    url: `${BASE_URL}/ppv-events/show/${event_slug}`,
    headers: {
      "Content-Type": "application/jason; charset =utf-8",
    },
  });
  return response.data;
};

const getAllPayperviewEvents = async () => {
  const response = await axios({
    method: "get",
    url: `${BASE_URL}/ppv-events/all`,
    headers: {
      "Content-Type": "application/jason; charset =utf-8",
    },
  });
  return response.data;
};
const getAllPayperviewEventsByPageNo = async (pageNo:any) => {
  const response = await axios({
    method: "get",
    url: `${BASE_URL}/ppv-events/all?page=${pageNo}`,
    headers: {
      "Content-Type": "application/jason; charset =utf-8",
    },
  });
  return response.data;
};

const getPurchasedEvents = async (user_id: any) => {
  try {
    const response = await axios({
      method: "get",
      url: `${BASE_URL}/users/purchased/events/${user_id}`,
      headers: authHeader(),
    });
    return response.data;
  } catch (error) {
    // Handle the error here
    console.error("getPurchasedEvents - Error occurred during payment:", error);
    throw error; // You can rethrow the error or handle it as needed
  }
};

const hasPurchasedEvent = async (content_id: any) => {
  try {
    const response = await axios({
      method: "get",
      url: `${BASE_URL}/has-purchased/${content_id}`,
      headers: authHeader(),
    });
    return response.data;
  } catch (error) {
    console.error("hasPurchasedEvent - Error occurred :", error);
    //throw error;
  }
};
const hasPurchasedMovie = async (content_id: any) => {
  try {
    const response = await axios({
      method: "get",
      url: `${BASE_URL}/rental-status/${content_id}`,
      headers: authHeader(),
    });
    return response.data;
  } catch (error) {
    console.error("hasPurchasedMovie - Error occurred :", error);
    //throw error;
  }
};

const likePpvEvent = async (data: any) => {
  const response = await axios({
    method: "post",
    data: data,
    url: `${BASE_URL}/ppv/like`,
    headers: authHeader(),
  });

  return response.data;
};
const unLikePpvEvent = async (data: any) => {
  const response = await axios({
    method: "post",
    data: data,
    url: `${BASE_URL}/ppv/unlike`,
    headers: authHeader(),
  });

  return response.data;
};

const verifyEventTicket = async (data: any) => {
  try {
    const response = await axios({
      method: "post",
      data: data,
      url: `${BASE_URL}/ppv-event/verify-ticket`,
      headers: authHeader(),
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

const paymentTransection = async () => {
  const response = await axios({
    method: "get",
    url: `${BASE_URL}/payment/transactions`,
    headers: authHeader(),
  });

  return response.data;
};

const PayperviewService = {
  getAllPayperviewCategories,
  getCategoryBySlug,
  getEventByEventSlug,
  getAllPayperviewEvents,
  getPurchasedEvents,
  hasPurchasedEvent,
  verifyEventTicket,
  getAllPayperviewFeatured,
  getAllPayperviewEventsByPageNo,
  likePpvEvent,
  unLikePpvEvent,
  hasPurchasedMovie,
  paymentTransection
};

export default PayperviewService;
