
function AnimatedText({ text, classname }: any) {
  return (
    <div className="scroll-container">
      <p className={`${classname}`}>{text}</p>
    </div>
  );
}

export default AnimatedText;
