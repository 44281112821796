import React, { ReactNode, useEffect } from "react";
import { AiOutlineClose } from "react-icons/ai";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  video_URL: string;
  title: string;
}
const PpvVideoModal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  video_URL,
  title,
}) => {
  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }

    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, [isOpen]);

  return isOpen ? (
    <div className="fixed inset-0 !z-100 flex items-center justify-center w-full login_modal" onClick={onClose}>
      {/* Backdrop */}
      <div className="fixed inset-0 bg-transparent opacity-50"></div>

      {/* Modal */}
      <div className="fixed inset-0 flex items-center justify-center backdrop-blur">
        <div
          className={`relative w-[800px] max-sm:w-full px-6 py-3 bg-black rounded-lg shadow-xl`}
        >
          <button
            className="absolute text-gray-300 top-6 right-6 hover:text-gray-700"
            onClick={onClose}
          >
            <AiOutlineClose className="text-[15px] font-extrabold" />
          </button>
          <div className="mt-4">
            <iframe
              className="responsive-ppvtrailer"
              src={video_URL}
              allow="autoplay;"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default PpvVideoModal;
