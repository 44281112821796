import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ClearQueueButton from "../Components/OtherButtons/ClearQueueButton";
import FullScreenButton from "../Components/OtherButtons/FullScreenButton";
import AddSongToFav from "pages/musicPage/shared/AddSongToFav";
import VolumeButton from "../Components/OtherButtons/VolumeButton";
import VisibleQueueButton from "../Components/OtherButtons/VisibleQueueButton";
import AddRadioToFavPlayer from "pages/radioPage/RadioPlayer/AddRadioToFavPlayer";

function OtherButtons({
  audioRef,
  showQueue,
  visibleQueue,
  isMuted,
  setIsMuted,
  toggleMute,
  fullScreen,
  setFullScreen,
  currentAudio,
  playlist,
  music,
  radio,
  podcast,
}: any) {
  const loggedInUser = useSelector((state: any) => state?.user?.currentUser);
  const userFavorites = useSelector((state: any) => state?.user?.userFavorites);
  const [favSongs, setFavSongs] = useState<any>();

  useEffect(() => {
    if (loggedInUser) {
      const favoriteMusicIds = userFavorites?.loved?.data.map(
        (item: any) => item.id
      );
      setFavSongs(favoriteMusicIds);
    }
  }, [currentAudio, loggedInUser]);

  const handleFullScreen = () => {
    setFullScreen(!fullScreen);
  };
  return (
    <div className="flex items-center justify-end gap-3 text-xl max-md:hidden">
      {/* clear queue */}
      <ClearQueueButton spinSize={20} />
      {/* full screen */}
      {!radio && <FullScreenButton handleFullScreen={handleFullScreen} />}
      {/* favorite */}
      {music && <AddSongToFav song={currentAudio} favorite={favSongs} />}
      {radio && <AddRadioToFavPlayer station={currentAudio} Icon={true} />}

      {/* volume */}
      <VolumeButton
        isMuted={isMuted}
        toggleMute={toggleMute}
        audioRef={audioRef}
        setIsMuted={setIsMuted}
      />

      {!radio && playlist?.length > 1 && (
        <>
          {/* visible queue */}
          <VisibleQueueButton
            visibleQueue={visibleQueue}
            executeFunction={showQueue}
          />
        </>
      )}
    </div>
  );
}

export default OtherButtons;
