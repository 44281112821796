const EpisodicSkeleton = () => {
  return (
    <div className="flex flex-col">
      <div className="lg:w-[50px] w-[50px] h-[50px] rounded-full bg-[#1a1919]  animate-pulse mt-2"></div>
      <div className="flex lg:flex-row max-sm:flex-col ">
        <div className="flex lg:flex-row lg:justify-between max-sm:justify-start lg:items-start">
          <div className="flex flex-col items-center justify-center mt-10 max-sm:flex max-sm:flex-col max-sm:items-center max-sm:justify-center">
            <div className="lg:w-[200px]  max-sm:w-[150px] h-[200px]  rounded-[10px] bg-[#1a1919]  animate-pulse mt-2"></div>
            <div className="lg:w-[300px]  max-sm:w-[200px]  h-[30px] bg-[#1a1919]  animate-pulse mt-6"></div>
            <div className="lg:w-[280px]  max-sm:w-[200px]  h-[30px] bg-[#1a1919]  animate-pulse mt-1"></div>
            <div className="flex flex-col items-center mt-6 max-sm:w-[350px]">
              <div className="lg:w-[350px]  h-[15px] bg-[#1a1919]  animate-pulse mt-2"></div>
              <div className="lg:w-[350px]  h-[15px] bg-[#1a1919]  animate-pulse mt-2"></div>
              <div className="lg:w-[350px]  h-[15px] bg-[#1a1919]  animate-pulse mt-2"></div>
              <div className="lg:w-[350px]  h-[15px] bg-[#1a1919]  animate-pulse mt-2"></div>
              <div className="lg:w-[150px]  h-[15px] bg-[#1a1919]  animate-pulse mt-2"></div>
            </div>
            <div className="w-[150px] max-sm:w-[150px] h-[50px] rounded-[40px] bg-[#1a1919]  animate-pulse mt-4"></div>
            <div className="w-[250px] max-sm:w-[150px] h-[50px] rounded-[40px] bg-[#1a1919]  animate-pulse mt-6"></div>
          </div>
        </div>
        <div className="flex flex-col gap-10">
          <div className="flex flex-row lg:ml-[200px] gap-1">
            <div>
              <div className="lg:w-[450px] max-sm:w-[250px] h-[30px] bg-[#1a1919]  animate-pulse mt-4"></div>
              <div className="lg:w-[250px] max-sm:w-[150px] h-[30px] bg-[#1a1919]  animate-pulse mt-4"></div>
              <div className="lg:w-[550px] max-sm:w-[300px] h-[15px] bg-[#1a1919]  animate-pulse mt-4"></div>
              <div className="lg:w-[550px] max-sm:w-[300px] h-[15px] bg-[#1a1919]  animate-pulse mt-4"></div>
            </div>
            <div className="lg:ml-[200px] max-sm:ml-[70px] mt-[50px]">
              <div className="lg:w-[30px] max-sm:w-[30px] h-[30px] bg-[#1a1919]  animate-pulse mt-4"></div>
            </div>
          </div>
          <div className="flex flex-row lg:ml-[200px]">
            <div>
              <div className="lg:w-[450px] max-sm:w-[250px] h-[30px] bg-[#1a1919]  animate-pulse mt-4"></div>
              <div className="lg:w-[250px] max-sm:w-[150px] h-[30px] bg-[#1a1919]  animate-pulse mt-4"></div>
              <div className="lg:w-[550px] max-sm:w-[300px] h-[15px] bg-[#1a1919]  animate-pulse mt-4"></div>
              <div className="lg:w-[550px] max-sm:w-[300px] h-[15px] bg-[#1a1919]  animate-pulse mt-4"></div>
            </div>
            <div className="lg:ml-[200px] max-sm:ml-[70px] mt-[50px]">
              <div className="lg:w-[30px] max-sm:w-[30px] h-[30px] bg-[#1a1919]  animate-pulse mt-4"></div>
            </div>
          </div>

          <div className="flex flex-row lg:ml-[200px]">
            <div>
              <div className="lg:w-[450px] max-sm:w-[250px] h-[30px] bg-[#1a1919]  animate-pulse mt-4"></div>
              <div className="lg:w-[250px] max-sm:w-[150px] h-[30px] bg-[#1a1919]  animate-pulse mt-4"></div>
              <div className="lg:w-[550px] max-sm:w-[300px] h-[15px] bg-[#1a1919]  animate-pulse mt-4"></div>
              <div className="lg:w-[550px] max-sm:w-[300px] h-[15px] bg-[#1a1919]  animate-pulse mt-4"></div>
            </div>
            <div className="lg:ml-[200px] max-sm:ml-[70px] mt-[50px]">
              <div className="lg:w-[30px] max-sm:w-[30px] h-[30px] bg-[#1a1919]  animate-pulse mt-4"></div>
            </div>
          </div>

          <div className="flex flex-row lg:ml-[200px]">
            <div>
              <div className="lg:w-[450px] max-sm:w-[250px] h-[30px] bg-[#1a1919]  animate-pulse mt-4"></div>
              <div className="lg:w-[250px] max-sm:w-[150px] h-[30px] bg-[#1a1919]  animate-pulse mt-4"></div>
              <div className="lg:w-[550px] max-sm:w-[300px] h-[15px] bg-[#1a1919]  animate-pulse mt-4"></div>
              <div className="lg:w-[550px] max-sm:w-[300px] h-[15px] bg-[#1a1919]  animate-pulse mt-4"></div>
            </div>
            <div className="ml-[200px] max-sm:ml-[70px] mt-[50px]">
              <div className="lg:w-[30px] max-sm:w-[30px] h-[30px] bg-[#1a1919]  animate-pulse mt-4"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EpisodicSkeleton;
