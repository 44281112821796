import LazyLoadImg from "components/LazyLoadImg";
import { RouteName } from "constant/routeNames";
import React from "react";
import { Link } from "react-router-dom";

const CreatorCard = ({ isLoading, creator }: any) => {
  // console.log(creator);
  return (
    <>
      {!isLoading && (
        <div>
          {creator && (
            <Link
              to={RouteName.VideoCreatorHomeRoute.replace(
                ":username",
                `@${creator.username}`
              )}
            >
              <div className="flex items-center justify-center w-full mx-auto text-center">
                <div className="flex-col items-center justify-center mx-auto rounded-full ">
                  {/* image */}
                  <div className=" w-[116px] h-[116px] items-center justify-center rounded-full overflow-hidden mx-auto">
                    <LazyLoadImg
                      imgSrc={creator.thumbnail_url}
                      imgAlt={"Artist"}
                      imgHeight={"116px"}
                      imgWidth={"116px"}
                      className="w-[116px] h-full drop-shadow-2xl object-cover overflow-hidden !rounded-full"
                      style={{
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        width: "100%",
                        height: "100%",
                        borderRadius: "5px",
                        objectFit: "cover",
                      }}
                    />
                  </div>
                  <p
                    className=" mt-2 text-white font-[600] text-[14px] w-full justify-center text-center"
                    // dangerouslySetInnerHTML={{ __html: creator?.title }}
                  >
                    {creator?.title}
                  </p>
                </div>
              </div>
            </Link>
          )}
        </div>
      )}
    </>
  );
};

export default CreatorCard;
