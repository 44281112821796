import BackButton from "components/shared/BackButton";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import LikeService from "services/music/LikeService";
import PodcastService from "services/podcast/PodcastService";
import Podcastcard from "./shared/Podcastcard";
import NewPodcastSkeleton from "components/skeleton/NewPodcastSkeleton";
import { Helmet } from "react-helmet-async";
import Pagination from "./shared/Pagination";
import GenreSelector from "pages/videoPage/shared/GenreSelector";
import GeneralServices from "services/General/GeneralServices";
import { Adsense } from "@ctrl/react-adsense";
import { RouteName } from "constant/routeNames";

function SeeAllNewPodcast() {
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const page = searchParams.get("page") || "1";
  const pageNoInt = parseInt(page, 10);
  const loggedInUser = useSelector((state: any) => state?.user?.currentUser);
  const [podcasts, setPodcasts] = useState<any>([]);
  const [lastPage, setLastPage] = useState<any>();
  const [currentPage, setCurrentPage] = useState(pageNoInt);
  const [isLoading, setIsLoading] = useState<any>(false);
  const [genres, setGenres] = useState<any>();
  const [selectedGenre, setSelectedGenre] = useState("");
  const [gAdSlot, setgAdSlot] = useState<any>();

  const currentUrl = window.location.pathname;
  const path = currentUrl + `?page=${currentPage}`;

  useEffect(() => {
    const handlePopstate = () => {
      const newPage =
        new URLSearchParams(window.location.search).get("page") || "1";
      setCurrentPage(parseInt(newPage, 10));
    };

    window.addEventListener("popstate", handlePopstate);

    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, []);
  useEffect(() => {
    GeneralServices.getAllConfigData()
      .then((res) => {
        const PageStatus = res?.data?.filter(
          (item: any) =>
            item?.title === "is_podcast_gAd" && item?.value !== null
        );
        if (PageStatus[0]?.value === "1") {
          const filteredData = res?.data?.filter(
            (item: any) => item?.title === "gAdSlot" && item?.value !== null
          );
          setgAdSlot(filteredData[0]?.value);
          console.log("setgAdStatus", filteredData[0]?.value);
        }
      })
      .catch((e) => {});
  }, []);

  useEffect(() => {
    PodcastService.getAllCategories().then((res) => {
      setGenres(res.data);
    });
  }, []);
  useEffect(() => {
    navigate(RouteName.SeeAllNewPodcastRoute + `?page=${currentPage}`);
  }, [currentPage, navigate]);
  useEffect(() => {
    // setTimeout(() => {
    setIsLoading(true);
    if (selectedGenre) {
      PodcastService.getPodcastByGenreSlug(selectedGenre).then((res) => {
        setIsLoading(false);
        setPodcasts(res.podcasts.data);
        // console.log(res.podcasts.data);
        setLastPage(1);
      });
    } else {
      PodcastService.getAllPodcasts(currentPage).then((res) => {
        setIsLoading(false);
        setPodcasts(res.data.data);
        setLastPage(res.data.last_page);
      });
    }
    // console.log(slug);
    // }, 1000);
  }, [selectedGenre, currentPage]);
  // console.log(selectedGenre);
  // like function
  const [favorite, setFavorite] = useState<any>();

  useEffect(() => {
    if (loggedInUser) {
      LikeService.getFavoriteById(loggedInUser?.id, loggedInUser?.id)
        .then((res) => {
          const favoritesIds = res?.profile?.favorite_podcasts?.map(
            (item: any) => item?.id
          );
          setFavorite(favoritesIds);
        })
        .catch(() => {});
    }
  }, [loggedInUser]);

  const FE_URL = process.env.REACT_APP_FRONTEND_URL;

  return (
    <>
      <Helmet>
        <title>All Podcasts - AYOZAT Podcast</title>
        <meta property="og:type" content="website" />
        <meta
          name="description"
          content="AYOZAT Streaming Platform - Watch free movies and TV shows online in HD on any device anytime. Free movie website search is over."
        />
        <link rel="canonical" href={`${FE_URL}/music/new-podcast`} />
        <meta property="og:title" content=" All Podcasts - AYOZAT Podcast" />
        <meta
          property="og:description"
          content="AYOZAT Streaming Platform - Watch free movies and TV shows online in HD on any device anytime. Free movie website search is over."
        />
        <meta property="og:image" content="https://ayozat.co.uk/logo192.png" />
        <meta property="og:url" content={`${FE_URL}/music/new-podcast`} />
        <meta property="og:type" content="website" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content=" All Podcasts - AYOZAT Podcast" />
        <meta
          name="twitter:description"
          content="AYOZAT Streaming Platform - Watch free movies and TV shows online in HD on any device anytime. Free movie website search is over."
        />
        <meta name="twitter:image" content="https://ayozat.co.uk/logo192.png" />
        <meta name="twitter:site" content="AYOZAT" />
        {/* <meta property="twitter:url" content={`${FE_URL}/music`}/> */}
      </Helmet>

      <BackButton />
      {!isLoading && (
        <>
          <div>
            <div className="flex items-center justify-between mb-8 mr-5 max-sm:block max-sm:mr-0">
              <h1 className="text-2xl font-medium text-white lg:text-2xl md:text-2xl max-sm:w-[60%] max-sm:ml-2">
                All Podcasts
              </h1>
              <GenreSelector
                selectedGenre={selectedGenre}
                setSelectedGenre={setSelectedGenre}
                genres={genres}
              />
            </div>
            {gAdSlot && (
              <Adsense
                client="ca-pub-5773929890067376"
                slot={gAdSlot}
                style={{
                  display: "flex",
                  marginBottom: "20px",
                  marginTop: "20px",
                  height: "90px",
                  justifyContent: "center",
                }}
                format="horizontal"
                data-full-width-responsive="true"
                // format="fluid"
              />
            )}
          </div>
          {podcasts && (
            <div className="grid w-full grid-cols-2 mt-4 mb-28 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 2xl:grid-cols-7 max-sm:w-full">
              {podcasts?.map((podcast: any, index: any) => (
                <div key={index} className="mb-5 mr-4">
                  <Podcastcard
                    podcast={podcast}
                    user={loggedInUser}
                    favorite={favorite}
                  />
                </div>
              ))}
            </div>
          )}
          <div className="flex justify-center my-5">
            <Pagination
              totalPages={lastPage}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
            {/* {Array.from({ length: 3 }, (_, i) => (
          <button
            key={i}
            onClick={() => setCurrentPage(i + 1)}
            className={`mx-2 px-4 py-2 rounded ${
              currentPage === i + 1 ? "bg-[#f30543] text-white" : "bg-white"
            }`}
          >
            {i + 1}
          </button>
        ))}  */}
          </div>
        </>
      )}
      {isLoading && <NewPodcastSkeleton />}
    </>
  );
}

export default SeeAllNewPodcast;
