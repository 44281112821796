import { useEffect, useState } from "react";
import PodcastEpisoceSection from "./podcastOpenEpisode/PodcastEpisoceSection";
import LeftSection from "./podcastOpenEpisode/LeftSection";
import { useParams } from "react-router-dom";
import PodcastService from "services/podcast/PodcastService";
import { Helmet, HelmetProvider } from "react-helmet-async";
// import PodcastcardSkeleton from "components/skeleton/PodcardSkeleton";
// import RadioSkeleton from "components/skeleton/RadioSkeleton";
import EpisodicSkeleton from "components/skeleton/EpisodicSkeleton";
import SubText from "components/shared/SubText";
import SuggestedPodcastSection from "./shared/SuggestedPodcastSection";

function PodcastOpenEpisode() {
  const { id } = useParams();
  const [podcast, setPodcast] = useState<any>();
  const [episodes, setEpisodes] = useState<any>();
  const [isLoading, setIsLoading] = useState<any>(false);
  const FE_URL = process.env.REACT_APP_FRONTEND_URL;

  useEffect(() => {
    setIsLoading(true);
    PodcastService.getPodcastById(id).then((res) => {
      setIsLoading(false);
      setPodcast(res.podcast);
      setEpisodes(res.episodes);
      // console.log(res);
    });
  }, [id]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <>
      <Helmet>
        <title>
          {podcast?.title ? `${podcast?.title} - AYOZAT` : "AYOZAT"}
        </title>
        <meta name="title" content={podcast?.title} />
        <meta name="description" content={podcast?.description} />
        <link rel="canonical" href={`${FE_URL}/podcast/${podcast?.id}`} />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={podcast?.title} />
        <meta name="twitter:description" content={podcast?.description} />
        <meta name="twitter:image" content={podcast?.artwork_url} />
        <meta name="twitter:url" content={`${FE_URL}/podcast/${podcast?.id}`} />

        <meta property="og:title" content={podcast?.title} />
        <meta property="og:description" content={podcast?.description} />
        <meta property="og:image" content={podcast?.artwork_url} />
        <meta property="og:url" content={`${FE_URL}/podcast/${podcast?.id}`} />
        <meta property="og:type" content="website" />
      </Helmet>

      {!isLoading && (
        <>
          {podcast && (
            <>
              <div className="flex justify-between w-full gap-2 max-sm:block max-md:block max-lg:block">
                <div className="w-[35%] max-sm:w-full max-md:w-full max-lg:w-full">
                  <LeftSection
                    artwork_url={podcast?.artwork_url}
                    podcastId={podcast?.id}
                    title={podcast?.title}
                    description={podcast?.description}
                    noOfEpisodes={episodes?.total}
                    episodes={episodes?.data}
                    podcast={podcast}
                  />
                </div>
                <div className="w-[65%] mt-11 max-sm:w-full max-md:w-full max-lg:w-full ">
                  <PodcastEpisoceSection
                    episodes={episodes}
                    podcastId={podcast?.id}
                  />
                </div>
              </div>
              {
                <div className="my-5 mt-10">
                  <SubText
                    text={`You may also like these`}
                    className="font-light"
                  />
                  <SuggestedPodcastSection podcast={podcast} />
                </div>
              }
            </>
          )}
        </>
      )}
      {isLoading && <EpisodicSkeleton />}
    </>
  );
}

export default PodcastOpenEpisode;
