import React from 'react'

const PodcastGridSkeleton = () => {
    return (
        <div className='flex flex-col gap-y-2'>
            <div className='flex lg:w-[250px] h-[35px] w-[800px] bg-[#1a1919] animate-pulse'></div>
            <div className='flex lg:w-[100px] h-[20px] w-[800px] bg-[#1a1919] animate-pulse'></div>
            <div className='flex lg:w-[200px] h-[250px] w-[200] bg-[#1a1919] animate-pulse'></div>
        </div>
    )
}

export default PodcastGridSkeleton