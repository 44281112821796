import { RouteName } from "constant/routeNames";
import { Channel, ChannelBox, ChannelLogo } from "planby";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { useNavigate } from "react-router-dom";
import {
  setCurrentChannelId,
  setLiveTVSuccess,
  setVideoStart,
} from "redux/VideoPlayerRedux";

interface ChannelItemProps {
  channel: Channel;
}

function ChannelItem({ channel }: ChannelItemProps) {
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const { position, logo } = channel;

  const video: any = { ...channel };
  const { currentLiveTv } = useSelector((state: any) => state?.video);

  const channelClicked = async () => {
    await dispatch(setVideoStart());
    let newId: any = position.top / 80;
    await dispatch(setCurrentChannelId(newId));
    await dispatch(setLiveTVSuccess(video));

    navigate(RouteName.LiveTVPlayerLinkWithSlug.replace(":slug", video.slug));
  };

  return (
    <>
      <ChannelBox {...position}>
        <div
          className={`flex items-center overflow-hidden h-[70px] justify-between cursor-pointer bg-[#1a1919] hover:bg-slate-800 hover:shadow-xl text-gray-300 text-base font-thin rounded-md hover:text-white w-30 ${
            video?.uuid === currentLiveTv?.slug
              ? "bg-slate-800 border-2 border-red-500"
              : ""
          }`}
          onClick={channelClicked}
          data-tooltip-id={video.uuid}
        >
          <ChannelLogo
            src={logo}
            alt="Logo"
            className={`rounded-md w-[74px] h-[74px] object-cover`}
          />
          <p className="mx-4">{video.channelNumber}</p>
        </div>
      </ChannelBox>
      <ReactTooltip
        id={video.uuid}
        place="right"
        content={video.name}
        className="!z-50 bg-slate-700 opacity-100"
      />
    </>
  );
}

export default ChannelItem;
