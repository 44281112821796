import { FaPlus } from "react-icons/fa";

function AddToQueue() {
  return (
    <p className="flex items-center justify-center  text-[12px] mx-6 py-3 px-3 text-base font-bold text-white cursor-pointer max-sm:text-[15px] hover:text-red-600 duration-200 ease-in-out max-sm:mx-3 rounded-full max-sm:w-full">
      <FaPlus className="mr-3" /> Add to queue
    </p>
  );
}

export default AddToQueue;
