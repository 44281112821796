import LazyLoadImg from "components/LazyLoadImg";
import { RouteName } from "constant/routeNames";
import { Link } from "react-router-dom";
import AddPodcastToFav from "./AddPodcastToFav";
import { useSelector } from "react-redux";
import { Tooltip as ReactTooltip } from 'react-tooltip';

function Podcastcard({ podcast, user }: any) {
  const loggedInUser = useSelector((state: any) => state?.user?.currentUser);

  return (
    <Link to={RouteName.PodcastOpenEpisodeRoute.replace(":id", podcast.id)}>
      <div
        className="mb-[15px]  inline-block w-[176px] px-2 py-2 rounded-md cursor-pointer bg-[#1a1919] hover:bg-slate-800 hover:shadow-xl place-items-center shadow-lg"
        // onMouseEnter={() => setHover(true)}
        // onMouseLeave={() => setHover(false)}
      >
        {/* image */}
        <div
          className="relative w-[100%] rounded-lg h-[165px]  bg-img white-text max-sm:w-[98%] max-sm:h-[180px]"
          // style={{
          //   background: `url(${podcast.artwork_url}) no-repeat center`,
          //   backgroundSize: "cover",
          //   backgroundPosition: "center",
          // }}
        >
          <LazyLoadImg
            imgSrc={podcast.artwork_url}
            imgAlt={podcast?.title}
            imgHeight={"165px"}
            imgWidth={"158px"}
            // classname={"w-[50px] h-[50px] cursor-pointer"}

            style={{
              backgroundSize: "cover",
              backgroundPosition: "center",
              width: "100%",
              height: "100%",
              borderRadius: "5px",
            }}
          />
          {/* card inside content */}
          <div className="absolute inset-x-0 bottom-0 flex justify-end p-2 mb-3">
            {/* <div
              className="items-center p-1 rounded-full add-button"
              onMouseEnter={() => setHover(true)}
              onMouseLeave={() => setHover(false)}
            > */}
            {/* <AddSongToFav song={song} musicCard={true} hover={hover} /> */}
            {loggedInUser && user?.id === loggedInUser.id && (
              <AddPodcastToFav podcast={podcast} podcastCard={true} />
            )}
            {/* </div> */}
            {/* <div>
            <button
              className="items-center p-1 rounded-full add-button"
              onClick={handleMusicPlayer}
            >
              <BsPlayCircle className="text-md action-icon-size " />
            </button>
          </div> */}
          </div>

          {/* {loggedInUser && (
            <div
              className="absolute bottom-0 right-[10px] !z-40 w-[195px] flex justify-end p-2"
              onClick={handleFavorite}
            >
              <FavoriteIcon isLiked={isLiked} handleFavorite={handleFavorite} />
            </div>
          )} */}
        </div>
        <div className="relative h-[60px] top-1 mb-4">
          <div className="my-[4px]">
            <p
              className="text-[14px] leading-tight font-[600] text-white hover:text-zinc-100"
              data-tooltip-id={`add-song-to-favorite-${podcast?.id}`}
            >
              {podcast?.title?.replace(/_/g, " ").replace(/&amp;/g, "&").length > 28
                ? `${podcast?.title?.replace(/_/g, " ").replace(/&amp;/g, "&").slice(0, 28)}...`
                : podcast?.title?.replace(/_/g, " ").replace(/&amp;/g, "&")}
            </p>
            <div className="text-sm">
              <ReactTooltip
                id={`add-song-to-favorite-${podcast?.id}`}
                place="bottom"
                content={podcast?.title}
              />
            </div>
            <div className="absolute mb-0">
              <p className="text-[11px] leading-tight w-[165px] font-[400] mt-[5px] text-white hover:text-zinc-100">
                {podcast.artist?.name.replace(/&amp;/g, "&")}
              </p>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
}

export default Podcastcard;
