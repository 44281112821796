import React, { useState, useEffect } from "react";
import PodcastOneEpisode from "./PodcastOneEpisode";
import { useSelector } from "react-redux";
import PodcastService from "services/podcast/PodcastService";
import Pagination from "../shared/Pagination";
import EpisodicSkeleton from "components/skeleton/EpisodicSkeleton";
import EpisodicPagePaginationSkeleton from "components/skeleton/EpisodicPagePaginationSkeleton";

function PodcastEpisoceSection({ episodes, podcastId }: any) {
  const [isSelected, setIsSelected] = useState<any>();
  const [currentPage, setCurrentPage] = useState(1);
  // const [totalPages, setTotalPages] = useState(episodes?.last_page);
  const { currentPodcast } = useSelector((state: any) => state?.audio);
  const [episodesData, setEpisodesData] = useState<any>(episodes?.data);
  const [isLoading, setIsLoading] = useState<any>(false);

  // const fetchVideos = (page: any) => {
  //   setIsLoading(true);
  //   const url = `${process.env.REACT_APP_BACKEND_SERVER}/movies/all?page=${page}`;
  //   fetch(url)
  //     .then((res) => res.json())
  //     .then((data) => {
  //       setIsLoading(false);
  //       setVideos(data.data);
  //       setTotalPages(data.meta.last_page);
  //     })
  //     .catch((e) => {
  //       setIsLoading(false);
  //       console.log(e);
  //     });
  // };

  // useEffect(() => {
  //   fetchVideos(currentPage);
  // }, [currentPage]);
  useEffect(() => {
    setIsLoading(true);
    PodcastService.getEpisodesByPageNumber(podcastId, currentPage).then(
      (res) => {
        setIsLoading(false);
        // console.log(res);
        setEpisodesData(res?.episodes?.data);
      }
    );
  }, [currentPage, podcastId]);

  return (
    <>
      <div className={`overflow-x-hidden w-[99%] `}>
        {!isLoading && (
          <div className=" max-h-[75vh] !overflow-y-scroll custom-scrollbar overflow-x-hidden w-full">
            {episodesData?.map((epi: any, i: number) => (
              <div key={i}>
                <PodcastOneEpisode
                  episode={epi}
                  setIsSelected={setIsSelected}
                  isSelected={isSelected}
                  // skipForward={skipForward}
                  currentPodcast={currentPodcast}
                />
              </div>
            ))}
          </div>
        )}
        {isLoading && <EpisodicPagePaginationSkeleton />}

        <div className="flex justify-center my-5">
          <Pagination
            totalPages={episodes?.last_page}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
          {/* {Array.from({ length: 3 }, (_, i) => (
          <button
            key={i}
            onClick={() => setCurrentPage(i + 1)}
            className={`mx-2 px-4 py-2 rounded ${
              currentPage === i + 1 ? "bg-[#f30543] text-white" : "bg-white"
            }`}
          >
            {i + 1}
          </button>
        ))} */}
        </div>
      </div>
    </>
  );
}

export default PodcastEpisoceSection;
