import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import RadioService from "services/radio/RadioService";
import RadioCard from "./shared/RadioCard";
import GenreSelector from "./discover/GenreSelector";
import DiscoverRadioSkeleton from "./skeleton/DiscoverRadioSkeleton";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { RouteName } from "constant/routeNames";
import { HiArrowLeft } from "react-icons/hi";
import { Adsense } from "@ctrl/react-adsense";
import GeneralServices from 'services/General/GeneralServices';

function RadioPageChannelsByCategoryPage() {
  const { slug } = useParams();
  const [stations, setStations] = useState([]);
  const [genre, setGenre] = useState<any>(slug);
  const [isLoadingDiscover, setIsLoadingDiscover] = useState<any>(false);
  const navigate = useNavigate();
  const [gAdSlot, setgAdSlot] = useState<any>();

  useEffect(() => {
    GeneralServices.getAllConfigData()
      .then((res) => {
        const PageStatus = res?.data?.filter(
          (item: any) => item?.title === "is_radio_gAd" && item?.value !== null
        );
        if (PageStatus[0]?.value === '1') {
          const filteredData = res?.data?.filter(
            (item: any) => item?.title === "gAdSlot" && item?.value !== null
          );
          setgAdSlot(filteredData[0]?.value);
          console.log("setgAdStatus", filteredData[0]?.value);
        }
      })
      .catch((e) => {});
  }, []);

  useEffect(() => {
    setGenre(slug);
  }, [slug]);

  useEffect(() => {
    setIsLoadingDiscover(true);
    RadioService.getRadioStationsByGenre(genre).then((res) => {
      // console.log(res.stations.data);
      setIsLoadingDiscover(false);
      setStations(res.stations.data);
    });
  }, []);

  const [categories, setCategories] = useState([]);
  const [categoryName, setCategoryName] = useState<any>("");

  useEffect(() => {
    RadioService.getAllRadioCategories()
      .then((res) => {
        // console.log(res);
        setCategories(res);
        const filteredData = res.filter((item: any) => item?.alt_name === genre);
        setCategoryName(filteredData[0]);
        // console.log(filteredData[0]);
      })
      .catch((e) => {
        // console.log(e);
      });
  }, []);

  useEffect(() => {
    setIsLoadingDiscover(true);
    RadioService.getRadioStationsByGenre(genre).then((res) => {
      // console.log(res.stations.data);
      setIsLoadingDiscover(false);
      setStations(res.stations.data);
    });

    const filteredData = categories.filter(
      (item: any) => item?.alt_name === genre
    );
    setCategoryName(filteredData[0]);
    // console.log(filteredData[0]);
  }, [genre]);

  // console.log("first", stations);

  return (
    <>
      {!isLoadingDiscover && (
        <>
          <div
            className={`!z-20 flex items-center justify-center w-10 h-10 text-2xl bg-white rounded-full cursor-pointer max-sm:ml-[7px] mb-3`}
            onClick={() => {
              navigate(RouteName.Radio.replace("/*", ""));
            }}
          >
            <HiArrowLeft className="text-black" />
          </div>
          {stations && (
            <div>
              <div>
                <div className="flex items-center justify-between mb-8 max-sm:block w-[98%]">
                  {categoryName && (
                    <h1 className="text-xl font-medium text-white lg:text-2xl md:text-3xl max-sm:ml-2 max-sm:mb-5">
                      Discover Radio in{" "}
                      <span className="red-text">
                        {categoryName?.name.replace(/&amp;/g, "&")}
                      </span>
                    </h1>
                  )}
                  <GenreSelector
                    slug={genre}
                    // setGenre={setGenre}
                    categories={categories}
                  />
                </div>
                {gAdSlot && (
                  <Adsense
                    client="ca-pub-5773929890067376"
                    slot={gAdSlot}
                    style={{ display: 'flex', marginBottom:'30px', marginTop:'20px', height:'90px', justifyContent: 'center' }}
                    format="horizontal"
                    data-full-width-responsive="true"
                    // format="fluid"
                    />
                  )}
              </div>

              <div className="grid w-full grid-cols-2 mt-4 mb-28 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 2xl:grid-cols-7 max-sm:w-full">
                {stations?.map((station: any, i: number) => (
                  <div key={i}>
                    <RadioCard station={station} />
                  </div>
                ))}
              </div>
            </div>
          )}
        </>
      )}
      {isLoadingDiscover && <DiscoverRadioSkeleton />}
    </>
  );
}

export default RadioPageChannelsByCategoryPage;
