import { Tooltip } from "antd";
import { RouteName } from "constant/routeNames";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "styles/card.css";
import { formatDurationHrAndMins } from "utils/timeFunctions";
function PickupCard({
  id,
  currentTime,
  duration,
  title,
  thumbnail,
  slug,
  stream,
  tv_show_name,
  type
}: any) {
  const navigate = useNavigate();

  const [watchPercentage, setWatchPercentage] = useState<any>();

 
  useEffect(() => {
    setWatchPercentage((currentTime / duration) * 100);
  }, [currentTime, duration]);
  // console.log('watchPercentage : ', watchPercentage);
  const setDataToPlayer = async () => {
    if (stream) {
      if (type === 'Movie') {
        // if creator
        navigate(
          RouteName.CreatorPlaylistRoute.replace(":slug", slug)
            .replace(":stream", stream)
        );
      } else {
        // if tv-series
        navigate(
          RouteName.WatchTVSeries.replace(":slug", slug).replace(
            ":stream",
            stream
          )
        );
      }
    } else {
      //  if movie
      navigate(RouteName.WatchMovies.replace(":slug", slug));
    }
  };
  return (
    <>
      <div className="flex w-[320px]  movie-card m-4 mx-auto p-2 rounded-lg">
        <div
          className="relative w-16 h-20 mr-2 bg-center bg-cover rounded-lg shadow-lg bg-img"
          style={{
            color: "#fff",
            background: `url(${thumbnail}) fixed center no-repeat`,
            backgroundSize: "cover",
          }}
        ></div>
        <div className="pl-4 w-[90%] flex flex-col justify-between">
          <div className="w-[90%]">
            <Tooltip
              title={
                stream
                  ? tv_show_name
                    ? tv_show_name.length > 25
                      ? `${tv_show_name.slice(0, 25)}...`
                      : tv_show_name
                    : title?.length > 25
                    ? `${title.slice(0, 25)}.`
                    : title
                  : title?.length > 25
                  ? `${title.slice(0, 25)}.`
                  : title
              }
            >
              <h2
                className="cursor-pointer white-text text-md leading-tight text-[15px]"
                onClick={setDataToPlayer}
              >
                {stream
                  ? tv_show_name
                    ? tv_show_name.length > 25
                      ? `${tv_show_name.slice(0, 25)}...`
                      : tv_show_name
                    : title?.length > 25
                    ? `${title.slice(0, 25)}.`
                    : title
                  : title?.length > 25
                  ? `${title.slice(0, 25)}.`
                  : title}
              </h2>
            </Tooltip>
            {/* <p className="text-xs gray-text">
              {stream
                ? title?.length > 25
                  ? `${title.slice(0, 25)}...`
                  : title
                : ""}
            </p> */}
          </div>
          <div>
            <div className="flex justify-between">
              <p className="text-xs gray-text">
                {formatDurationHrAndMins(duration - currentTime)} left
              </p>
            </div>
            <div className="bg-gray-500 rounded-full w-[100%] h-1 mt-2">
              <div
                className={`bg-red-500 h-full `}
                style={{ width: `${watchPercentage}%` }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PickupCard;
