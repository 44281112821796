import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import laptop from "Assets/Images/laptop.png";

function LiveTVCaroursel() {
  return (
    <div className="my-5 h-80 ">
      <div className="absolute z-10 w-full mx-auto mt-[-35px] gradientX">
        <img src={laptop} alt="" className="mx-auto " width={"600px"} />
      </div>

      <div className="relative flex items-center">
        <Swiper
          slidesPerView={5}
          loop={true}
          loopFillGroupWithBlank={true}
          breakpoints={{
            0: {
              slidesPerView: 1,
            },
            650: {
              slidesPerView: 2,
            },
            1000: {
              slidesPerView: 3,
            },
            1100: {
              slidesPerView: 3,
            },
            1200: {
              slidesPerView: 4,
            },
            1500: {
              slidesPerView: 6,
            },
            1600: {
              slidesPerView: 6,
            },
          }}
          modules={[Pagination]}
          className="relative mt-10 mb-5 mySwiper"
          // autoplay={{ delay: 3000 }}
          // speed={1000}
        >
          {channels.map((channel) => (
            <div key={channel.id}>
              <SwiperSlide className="">
                <img
                  src={channel.imageSrc}
                  alt=""
                  style={{
                    height: "200px",
                    width: "280px",
                    objectFit: "cover",
                    borderRadius: "5px",
                  }}
                />
              </SwiperSlide>
            </div>
          ))}
        </Swiper>
      </div>
    </div>
  );
}

export default LiveTVCaroursel;

const channels = [
  {
    id: 1,
    channelName: "Channel Name",
    imageSrc:
      "https://static01.nyt.com/images/2019/07/15/sports/15cricketWEB1/merlin_157953327_fcf2c9a5-91b0-45bd-a558-82c3ea87e047-superJumbo.jpg",
  },
  {
    id: 2,
    channelName: "Channel Name",
    imageSrc:
      "https://www.epiphan.com/wp-content/uploads/2019/04/How-to-live-stream-an-event-well_FB.jpg",
  },
  {
    id: 3,
    channelName: "Channel Name",
    imageSrc:
      "https://cdn.alivenetwork.com/images/extrabandpics/MusicialsLive5.jpg",
  },
  {
    id: 4,
    channelName: "Channel Name",
    imageSrc:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTWGb3H9v3nkwHBsof8d6Cthm25_3PouK2Sg1gAYRj0IjlySSu7aaoEHYvw4rETkuLrDPw&usqp=CAU",
  },
  {
    id: 5,
    channelName: "Channel Name",
    imageSrc:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTKKmSTq6N9yUriZa_CM7OtyLYoH8RsqYYuiA&usqp=CAU",
  },
  {
    id: 6,
    channelName: "Channel Name",
    imageSrc:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRC7HE-Gxq1PFx7RLhCGWSJUYbDUviTtz9FuA&usqp=CAU",
  },
  {
    id: 7,
    channelName: "Channel Name",
    imageSrc:
      "https://static01.nyt.com/images/2019/07/15/sports/15cricketWEB1/merlin_157953327_fcf2c9a5-91b0-45bd-a558-82c3ea87e047-superJumbo.jpg",
  },
  {
    id: 8,
    channelName: "Channel Name",
    imageSrc:
      "https://static01.nyt.com/images/2019/07/15/sports/15cricketWEB1/merlin_157953327_fcf2c9a5-91b0-45bd-a558-82c3ea87e047-superJumbo.jpg",
  },
  {
    id: 9,
    channelName: "Channel Name",
    imageSrc:
      "https://static01.nyt.com/images/2019/07/15/sports/15cricketWEB1/merlin_157953327_fcf2c9a5-91b0-45bd-a558-82c3ea87e047-superJumbo.jpg",
  },
  {
    id: 10,
    channelName: "Channel Name",
    imageSrc:
      "https://static01.nyt.com/images/2019/07/15/sports/15cricketWEB1/merlin_157953327_fcf2c9a5-91b0-45bd-a558-82c3ea87e047-superJumbo.jpg",
  },
];
