import React, { useRef, useState, useEffect } from "react";

const CustomProgressBar = ({
  progress,
  onSeek,
  fullScreen,
  show = false,
}: any) => {
  const progressBarRef = useRef<any>(null);
  const [isDragging, setIsDragging] = useState(false);

  useEffect(() => {
    const handleMove = (e: any) => {
      if (isDragging) {
        const progressBar = progressBarRef.current;
        if (progressBar) {
          const rect = progressBar.getBoundingClientRect();
          const progressBarWidth = rect.width;
          let offsetX;
          if (e.type === "touchmove") {
            offsetX = e.touches[0].clientX - rect.left;
          } else {
            offsetX = e.clientX - rect.left;
          }
          offsetX = Math.min(progressBarWidth, Math.max(0, offsetX));
          const seekPercentage = (offsetX / progressBarWidth) * 100;
          onSeek(seekPercentage);
        }
      }
    };

    const handleMouseDown = (e: any) => {
      setIsDragging(true);
      handleMove(e);
    };

    const handleTouchMove = (e: any) => {
      handleMove(e);
    };

    const handleTouchStart = (e: any) => {
      setIsDragging(true);
      handleMove(e);
    };

    const handleEnd = () => {
      setIsDragging(false);
    };

    const progressBar = progressBarRef.current;
    if (progressBar) {
      progressBar.addEventListener("mousedown", handleMouseDown);
      progressBar.addEventListener("touchstart", handleTouchStart);
    }

    document.addEventListener("mousemove", handleMove);
    document.addEventListener("touchmove", handleTouchMove);
    document.addEventListener("mouseup", handleEnd);
    document.addEventListener("touchend", handleEnd);

    return () => {
      if (progressBar) {
        progressBar.removeEventListener("mousedown", handleMouseDown);
        progressBar.removeEventListener("touchstart", handleTouchStart);
      }
      document.removeEventListener("mousemove", handleMove);
      document.removeEventListener("touchmove", handleTouchMove);
      document.removeEventListener("mouseup", handleEnd);
      document.removeEventListener("touchend", handleEnd);
    };
  }, [isDragging, progressBarRef, onSeek]);

  const handleSeekByClick = (e: any) => {
    const rect = progressBarRef.current.getBoundingClientRect();
    const progressBarWidth = rect.width;
    const p = (e.clientX / progressBarWidth) * 100;
    onSeek(p);
  };

  return (
    <>
      <div
        className={`h-[4px] bg-white cursor-pointer w-full max-md:w-[95%] max-md:mx-auto  ${
          fullScreen ? `rounded-[4px] z-50` : ``
        } ${show && fullScreen && `hidden`}`}
        ref={progressBarRef}
        onClick={(e: any) => handleSeekByClick(e)}
      >
        <div
          style={{
            width: `${progress}%`,
            height: "100%",
            background: "red",
            borderRadius: "4px",
          }}
          className="flex items-center justify-end"
        >
          <div
            className={`w-[12px] h-[12px] sm:hover:scale-[1.2]  ease-in-out duration-200 rounded-full bg-[#FF0000] z-40`}
          ></div>
        </div>
      </div>
    </>
  );
};

export default CustomProgressBar;
