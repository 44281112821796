function StationSkeleton() {
  const arr = [1, 2, 3, 4, 5, 6];
  return (
    <div>
      <div className="w-[250px] h-[20px] bg-[#1a1919] mt-16"></div>
      <div
        className={
          "grid w-full grid-cols-1 mt-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 max-sm:w-full"
        }
      >
        {arr.map((item: any) => (
          <div
            key={item}
            className="flex flex-row items-start justify-center mr-10"
          >
            <div className="w-[75px] h-[75px] mr-3 bg-[#1a1919] animate-pulse mt-2"></div>
            <div className="mt-5">
              <div className="w-[100px] h-[20px] bg-[#1a1919]"></div>
              <div className="w-[50px] h-[20px] mt-2 bg-[#1a1919]"></div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default StationSkeleton;
