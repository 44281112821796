import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './app/App';
import reportWebVitals from './reportWebVitals';
import './styles/index.css';

import { store, persistor } from './redux/store';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import 'pages/videoPage/home/shared/video.css';
// import "styles/videoplayercss/vjs-luxmty.css";
import 'styles/videoplayercss/vjs-luxmty.min.css';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const container = document.getElementById('root')!;
const root = createRoot(container);
const helmetContext = {};

// replace console.* for disable log on production
if (process.env.REACT_APP_NODE_ENV === 'production') {
  console.log = () => {}
  console.error = () => {}
  console.debug = () => {}
}

root.render(
  // <React.StrictMode> 
  <HelmetProvider context={helmetContext}>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </HelmetProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
