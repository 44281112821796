import { Adsense } from "@ctrl/react-adsense";
import TvChannelSeeAllSkeleton from "components/skeleton/TvChannelSeeAllSkeleton";
import { RouteName } from "constant/routeNames";
import Pagination from "pages/podcastPage/shared/Pagination";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { useLocation, useNavigate } from "react-router-dom";
import GeneralServices from "services/General/GeneralServices";
import ArtistService from "services/music/ArtistService";
import { ArtistCard } from "./shared/ArtistCard";

function SeeAllArtist() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const page = searchParams.get("page") || "1";
  const pageNoInt = parseInt(page, 10);
  const [allArtist, setAllArtist] = useState<any>(null);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<any>(false);
  const [url, setUrl] = useState(`artists?page=${pageNoInt}`);

  const [currentPage, setCurrentPage] = useState(pageNoInt);
  const [lastPage, setLastPage] = useState<any>();
  const [gAdSlot, setgAdSlot] = useState<any>();
  const currentUrl = window.location.pathname;
  const path = currentUrl + `?page=${currentPage}`;

  useEffect(() => {
    const handlePopstate = () => {
      const newPage =
        new URLSearchParams(window.location.search).get("page") || "1";
      setCurrentPage(parseInt(newPage, 10));
    };

    window.addEventListener("popstate", handlePopstate);

    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, []);
  useEffect(() => {
    GeneralServices.getAllConfigData()
      .then((res) => {
        const PageStatus = res?.data?.filter(
          (item: any) => item?.title === "is_music_gAd" && item?.value !== null
        );
        if (PageStatus[0]?.value === "1") {
          const filteredData = res?.data?.filter(
            (item: any) => item?.title === "gAdSlot" && item?.value !== null
          );
          setgAdSlot(filteredData[0]?.value);
          console.log("setgAdStatus", filteredData[0]?.value);
        }
      })
      .catch((e) => {});
  }, []);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    setUrl(`artists?page=${currentPage}`);
  }, [currentPage]);
  useEffect(() => {
    navigate(RouteName.SeeAllArtistRoute + `?page=${currentPage}`);
  }, [currentPage, navigate]);

  useEffect(() => {
    setIsLoading(true);
    ArtistService.getAllArtists(url)
      .then((res) => {
        setLastPage(res?.meta.last_page);
        setAllArtist(res.data);
        setIsLoading(false);
      })
      .catch((e: any) => {
        setIsLoading(false);
        console.log(e);
      });
  }, [url]);
  const FE_URL = process.env.REACT_APP_FRONTEND_URL;

  return (
    <>
      <Helmet>
        <title>All Artists - AYOZAT Music</title>
        <meta property="og:type" content="website" />
        <meta
          name="description"
          content="AYOZAT Streaming Platform - Watch free movies and TV shows online in HD on any device anytime. Free movie website search is over."
        />
        <link rel="canonical" href={`${FE_URL}/music/all-artist`} />
        <meta property="og:title" content=" All Artists - AYOZAT Music" />
        <meta
          property="og:description"
          content="AYOZAT Streaming Platform - Watch free movies and TV shows online in HD on any device anytime. Free movie website search is over."
        />
        <meta property="og:image" content="https://ayozat.co.uk/logo192.png" />
        <meta property="og:url" content={`${FE_URL}/music/all-artist`} />
        <meta property="og:type" content="website" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content=" All Artists - AYOZAT Music" />
        <meta
          name="twitter:description"
          content="AYOZAT Streaming Platform - Watch free movies and TV shows online in HD on any device anytime. Free movie website search is over."
        />
        <meta name="twitter:image" content="https://ayozat.co.uk/logo192.png" />
        <meta name="twitter:site" content="AYOZAT" />
        {/* <meta property="twitter:url" content={`${FE_URL}/music`}/> */}
      </Helmet>
      {!isLoading && (
        <div>
          {/* main topic section */}
          <div>
            <button
              className="w-10 h-10 mb-5 bg-white rounded-full max-sm:ml-[16px]"
              onClick={() => {
                navigate(-1);
              }}
            >
              <AiOutlineArrowLeft className="ml-3" />
            </button>
          </div>
          <div className="mb-8">
            <h1 className="text-2xl font-medium text-white lg:text-2xl md:text-2xl max-sm:w-[60%] max-sm:ml-2">
              Artists
            </h1>
          </div>
          {gAdSlot && (
            <Adsense
              client="ca-pub-5773929890067376"
              slot={gAdSlot}
              style={{
                display: "flex",
                marginBottom: "30px",
                marginTop: "20px",
                height: "90px",
                justifyContent: "center",
              }}
              format="horizontal"
              data-full-width-responsive="true"
              // format="fluid"
            />
          )}
          {allArtist && (
            <div className="grid w-full grid-cols-2 gap-4 mt-4 mb-8 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 2xl:grid-cols-7 max-sm:w-full">
              {allArtist?.map((artist: any, i: number) => (
                <div key={i}>
                  <ArtistCard artist={artist} />
                </div>
              ))}
            </div>
          )}

          <div className="flex justify-center my-5">
            {allArtist?.length === 0 && allArtist ? (
              <></>
            ) : (
              <>
                <Pagination
                  totalPages={lastPage}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                />
              </>
            )}
          </div>
        </div>
      )}

      {isLoading && <TvChannelSeeAllSkeleton />}
    </>
  );
}

export default SeeAllArtist;
