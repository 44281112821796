import React from "react";
import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import RadioCard from "./RadioCard";
import { radios } from "constant/radios";

function LiveProgrammeCoursel({ liveProgrammes }: any) {
  return (
    <div>
      {/* top section */}
      <div className="mb-8 w-[98%] max-sm:mt-3">
        <div>
          <h1 className="text-xl font-medium text-white lg:text-2xl md:text-2xl">
            Live programs right now
          </h1>
        </div>
      </div>
      {/* coursel */}
      <div className="">
        <Swiper
          slidesPerView={5}
          breakpoints={{
            0: {
              slidesPerView: 1,
            },
            370: {
              slidesPerView: 2,
            },
            740: {
              slidesPerView: 3,
            },
            1100: {
              slidesPerView: 3,
            },
            1200: {
              slidesPerView: 4,
            },
            1500: {
              slidesPerView: 5,
            },
            1600: {
              slidesPerView: 6,
            },
          }}
          modules={[Pagination]}
          className="mt-4 mb-5 mySwiper"
        >
          {liveProgrammes.map((liveProgramme: any, i: number) => (
            <div key={i}>
              <SwiperSlide className="">
                <RadioCard radio={liveProgramme} />
              </SwiperSlide>
            </div>
          ))}
        </Swiper>
      </div>
    </div>
  );
}

export default LiveProgrammeCoursel;
