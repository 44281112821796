import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import logoImage from "Assets/Images/a_logo.png";

function LazyLoadImg({
  imgSrc,
  imgAlt,
  imgHeight,
  imgWidth,
  classname,
  style,
}: any) {
  return (
    <>
      <LazyLoadImage
        effect="blur"
        src={imgSrc}
        alt={imgAlt && imgAlt}
        height={imgHeight && imgHeight}
        width={imgWidth && imgWidth}
        placeholderSrc={logoImage}
        className={classname && classname}
        style={style && style}
      />
    </>
  );
}

export default LazyLoadImg;
