import React, { useState } from "react";
import { FaShare, FaCheck } from "react-icons/fa";
import { triggerSuccessNotification } from "utils/notification";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { Notification } from "constant/notifications";

function AnimatedShareButton({ shareUrl }: any) {
  const [copied, setCopied] = useState(false);

  const copyToClipboard = async () => {
    await navigator.clipboard.writeText(shareUrl);
    triggerSuccessNotification(Notification.CopyToClipboard);
    setCopied(true);

    // Reset copied state after a delay (you can adjust the timing)
    setTimeout(() => {
      setCopied(false);
    }, 4000); // Adjust the timing as needed
  };

  return (
    <div>
      <p
        className={`relative flex items-center mx-auto text-base font-bold text-white cursor-pointer hover:bg-[#48484895] py-3 px-5 border-[#f30543] border-2 rounded-full duration-500 ease-in-out transform-gpu  ${
          copied ? "bg-green-500 border-green-700" : ""
        }`}
        onClick={copyToClipboard}
      >
        {copied ? (
          <>
            <FaCheck
              className="mr-3 transition-transform animate-ping "
              size={15}
            />
            Copied
          </>
        ) : (
          <>
            <FaShare className={`mr-3 transition-transform `} />
            {copied ? "Click" : "Share"}
          </>
        )}
      </p>
      <ReactTooltip id="copy-media-link" place="bottom" content="Copy Link" />
    </div>
  );
}

export default AnimatedShareButton;
