import React from "react";
import CreatorMusic from "./shared/CreatorMusic";

const MusicPage = ({ creatorData }: any) => {
  return (
    <div>
      <CreatorMusic creatorData={creatorData} />
    </div>
  );
};

export default MusicPage;
