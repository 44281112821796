import axios from "axios";

axios.defaults.baseURL = process.env.REACT_APP_BACKEND_SERVER;
// const VIUZAT_URL = process.env.REACT_APP_VIUZAT_SERVER;
const BASE_URL = process.env.REACT_APP_BACKEND_SERVER;

const getAllConfigData = async () => {
  const response = await axios({
    method: "get",
    url: `${BASE_URL}/config/data`,
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
  });
  return response.data;
};

const getRedirectUrl = async () => {
  const response = await axios({
    method: "get",
    url: `${BASE_URL}/redirect/urls`,
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
  });
  return response.data;
};

const GeneralServices = {
  getAllConfigData,
  getRedirectUrl,
};

export default GeneralServices;
