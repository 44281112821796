import NotificationSkeleton from "components/skeleton/NotificationSkeleton";
import React from "react";
import { MdPeople } from "react-icons/md";
import { getTimeAgo } from "utils/timeFunctions";

function FollowNotification({ notification, isLoading }: any) {
  return (
    <>
      {!isLoading && (
        <div>
          <div className="flex gap-3 items-center pl-[5px] cursor-default rounded-md hover:bg-slate-800 hover:shadow-xl h-[80px]">
            <div className="w-[20%] relative">
              <img
                src={notification?.details?.host?.artwork_url}
                alt=""
                className="w-[40px] h-[40px] rounded-full"
              />
              <div className="absolute -mt-4 ml-6 px-1 py-1 bg-[#f604ee] rounded-full">
                <MdPeople className="w-4 h-4 text-white" />
              </div>
            </div>
            <div className="ml-3">
              <h1 className="text-white font-normal text-[13px]">
                <p>
                  <span className="font-bold">
                    {notification?.details?.host?.name}
                  </span>{" "}
                  is now following you.
                </p>
              </h1>
              <p className="text-white font-extralight text-[10px] mt-1">
                {getTimeAgo(notification?.updated_at)}
              </p>
            </div>
          </div>
        </div>
      )}
      {/* {isLoading && <NotificationSkeleton />} */}
    </>
  );
}

export default FollowNotification;
