import { Tooltip as ReactTooltip } from "react-tooltip";
import { BsPlayCircle } from "react-icons/bs";
import { MdPlaylistAdd } from "react-icons/md";
import { useDispatch } from "react-redux";
import { addMusicToPlayer, addToQueue } from "redux/AudioPlayerRedux";
import { triggerWarningNotification } from "utils/notification";
import { Notification } from "constant/notifications";
import LazyLoadImg from "components/LazyLoadImg";

const HeroSlider = ({ value }: any) => {
  const dispatch = useDispatch<any>();
  const handleMusicPlayer = () => {
    dispatch(addMusicToPlayer(value));
  };

  const addToQueueSong = () => {
    if (value) {
      dispatch(addToQueue(value));
      // triggerSuccessNotification(Notification.SongAddedToQueue);
    } else {
      triggerWarningNotification(Notification.NoSongs);
    }
  };
  return (
    <div>
      <div className="relative ">
        <div className="w-full h-[510px] max-sm:w-full max-md:w-full overflow-hidden ">
          <LazyLoadImg
            imgSrc={value?.artwork_url}
            imgAlt={"/"}
            imgHeight={"560px"}
            imgWidth={"100%"}
            className={"cover w-full rounded-md"}
          />
          {/* <img
            src={value?.artwork_url}
            alt="/"
            className="object-cover w-full h-[90%] overflow-hidden rounded-md"
          /> */}
        </div>
        <div className="absolute top-0 flex flex-row items-center justify-between w-full h-full text-white bg-gradient-to-t from-black ">
          <div className="flex flex-col ">
            <div className="flex flex-row gap-2 mt-2 ml-4">
              <div
                className="flex items-center justify-center duration-300 ease-in-out text-[#7B9DB4] bg-gray-300 rounded-full cursor-pointer hover:bg-red-500 hover:text-white w-11 h-11"
                onClick={handleMusicPlayer}
              >
                <BsPlayCircle className="w-[27px]" size={24} />
              </div>
              <div
                className="flex items-center justify-center duration-300 ease-in-out text-[#7B9DB4] bg-gray-300 rounded-full cursor-pointer hover:bg-red-500 hover:text-white w-11 h-11"
                onClick={addToQueueSong}
                data-tooltip-id="my-tooltip-1"
              >
                <MdPlaylistAdd className="w-[27px]" size={24} />
              </div>

              <div className="text-sm">
                <ReactTooltip
                  id="my-tooltip-1"
                  place="bottom"
                  content="Add to queue"
                />
              </div>
            </div>
            <div className="ml-5 mt-[30vh]">
              <p className="text-[#FAFAFA] font-[500] text-[16px]">
                {" "}
                Featured Song
              </p>
              <p className="font-[700] font-rubik text-[#FAFAFA] text-[35px] leading-10">
                {value?.title}
              </p>
              <p className="text-[#FAFAFA] font-[500] text-[16px] leading-10 mb-4">
                {value?.artists[0].name}
              </p>
            </div>
          </div>
          {/* <div className="mr-[30px] text-right">
            <ul className="space-y-4 cursor-pointer">
              <li className="hover:underline hover:scale-110 opacity-20 hover:opacity-100 hover:font-[700]">
                Hip-Hop
              </li>
              <li className="hover:underline hover:scale-110 opacity-20 hover:opacity-100 hover:font-[700]">
                Classical
              </li>
              <li className="hover:underline hover:scale-110 opacity-20 hover:opacity-100 hover:font-[700]">
                Rap
              </li>
              <li className="hover:underline hover:scale-110 opacity-20 hover:opacity-100 hover:font-[700]">
                Pop
              </li>
              <li className="hover:underline hover:scale-110 opacity-20 hover:opacity-100 hover:font-[700]">
                Metal
              </li>
            </ul>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default HeroSlider;
