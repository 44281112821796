import { ChannelCard } from "pages/liveTvPage/shared/ChannelCard";
import NoSearch from "pages/searchPage/shared/NoSearch";
import React from "react";

const CreatorLiveTv = ({ creatorData }: any) => {
  const channels = creatorData?.tv_channels || [];

  // console.log(channels);
  return (
    <div>
      {/* card section */}
      {channels.length === 0 && channels ? (
        <NoSearch text="Live TV Channels" />
      ) : (
        <div>
          <div className="mt-4 mb-5 w-[95%] grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 2xl:grid-cols-7 max-sm:mx-auto max-sm:w-full max-sm:gap-4">
            {channels.length > 0 &&
              channels.map((channel: any, i: any) => (
                <div key={i}>
                  <ChannelCard data={channel} img={channel.thumbnail_url} />
                </div>
              ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default CreatorLiveTv;
