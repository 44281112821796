import React from "react";

function HeroSection() {
  return (
    <div>
      <div className="flex lg:w-full h-[650px] w-full bg-[#1a1919] max-sm:h-[600px] max-sm:w-full animate-pulse"></div>
    </div>
  );
}

export default HeroSection;
