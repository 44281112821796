import { Notification } from "constant/notifications";
import React from "react";

function GeoLocationPopup({ text, onClose }: any) {
  return (
    <>
      <div className="text-center text-white items-center min-h-[50px] w-full sm:mt-8">
        {text ? text : Notification.geoLocationNotAllowedText}
      </div>
      <div
        className="bg-[--red] text-white p-2 rounded-3xl w-32 text-center mx-auto cursor-pointer"
        onClick={onClose}
      >
        Go Back
      </div>
    </>
  );
}

export default GeoLocationPopup;
