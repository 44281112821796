import LazyLoadImg from "components/LazyLoadImg";
import { useDrag, useDrop } from "react-dnd";
import { IoMdRemoveCircle } from "react-icons/io";
import { MdOutlineDragIndicator } from "react-icons/md";
import { useDispatch } from "react-redux";
import {
  removePodcastFromPlaylist,
  removePodcastFromPlaylistLoading,
  removeSongFromPlaylist,
  removeSongFromPlaylistLoading,
  setCurrentAudio,
  setCurrentPodcast,
} from "redux/AudioPlayerRedux";
import { formatDuration } from "utils/timeFunctions";

const ItemType = "ITEM";

function DraggablePlaylistCard({
  song,
  currentSong,
  index,
  moveItem,
  music,
  radio,
  podcast,
}: any) {
  // console.log(song);
  const dispatch = useDispatch<any>();

  // draggable options
  const [, ref] = useDrag({
    type: ItemType,
    item: { index },
  });

  const [, drop] = useDrop({
    accept: ItemType,
    hover: (draggedItem: { index: number; song: any; currentSong: any }) => {
      if (draggedItem.index !== index) {
        moveItem(draggedItem.index, index);
        draggedItem.index = index;
      }
    },
  });

  const chooseSong = () => {
    if (music) {
      dispatch(setCurrentAudio(song));
    } else if (podcast) {
      dispatch(setCurrentPodcast(song));
    }
  };

  const handleDelete = async () => {
    if (music) {
      const obj = {
        songId: song.id,
        index: index,
      };
      await dispatch(removeSongFromPlaylistLoading());
      await dispatch(removeSongFromPlaylist(obj));
    } else if (podcast) {
      const obj = {
        songId: song.id,
        index: index,
      };
      await dispatch(removePodcastFromPlaylistLoading());
      await dispatch(removePodcastFromPlaylist(obj));
    }
  };
  return (
    <>
      {song && (
        <div className="music-playlist-card-bg" ref={(node) => ref(drop(node))}>
          {/* {QueueCount > 1 && ( */}
          <div
            className={`music-playlist-card-remove-btn`}
            onClick={handleDelete}
          >
            <IoMdRemoveCircle />
          </div>
          <div
            className="music-playlist-card-choose-song-btn"
            onClick={() => chooseSong()}
          >
            <div className="flex justify-start">
              <MdOutlineDragIndicator className="text-2xl text-[#7B9DB4] !cursor-grab !active:cursor-grabbing" />
            </div>
            {(song.artwork_url || song?.podcast?.artwork_url) && (
              <LazyLoadImg
                imgSrc={
                  music
                    ? song?.artwork_url
                    : podcast
                    ? song?.podcast?.artwork_url
                    : ``
                }
                imgAlt={"first artist"}
                imgHeight={"60px"}
                imgWidth={"60px"}
                className="rounded-md album-page-image max-sm:h-[20%]"
                style={{
                  borderRadius: "6px",
                }}
              />
            )}

            <div className="flex items-center justify-between w-full pl-3">
              <div className={`block`}>
                <p
                  className={`${
                    currentSong?.id === song.id ? "text-red-600" : "text-white"
                  }`}
                >
                  {song.title.length > 15
                    ? `${song.title.slice(0, 15)}...`
                    : song.title}
                </p>
                <p className="mt-1 music-playlist-card-artist-name">
                  {music && song?.artists && song?.artists[0]?.name}
                  {podcast && currentSong?.artist && currentSong?.artist?.name}
                </p>
              </div>
              {song?.duration ? formatDuration(song?.duration) : "00:00"}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default DraggablePlaylistCard;
