import React from "react";
import { useSelector } from "react-redux";

function NoFavorite({ text, user }: any) {
  const loggedInUser = useSelector((state: any) => state?.user?.currentUser);
  return (
    <div>
      <div className="items-center h-full mt-20">
        {user?.id === loggedInUser?.id ? (
          <>
            <p className="flex items-center justify-center text-base text-gray-500">
              You don't have any favourite {text} yet
            </p>
          </>
        ) : (
          <>
            <p className="flex items-center justify-center text-base text-gray-500">
              No any favourite {text} yet
            </p>
          </>
        )}

        {/* <p className="flex justify-center text-lg font-normal text-white">
          Share your profile with your friends to gain more followers!
        </p> */}
      </div>
    </div>
  );
}

export default NoFavorite;
