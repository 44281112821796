import SubText from "components/shared/SubText";
import React, { useEffect, useState } from "react";
import PayPerViewCategoryCarousal from "./PayPerViewCategoryCarousal";
import PayperviewService from "services/payperview/PayperviewServie";
import ConditionalSeeAll from "./ConditionalSeeAll";
import { RouteName } from "constant/routeNames";
import CategoryGridView from "./CategoryGridView";

const PayPerViewCategories = ({
  categories,
  isLoading,
  categoryLength,
}: any) => {
  return (
    <div className="my-8 mb-10">
      {!isLoading && (
        <div className="flex justify-between items-center w-[95%]">
          <SubText text={"Pay Per View Categories"} className="font-bold " />

          <ConditionalSeeAll
            length={categories.length}
            route={RouteName.SeeAllPayperviewCategories}
          />
        </div>
      )}
      {categories.length >= 4 ? (
        <>
          <div className="">
            <PayPerViewCategoryCarousal
              categories={categories}
              isLoading={isLoading}
            />
          </div>
        </>
      ) : (
        <>
          <div className="">
            <CategoryGridView categories={categories} isLoading={isLoading} />
          </div>
        </>
      )}
    </div>
  );
};

export default PayPerViewCategories;
