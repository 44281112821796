import React from 'react'
import { Swiper, SwiperSlide } from "swiper/react";


const NewPodcastSkeleton = () => {

    const arr = [1, 2, 3, 4, 5];

    return (
        <div className='mt-10'>
            <div className="">
                <Swiper
                    slidesPerView={5}
                    breakpoints={{
                        0: {
                            slidesPerView: 1,
                        },
                        370: {
                            slidesPerView: 2,
                        },
                        700: {
                            slidesPerView: 3,
                        },
                        1100: {
                            slidesPerView: 3,
                        },
                        1200: {
                            slidesPerView: 4,
                        },
                        1500: {
                            slidesPerView: 5,
                        },
                        1600: {
                            slidesPerView: 6,
                        },
                    }}
                    className="mt-4 mb-5 mySwiper"
                >
                    <div className='w-[200px] h-[30px] mb-5 bg-[#1a1919] animate-pulse mt-2'></div>
                    <div className='flex flex-row lg:gap-[100px] sm:gap-[20px] gap-3'>
                        {arr.map((item: any) => (
                            <div key={item} className="flex flex-col justify-center items-start">
                                <div className='lg:w-[160px] h-[180px] rounded-[10px] w-[130px] bg-[#1a1919] animate-pulse mt-2'></div>
                            </div>
                        ))
                        }
                    </div>
                </Swiper>
            </div>
        </div>
    )
}

export default NewPodcastSkeleton