const PickupSkeletons = () => {
    const arr = [1, 2, 3, 4];
  
    return (
      <div className="mt-10">
         <div className="flex flex-row justify-between">
                  <div className="w-[250px] h-[30px] bg-[#1a1919]"></div>
                  <div className="w-[100px] h-[30px] mr-[50px] bg-[#1a1919]"></div>
              </div>
        <div className={'flex flex-row justify-between w-full mt-12 mr-12'}>
          {arr.map((item: any) => (
            <div key={item} className="flex flex-col items-start justify-center">
              <div className="lg:w-[300px] h-[90px] rounded-lg w-[300px] bg-[#1a1919] animate-pulse mt-2"></div>
            </div>
          ))}
        </div>
      </div>
    );
  };
  
  export default PickupSkeletons;
  