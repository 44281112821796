import React, { useEffect, useState } from "react";
import NoSearch from "./NoSearch";
import AlbumCard from "pages/musicPage/shared/AlbumCard";
import DiscoverAlbumSkeleton from "components/skeleton/DiscoverAlbumSkeleton";

function SearchAlbumSection({ data, isLoading }: any) {
  const albums = data?.albums || [];
  // const [albums, setAlbums] = useState<any>([]);

  // useEffect(() => {
  //   if (data) {
  //     setAlbums(data.albums);
  //   }
  // }, [data]);

  return (
    <>
      {!isLoading && (
        <div>
          {/* top section */}
          <div className="mb-8 w-[98%] ml-2 mt-8 max-sm:w-full max-sm:mx-0">
            <div>
              <h1 className="text-xl font-semibold text-white lg:text-xl md:text-xl max-sm:ml-3">
                {/* search albums */}
                Albums
              </h1>
            </div>
          </div>
          {/* card section */}
          {albums.length === 0 && albums ? (
            <NoSearch text="Albums" />
          ) : (
            <div>
              <div className="mt-4 mb-5 w-[95%] grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 2xl:grid-cols-6 max-sm:mx-auto max-sm:w-full max-sm:gap-4">
                {albums.length > 0 &&
                  albums.map((album: any, i: any) => (
                    <div key={i} className="mx-3 my-1 max-sm:mx-1">
                      <AlbumCard {...album} />
                    </div>
                  ))}
              </div>
            </div>
          )}
        </div>
      )}
      {isLoading && <DiscoverAlbumSkeleton />}
    </>
  );
}

export default SearchAlbumSection;
