import { Notification } from "constant/notifications";
import React, { useEffect, useState } from "react";
import {
  AiFillHeart,
  AiFillStar,
  AiOutlineHeart,
  AiOutlineStar,
} from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { updateFavorites } from "redux/userRedux";
import VideoService from "services/video/VideoService";
import {
  triggerErrorNotification,
  triggerSuccessNotification,
} from "utils/notification";
import { Tooltip as ReactTooltip } from "react-tooltip";

function AddToFaavVideo({
  seasons,
  id,
  title,
  movie,
  tvSeries,
  VideoCard,
  Icon,
  hover,
}: any) {
  const [addFav, setAddFav] = useState<boolean>(false);
  const dispatch = useDispatch<any>();
  const loggedInUser = useSelector((state: any) => state?.user?.currentUser);
  const favorite = useSelector((state: any) => state?.user?.userFavorites);

  // console.log("favorite", favorite);

  useEffect(() => {
    const favoritesMoviesIds = favorite?.favorite_movies?.map(
      (item: any) => item.id
    );
    const favoritesTvSeriesIds = favorite?.favorite_tv_series?.map(
      (item: any) => item.id
    );
    // if (id && favoritesMoviesIds && favoritesTvSeriesIds) {
    if (
      favoritesMoviesIds?.includes(movie?.id) ||
      favoritesTvSeriesIds?.includes(tvSeries?.id)
    ) {
      setAddFav(true);
    } else {
      setAddFav(false);
    }
    // }
  }, [favorite, movie, tvSeries]);

  //Movies
  const addFavMoviesToRedux = () => {
    const updatedMoviesData = [...favorite?.favorite_movies, movie];

    const updatedFavorite = {
      ...favorite,
      favorite_movies: updatedMoviesData,
    };
    // Dispatch the action to update the 'favorite' object in the Redux store
    dispatch(updateFavorites(updatedFavorite));
  };

  const removeFavMoviesFromRedux = () => {
    const updatedMoviesData = favorite?.favorite_movies.filter(
      (favMovie: any) => favMovie.id !== id
    );
    const updatedFavorite = {
      ...favorite,
      favorite_movies: updatedMoviesData,
    };

    // Dispatch the action to update the 'favorite' object in the Redux store
    dispatch(updateFavorites(updatedFavorite));
  };

  //TV Series
  const addFavTvSeriesToRedux = () => {
    const updatedTvSeriesData = [...favorite?.favorite_tv_series, tvSeries];
    const updatedFavorite = {
      ...favorite,
      favorite_tv_series: updatedTvSeriesData,
    };
    // Dispatch the action to update the 'favorite' object in the Redux store
    dispatch(updateFavorites(updatedFavorite));
  };

  const removeFavTvSeriesFromRedux = () => {
    // const updatedLovedData = [...favorite?.loved?.data, song];
    const updatedTvSeriesData = favorite?.favorite_tv_series.filter(
      (favTvSeries: any) => favTvSeries.id !== id
    );
    const updatedFavorite = {
      ...favorite,
      favorite_tv_series: updatedTvSeriesData,
    };

    // Dispatch the action to update the 'favorite' object in the Redux store
    dispatch(updateFavorites(updatedFavorite));
  };

  const handleFavorite = () => {
    const favoriteTvSeries = {
      id: tvSeries?.id,
      object_type: "tv_show",
    };
    const favoriteMovis = {
      id: movie?.id,
      object_type: "movie",
    };
    // console.log(favoriteMovis);

    if (!addFav) {
      setAddFav(true);
      if (tvSeries) {
        VideoService.likeTVSeries(favoriteTvSeries)
          .then((res) => {
            setAddFav(true);
            // toast.info(`You Liked ${title}`);
            addFavTvSeriesToRedux();
            triggerSuccessNotification(Notification.AddToFav);
          })
          .catch((e) => {
            setAddFav(false);
            triggerErrorNotification(Notification.FavError);
          });
      } else if (movie) {
        VideoService.likeMovie(favoriteMovis)
          .then((res) => {
            setAddFav(true);
            // toast.info(`You Liked ${title}`);
            addFavMoviesToRedux();
            triggerSuccessNotification(Notification.AddToFav);
          })
          .catch((e) => {
            setAddFav(false);
            triggerErrorNotification(Notification.FavError);
          });
      }
    } else {
      setAddFav(false);
      if (tvSeries) {
        VideoService.unlikeTVSeries(favoriteTvSeries)
          .then((res) => {
            triggerSuccessNotification(Notification.RemovedFromFav);
            removeFavTvSeriesFromRedux();
            setAddFav(false);
          })
          .catch((e) => {
            setAddFav(true);
            triggerErrorNotification(Notification.FavError);
          });
      } else if (movie) {
        VideoService.unlikeMovie(favoriteMovis)
          .then((res) => {
            triggerSuccessNotification(Notification.RemovedFromFav);
            removeFavMoviesFromRedux();
            setAddFav(false);
          })
          .catch((e) => {
            setAddFav(true);
            triggerErrorNotification(Notification.FavError);
          });
      }
      // setIsLiked(false);
    }
  };
  return (
    <div>
      {loggedInUser ? (
        <div
          onClick={handleFavorite}
          className={`cursor-pointer`}
          data-tooltip-id={`add-podcast-to-favorite-${id}`}
        >
          {addFav ? (
            <div className="text-red-600">
              {Icon ? (
                <AiFillHeart className="w-10 h-10 cursor-pointer" />
              ) : (
                <AiFillStar />
              )}
            </div>
          ) : (
            <div
              className={
                VideoCard
                  ? `${
                      hover
                        ? `text-red-600 duration-500 ease-in-out`
                        : `text-white duration-500 ease-in-out`
                    }`
                  : "text-white"
              }
            >
              {Icon ? (
                <AiOutlineHeart className="w-10 h-10 cursor-pointer" />
              ) : (
                <AiOutlineStar className="hover:text-red-600" />
              )}
            </div>
          )}

          <div className="text-sm">
            <ReactTooltip
              id={`add-podcast-to-favorite-${id}`}
              place="top"
              content={addFav ? "Remove from favourites" : "Add to favourites"}
            />
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default AddToFaavVideo;
