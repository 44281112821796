import axios from "axios";
import authHeader from "utils/auth-header";

axios.defaults.baseURL = process.env.REACT_APP_BACKEND_SERVER;

const BASE_URL = process.env.REACT_APP_BACKEND_SERVER;

// get all creators
const getAllCreators = async () => {
  const response = await axios({
    method: "get",
    url: `${BASE_URL}/creators/all`,
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
  });

  return response.data;
};

// get all random Videos
const getCreatorsRandomVideo = async () => {
  const response = await axios({
    method: "get",
    url: `${BASE_URL}/creators/random/videos`,
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
  });

  return response.data;
};

// get all Videos
const getCreatorsAllVideo = async (pageNo:any) => {
  const response = await axios({
    method: "get",
    url: `${BASE_URL}/creators/videos/all?page=${pageNo}`,
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
  });

  return response.data;
};

//get creator by ID
const getCreatorByUserName = async (username: any) => {
  const response = await axios({
    method: "get",
    url: `${BASE_URL}/creators/${username}`,
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
  });

  return response.data;
};

//get details of movie by slig
const getCreatorVideoDetailsByStreamKey = async (streamKey: any) => {
  const response = await axios({
    method: "get",
    url: `${BASE_URL}/creators/video/${streamKey}`,
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
  });

  return response.data;
};

const getVideosByPlaylistStreamKey = async (stream: any) => {
  const response = await axios({
    method: "get",
    url: `${BASE_URL}/creators/playlist/videos/${stream}`,
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
  });

  return response.data;
};

const likeCreatorVideo = async (data: any) => {
  const response = await axios({
    method: "post",
    data: data,
    url: `${BASE_URL}/creators/video/like`,
    headers: authHeader(),
  });

  return response.data;
};

const unLikeCreatorVideo = async (data: any) => {
  const response = await axios({
    method: "post",
    data: data,
    url: `${BASE_URL}/creators/video/unlike`,
    headers: authHeader(),
  });

  return response.data;
};

const CreatorService = {
  getAllCreators,
  getCreatorByUserName,
  getCreatorVideoDetailsByStreamKey,
  getVideosByPlaylistStreamKey,
  getCreatorsRandomVideo,
  getCreatorsAllVideo,
  likeCreatorVideo,
  unLikeCreatorVideo,
};

export default CreatorService;
