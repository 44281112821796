import { Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";

import { useEffect, useState } from "react";
import ArtistService from "services/music/ArtistService";
import AlbumCard from "../shared/AlbumCard";

function AlbumCardCoursel({ artistData }: any) {
  const [albums, setAlbums] = useState([]);
  // console.log(artistData.id);
  useEffect(() => {
    ArtistService.getAlbumsByArtistId(artistData.id).then((res) => {
      setAlbums(res.artist.albums.data);
      // console.log(res.artist.albums.data);
    });
  }, [artistData]);

  return (
    <div>
      <div className="">
        <Swiper
          slidesPerView={7}
          breakpoints={{
            0: {
              slidesPerView: 1,
            },
            370: {
              slidesPerView: 2,
            },
            700: {
              slidesPerView: 3,
            },
            1000: {
              slidesPerView: 4,
            },
            1200: {
              slidesPerView: 5,
            },
            1400: {
              slidesPerView: 6,
            },
            1600: {
              slidesPerView: 7,
            },
          }}
          modules={[Pagination, Navigation]}
          className="mt-4 mb-5 mySwiper"
        >
          {albums &&
            albums.map((album: any, i: number) => (
              <SwiperSlide key={i}>
                <AlbumCard {...album} />
              </SwiperSlide>
            ))}
        </Swiper>
      </div>
    </div>
  );
}

export default AlbumCardCoursel;
