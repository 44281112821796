import { RouteName } from "constant/routeNames";
import { Link } from "react-router-dom";
import LazyLoadImg from "components/LazyLoadImg";

export const ArtistCard = ({ artist, isLoading }: any) => {
  // console.log(artist);
  return (
    <>
      {!isLoading && (
        <div>
          {artist && (
            <Link to={RouteName.MusicArtistPage.replace(":slug", artist?.slug)}>
              <div className="inline-block text-center">
                <div className="mx-2 inline-block w-[126px] h-[160px] rounded-full ">
                  {/* image */}
                  <div className=" w-[116px] h-[116px] items-center justify-center rounded-full overflow-hidden">
                    <LazyLoadImg
                      imgSrc={
                        artist?.profile_image
                          ? artist?.profile_image
                          : artist?.artwork_url
                      }
                      imgAlt={"Artist"}
                      imgHeight={"116px"}
                      imgWidth={"116px"}
                      className="w-[116px] h-full drop-shadow-2xl object-cover overflow-hidden !rounded-full"
                      style={{
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        width: "100%",
                        height: "100%",
                        borderRadius: "5px",
                      }}
                    />
                    {/* <img
                      src={
                        artist?.profile_image
                          ? artist?.profile_image
                          : artist?.artwork_url
                      }
                      alt="Artist"
                      className="w-[120px] h-full drop-shadow-2xl object-cover overflow-hidden rounded-full"
                    /> */}
                  </div>
                  <p
                    className="inline-block mt-2 text-white font-[600] text-[14px] max-w-[126px]"
                    dangerouslySetInnerHTML={{ __html: artist?.name }}
                  ></p>
                </div>
              </div>
            </Link>
          )}
        </div>
      )}
      {/* {isLoading && } */}
    </>
  );
};
