import TvChannelSkeleton from "components/skeleton/TvChannelSkeleton";
import React from "react";
import NoSearch from "./NoSearch";
import { ArtistCard } from "pages/musicPage/shared/ArtistCard";

function SearchArtistSection({ data, isLoading }: any) {
  const artists = data?.artists || [];
  return (
    <>
      {!isLoading && (
        <div>
          {/* top section */}
          <div className="mb-8 w-[98%] ml-2 mt-8 max-sm:w-full max-sm:mx-0">
            <div>
              <h1 className="text-xl font-semibold text-white lg:text-xl md:text-xl max-sm:ml-3">
                {/* search artists */}
                Artists
              </h1>
            </div>
          </div>
          {/* card section */}
          {artists.length === 0 && artists ? (
            <NoSearch text="Artists" />
          ) : (
            <div>
              <div className="mt-4 mb-5 w-[95%] grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 2xl:grid-cols-7 max-sm:mx-auto max-sm:w-full max-sm:gap-4">
                {artists.length > 0 &&
                  artists.map((artist: any, i: any) => (
                    <div key={i}>
                      <ArtistCard artist={artist} isLoading={isLoading} />
                    </div>
                  ))}
              </div>
            </div>
          )}
        </div>
      )}
      {isLoading && <TvChannelSkeleton />}
    </>
  );
}

export default SearchArtistSection;
