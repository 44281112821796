const ArtistSkeleton = () => {
  const arr = [1, 2, 3, 4, 5, 6];
  return (
    <div>
      <div className={"flex flex-row justify-between w-[65vw] mt-12"}>
        {arr.map((item: any) => (
          <div
            key={item}
            className="flex flex-col items-center justify-center animate-pulse"
          >
            <div className="w-[120px] h-[120px] rounded-full bg-[#1a1919] "></div>
            <div className="w-[100px] h-[20px] mt-3 bg-[#1a1919]"></div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ArtistSkeleton;
