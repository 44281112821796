import { Notification } from "constant/notifications";
import React, { useEffect, useState } from "react";
import { AiFillStar, AiOutlineHeart, AiOutlineStar } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { updateFavorites } from "redux/userRedux";
import CreatorService from "services/creators/CreatorService";
import VideoService from "services/video/VideoService";
import {
  triggerErrorNotification,
  triggerSuccessNotification,
} from "utils/notification";
import { Tooltip as ReactTooltip } from "react-tooltip";

const AddToFavCreator = ({ loggedInUser, video, id, icon }: any) => {
  const [addFav, setAddFav] = useState<boolean>(false);
  const dispatch = useDispatch<any>();
  const favorite = useSelector((state: any) => state?.user?.userFavorites);
  const [hover, setHover] = useState(false);
  // console.log("id", video.id);
  // console.log(favorite);

  useEffect(() => {
    const favoritesEpisodeIds = favorite?.favorite_publication_videos?.map(
      (item: any) => item?.id
    );
    if (favoritesEpisodeIds?.includes(video?.id)) {
      setAddFav(true);
    } else {
      setAddFav(false);
    }
    // }
  }, [favorite, video]);

  //Movies
  const addFavEpisodeRedux = () => {
    const updatedEpisodesData = [
      ...favorite?.favorite_publication_videos,
      video,
    ];

    const updatedFavorite = {
      ...favorite,
      favorite_publication_videos: updatedEpisodesData,
    };
    // Dispatch the action to update the 'favorite' object in the Redux store
    dispatch(updateFavorites(updatedFavorite));
  };

  const removeFavEpisodeFromRedux = () => {
    const updatedEpisodesData = favorite?.favorite_publication_videos.filter(
      (favEpisode: any) => favEpisode.id !== video?.id
    );
    const updatedFavorite = {
      ...favorite,
      favorite_publication_videos: updatedEpisodesData,
    };

    // Dispatch the action to update the 'favorite' object in the Redux store
    dispatch(updateFavorites(updatedFavorite));
  };

  const handleFavorite = () => {
    const favoriteVideo = {
      id: video?.id,
      object_type: "publication_video",
    };
    if (!addFav) {
      setAddFav(true);
      CreatorService.likeCreatorVideo(favoriteVideo)
        .then((res) => {
          setAddFav(true);
          // toast.info(`You Liked ${title}`);
          addFavEpisodeRedux();
          triggerSuccessNotification(Notification.AddToFav);
        })
        .catch((e) => {
          setAddFav(false);
          triggerErrorNotification(Notification.FavError);
        });
    } else {
      CreatorService.unLikeCreatorVideo(favoriteVideo)
        .then((res) => {
          triggerSuccessNotification(Notification.RemovedFromFav);
          removeFavEpisodeFromRedux();
          setAddFav(false);
        })
        .catch((e) => {
          setAddFav(true);
          triggerErrorNotification(Notification.FavError);
        });
    }
  };

  return (
    <>
      {loggedInUser ? (
        <div
          className={
            icon
              ? `${`cursor-pointer items-center p-1 rounded-full add-button ${
                  icon && addFav && `bg-red-600`
                }`}`
              : ""
          }
          data-tooltip-id={`add-Creator-video-to-favorite-${id}`}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          onClick={handleFavorite}
        >
          {addFav ? (
            <div>
              {icon ? (
                <>
                  <div className="">
                    {hover ? (
                      <AiFillStar className="text-red-600 cursor-pointer" />
                    ) : (
                      <AiOutlineStar className="text-white cursor-pointer" />
                    )}
                  </div>
                </>
              ) : (
                <>
                  <p className="flex items-center mx-auto text-base font-bold text-white  cursor-pointer hover:bg-[#48484895] py-3 px-5 border-white border-[1px] rounded-full duration-200 ease-in-out">
                    <AiFillStar className="mr-3 text-red-600 " /> Unlike
                  </p>
                </>
              )}
            </div>
          ) : (
            <>
              <div
                className={
                  icon
                    ? `${
                        hover
                          ? `text-red-600 duration-500 ease-in-out`
                          : `text-white duration-500 ease-in-out`
                      }`
                    : ""
                }
              >
                {icon ? (
                  <>
                    <AiOutlineStar />
                  </>
                ) : (
                  <>
                    <p className="flex items-center mx-auto text-base font-bold text-white cursor-pointer hover:bg-[#48484895] py-3 px-5 border-white border-[1px] rounded-full duration-200 ease-in-out">
                      <AiOutlineStar className="mr-3" /> Like
                    </p>
                  </>
                )}
              </div>
            </>
          )}
          <div className="text-[12px] z-50">
            <ReactTooltip
              id={`add-Creator-video-to-favorite-${id}`}
              place="top"
              content={addFav ? "Remove from favourites" : "Add to favourites"}
            />
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default AddToFavCreator;
