export const onePodcast = [
    {
        id:1,
       name:"The Quarter-Life Crisis...",
       artist:"On Purpose with Jay Shetty",
       duration: "10pm - 1am",
       img:"https://i.scdn.co/image/ab6765630000ba8a882986685255a2e620b489f9",
    },
    {
        id:2,
       name:"Chris Ros",
       artist:"On Purpose with Jay Shetty",
       duration: "10pm - 1am",
       img:"https://i.scdn.co/image/ab6765630000ba8a882986685255a2e620b489f9",
    },
    {
        id:3,
       name:"Lauren Layfield",
       artist:"On Purpose with Jay Shetty",
       duration: "10pm - 1am",
       img:"https://i.scdn.co/image/ab6765630000ba8a882986685255a2e620b489f9",
    },
    {
        id:4,
       name:"Capital Breakfast with Roman Kemp",
       artist:"On Purpose with Jay Shetty",
       duration: "10pm - 1am",
       img:"https://i.scdn.co/image/ab6765630000ba8a882986685255a2e620b489f9",
    },
    {
        id:5,
       name:"Will Manning",
       artist:"Daily show with trevor nova",
       duration: "10pm - 1am",
       img:"https://m.media-amazon.com/images/M/MV5BN2E4M2M5OWQtZjgyMy00ZGQ5LTg2YTAtMGE3Y2FiZjA3Y2Y3XkEyXkFqcGdeQXVyMTM2Mzg4MA@@._V1_FMjpg_UX1000_.jpg",
    },
    {
        id:6,
       name:"Will Manning",
       artist:"Daily show with trevor nova",
       duration: "10pm - 1am",
       img:"https://m.media-amazon.com/images/M/MV5BN2E4M2M5OWQtZjgyMy00ZGQ5LTg2YTAtMGE3Y2FiZjA3Y2Y3XkEyXkFqcGdeQXVyMTM2Mzg4MA@@._V1_FMjpg_UX1000_.jpg",
    },
    {
        id:7,
       name:"Will Manning",
       artist:"Daily show with trevor nova",
       duration: "10pm - 1am",
       img:"https://m.media-amazon.com/images/M/MV5BN2E4M2M5OWQtZjgyMy00ZGQ5LTg2YTAtMGE3Y2FiZjA3Y2Y3XkEyXkFqcGdeQXVyMTM2Mzg4MA@@._V1_FMjpg_UX1000_.jpg",
    },
]