import axios from "axios";

const baseURL = process.env.REACT_APP_BACKEND_SERVER;

const getAlbumById = async (id: any) => {
  const response = await axios({
    method: "get",
    url: `${baseURL}/album/${id}`,
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
  });
  return response.data;
};

const getAllNewReleaseSongs = async () => {
  const response = await axios({
    method: "get",
    url: `${baseURL}/latest/songs`,
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
  });
  return response.data;
};
// const getAllAlbums = async () => {
//   const response = await axios({
//     method: "get",
//     url: `${baseURL}/all/albums`,
//     headers: {
//       "Content-Type": "application/json; charset=utf-8",
//     },
//   });
//   return response.data;
// };

const getAllAlbums = async (url: any) => {
  const response = await axios({
    method: "get",
    url: `${baseURL}/all/${url}`,
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
  });
  return response.data;
};

const getRandomAlbums = async () => {
  const response = await axios({
    method: "get",
    url: `${baseURL}/random/albums`,
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
  });
  return response.data;
};

const getAlbumsByGenre = async (slug: any) => {
  const response = await axios({
    method: "get",
    url: `${baseURL}/genre/${slug}/albums`,
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
  });
  return response.data;
};

const getSuggestedAlbumsById = async (id: any) => {
  const response = await axios({
    method: "get",
    url: `${baseURL}/similar/genre/albums/${id}`,
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
  });
  return response.data;
};

const AlbumService = {
  getAlbumById,
  getAllNewReleaseSongs,
  getAllAlbums,
  getRandomAlbums,
  getAlbumsByGenre,
  getSuggestedAlbumsById,
};

export default AlbumService;
