import { RouteName } from "constant/routeNames";
import { Link } from "react-router-dom";

function CategoryCard({ data }: any) {
  //category card in movie page

  return (
    <Link to={`${RouteName.VideoDiscoverRoute}/${data.alt_name}`}>
      <div className="button-red w-[90%] rounded-full-btn flex items-center h-14 lg:h-20 cursor-pointer max-sm:h-16 ">
        <p className="mx-auto text-base font-bold lg:text-xl white-text">
          {data.name.replace(/&amp;/g, "&")}
        </p>
      </div>
    </Link>
  );
}

export default CategoryCard;
