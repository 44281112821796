import React, { useEffect, useState } from "react";
import NoSearch from "./shared/NoSearch";
import MusicTable from "pages/musicPage/shared/MusicTable";
import { useSelector } from "react-redux";
import PlaylistService from "services/music/PlaylistService";
import LikeService from "services/music/LikeService";

function SearchTrackList({ songs, user }: any) {
  const loggedInUser = useSelector((state: any) => state?.user?.currentUser);
  const [userPlaylists, setUserPlaylists] = useState([]);
  const [favorite, setFavorite] = useState<any>();

  useEffect(() => {
    if (loggedInUser) {
      PlaylistService.getUserPlaylists().then((res) => {
        setUserPlaylists(res.data.data);
      });
    }
  }, [loggedInUser]);

  useEffect(() => {
    if (loggedInUser) {
      LikeService.getFavoriteById(loggedInUser?.id, loggedInUser?.id)
        .then((res) => {
          const favoritesIds = res.profile.loved.data.map(
            (item: any) => item.id
          );
          setFavorite(favoritesIds);
        })
        .catch(() => {});
    }
  }, [loggedInUser, loggedInUser?.id]);
  return (
    <div>
      {songs.length === 0 && songs ? (
        <div>
          <NoSearch text="Songs" />
        </div>
      ) : (
        <>
          <div className="my-5">
            <MusicTable
              songs={songs}
              userPlaylists={userPlaylists}
              setUserPlaylists={setUserPlaylists}
              favorite={favorite}
            />
          </div>
          {/* <div className="w-full px-2 mt-20 text-white">
            <table className="w-full px-2">
              <thead className="text-left">
                <tr>
                  <th className="text-[12px] text-[#7B9DB4] w-4/12">Title</th>
                  <th className="text-[12px] text-[#7B9DB4] w-4/12">Artist</th>
                  <th className="text-[12px] text-[#7B9DB4] w-4/12">
                    duration
                  </th>
                </tr>
              </thead>
              <tr>
                <td colSpan={5}>
                  <hr className="my-5 border-[#7B9DB4]" />
                </td>
              </tr>
              <tbody className="my-5">
                {songs.length > 0 &&
                  songs.map((data: any, i: number) => (
                    // <p>anknfkanfa</p>
                    <MusicAlbumTableRow
                      id={i + 1}
                      data={data}
                      key={i}
                      user={user}
                    />
                  ))}
              </tbody>
            </table>
          </div> */}

          {/* bottom more playlist */}
          {/* <div className="my-10">
        <SubText text={`More by ${album && album.artists[0].name}`} />
        <DescText text="Based on what you played" />
      </div>
      <div className="items-center justify-between  mt-4 w-[95%] grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 2xl:grid-cols-7 max-sm:ml-2">
        {played.map((p: any, i: number) => (
          <MusicCard song={p} />
        ))}
      </div> */}
        </>
      )}
    </div>
  );
}

export default SearchTrackList;
