import React from 'react'

const TrendingPodcastSkeleton = () => {
    return (
        <div>
            <div className="flex flex-col items-start justify-end">
                <div className="lg:w-[200px] h-[30px] bg-[#1a1919] animate-pulse mt-2"></div>
                <div className="lg:w-[80px] h-[20px] bg-[#1a1919] animate-pulse mt-2"></div>
                <div className="lg:w-[400px] h-[300px] bg-[#1a1919] animate-pulse mt-2"></div>
            </div>
        </div>
    )
}

export default TrendingPodcastSkeleton