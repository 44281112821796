import React from "react";
import StarPageHome from "./StarPage/StarPageHome";
import FeaturePages from "layout/FeaturePages";
import { Routes, Route } from "react-router-dom";

function StarPage({
  handleClick,
  isActive,
  setIsActive,
  anyPlayerActive,
}: any) {
  return (
    <div className={`${anyPlayerActive ? `mb-[140px]` : `mb-30`}`}>
      <Routes>
        <Route
          path=""
          element={
            <FeaturePages
              Content={StarPageHome}
              handleClick={handleClick}
              isActive={isActive}
              setIsActive={setIsActive}
              title={"Star"}
            />
          }
        />
      </Routes>
    </div>
  );
}

export default StarPage;
