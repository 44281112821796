import React from "react";
import MovieCarouselCard from "../shared/MovieCarouselCard";
import { useSelector } from "react-redux";

function MoviesGrid({ cardDetails, isMovie }: any) {
  const loggedInUser = useSelector((state: any) => state?.user?.currentUser);
  const favorite = useSelector((state: any) => state?.user?.userFavorites);
  return (
    <div>
      <div className="flex justify-between w-[95%]">
        {isMovie == true ? (
          <>
            <p className="text-white md:text-xl text-md max-sm:ml-2 ">Movies</p>
          </>
        ) : (
          <>
            <p className="text-white md:text-xl text-md max-sm:ml-2 ">
              TV series
            </p>
          </>
        )}
        {/* <p className="text-sm font-bold cursor-pointer red-text place-self-end">
          {""} See all
        </p> */}
      </div>
      <div>
        <div className="grid w-[98%] grid-cols-2 gap-5 mt-4 mb-5 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 2xl:grid-cols-7 max-sm:w-full max-sm:gap-4 ">
          {cardDetails.map((card: any, i: number) => (
            <div
              key={i}
              className="mt-4 mb-5 h-[400px] overflow-visible max-sm:mt-0 max-sm:mb-0"
            >
              <MovieCarouselCard
                time={card.runtime}
                title={card.title}
                info={card.title}
                year={card.released_at}
                type={card.genre}
                img={card.thumbnail}
                slug={card.slug}
                favoriteMovies={favorite}
                user={loggedInUser}
                tvSeries={card}
                first_episode={card.first_episode}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default MoviesGrid;
