import axios from "axios";
import authHeader from "utils/auth-header";

const baseURL = process.env.REACT_APP_BACKEND_SERVER;

const getFavoriteById = async (loggedUserId: any, id: any) => {
  // console.log("loggedUserId", loggedUserId);
  // console.log("id", id);
    const response = await axios({
      method: "get",
      url: `${baseURL}/user/${id}/favorites`,
      params: { current_user_id: loggedUserId },
      headers: {
        "Content-Type": "application/json",
      },
      // headers:authHeader(),
    });
    return response.data;
  };

const likeByUserId = async (data:any) => {
    const response = await axios({
      method: "post",
      data:data,
      url: `${baseURL}/song/like`,
      headers:authHeader(),
    });
    return response.data;
  };
const unLikeByUserId = async (data:any) => {
    const response = await axios({
      method: "post",
      data:data,
      url: `${baseURL}/song/unlike`,
      headers:authHeader(),
    });
    return response.data;
  };

const LikeService = {
    getFavoriteById,
    likeByUserId,
    unLikeByUserId
  };
  
  export default LikeService;