import React from "react";
import CreatorsMovies from "./shared/CreatorsMovies";

const MoviesPage = ({ creatorData }: any) => {
  return (
    <div>
      <CreatorsMovies creatorData={creatorData} />
    </div>
  );
};

export default MoviesPage;
