import CryptoJS from "crypto-js";

export const decryptData = (encryptedData: string, key: string) => {
  // Parse the base64 encoded data
  const b64 = CryptoJS.enc.Base64.parse(encryptedData);
  // Convert parsed data to UTF8 for JSON parsing
  const json = JSON.parse(b64.toString(CryptoJS.enc.Utf8));

  console.log("Parsed JSON:", json);

  const iv = CryptoJS.enc.Base64.parse(json.iv);
  const cipherText = json.value;

  // Decrypt the data
  const decrypted = CryptoJS.AES.decrypt(
    cipherText,
    CryptoJS.enc.Base64.parse(key),
    {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    }
  );

  // Try to convert decrypted data to UTF-8 string
  const decryptedString = decrypted.toString(CryptoJS.enc.Utf8);
  console.log("Decrypted String:", decryptedString);

  // Extract the URL using a regular expression
  const urlMatches = decryptedString.match(/"([^"]+)"/);

  if (urlMatches && urlMatches.length >= 2) {
    return urlMatches[1]; // Extracted URL without quotes
  } else {
    console.error("URL extraction failed");
    return null;
  }
};
