import React from "react";

function CategorySkeleton() {
  const arr = [1, 2, 3, 4, 5, 6, 7];
  return (
    <div>
      <div className="w-[200px] h-[20px] bg-[#1a1919] mt-16"></div>
      <div className="grid w-full grid-cols-1 mt-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 max-sm:w-full">
        {arr.map((item: any) => (
          <div key={item} className="items-start justify-center mb-5">
            <div className="w-[150px] h-16 mr-3 bg-[#1a1919] animate-pulse mt-2"></div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default CategorySkeleton;
