import LoadingSpin from "components/Loading spins/LoadingSpin";
import AntDInputField from "./AntDInputField";
import { useState } from "react";
import { Form } from "antd";
import UserProfileService from "services/UserProfileService";
import { toast } from "react-toastify";
function ChangePassword() {
  const [loadingSpin, setLoadingSpin] = useState<boolean>(false);
  const [form] = Form.useForm();
  const [errors, setErrors] = useState({
    current_password: "",
    new_password: "",
  });

  const onSubmit = async () => {
    setLoadingSpin(true);
    const formData = form.getFieldsValue();
    const user: any = localStorage.getItem("persist:root");
    const data = JSON.parse(JSON.parse(user).user);
    setErrors({ current_password: "", new_password: "" });
    await UserProfileService.changePassword(
      data.currentUser.access_token,
      formData
    )
      .then((res) => {
        setLoadingSpin(false);
        if (res.status === 200) {
          toast.success("Password successfully changed!");
          form.setFieldsValue({
            current_password: "",
            new_password: "",
            new_password_confirmation: "",
          });
        } else {
          toast.error("There is an issue when changing password!");
        }
        //console.log(res);
      })
      .catch((e: any) => {
        setLoadingSpin(false);
        if (e.response && e.response.status === 422) {
          let errorData = "";
          if (e?.response?.data?.errors?.current_password) {
            errorData = e?.response?.data?.errors?.current_password[0];
            setErrors({ ...errors, current_password: errorData });
          } else {
            errorData = e?.response?.data?.errors?.new_password[0];
            setErrors({ ...errors, new_password: errorData });
          }
          toast.warning(errorData);
          // console.log("first", errors);
        } else {
          // Handle other types of errors (e.g., server errors)
          toast.error("An error occurred. Please try again.");
        }
      });
  };
  return (
    <div className="h-screen w-full ml-[20px] max-sm:w-full max-sm:ml-0">
      <div className="flex justify-between">
        <h1 className="text-white text-[25px] font-bold mt-20 my-3 max-sm:w-full">
          Change Password
        </h1>
      </div>
      <Form
        form={form}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 20 }}
        style={{ maxWidth: 600 }}
      >
        <div className="w-[50%] max-sm:w-full">
          <div className="flex-row w-full my-4">
            <span className="text-[15px] text-white">Current Password</span>

            <AntDInputField
              name="current_password"
              type="password"
              placeholder="Current Password"
              rules={[
                {
                  required: true,
                  message: "Please enter your current password!",
                },
              ]}
              password={true}
            />
            {errors?.current_password ? (
              <p className="red-text">{errors?.current_password}</p>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className={`flex justify-between w-full gap-3 mt-5 max-sm:block `}>
          <div className="flex-row w-full">
            <span className="text-[15px] text-white my-4">New Password</span>

            <AntDInputField
              name="new_password"
              type="password"
              placeholder="New Password"
              rules={[
                {
                  required: true,
                  message: "Please confirm your password!",
                },
              ]}
              password={true}
            />
          </div>

          <div className="flex-row w-full">
            <span className="text-[15px] text-white my-4">
              Confirm New Password
            </span>
            <AntDInputField
              name="new_password_confirmation"
              type="password"
              placeholder="Confirm New Password"
              rules={[
                {
                  required: true,
                  message: "Please confirm your password!",
                },
                ({ getFieldValue }: any) => ({
                  validator(_: any, value: any) {
                    if (!value || getFieldValue("new_password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error("Incorrect password. Please try again.")
                    );
                  },
                }),
              ]}
              password={true}
            />
          </div>
        </div>

        <button
          className="px-4 py-2 mt-8 text-white min-w-[130px] rounded-full button-red max-sm:w-full"
          type="submit"
          onClick={onSubmit}
        >
          {loadingSpin ? (
            <LoadingSpin fontSize={20} color={"white"} />
          ) : (
            `Change Password`
          )}
        </button>
      </Form>
    </div>
  );
}

export default ChangePassword;
