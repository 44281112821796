import React, { useEffect, useState } from "react";
import app from "Assets/Images/app.png";
import Footer from "components/Footer";
import { useNavigate } from "react-router-dom";
import { HiArrowLeft } from "react-icons/hi";
import ContentNavbar from "components/ContentNavbar";
import WelcomePageSidebar from "pages/welcome/WelcomePageSidebar";
import { contentNavData } from "constant/ContentNavBarData";
import { AppsData } from "constant/AppsData";
import GeneralServices from "services/General/GeneralServices";

function AppPage({ handleClick, isActive, setIsActive }: any) {
  let navigate = useNavigate();
  const [apps, setApps] = useState<any>();

  useEffect(() => {
    GeneralServices.getAllConfigData()
      .then((res) => {
        // console.log(res?.data);
        const filteredData = res.data.filter((item: any) => {
          // Check if the item's title matches any title in FooterSocialMedia
          return AppsData.some(
            (app) => app.title === item.title && item.value !== null
          );
        });
        setApps(filteredData);
        // console.log(filteredData);
      })
      .catch((e) => {});
  }, []);
  return (
    <>
      <ContentNavbar handleClick={handleClick} />
      <div className="mt-[-10px]">
        <WelcomePageSidebar
          isActive={isActive}
          navData={contentNavData}
          className=""
          setIsActive={setIsActive}
        />
      </div>
      <div
        className={
          isActive
            ? "pl-[80px] duration-500 p-2 max-sm:w-full"
            : "pl-0 duration-500 p-2 max-sm:w-full "
        }
      >
        <div
          className=" max-sm:w-full mt-[-20px] ml-[-10px] max-sm:ml-0"
          // main back image
          style={{
            color: "#fff",
            backgroundImage: `url('')`,
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            height: "",
          }}
        >
          <div className="loginpage-back scrollbar-hide max-sm:w-full">
            <div
              className="absolute h-[1200px] w-[1200px] -right-[-10px] -top-[300px] overflow-hidden "
              style={{
                color: "#fff",
                backgroundImage: `url('https://i.stack.imgur.com/G7vn7.png')`,
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
              }}
            ></div>

            <div className="absolute h-[500px] w-[700px] right-[160px] top-[230px] overflow-hidden scrollbar-hide max-sm:blur-md max-sm:opacity-30 max-sm:right-0 max-md:blur-md max-md:opacity-30 max-md:right-0 max-lg:blur-md max-lg:opacity-30 max-lg:right-[100px] max-xl:right-[50px] max-xl:blur-md max-xl:opacity-30  max-2xl:right-[100px]">
              <img src={app} alt="" />
            </div>
            <div className="app-page-form-container w-[60%] max-sm:w-full max-md:w-full max-xl:w-full">
              <div
                className={`!z-20 flex items-center justify-center w-10 h-10 text-2xl bg-white rounded-full cursor-pointer ml-5 mt-10 mb-4 max-sm:ml-[-15px]`}
              >
                <HiArrowLeft
                  className="text-black"
                  onClick={() => {
                    navigate(-1);
                  }}
                />
              </div>
              <div className="w-[80%] mt-[10px] max-sm:w-[90%]  ">
                <div className="flex flex-col items-center">
                  <h1 className="w-[50%] text-center text-3xl font-extrabold text-white my-8 max-sm:text-[25px] max-sm:w-full">
                    Download Now
                  </h1>
                  <p className="w-[50%] text-[15px] font-medium text-center text-white  max-sm:text-[15px] max-sm:w-[95%]">
                    Over 100+ TV channels anytime, anywhere. Video on Demand
                    anytime, anywhere. Watch live sports, entertainment, music,
                    gaming, esports, kids and documentaries for free!
                  </p>
                </div>
                <div className="flex flex-col items-center justify-center max-sm:w-full max-sm:mt-10">
                  {apps &&
                    apps.map((link: any, i: number) => {
                      const appData = AppsData.find(
                        (app: any) => app.title === link.title
                      );

                      if (appData) {
                        const IconComponent = appData.icon;
                        return (
                          <div key={i}>
                            <a
                              href={link.value}
                              target="_blank"
                              rel="noreferrer"
                              key={i}
                              className="flex items-center py-2 px-8 mt-6 duration-200 ease-in-out text-white rounded-full bg-[#f30543] hover:bg-[#c90336] min-w-[360px]"
                            >
                              <div className="flex items-center justify-center">
                                <IconComponent className="w-10 h-10 mr-5" />
                                <p>
                                  DOWNLOAD ON{" "}
                                  <span className="uppercase">
                                    {link.title.replace("_", " ")}
                                  </span>
                                </p>
                              </div>
                            </a>
                          </div>
                        );
                      }

                      return null; // Return null for items with no matching social media
                    })}
                </div>
              </div>
            </div>
          </div>
          <div className="mt-[300px] ">
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
}

export default AppPage;
