import React from 'react'

const MusicPlaylistSkeleton = () => {
    return (
        <div className='flex flex-col'>
            <div className='w-[50px] h-[50px] rounded-full bg-[#1a1919] animate-pulse'></div>
            <div className='flex flex-row gap-2 mt-[70px] gap-x-[30px]'>
                <div className='w-[200px] h-[200px] bg-[#1a1919] animate-pulse'></div>
                <div className='flex flex-col gap-2 mt-[80px]'>
                    <div className='w-[400px] h-[60px] bg-[#1a1919] animate-pulse'></div>
                    <div className='w-[100px] h-[20px] bg-[#1a1919] animate-pulse'></div>
                    <div className='w-[150px] h-[30px] bg-[#1a1919] animate-pulse'></div>
                </div>
            </div>
            <div className='flex flex-row gap-[40px] mt-[40px]'>
                <div className='w-[200px] h-[50px] rounded-[30px] bg-[#1a1919] animate-pulse'></div>
                <div className='w-[200px] h-[50px] rounded-[30px] bg-[#1a1919] animate-pulse'></div>
            </div>
            <div className='flex flex-row gap-5 mt-[50px] items-baseline justify-between'>
                <div className='w-[70px] h-[20px] bg-[#1a1919] animate-pulse'></div>
                <div className='w-[70px] h-[20px] bg-[#1a1919] animate-pulse'></div>
                <div className='w-[70px] h-[20px] bg-[#1a1919] animate-pulse'></div>
            </div>
            <div className='w-full h-1 bg-[#1a1919] animate-pulse mt-[25px]'></div>
            <div className='flex flex-col gap-2 mt-[20px]'>
                <div className='w-full h-[60px] bg-[#1a1919] animate-pulse'></div>
                <div className='w-full h-[60px] bg-[#1a1919] animate-pulse'></div>
                <div className='w-full h-[60px] bg-[#1a1919] animate-pulse'></div>
            </div>
        </div>
    )
}

export default MusicPlaylistSkeleton