import React from "react";
import { AiOutlineCheckCircle } from "react-icons/ai";
import BoosterButton from "./BoosterButton";
import booster from "./../../../Assets/Images/booster.png";

function HeroBoosterSection({ follow }: any) {
  return (
    <div className="w-full">
      <div
        className="relative h-auto shadow-lg"
        style={{
          backgroundImage: `url(${booster})`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <div className="py-10 pl-6 bg-gradient-to-t from-black to-black/50">
          <div className="flex items-center ">
            <div className="p-5 rounded-full max-sm:w-[40%]">
              {/* <HiLightningBolt className="text-6xl" /> */}
              <img
                src={booster}
                alt="booster image"
                className="w-[176px] h-[176px] max-sm:w-[100%] max-sm:h-[100%]"
              />
            </div>
            <div>
              <div className="flex items-center ml-4 ">
                <div>
                  <p className="text-white font-[500] text-[16px] max-sm:text-sm">
                    content creator
                  </p>
                </div>
                <div className="ml-4 bg-blue-500 rounded-full">
                  <AiOutlineCheckCircle className="w-[22px] h-[22px]" />
                </div>
              </div>
              <div className="flex items-center ml-4">
                <h1 className="text-[50px] font-bold text-white max-sm:text-[40px]">
                  Booster
                </h1>
              </div>
              <div className="flex max-sm:block">
                <div className="ml-4 max-sm:ml-0">
                  <BoosterButton />
                </div>
                <div className="flex items-center ml-4 text-[18px] font-[600] text-[#7B9DB4] max-sm:my-2">
                  {follow}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeroBoosterSection;
