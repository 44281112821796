import BackButton from "components/shared/BackButton";
import SubText from "components/shared/SubText";
import LibrarySkeleton from "components/skeleton/LibrarySkeleton";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import UserProfileService from "services/UserProfileService";
import LikeService from "services/music/LikeService";
import PlaylistService from "services/music/PlaylistService";
import SubscribedPodcasts from "./LibraryPage/SubscribedPodcasts";
import PlaylistCoursel from "./musicPage/playlist/PlaylistCoursel";
import { Link } from "react-router-dom";
import { RouteName } from "constant/routeNames";
import LibraryButtonSection from "./LibraryPage/LibraryButtonSection";
import { Helmet } from "react-helmet-async";

const PlaylistPage = () => {
  const loggedInUser = useSelector((state: any) => state?.user?.currentUser);
  const [userPlaylists, setUserPlaylists] = useState([]);
  const [isLoadingPlaylist, setIsLodingPlaylist] = useState<any>(false);

  const [user, setUser] = useState<any>();
  const [isLoading, setIsLoading] = useState<any>();
  const [haveData, setHaveData] = useState<boolean>(false);

  useEffect(() => {
    setIsLodingPlaylist(true);
    setIsLoading(true);
    if (loggedInUser) {
      PlaylistService.getUserPlaylists().then((res) => {
        setIsLoading(false);
        setIsLodingPlaylist(false);
        setUserPlaylists(res.data.data);
        if (res.data.data.length > 0) {
          setHaveData(true);
        }
      });
    }
  }, [loggedInUser]);

  useEffect(() => {
    if (loggedInUser.username) {
      UserProfileService.getUserByUserName(loggedInUser.username).then(
        (res: any) => {
          setUser(res.user);
        }
      );
    }
  }, [loggedInUser.username]);

  return (
    <>
      <Helmet>
        <title> My Playlists - AYOZAT </title>
        <meta name="title" content="My Playlists - AYOZAT" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="My Playlists - AYOZAT" />

        <meta property="og:title" content="My Playlists - AYOZAT" />
        <meta property="og:type" content="website" />
      </Helmet>
      {!isLoading && (
        <div>
          <BackButton />
          <h1 className="text-[24px] text-white max-sm:ml-4">My Playlists</h1>

          {haveData ? (
            <>
              <PlaylistCoursel
                playlists={userPlaylists}
                isLoadingPlaylist={isLoadingPlaylist}
                libraryPage={true}
              />
              {/* <LibraryButtonSection
                loggedInUser={loggedInUser}
                user={user}
                userPlaylists={userPlaylists}
                isLoadingPlaylist={isLoadingPlaylist}
              /> */}
            </>
          ) : (
            <>
              <div className="flex flex-col items-center justify-center h-96 max-sm:w-[95%] max-sm:mx-auto">
                <p className="text-2xl text-white max-sm:text-lg">
                  You have no items in your playlist.
                </p>
                <div className="flex items-center gap-2 mt-8">
                  <Link to={RouteName.Music.replace("/*", "")}>
                    <button
                      className={`max-sm:justify-center  w-[200px] py-3 ml-10 text-center text-white rounded-full button-red max-sm:py-3  max-sm:text-[13px] max-sm:w-[160px] max-sm:mx-auto `}
                    >
                      Explore Music
                    </button>
                  </Link>
                </div>
              </div>
            </>
          )}
        </div>
      )}

      {isLoading && (
        <>
          <LibrarySkeleton />
          {/* <LibrarySkeleton /> */}
        </>
      )}
    </>
  );
};

export default PlaylistPage;
