/* eslint-disable react-hooks/exhaustive-deps */
import { Form, Select, Switch } from "antd";
import { useForm } from "antd/lib/form/Form";
import Ripple from "components/Ripple";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import IEditProfile from "types/IEditProfile";
import AntDInputField from "./AntDInputField";
import UplaodProfilePicture from "./UplaodProfilePicture";
import UserProfileService from "services/UserProfileService";
import { updatePrfile } from "redux/userRedux";
import { BiLockAlt } from "react-icons/bi";
import LoadingSpin from "components/Loading spins/LoadingSpin";
import { Helmet } from "react-helmet-async";

const layout = {
  wrapperCol: { span: 24 },
  style: { width: "100%" },
};

const onFinishFailed = (errorInfo: any) => {
  // console.log("Failed:", errorInfo);
  toast.error(errorInfo.errorFields[0].errors[0]);
};

const options: any[] | undefined = [];
for (let i = 10; i < 36; i++) {
  options.push({
    value: i.toString(36) + i,
    label: i.toString(36) + i,
  });
}

function ProfileDetails({ profileDetails }: any) {
  const dispatch = useDispatch<any>();
  const loggedInUser = useSelector((state: any) => state?.user?.currentUser);
  const [form] = useForm<IEditProfile>();
  const [loading, setLoading] = useState<boolean>(false);
  const [profileImgeDetails, setProfileImageDetails] = useState("");
  const [loadingSpin, setLoadingSpin] = useState<boolean>(false);
  const [privateAccountChecked, setPrivateAccountChecked] = useState<boolean>(
    loggedInUser?.activity_privacy == 1 ? true : false
  );
  // const [allowChangePassword, setAllowChnagePassword] = useState<boolean>(false);

  const onChange = (checked: boolean) => {
    // console.log("Switch toggled:", checked);
    setPrivateAccountChecked(checked);
    // console.log(`switch to ${checked}`);
  };

  const onFinish = async (value: IEditProfile) => {
    setLoadingSpin(true);
    const finalObj: any = {
      ...value,
      artwork: profileImgeDetails,
      activity_privacy: privateAccountChecked ? 1 : 0,
    };
    const formDataObj = new FormData();

    for (const key in finalObj) {
      // console.log(key, finalObj[key]);
      formDataObj.append(key, finalObj[key]);
    }

    await UserProfileService.editProfile(formDataObj)
      .then((response: any) => {
        setLoadingSpin(false);
        if (response.status === 200) {
          // console.log(response);
          toast.success("Profile Updated");
          dispatch(updatePrfile(response.data));
        } else {
          toast.error("There is an issue when updating profile");
        }
      })
      .catch((e: any) => {
        setLoadingSpin(false);
        toast.error("An error occurred. Please try again.");
      });
  };

  useEffect(() => {
    setLoading(true);
    const initialValue: IEditProfile = {
      name: loggedInUser?.name,
      bio: loggedInUser?.bio ? loggedInUser?.bio : "",
      artwork: loggedInUser?.artwork_url ? loggedInUser?.artwork_url : "",
      soundcloud_url:
        loggedInUser?.soundcloud_url && loggedInUser?.soundcloud_url !== "null"
          ? loggedInUser?.soundcloud_url
          : "",
      instagram_url:
        loggedInUser?.instagram_url && loggedInUser?.instagram_url !== "null"
          ? loggedInUser?.instagram_url
          : "",
      youtube_url:
        loggedInUser?.youtube_url && loggedInUser?.youtube_url !== "null"
          ? loggedInUser?.youtube_url
          : "",
      facebook_url:
        loggedInUser?.facebook_url && loggedInUser?.facebook_url !== "null"
          ? loggedInUser?.facebook_url
          : "",
      twitter_url:
        loggedInUser?.twitter_url && loggedInUser?.twitter_url !== "null"
          ? loggedInUser?.twitter_url
          : "",
      website_url:
        loggedInUser?.website_url && loggedInUser?.website_url !== "null"
          ? loggedInUser?.website_url
          : "",
      gender: loggedInUser?.gender ? loggedInUser?.gender : "",
    };
    form.setFieldsValue(initialValue);
    setLoading(false);
  }, []);

  return (
    <>
    <Helmet>
        <title>Settings - AYOZAT</title>
        <meta
          name="description"
          content="Experience exclusive content and events on demand with our Pay-Per-View service."
        />
        {/* <link rel="canonical" href={`${FE_URL}/ppv`} /> */}
        <meta property="og:title" content="Settings - AYOZAT" />
        <meta
          property="og:description"
          content="Experience exclusive content and events on demand with our Pay-Per-View service."
        />
        {/* <meta property="og:image:secure_url" content="https://ayozat.co.uk/logo192.png" /> */}
        <meta property="og:image" content="https://ayozat.co.uk/logo192.png" />
        {/* <meta property="og:url" content={`${FE_URL}/ppv`} /> */}
        <meta property="og:type" content="website" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Settings - AYOZAT" />
        <meta
          name="twitter:description"
          content="Experience exclusive content and events on demand with our Pay-Per-View service."
        />
        <meta name="twitter:image" content="https://ayozat.co.uk/logo192.png" />
        <meta name="twitter:site" content="AYOZAT Payperview" />
        {/* <meta name="twitter:url" content={`${FE_URL}/ppv`} /> */}
      </Helmet>
    <div className="ml-[20px] mb-[15px] mx-auto flex w-full rounded-md max-sm:w-full max-md:w-full max-lg:w-full max-sm:px-3 max-sm:mx-0 ">
      <div className="w-full mt-5">
        {!loading ? (
          <Form
            {...layout}
            form={form}
            onFinishFailed={onFinishFailed}
            onFinish={onFinish}
            autoComplete="on"
          >
            <div className="flex justify-center gap-5 ">
              {/* left size */}
              <div className="w-full">
                <div className="text-white text-[25px] font-bold my-3">
                  <h1>Edit Profile</h1>
                </div>

                <div className="my-3 text-xs font-normal Color7B9DB4">
                  {/* <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam
                  </p> */}
                </div>
                <UplaodProfilePicture
                  setProfileImageDetails={setProfileImageDetails}
                />

                <div className="flex items-center justify-start my-3">
                  <BiLockAlt className="text-white" />
                  <p className="mx-3 text-white text-md">Private Account</p>
                  <div
                  // className={
                  //   loggedInUser?.activity_privacy === 1
                  //     ? "border-solid border-[5px] border-[#04cbfd] rounded-full"
                  //     : ""
                  // }
                  >
                    <Switch
                      // defaultChecked={false}
                      defaultChecked={privateAccountChecked}
                      onChange={onChange}
                      style={{
                        backgroundColor: privateAccountChecked
                          ? "red"
                          : "#313131",
                        transitionDuration: "300ms",
                      }}
                    />
                  </div>
                </div>

                {/* form */}
                <div className="flex items-center w-full">
                  <div className="flex-3 grow ">
                    <span className="text-[15px] text-white my-4">Name</span>
                    <AntDInputField
                      name="name"
                      rules={[
                        {
                          required: true,
                          message: "Please enter your name!",
                        },
                      ]}
                    />
                  </div>

                  <div className="flex-1 ml-10 grow">
                    <span className="text-[15px] text-white my-4">Gender</span>
                    <Form.Item
                      required
                      name="gender"
                      rules={[
                        {
                          required: true,
                          message: "Please select a gender.",
                        },
                      ]}
                    >
                      <Select
                        style={{ width: 120 }}
                        options={[
                          { value: "M", label: "Male" },
                          { value: "F", label: "Female" },
                          { value: "O", label: "Other" },
                          { value: "P", label: "Prefer not to say" },
                        ]}
                      />
                    </Form.Item>
                  </div>
                </div>
                <span className="text-[15px] text-white my-4">Bio</span>
                <AntDInputField name="bio" />
                <div className="grid grid-cols-2 gap-6">
                  <div>
                    <span className="text-[15px] text-white">
                      Soundcloud url
                    </span>
                    <AntDInputField name="soundcloud_url" className="!mb-2" />
                  </div>

                  <div>
                    <span className="text-[15px] text-white">
                      Instagram url
                    </span>
                    <AntDInputField name="instagram_url" className="!mb-2" />
                  </div>

                  <div>
                    <span className="text-[15px] text-white">Youtube url</span>
                    <AntDInputField name="youtube_url" className="!mb-2" />
                  </div>

                  <div>
                    <span className="text-[15px] text-white">Facebook url</span>
                    <AntDInputField name="facebook_url" className="!mb-2" />
                  </div>

                  <div>
                    <span className="text-[15px] text-white">Twitter url</span>
                    <AntDInputField name="twitter_url" className="!mb-2" />
                  </div>
                  <div>
                    <span className="text-[15px] text-white">Website url</span>
                    <AntDInputField name="website_url" className="!mb-2" />
                  </div>
                </div>

                <Form.Item wrapperCol={{ offset: 0, span: 10 }}>
                  <button
                    className="px-4 py-2 min-w-[130px] mt-8 text-white rounded-full button-red max-sm:w-full"
                    type="submit"
                  >
                    {loadingSpin ? (
                      <LoadingSpin fontSize={20} color={"white"} />
                    ) : (
                      `Save Changes`
                    )}
                  </button>
                </Form.Item>
              </div>

              {/* right side */}
              {/* <div className="w-full ">
              <div>
                <div>
                  <p className="text-white text-[20px]">Preferences</p>
                </div>
                <div>
                  <p className="text-gray-400 text-[12px] my-2">
                    Enter your preferences so we can provide you with better
                    recommendations
                  </p>
                </div>
                <span className="text-[15px] text-white  my-4">
                  Movie Genres
                </span>
                <Form.Item
                 
                  name="movie_genres"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Movie Genres!",
                    },
                  ]}
                  className="w-full mt-3"
                >
                  <Select
                    mode="tags"
                    style={{
                      width: "100%",
                    }}
                    placeholder="Tags Mode"
                    onChange={handleChange}
                    options={options}
                  />
                </Form.Item>

                <span className="text-[15px] text-white  my-4">
                  Music Genres
                </span>
                <Form.Item
                 
                  name="music_genre"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Music Genre!",
                    },
                  ]}
                  className="w-full mt-3"
                >
                  <Select
                    mode="tags"
                    style={{
                      width: "100%",
                    }}
                    placeholder="Tags Mode"
                    onChange={handleChange}
                    options={options}
                  />
                </Form.Item>

                <span className="text-[15px] text-white  my-4">
                  Podcast Genres
                </span>
                <Form.Item
                 
                  name="podcast Genre"
                  rules={[
                    {
                      required: true,
                      message: "Please input your PodCast Genre!",
                    },
                  ]}
                  className="w-full mt-3"
                >
                  <Select
                    mode="tags"
                    style={{
                      width: "100%",
                    }}
                    placeholder="Tags Mode"
                    onChange={handleChange}
                    options={options}
                  />
                </Form.Item>

                <span className="text-[15px] text-white  my-4">
                  LiveTv Genres
                </span>
                <Form.Item
                 
                  name="livetv_genre"
                  rules={[
                    {
                      required: true,
                      message: "Please input your LiveTv Genre!",
                    },
                  ]}
                  className="w-full mt-3"
                >
                  <Select
                    mode="tags"
                    style={{
                      width: "100%",
                    }}
                    placeholder="Tags Mode"
                    onChange={handleChange}
                    options={options}
                  />
                </Form.Item>
              </div>
            </div> */}
            </div>
          </Form>
        ) : (
          <Ripple />
        )}
      </div>
    </div>
    </>
  );
}

export default ProfileDetails;
