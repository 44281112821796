import ChannelCoursel from "pages/liveTvPage/home/ChannelCoursel";
import { useEffect, useState } from "react";
import LiveTVService from "services/liveTv/LiveTVService";
import { convertFromGetAllChannels } from "utils/convertLiveTVData";

function TVChannelSection() {
  const [channels, setChannels] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<any>(false);

  useEffect(() => {
    setIsLoading(true);
    LiveTVService.getFeaturedChannels()
      .then((res) => {
        setIsLoading(false);
        res.data?.map((d: any, i: number) => {
          let c: any = convertFromGetAllChannels(d);
          setChannels((prevChannels: any) => {
            // Check if the new channel (d) already exists in the channels array
            const exists = prevChannels?.some(
              (channel: any) => channel?.uuid === c?.uuid
            ); // Assuming there's an "id" property to identify the channel
            // If it doesn't exist, add the new channel to the channels array
            if (!exists) {
              return [...prevChannels, c];
            }
            // If it already exists, return the previous channels array as it is
            return prevChannels;
          });
        });
      })
      .catch((e) => {
        setIsLoading(false);
        // console.log(e);
      });
  }, []);

  return (
    <div className="mb-10">
      <ChannelCoursel data={channels} isLoading={isLoading} />
    </div>
  );
}

export default TVChannelSection;
