import React from "react";
import RadioCardGridSection from "./shared/RadioCardGridSection";
import { radios } from "constant/radios";

function RadioPageHistory() {
  return (
    <div>
      {/* main topic part */}
      <div>
        <h1 className="text-2xl font-medium text-white lg:text-2xl md:text-2xl">
          User's History
        </h1>
      </div>

      {/* card section */}
      <RadioCardGridSection radios={radios} />
    </div>
  );
}

export default RadioPageHistory;
