const SearchPageSkeleton = () => {
  const arr = [1, 2, 3, 4, 5, 6, 7, 8, 9];

  return (
    <div>
      <div className="flex flex-col items-start justify-center">
        <div className="lg:w-[300px]  h-[40px] bg-[#1a1919] animate-pulse"></div>
        <div className="flex flex-row gap-5 mt-6">
          {arr.map((item: any) => (
            <div key={item}>
              <div className="lg:w-[130px]  h-[50px] rounded-[20px] bg-[#1a1919] animate-pulse mt-2"></div>
            </div>
          ))}
          {/* <div className="lg:w-[120px]  h-[40px] bg-[#1a1919] animate-pulse"></div> */}
        </div>
      </div>
    </div>
  );
};

export default SearchPageSkeleton;
