import React from "react";
import { AiOutlineStar } from "react-icons/ai";
import { RiDeleteBin7Line } from "react-icons/ri";

function OnePodcast({ onePodcast }: any) {
  return (
    <div className="ml-4 mt-4">
      <div className="flex h-14 my-auto">
        <div className="flex w-[594px] items-center justify-between">
          <div className="flex">
            <img
              className=" w-[57px] h-[57px] rounded-sm object-cover"
              src={onePodcast.img}
              alt="podcast episode"
            />
            <div className="flex items-center ml-3">
              <div className="text-[#FFFFFF] font-[600] text-[15px]">
                {onePodcast.name}
                <div className="text-[#7B9DB4] font-[400] text-[12px]">
                  {onePodcast.artist}
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-center">
            <div className="text-[#FFFFFF] ml-3 font-[400] text-[15px]">
              {onePodcast.duration}
            </div>
            <div className="text-[#7B9DB4] ml-3 text-2xl hover:text-red-600">
              <AiOutlineStar />
            </div>
            <div className="text-[#7B9DB4] ml-3 text-2xl">
              <RiDeleteBin7Line />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OnePodcast;
