const FavMusicAndArtistSkeleton = () => {
  const arr = [1, 2, 3];
  const arr1 = [1, 2, 3, 4, 5];

  return (
    <div className="flex flex-col mt-5">
      <div className="w-[200px] h-[40px] bg-[#1a1919] animate-pulse mt-0 ml-5"></div>
      <div className="flex flex-col gap-2 mt-5">
        {arr.map((item: any) => (
          <div key={item}>
            <div className="w-full h-[50px] bg-[#1a1919] animate-pulse mt-0 ml-5"></div>
          </div>
        ))}
      </div>
      <div className="w-[200px] h-[40px] bg-[#1a1919] animate-pulse ml-5 mt-10"></div>
      <div className="flex gap-8 mt-5 flex-rpw">
        {arr1.map((item: any) => (
          <div key={item}>
            <div className="w-[200px] h-[300px] bg-[#1a1919] animate-pulse mt-0 ml-5"></div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FavMusicAndArtistSkeleton;
