import { Swiper, SwiperSlide } from 'swiper/react';

import TVSeriesCarouselCard from 'pages/videoPage/shared/TVSeriesCarouselCard';
import { useEffect, useState } from 'react';
import { Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';

function TrendingNearYouSideBar({ genre }: any) {
  const [cardDetails] = useState([]);
  const [, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
  }, []);

  return (
    <div>
      <div className="flex justify-between w-[95%]">
        <h1 className="text-white md:text-xl text-md max-md:mx-3">
          {genre.data.name}
        </h1>
        <p className="text-sm font-bold cursor-pointer red-text place-self-end">
          {''} See all
        </p>
      </div>

      <div className="">
        <Swiper
          slidesPerView={7}
          breakpoints={{
            0: {
              slidesPerView: 1,
            },
            380: {
              slidesPerView: 2,
            },
            700: {
              slidesPerView: 3,
            },
            1000: {
              slidesPerView: 4,
            },
            1200: {
              slidesPerView: 5,
            },
            1400: {
              slidesPerView: 6,
            },
            1600: {
              slidesPerView: 7,
            },
          }}
          modules={[Pagination]}
          className="mySwiper"
        >
          {cardDetails.map((card: any, i: number) => (
            <SwiperSlide key={i}>
              <div className="mx-3 max-sm:mx-1">
                <TVSeriesCarouselCard data={card} />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
}

export default TrendingNearYouSideBar;
