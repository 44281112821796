import AyozatAudioPlayer from "components/AudioPlayer/AyozatAudioPlayer";
import React from "react";
import { useSelector } from "react-redux";
import { setCurrentPodcast } from "redux/AudioPlayerRedux";

function PodcastPlayer({ isActive }: any) {
  const { podcastPlaylist, currentPodcast, podcastLoading } = useSelector(
    (state: any) => state?.audio
  );
  return (
    <>
      <AyozatAudioPlayer
        playlist={podcastPlaylist}
        currentAudio={currentPodcast}
        playlistLoading={podcastLoading}
        setCurrentAudio={setCurrentPodcast}
        isActive={isActive}
        podcast={true}
      />
    </>
  );
}

export default PodcastPlayer;
