import { audios } from "constant/audios";
import MusicCardCourselHistory from "./history/MusicCardCourselHistory";
import SubText from "components/shared/SubText";
import AdvertisementsSection from "pages/videoPage/home/AdvertisementsSection";
import PlayListCardCoursel from "./home/PlayListCardCoursel";

const MusicPageHistory = () => {
  return (
    <div className="mb-30">
      {/* main topic part */}
      <div className="mb-8 flex items-center justify-between w-[98%]">
        {/* right corner */}
        <div>
          <h1 className="text-2xl font-medium text-white lg:text-3xl md:text-3xl">
            User's History
          </h1>
        </div>
        {/* left corner */}
      </div>
      <SubText text="Recent Tracks" className="font-bold" />
      <div>
        <MusicCardCourselHistory audios={audios} />

        <MusicCardCourselHistory audios={audios} />
      </div>

      <div className="mt-[150px] w-[98%] max-sm:mt-4">
        <AdvertisementsSection />
      </div>

      <div className="mt-[50px]">
        <SubText text="Recent Albums" className="font-bold" />
        <MusicCardCourselHistory audios={audios} />
      </div>

      <div className="mt-[50px] mb-28">
        <SubText text="Recent Playlist" className="font-bold" />
        <PlayListCardCoursel />
      </div>
    </div>
  );
};

export default MusicPageHistory;
