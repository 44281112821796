import VideoSeeAllSkeleton from "components/skeleton/VideoSeeAllSkeleton";
import NoSearch from "./NoSearch";
import MovieCarouselCard from "pages/videoPage/shared/MovieCarouselCard";

function SearchVideoSection({ data, user, isLoading }: any) {
  const videos = data?.movies || [];
  // const [videos, setVideos] = useState([]);

  // useEffect(() => {
  //   if (data) {
  //     setVideos(data.movies);
  //   }
  // }, [data]);

  return (
    <>
      {!isLoading && (
        <div>
          {/* top section */}
          <div className="mb-8 w-[98%] ml-2 mt-8 max-sm:w-full max-sm:mx-0">
            <div>
              <h1 className="text-xl font-semibold text-white lg:text-xl md:text-xl max-sm:ml-3">
                {/* search videos */}
                Videos
              </h1>
            </div>
          </div>
          {/* card section */}
          {videos.length === 0 && videos ? (
            <NoSearch text="Videos" />
          ) : (
            <div>
              <div className="mt-4 mb-5 w-[98%] grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 2xl:grid-cols-6 max-sm:mx-auto max-sm:w-full max-sm:gap-4">
                {videos.length > 0 &&
                  videos.map((card: any, i: any) => (
                    <div key={i}>
                      <div className="mx-3 my-2 max-sm:mx-1">
                        <MovieCarouselCard
                          id={card.id}
                          time={card.runtime}
                          title={card.title}
                          info={card.title}
                          year={card.release}
                          type={card.genre}
                          img={card.thumbnail_url}
                          slug={card.slug}
                          user={user}
                          movie={card}
                          searchPage={true}
                        />
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          )}
        </div>
      )}
      {isLoading && <VideoSeeAllSkeleton />}
    </>
  );
}

export default SearchVideoSection;
