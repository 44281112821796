import LazyLoadImg from "components/LazyLoadImg";
import React from "react";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import verify from "Assets/Images/verify.png";

function StarDetails({ star }: any) {
  // console.log(star);
  const navigate = useNavigate();
  const artistType =
    star?.type === "actor"
      ? `Actor`
      : star?.type === "director"
      ? `Director`
      : star?.type === "producer"
      ? `Producer`
      : ``;
  const artwork_url = star?.image ? star?.image : ``;

  // const verified = true;
  // const name = "Umesh Perera";
  // const artistType = "Director";

  return (
    <>
      <div className="relative h-auto ">
        <div
          className="relative h-auto mb-30"
          style={{
            backgroundImage: `url(${artwork_url})`,
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        >
          <div className="p-3 full-backdrop-bottom">
            <div>
              <button
                className="w-10 h-10 mb-5 bg-white rounded-full max-sm:ml-[8px]"
                onClick={() => {
                  navigate(-1);
                }}
              >
                <AiOutlineArrowLeft className="ml-3 font-bold text-black" />
              </button>
            </div>
            <div className="flex items-end max-sm:h-[250px]  ">
              <div className="mt-5 max-sm:hidden h-[224px] overflow-hidden">
                {/* <img
                  src={artwork_url}
                  alt=""
                  className="rounded-md album-page-image max-sm:h-[20%]"
                /> */}
                <LazyLoadImg
                  imgSrc={artwork_url}
                  imgAlt={"first artist"}
                  imgHeight={"224px"}
                  imgWidth={"224px"}
                  className=" max-sm:scale-50"
                  style={{
                    borderRadius: "6px",
                    // width: "24px",
                    // height: "24px",
                    backgroundSize: "cover",
                    marginRight: "8px",
                  }}
                />
              </div>
              <div className="ml-10 max-sm:ml-0 ">
                <p className="text-base font-medium fafafa max-sm:text-sm">
                  {artistType}
                </p>
                <div className="flex items-center justify-between">
                  <div className="flex flex-row items-center justify-start gap-3">
                    <h1
                      className="text-6xl font-bold font-secular fafafa max-sm:text-[30px] max-sm:w-full"
                      dangerouslySetInnerHTML={{
                        __html: star?.name ? star?.name : "",
                      }}
                    ></h1>
                    {star?.verified ? (
                      <div className="flex items-center justify-center">
                        <img
                          src={verify}
                          alt=""
                          className="mx-3 mt-2 place-self-center verify-blue-tik max-sm:mx-2"
                        />
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>

                {/* <p className="text-[12px] text-[#7B9DB4]">{description}</p> */}
                {/* <div className="flex flex-row gap-5 max-sm:mt-3">
                      <p className="text-lg font-semibold Color7B9DB4 max-sm:text-sm">
                        {song_count} songs
                      </p>
                      <p className="text-lg font-semibold Color7B9DB4 max-sm:text-sm">
                        {album_count} Albums
                      </p>
                    </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default StarDetails;
