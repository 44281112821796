import { RouteName } from "constant/routeNames";
import Podcastcard from "pages/podcastPage/shared/Podcastcard";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import NoFavorite from "./NoFavorite";
import { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";

function PodcastByUser({ favoriteData, user, favorite }: any) {
  const [podcasts, setPodcasts] = useState<any>([]);
  const allPodcasts = favoriteData?.favorite_podcasts || [];

  useEffect(() => {
    setPodcasts(allPodcasts);
  }, []);
  return (
    <div>
      {/* top section */}
      <div className="mb-8 w-[98%] ml-6 mt-8 max-sm:w-full max-sm:mx-0">
        <div>
          <h2 className="text-xl font-medium text-white lg:text-xl md:text-xl">
            {/* Podcasts by User */}
            Favourite Podcasts
          </h2>
        </div>
      </div>
      {/* card section */}
      {allPodcasts.length === 0 && allPodcasts ? (
        <div>
          <NoFavorite text="Podcasts" />
        </div>
      ) : (
        // <div>
        //   <div className="mt-4 mb-5 w-[95%] grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 2xl:grid-cols-7 max-sm:mx-0 max-sm:w-full max-sm:gap-3 ">
        //     {podcasts.length > 0 &&
        //       podcasts.map((podcasts: any, i: number) => (
        //         <div key={i}>
        //           <Podcastcard
        //             podcast={podcasts}
        //             user={user}
        //             favorite={favorite}
        //           />
        //         </div>
        //       ))}
        //   </div>
        // </div>

        <>
          {/* coursel */}
          <div className="">
            <Swiper
              slidesPerView={6}
              breakpoints={{
                0: {
                  slidesPerView: 1,
                },
                370: {
                  slidesPerView: 2,
                },
                700: {
                  slidesPerView: 3,
                },
                1000: {
                  slidesPerView: 4,
                },
                1200: {
                  slidesPerView: 5,
                },
                1400: {
                  slidesPerView: 6,
                },
                1600: {
                  slidesPerView: 7,
                },
              }}
              modules={[Pagination, Navigation]}
              navigation={true}
              className="mt-4 mb-10 mySwiper"
            >
              {allPodcasts.length > 0 &&
                allPodcasts.map((podcasts: any, i: number) => (
                  <SwiperSlide className="" key={i}>
                    <Link
                      to={RouteName.PodcastOpenEpisodeRoute.replace(
                        ":id",
                        podcasts.id
                      )}
                    >
                      <Podcastcard
                        podcast={podcasts}
                        user={user}
                        favorite={favorite}
                      />
                    </Link>
                  </SwiperSlide>
                ))}
            </Swiper>
          </div>
        </>
      )}
    </div>
  );
}

export default PodcastByUser;
