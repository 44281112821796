import React from "react";

function ArtistDetailsSkeleton() {
  return (
    <div className="flex flex-col">
      {/* back btn */}
      <div className="w-[50px] h-[50px] rounded-full bg-[#1a1919] animate-pulse"></div>
      {/* artist details */}
      <div className="flex flex-row max-sm:block gap-2 mt-[70px] gap-x-[30px]">
        {/* image  */}
        <div className="w-[200px] h-[200px] bg-[#1a1919] animate-pulse"></div>
        {/* artist details */}
        <div className="flex flex-col gap-2 max-sm:mt-3 sm:mt-[80px]">
          <div className="w-[100px] h-[20px] bg-[#1a1919] animate-pulse"></div>
          <div className="max-sm:w-full sm:w-[400px] h-[60px] bg-[#1a1919] animate-pulse"></div>
        </div>
      </div>

      {/* description */}
      <div>
        {/* Description Title */}
        <div className="sm:w-[300px] max-sm:w-[200px] h-[40px] bg-[#1a1919] animate-pulse my-5"></div>
        <div className="flex flex-col gap-2 mt-[20px]">
          <div className="w-full h-[20px] bg-[#1a1919] animate-pulse"></div>
          <div className="w-full h-[20px] bg-[#1a1919] animate-pulse"></div>
          <div className="w-full h-[20px] bg-[#1a1919] animate-pulse"></div>
        </div>
      </div>
    </div>
  );
}

export default ArtistDetailsSkeleton;
