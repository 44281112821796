import React from 'react'

const UserProfileSkeleton = () => {
    return (
        <div>
            <div className='lg:w-full lg:h-[250px] md:w-[1000px] sm:w-[300px] bg-[#1a1919] animate-pulse mt-2'></div>
        </div>
    )
}

export default UserProfileSkeleton