import HeroBoosterSection from "./shared/HeroBoosterSection";

function BoosterHome() {
  return (
    <>
      <HeroBoosterSection follow="257 Followers" />
      {/* <ButtonSection /> */}
      <div className="mt-8 ml-6 max-sm:w-full max-sm:mx-0">
        <p className="text-white text-[20px] font-medium">About booster</p>
        <p className="text-[#7B9DB4] text-[14px] mt-3 font-semibold w-[65%] max-sm:w-full">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat.{" "}
        </p>
      </div>
    </>
  );
}

export default BoosterHome;
