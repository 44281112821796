import WidgetBot from "@widgetbot/react-embed";
import { useEffect, useRef } from "react";

type DiscordChatProps = {
  channelId?: string;
  height?: string | number;
  width?: string | number;
  serverId?: string;
};

export const DiscordChat = ({
  channelId,
  height,
  width,
  serverId,
}: DiscordChatProps): JSX.Element => {
  return (
    <WidgetBot
      server={serverId}
      channel={channelId}
      width={width}
      height={height}
    />
  );
};
