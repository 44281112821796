import { useEffect, useRef, useState } from 'react';
import {
  HiOutlineBell,
  HiOutlineUserGroup,
  HiOutlineViewGrid,
} from 'react-icons/hi';
import { Link, useNavigate } from 'react-router-dom';
import LoginModel from 'components/LoginModal';
import NavbarPageNav from 'components/shared/NavbarPageNav';
import { RouteName } from 'constant/routeNames';
import Logo from 'Assets/ayozat_logo.svg';
import NavIconLayout from './ContentNavBar/NavIconLayout';
import { useSelector } from 'react-redux';
import ProfileIconSubMenu from './ContentNavBar/ProfileIconSubMenu';

function HomeNavbar() {
  const [loginModel, setLoginModel] = useState(false);
  const [isActive, setIsActive] = useState(true);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const loggedInUser = useSelector((state: any) => state?.user?.currentUser);

  const navigate = useNavigate();

  const handleClickOutside = (event: MouseEvent) => {
    if (
      containerRef.current &&
      !containerRef.current?.contains(event.target as Node)
    ) {
      setIsActive(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const clickLogin = (e: any) => {
    // setLoginModel((current) => !current);
    navigate(RouteName.Login);
  };

  //toggle dropdown
  const dropDown = (e: any) => {
    setIsActive((current) => !current);
  };

  return (
    <>
      {/* home page navbar */}
      <div className="flex w-[95%] h-16 mx-auto justify-between items-center">
        {/* logo */}
        <div className="flex-none text-2xl font-extrabold navbar-image">
          {/* AYOZAT */}
          <Link to={RouteName.Home}>
            <img src={Logo} alt="logo" />
          </Link>
        </div>
        {/* search bar in the middle */}
        <div className="justify-center navbar-search grow">
          <div className=" md:block ml-[35%]">
            <div className="relative flex items-center white-text">
              <span className="absolute flex items-center pr-3 left-4 md:hidden">
                <svg
                  xmlns="http://ww50w3.org/2000/svg"
                  className="w-4 fill-current "
                  viewBox="0 0 35.997 36.004"
                >
                  <path
                    id="Icon_awesome-search"
                    data-name="search"
                    d="M35.508,31.127l-7.01-7.01a1.686,1.686,0,0,0-1.2-.492H26.156a14.618,14.618,0,1,0-2.531,2.531V27.3a1.686,1.686,0,0,0,.492,1.2l7.01,7.01a1.681,1.681,0,0,0,2.384,0l1.99-1.99a1.7,1.7,0,0,0,.007-2.391Zm-20.883-7.5a9,9,0,1,1,9-9A8.995,8.995,0,0,1,14.625,23.625Z"
                  ></path>
                </svg>
              </span>
              <input
                type="text"
                name="leadingIcon"
                id="leadingIcon"
                placeholder="Search.."
                className="w-[50%] pl-12 pr-4 py-1.5 rounded-md text-md outline-none max-sm:hidden"
              />
            </div>
          </div>
        </div>

        {/* end section of navbar */}
        {loggedInUser === null ? (
          <div className="flex flex-row items-center flex-none navbar-end">
            <NavIconLayout
              icon={<HiOutlineViewGrid />}
              content={<NavbarPageNav />}
            />
            <button
              className="px-8 py-2 ml-4 rounded-full white-text button-red"
              onClick={clickLogin}
            >
              Login
            </button>
          </div>
        ) : (
          <div className="flex flex-row flex-none navbar-end ">
            <NavIconLayout
              icon={<HiOutlineUserGroup />}
              content={<NavbarPageNav />}
            />

            <NavIconLayout
              icon={<HiOutlineViewGrid />}
              content={<NavbarPageNav />}
            />

            <NavIconLayout
              icon={<HiOutlineBell />}
              content={<NavbarPageNav />}
            />

            <NavIconLayout
              img={
                'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80'
              }
              content={<ProfileIconSubMenu />}
            />
          </div>
        )}

        {/* dropdown on grid icon select */}
        <div
          className={`absolute duration-200 p-2 pt-4 w-[200px] md:w-[300px] overflow-x-auto text-center shadow mt-8 navbar rounded-md z-50 border-black max-sm:w-[80%] max-sm:right-[10%] max-sm:top-[60px] max-sm:h-[250px] border-solid border-2
            ${
              isActive
                ? ' top-[10px] right-[80px] md:right-[130px]   opacity-0  pointer-events-none '
                : ' top-5 right-[80px] mx-auto md:right-[130px] opacity-100   '
            }
          `}
        >
          <NavbarPageNav />
        </div>
      </div>

      {loginModel && <LoginModel clickLogin={clickLogin} />}
    </>
  );
}

export default HomeNavbar;
