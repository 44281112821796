import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const RedirectComponent = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (window.location.pathname.endsWith(".html")) {
      const pathWithoutHtml = window.location.pathname.slice(0, -5);
      navigate(pathWithoutHtml);
    }
  }, [navigate]);

  // You can render a loading message or a component if needed
  return <div></div>;
};

export default RedirectComponent;
