import React from "react";

function VideoDetailsButton({
  pages,
  isSelected,
  setIsSelected,
  selected,
  setComponents,
}: any) {
  const handleSelect = () => {
    if (isSelected === pages.id) {
      setIsSelected();
    } else {
      setIsSelected(pages.id);
    }
    selectComponent();
  };

  const selectComponent = () => {
    setComponents(pages);
  };
  return (
    <div onClick={handleSelect}>
      {selected === true ? (
        <button
          className={`flex justify-center items-center min-w-[120px] h-[40px] p-3 text-center  text-[#f30543] rounded-md  max-sm:p-2 border-b-[1px] max-sm:ml-0  my-1 hover:border-[#f30543] hover:text-[#f30543] border-[#f30543]`}
        >
          {pages.name}
        </button>
      ) : (
        <button
          className={`flex justify-center items-center min-w-[120px] h-[40px] p-3 text-center rounded-md  text-white max-sm:p-2 max-sm:ml-0 my-1 hover:border-[#f30543] hover:border-b-[1px] hover:text-[#f30543]`}
        >
          {pages.name}
        </button>
      )}
    </div>
  );
}
export default VideoDetailsButton;
