import PayPalButton from "components/PayPalButton";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PaymentService from "services/PaymentService";

const PayPalCheckoutForm = ({ ppv_event }: any) => {
  const [transactionData, setTransactionData] = useState(null);
  const navigate = useNavigate();

  const handlePaymentSuccess = (details: any) => {
    setTransactionData(details);
  };

  const handlePaymentError = (error: any) => {
    console.error("PayPal Payment error:", error);
    // Add modal to display error message (For example: Something went wrong, Please tray again!)
  };

  useEffect(() => {
    if (transactionData) {
      createPaypalPaymentIntense();
    }
  }, [transactionData]);

  const createPaypalPaymentIntense = async () => {
    console.log("calling createPaypalPaymentIntense");
    const ppv_event_id = ppv_event?.id;
    try {
      const response = await PaymentService.paypalPaymentIntense(
        ppv_event_id,
        transactionData
      );

      if (response.success) {
        const payment_intent = response.payment_intent;
        navigate(
          "/ppv/success?payment_intent=" +
            payment_intent +
            "&redirect_status=succeeded"
        );
      }
    } catch (error) {
      console.error(
        "Error occurred during createPaypalPaymentIntense: ",
        error
      );
      // Add modal to display error message (For example: Something went wrong, Please tray again!)
    }
  };

  return (
    <div>
      <PayPalButton
        amount={ppv_event.price}
        onSuccess={handlePaymentSuccess}
        onError={handlePaymentError}
      />
    </div>
  );
};

export default PayPalCheckoutForm;
