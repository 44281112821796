import React from 'react'
import MusicCardSkeleton from './MusicCardSkeleton'

const OverviewSkeleton = () => {

    const arr = [1, 2, 3, 4, 5, 6, 7];

    return (
        <div className='flex flex-col mt-5'>
            <div className='w-[200px] h-[40px] bg-[#1a1919] animate-pulse mt-0 ml-5'></div>
            <div className='mt-5 flex flex-row gap-5'>
                {arr.map((item: any) => (
                    <div key={item}>
                        <MusicCardSkeleton />
                    </div>
                ))
                }
            </div>
            <div className='w-[200px] h-[40px] bg-[#1a1919] animate-pulse mt-0 ml-5'></div>
            <div className='mt-5 flex flex-row gap-5'>
                {arr.map((item: any) => (
                    <div key={item}>
                        <MusicCardSkeleton />
                    </div>
                ))
                }
            </div>
        </div>
    )
}

export default OverviewSkeleton