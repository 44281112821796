import AlbumCard from "pages/musicPage/shared/AlbumCard";
import { MusicCard } from "pages/musicPage/shared/MusicCard";
import NoSearch from "pages/searchPage/shared/NoSearch";
import React from "react";

const CreatorMusic = ({ creatorData }: any) => {
  const albums = creatorData?.albums || [];

  // console.log(albums);

  return (
    <div>
      {/* card section */}
      {albums?.length === 0 && albums ? (
        <NoSearch text="Albums" />
      ) : (
        <div>
          <div className="mt-4 mb-5 w-[95%] grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 2xl:grid-cols-7 max-sm:mx-auto max-sm:w-full max-sm:gap-4">
            {albums?.length > 0 &&
              albums?.map((album: any, i: any) => (
                <div key={i}>
                  <AlbumCard
                    artwork_url={album.artwork_url}
                    title={album.title}
                    song_count={album.song_count}
                    id={album.id}
                    artists={album.artists}
                  />
                </div>
              ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default CreatorMusic;
