import React from 'react'
import MusicCardSkeleton from './MusicCardSkeleton'

const MusicTractPageSkeleton = () => {

    const arr = [1, 2, 3, 4, 5, 6];

    return (
        <div>
            <div className='flex flex-col'>
                <div className='w-[50px] h-[50px] rounded-full bg-[#1a1919] animate-pulse'></div>
                <div className='flex flex-row gap-2 mt-[70px] gap-x-[30px]'>
                    <div className='w-[300px] h-[300px] bg-[#1a1919] animate-pulse'></div>
                    <div className='flex flex-col gap-2 mt-[80px]'>
                        <div className='w-[400px] h-[60px] bg-[#1a1919] animate-pulse'></div>
                        <div className='w-[100px] h-[20px] bg-[#1a1919] animate-pulse'></div>
                        <div className='w-[150px] h-[30px] bg-[#1a1919] animate-pulse'></div>
                    </div>
                </div>
                <div className='flex flex-row gap-[40px] mt-[40px]'>
                    <div className='w-[200px] h-[50px] rounded-[30px] bg-[#1a1919] animate-pulse'></div>
                    {/* <div className='w-[50px] h-[50px] rounded-full bg-[#1a1919] animate-pulse'></div> */}
                    <div className='w-[50px] h-[50px]  bg-[#1a1919] animate-pulse'></div>
                </div>
                <div className='w-full h-1 bg-[#1a1919] animate-pulse mt-[25px]'></div>
                <div className='flex flex-col gap-2 mt-[20px]'>
                    <div className='w-[400px] h-[60px] bg-[#1a1919] animate-pulse'></div>
                </div>
                <div className='mt-[40px]'>
                    <div className='w-[400px] h-[30px] bg-[#1a1919] animate-pulse'></div>
                    <div className='grid  lg:grid-cols-6 max-md:grid-cols-3 max-sm:grid-cols-1 mt-5'>
                        {arr.map((item: any) => (
                            <div key={item}>
                                <MusicCardSkeleton />
                            </div>
                        ))
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MusicTractPageSkeleton