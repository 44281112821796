import { RouteName } from "constant/routeNames";
import React from "react";
import { Link } from "react-router-dom";

function UserCard({ user }: any) {
  return (
    <div>
      <Link to={RouteName.UserProfile.replace("*", user?.username)}>
        <div className="w-[185px] h-[185px] flex items-center justify-center mt-5">
          <div className="">
            <div className="flex justify-center h-[130px] ">
              {/* image */}
              <img
                src={user?.artwork_url}
                alt="profile pic"
                className="rounded-full h-[102px] bg-img white-text object-cover border-red-900 border-solid border-[1px]"
              />
            </div>
            <p className="w-[50%] flex justify-center mx-auto text-sm font-semibold text-center text-white">
              {user?.name}
            </p>
          </div>
        </div>
      </Link>
    </div>
  );
}

export default UserCard;
