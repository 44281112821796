import { FacebookShareButton } from "react-share";
import TextArea from "antd/es/input/TextArea";
import { useState } from "react";
import SocialMediaShareBtn from "../SocialMediaShareBtn";
import CopySharebleLink from "../CopySharebleLink";

function Instagram({ shareUrl }: any) {
  const [quote, setQuote] = useState();
  return (
    <div className="text-white">
      <p className="mb-3">Add your caption</p>
      <TextArea
        showCount
        maxLength={100}
        style={{ height: 150 }}
        onChange={(e: any) => {
          setQuote(e.target.value);
        }}
      />

      <div className="social-media-pop-up-btn-section">
        <CopySharebleLink shareUrl={shareUrl} />
        <FacebookShareButton url={shareUrl} quote={quote} hashtag={"hashtag"}>
          <SocialMediaShareBtn />
        </FacebookShareButton>
      </div>
    </div>
  );
}

export default Instagram;
