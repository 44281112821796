import { RouteName } from "constant/routeNames";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

function PayperviewGenreSelector({ slug, categories }: any) {
  const navigate = useNavigate();
  const [selected, setSelected] = useState(slug);

  useEffect(() => {
    navigate(
      RouteName.PayPerView.replace(
        "*",
        RouteName.PayperviewDiscoverByGenreId.replace(":slug", selected)
      )
    );
    // setGenre(selected);
  }, [selected]);

  const onChange = (e: any) => {
    setSelected(e.target.value);
  };

  return (
    <div className="mr-5">
      <label
        htmlFor="watched"
        className="text-white max-sm:hidden max-md:hidden"
      >
        Genres{" "}
      </label>
      <select
        name="cars"
        id="cars"
        className="p-1 ml-2 text-white rounded-md outline-none searchbar max-h-[60px] overflow-y-auto"
        onChange={onChange}
      >
        {categories?.map((c: any, i: number) => (
          <option
            value={c?.slug}
            key={i}
            selected={c?.slug === slug ? true : false}
          >
            {c?.name}
          </option>
        ))}
      </select>
    </div>
  );
}

export default PayperviewGenreSelector;
