import FollowNotification from "./FollowNotification";

function NotificationText({ notification, isLoading }: any) {
  // console.log("AAA", notification);
  return (
    <div>
      {notification?.action === "followUser" && (
        <FollowNotification notification={notification} isLoading={isLoading} />
      )}
    </div>
  );
}

export default NotificationText;
