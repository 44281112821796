import React, { useEffect, useState } from "react";
import { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import PlaylistCard from "./PlaylistCard";
import DiscoverAlbumSkeleton from "components/skeleton/DiscoverAlbumSkeleton";

function PlaylistCoursel({
  playlists,
  welcomePage,
  libraryPage,
  isLoading,
}: any) {
  return (
    <div>
      {!isLoading && (
        <div className="">
          <Swiper
            slidesPerView={7}
            breakpoints={{
              0: {
                slidesPerView: 1,
              },
              380: {
                slidesPerView: 2,
              },
              700: {
                slidesPerView: 3,
              },
              1000: {
                slidesPerView: 4,
              },
              1200: {
                slidesPerView: 5,
              },
              1400: {
                slidesPerView: 6,
              },
              1600: {
                slidesPerView: 7,
              },
            }}
            modules={[Pagination, Navigation]}
            navigation={true}
            className="mt-8 mb-8 mySwiper"
          >
            {playlists &&
              playlists?.map((playlist: any, i: number) => (
                <SwiperSlide className="" key={i}>
                  <div className=" max-sm:mx-1">
                    <PlaylistCard
                      playlist={playlist}
                      // welcomePage={welcomePage}
                      // libraryPage={libraryPage}
                      isLoading={isLoading}
                      playlistPage={true}
                    />
                  </div>
                </SwiperSlide>
              ))}
          </Swiper>
        </div>
      )}
      {isLoading && <DiscoverAlbumSkeleton />}
    </div>
  );
}

export default PlaylistCoursel;
