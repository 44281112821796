import axios from "axios";
import authHeader from "utils/auth-header";

const baseURL = process.env.REACT_APP_BACKEND_SERVER;

const createPlaylist = async (data: any) => {
  const response = await axios({
    method: "post",
    data: data,
    url: `${baseURL}/users/create-playlist`,
    headers: authHeader(),
  });
  // console.log(response);

  return response;
};

const getUserPlaylists= async () => {
  const response = await axios({
    method: "get",
    url: `${baseURL}/users/playlists/all`,
    headers: authHeader(),
  });
  return response;
};

const addSongToPlaylist = async (data: any) => {
  const response = await axios({
    method: "post",
    data: data,
    url: `${baseURL}/users/add-to-playlist`,
    headers: authHeader(),
  });
  // console.log(response);
  
  return response;
};

const getSongsByPlaylistId= async (id:any) => {
  const response = await axios({
    method: "get",    
    url: `${baseURL}/users/playlists/songs/${id}`,
    headers: authHeader(),
  });
  return response;
};

const getPlaylistByPlaylistId= async (id:any) => {
  const response = await axios({
    method: "get",    
    url: `${baseURL}/playlist/${id}`,    
    headers: authHeader(),
  });
  return response;
};

const removeSongFromPlaylist= async (data:any) => {
  const response = await axios({
    method: "post",
    data: data,
    url: `${baseURL}/users/remove-from-playlist`,
    headers: authHeader(),
  });
  return response;
};

const deletePlaylist = async (data:any) => {
  const response = await axios({
    method: "post",
    data: data,
    url: `${baseURL}/users/delete-playlist`,
    headers: authHeader(),
  });
  return response;
}

const updatePlaylist = async (data: any) => {
  const response = await axios({
    method: "post",
    data: data,
    url: `${baseURL}/users/edit-playlist`,
    headers: authHeader(),
  });
  // console.log(response);
  
  return response;
};

const getPublicPlaylists= async () => {
  const response = await axios({
    method: "get",
    url: `${baseURL}/public/playlists`,
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
  });
  return response;
};

const getPublicPlaylistSongsbyPlaylistId= async (playlist_id:any) => {
  const response = await axios({
    method: "get",
    url: `${baseURL}/public/playlists/songs/${playlist_id}`,
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
  });
  return response;
};


const getPublicPlaylistByUserId= async (user_id:any) => {
  const response = await axios({
    method: "get",
    url: `${baseURL}/public/playlists/${user_id}`,
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
  });
  return response;
};

const PlaylistService = {
  createPlaylist,
  getUserPlaylists,
  addSongToPlaylist,
  getSongsByPlaylistId,
  removeSongFromPlaylist,
  deletePlaylist,
  updatePlaylist,
  getPlaylistByPlaylistId,
  getPublicPlaylists,
  getPublicPlaylistSongsbyPlaylistId,
  getPublicPlaylistByUserId,
};

export default PlaylistService;
