import LoadingSpin from "components/Loading spins/LoadingSpin";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

function UplaodProfilePicture({ setProfileImageDetails }: any) {
  const [profileImge, setProfileImage] = useState("");
  const [loadingSpin, setLoadingSpin] = useState<boolean>(false);
  const loggedInUser = useSelector((state: any) => state?.user?.currentUser);

  useEffect(() => {
    setProfileImage(loggedInUser?.artwork_url);
  }, [loggedInUser?.artwork_url]);

  const upload = useRef<HTMLInputElement>(null);

  const handleClickUpload = () => {
    // setLoadingSpin(true);
    upload.current?.click();
  };

  const handleFileChange = (event: any) => {
    // setLoadingSpin(true);
    const fileObj = event.target.files && event.target.files[0];
    // console.log(fileObj);
    // setLoadingSpin(true);
    if (!fileObj) {
      return;
    } else {
      if (fileObj.type.startsWith("image/")) {
        // setLoadingSpin(true);
        // console.log(fileObj?.size / 1024);
        if (fileObj?.size / 1024 < 8096) {
          // Set the uploaded image URL
          setProfileImageDetails(fileObj);
          setProfileImage(URL.createObjectURL(fileObj));
        } else {
          toast.error(
            "Sorry, Please choose an image that is smaller than 8MB."
          );
        }
      } else {
        // setLoadingSpin(false);
        toast.error("Please select a valid image file.");
      }
    }
    // reset file input
    event.target.value = null;
  };
  return (
    <div className="flex mb-10">
      {/* image */}
      <div className="flex">
        <img
          src={profileImge}
          alt="profile"
          className="w-[100px] rounded-full h-[100px] bg-img white-text object-cover border-4 border-primaryRed"
        />
      </div>
      <input
        style={{ display: "none" }}
        ref={upload}
        type="file"
        onChange={handleFileChange}
        name="attachment"
      />
      {/* details */}
      <div className="flex">
        <div className="flex items-center">
          <button
            className="px-4 py-2 ml-4 text-white rounded-full button-red"
            type="button"
            onClick={handleClickUpload}
          >
            {loadingSpin ? (
              <LoadingSpin fontSize={20} color={"white"} />
            ) : (
              ` Upload New Image`
            )}
          </button>
        </div>
      </div>
    </div>
  );
}

export default UplaodProfilePicture;
