import React from "react";

function TransectionSkeleton() {
  return (
    <div>
      <div className="w-full h-1 bg-[#1a1919] animate-pulse mt-[25px]"></div>
      <div className="flex flex-col gap-2 mt-[20px]">
        <div className="w-full h-[60px] bg-[#1a1919] animate-pulse"></div>
        <div className="w-full h-[60px] bg-[#1a1919] animate-pulse"></div>
        <div className="w-full h-[60px] bg-[#1a1919] animate-pulse"></div>
      </div>
    </div>
  );
}

export default TransectionSkeleton;
