import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Navigation, Pagination } from "swiper";
import { MusicCard } from "../shared/MusicCard";

function MusicCardCourselLong({ audios, user }: any) {
  return (
    <div>
      <div className="">
        <Swiper
          slidesPerView={7}
          breakpoints={{
            0: {
              slidesPerView: 1,
            },
            370: {
              slidesPerView: 2,
            },
            700: {
              slidesPerView: 3,
            },
            1000: {
              slidesPerView: 4,
            },
            1200: {
              slidesPerView: 5,
            },
            1400: {
              slidesPerView: 6,
            },
            1600: {
              slidesPerView: 7,
            },
          }}
          modules={[Pagination, Navigation]}
          navigation={true}
          className="mt-4 mb-5 mySwiper"
        >
          {audios.map((song: any, i: number) => (
            <SwiperSlide className="" key={i}>
              <div className="">
                <MusicCard song={song} user={user} />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
}

export default MusicCardCourselLong;
