import GenreCard from "../shared/GenreCard";
import { useEffect, useState } from "react";
import GenreService from "services/GenreService";
import SubText from "components/shared/SubText";
import { Link } from "react-router-dom";
import { RouteName } from "constant/routeNames";
import MusicCardSkeleton from "../../../components/skeleton/GenreSkeleton";
import GenreSkeleton from "../../../components/skeleton/GenreSkeleton";
import { Adsense } from "@ctrl/react-adsense";
import GeneralServices from "services/General/GeneralServices";

const GenreSection = () => {
  const [genres, setGenres] = useState<any>([]);

  const [isLoading, setIsLoding] = useState<any>(false);
  const [gAdSlot, setgAdSlot] = useState<any>();
  
  useEffect(() => {
    GeneralServices.getAllConfigData()
      .then((res) => {
        const PageStatus = res?.data?.filter(
          (item: any) => item?.title === "is_music_gAd" && item?.value !== null
        );
        if (PageStatus[0]?.value === '1') {
          const filteredData = res?.data?.filter(
            (item: any) => item?.title === "gAdSquSlot" && item?.value !== null
          );
          setgAdSlot(filteredData[0]?.value);
          console.log("setgAdStatus", filteredData[0]?.value);
        }
      })
      .catch((e) => {});
  }, []);

  useEffect(() => {
    setIsLoding(true);
    GenreService.getAllGenres().then((res: any) => {
      // console.log(res.data);
      setIsLoding(false);
      setGenres(res.data.slice(0, 6));
    });
  }, []);
  return (
    <>
      {!isLoading && (
        <div>
          {gAdSlot && (
            <Adsense
                  client="ca-pub-5773929890067376"
                  slot="9259680441"
                  style={{
                    display: "block",
                    marginBottom: "10px",
                    height: "90px",
                    justifyContent: "center",
                  }}
                  layout="in-article"
                  data-full-width-responsive="true"
                  data-ad-format="auto"
                  // format="fluid"
                />
          )}
          <div className="flex flex-row justify-between w-full mt-4">
            <SubText text="Genre " className="font-light " />
            <Link to={RouteName.SeeAllGenreRoute}>
              <button className="text-[#F50537] text-[15px] font-[500] mr-2">
                See all
              </button>
            </Link>
          </div>
          <div className="grid w-full grid-cols-2 grid-rows-3 gap-3 pt-5 max-sm:grid-cols-2">
            {genres &&
              genres.map((value: any, index: number) => (
                <GenreCard key={index} value={value} />
              ))}
          </div>
        </div>
      )}
      {isLoading && <GenreSkeleton />}
    </>
  );
};

export default GenreSection;
