import PodecastSkeletonCard from './PodecastSkeletonCard';

const LibrarySkeleton = () => {
  const arr = [1, 2, 3, 4, 5, 6];

  return (
    <div className="flex flex-col">
      <div className="w-[50px] h-[50px] rounded-full bg-[#1a1919] animate-pulse"></div>
      <div className="w-[200px] h-[45px]  mb-5 bg-[#1a1919] animate-pulse mt-2"></div>

      <div className="w-[300px] h-[40px]  mb-3 bg-[#1a1919] animate-pulse mt-2"></div>

      <div className="grid gap-5 lg:grid-cols-6 md:grid-cols-3 sm:grid-cols-1">
        {arr.map((item: any) => (
          <div key={item} className="flex flex-row">
            <PodecastSkeletonCard />
          </div>
        ))}
      </div>
    </div>
  );
};

export default LibrarySkeleton;
