import React from "react";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

function NoFollowers() {
  const navigate = useNavigate();
  return (
    <div>
      <div>
        <button
          className="w-10 h-10 mb-5 bg-white rounded-full ml"
          onClick={() => {
            navigate(-1);
          }}
        >
          <AiOutlineArrowLeft className="ml-3" />
        </button>
      </div>
      <div className="items-center h-full mt-52">
        <p className="flex items-center justify-center text-2xl font-semibold text-white max-sm:w-full max-sm:text-[20px]">
          You don't have any followers yet
        </p>
        <p className="flex justify-center text-lg font-normal text-white max-sm:text-[15px]">
          Share your profile with your friends to gain more followers!
        </p>
      </div>
    </div>
  );
}

export default NoFollowers;
