import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";

const antIcon = (
  <LoadingOutlined
    style={{ fontSize: 40, color: "red" }}
    spin
    rev={undefined}
  />
);

function PlayerLoadingSpin() {
  return <Spin indicator={antIcon} />;
}

export default PlayerLoadingSpin;
