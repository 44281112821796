import BackButton from "components/shared/BackButton";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PayperviewService from "services/payperview/PayperviewServie";
import PayPerViewCard from "./shared/PayPerViewCard";
import PayperviewPickedout from "components/skeleton/PayperviewPickedout";
import Pagination from "pages/podcastPage/shared/Pagination";

function StreamingSeeAllPage() {
  const loggedInUser = useSelector((state: any) => state?.user?.currentUser);
  const [events, setEvents] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [purchased, setPurchased] = useState<any>([]);
  const [lastPage, setLastPage] = useState<any>();
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    setIsLoading(true);
    PayperviewService.getAllPayperviewEventsByPageNo(currentPage)
      .then((res) => {
        console.log("event", res.data);
        setIsLoading(false);
        setLastPage(res.meta?.last_page);
        if (res.data) {
          const streamingEvents = res.data.filter(
            (event: any) => event?.event_status === "streaming"
          );
          setEvents(streamingEvents);
        }
      })
      .catch((e: any) => {
        console.log(e);
      });
  }, []);

  useEffect(() => {
    setIsLoading(true);
    PayperviewService.getPurchasedEvents(loggedInUser?.id)
      .then((res) => {
        console.log("event", res.data);
        setIsLoading(false);
        setPurchased(res.data);
      })
      .catch((e: any) => {
        console.log("getPurchasedEvents ", e);
      });
  }, []);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div>
      {!isLoading && (
        <>
          <BackButton />
          <div>
            <div className="flex items-center justify-between mb-8 mr-5 max-sm:block max-sm:mr-0">
              <h1 className="text-2xl font-medium text-white lg:text-2xl md:text-2xl max-sm:w-[60%] max-sm:ml-2">
                All Streaming Events
              </h1>
            </div>
          </div>
          {events && (
            <div className="grid w-full grid-cols-2 mt-4 mb-28 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 2xl:grid-cols-7 max-sm:w-full">
              {events?.map((event: any, index: any) => (
                <div key={index} className="mb-5 mr-4">
                  <PayPerViewCard
                    event={event}
                    isPurchased={purchased.some((payperviewEvent: any) =>
                      payperviewEvent?.id === event?.id ? true : false
                    )}
                    user={loggedInUser}
                  />
                </div>
              ))}
            </div>
          )}
          <div className="flex justify-center my-5">
            <Pagination
              totalPages={lastPage}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </div>
        </>
      )}
      {isLoading && <PayperviewPickedout />}
    </div>
  );
}

export default StreamingSeeAllPage;
