import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PodcastService from "services/podcast/PodcastService";
import PodcastGenreSelector from "./shared/PodcastGenreSelector";
import Podcastcard from "./shared/Podcastcard";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { HiArrowLeft } from "react-icons/hi";
import { RouteName } from "constant/routeNames";
import { Adsense } from "@ctrl/react-adsense";
import GeneralServices from 'services/General/GeneralServices';

function DiscoverPodcastCategory() {
  const { slug } = useParams();
  const [podcast, setPodcast] = useState([]);
  // const [loading, setLoading] = useState(false);
  const [category, setCategory] = useState<any>("");
  const navigate = useNavigate();

  const [genre, setGenre] = useState<any>(slug);
  const [gAdSlot, setgAdSlot] = useState<any>();

  useEffect(() => {
    GeneralServices.getAllConfigData()
      .then((res) => {
        const PageStatus = res?.data?.filter(
          (item: any) => item?.title === "is_podcast_gAd" && item?.value !== null
        );
        if (PageStatus[0]?.value === '1') {
          const filteredData = res?.data?.filter(
            (item: any) => item?.title === "gAdSlot" && item?.value !== null
          );
          setgAdSlot(filteredData[0]?.value);
          console.log("setgAdStatus", filteredData[0]?.value);
        }
      })
      .catch((e) => {});
  }, []);

  useEffect(() => {
    setGenre(slug);
  }, [slug]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  //load data according to the slug
  useEffect(() => {
    // setLoading(true);
    PodcastService.getPodcastByGenreSlug(slug)
      .then((res) => {
        console.log("BBB>>", res);
        setPodcast(res.podcasts.data);
        // setLoading(false);
        setCategory(res.category);
      })
      .catch((e) => {
        // console.log(e);
        // setLoading(false);
      });
  }, [slug]);

  // const [genreName, setGenreName] = useState();

  const [categories, setCategories] = useState([]);

  useEffect(() => {
    PodcastService.getAllCategories()
      .then((res) => {
        setCategories(res.data);
        // console.log("genters>>", res.data);
      })
      .catch((e) => {
        // console.log(e);
      });
  }, []);

  return (
    <div>
      {/* main topic section */}
      <div
        className={`!z-20 flex items-center justify-center w-10 h-10 text-2xl bg-white rounded-full cursor-pointer max-sm:ml-[7px] mb-3`}
        onClick={() => {
          navigate(RouteName.Podcast.replace("/*", ""));
        }}
      >
        <HiArrowLeft className="text-black" />
      </div>
      <div>
        <div className="flex items-center justify-between mb-8 max-sm:block w-[98%]">
          <h1 className="text-xl font-medium text-white lg:text-2xl md:text-3xl max-sm:ml-2 max-sm:mb-5">
            Discover Podcast in{" "}
            <span className="red-text">{category.name}</span>
          </h1>
          <PodcastGenreSelector slug={genre} categories={categories} />
        </div>
        {gAdSlot && (
            <Adsense
            client="ca-pub-5773929890067376"
            slot={gAdSlot}
            style={{ display: 'flex', marginBottom:'30px', marginTop:'20px', height:'90px', justifyContent: 'center' }}
            format="horizontal"
            data-full-width-responsive="true"
            // format="fluid"
            />
          )}
      </div>

      {/* podcast grid */}
      <div className="grid w-full grid-cols-2 mt-4 mb-28 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 2xl:grid-cols-7 max-sm:w-full">
        {podcast?.map((podcastDetails: any, index: number) => (
          <div key={index} className="mb-5 mr-4">
            <Podcastcard podcast={podcastDetails} />
          </div>
        ))}
      </div>
    </div>
  );
}

export default DiscoverPodcastCategory;
