import { RouteName } from "constant/routeNames";
import FeaturePages from "layout/FeaturePages";
import React from "react";
import { Route, Routes } from "react-router-dom";
import VideoCreator from "./BecomeACreator/VideoCreator";
import SeeAllCreators from "./BecomeACreator/SeeAllCreators";
import SeeAllCreratorsVideos from "./BecomeACreator/SeeAllCreratorsVideos";

function CreatorPage({
  handleClick,
  isActive,
  setIsActive,
  anyPlayerActive,
}: any) {
  return (
    <div className={`${anyPlayerActive ? `mb-[140px]` : `mb-30`}`}>
      <Routes>
        <Route
          path={RouteName.VideoCreatorHome}
          element={
            <FeaturePages
              Content={VideoCreator}
              handleClick={handleClick}
              isActive={isActive}
              setIsActive={setIsActive}
              title={""}
            />
          }
        />
        <Route
          path={RouteName.SeeAllCreators}
          element={
            <FeaturePages
              Content={SeeAllCreators}
              handleClick={handleClick}
              isActive={isActive}
              setIsActive={setIsActive}
              title={""}
            />
          }
        />
        <Route
          path={RouteName.SeeAllCreatorsVideos}
          element={
            <FeaturePages
              Content={SeeAllCreratorsVideos}
              handleClick={handleClick}
              isActive={isActive}
              setIsActive={setIsActive}
              title={""}
            />
          }
        />
        {/* <Route
          path={RouteName.CreatorPlaylist}
          element={
            <FeaturePages
              Content={VideoCreatorPlaylist}
              handleClick={handleClick}
              isActive={isActive}
              setIsActive={setIsActive}
              title={""}
            />
          }
        /> */}
        {/* <Route
          path={RouteName.VideoCreatorPlayerPage}
          element={<CreatorWatchPage />}
        /> */}
      </Routes>
    </div>
  );
}

export default CreatorPage;
