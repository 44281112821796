import ContentNavbar from "components/ContentNavbar";
import Footer from "components/Footer";
// import FeaturePageSideBar from 'components/shared/FeaturePageSideBar';
import { contentNavData } from "constant/ContentNavBarData";
import WelcomePageSidebar from "pages/welcome/WelcomePageSidebar";
import { ScrollRestoration } from "react-router-dom";

function FeaturePages({
  Content,
  title,
  isActive,
  handleClick,
  setIsActive,
}: any) {
  return (
    <>
      <ContentNavbar
        handleClick={handleClick}
        title={title}
        isActive={isActive}
      />
      {/* <FeaturePageSideBar isActive={isActive} navData={navData} /> */}
      <WelcomePageSidebar
        isActive={isActive}
        navData={contentNavData}
        setIsActive={setIsActive}
      />
      <div
        className={
          isActive
            ? "md:pl-[100px] pl-[80px] duration-500 p-2 mt-2 ml-3 max-sm:pl-0 max-sm:ml-2 max-sm:mt-2"
            : "pl-0 duration-500 p-2 mt-2 ml-8 max-sm:pl-0 max-sm:ml-2  "
        }
      >
        <div className="ml-1 min-h-[500px]">
          <Content />
        </div>
      </div>
      <Footer />
    </>
  );
}

export default FeaturePages;
