import React, { ReactNode, useEffect } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { HiArrowLeft } from "react-icons/hi";
import { useNavigate } from "react-router-dom";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
}
const PayPerViewPop: React.FC<ModalProps> = ({ isOpen, onClose, children }) => {
  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }

    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, [isOpen]);

  return isOpen ? (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      {/* Backdrop */}
      <div className="fixed inset-0 bg-black opacity-50"></div>

      {/* Modal */}
      <div className="fixed inset-0 flex items-center justify-center backdrop-blur">
        <div className="absolute w-[60%] max-sm:w-full overflow-hidden rounded-lg shadow-xl max-lg:w-[80%]">
          <div className="mt-4 rounded-lg ">
            {children}
            <button
              className="absolute flex items-center justify-center w-10 h-10 text-2xl text-gray-500 bg-white rounded-full cursor-pointer z-100 hover:text-gray-700 top-10 left-5"
              onClick={onClose}
            >
              <HiArrowLeft className="text-black " />
            </button>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default PayPerViewPop;
