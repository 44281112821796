import { useEffect, useState } from "react";
import UserPageOverView from "../UserPageOverView";
import UserVideoPage from "../UserVideoPage";
import UserMusicPage from "../UserMusicPage";
import UserPodcastPage from "../UserPodcastPage";
import UserRadioPage from "../UserRadioPage";
import UserPageNavButton from "components/shared/UserPageNavButton";
import PlaylistService from "services/music/PlaylistService";
import UserPlaylistPage from "../UserPlaylistPage";
import { useSelector } from "react-redux";
import UserPayperviewPage from "../UserPayperviewPage";
import PaidSubscription from "../PaidSubscription";
// import UserPageNavButton from "components/shared/UserPageNavButton";

function UserProfileButtonSection({
  userPlaylist,
  isLoadingPlaylist,
  // haveData,
  user,
  favorite,
  ppvEvent,
}: any) {
  const loggedInUser = useSelector((state: any) => state?.user?.currentUser);
  const [isSelected, setIsSelected] = useState<any>(1);
  const [components, setComponents] = useState<any>(pageName[0]);
  const [userPlaylists, setUserPlaylists] = useState([]);
  const [userPublicPlaylists, setUserPublicPlaylists] = useState([]);
  const [, setIsLodingPlaylist] = useState<any>(false);
  const [isLoading, setIsLoading] = useState<any>(false);
  const enabled = localStorage.getItem("ppv_enabled");

  useEffect(() => {
    if (user && user?.id !== loggedInUser?.id && user?.activity_privacy === 1) {
      setComponents(pageName[3]);
      setIsSelected(4);
    }
  }, [user, loggedInUser]);

  // useEffect(() => {
  //   setIsLoading(true);
  //   setIsLodingPlaylist(true);
  //   if (user) {
  //     if (loggedInUser && user.id === loggedInUser.id) {
  //       PlaylistService.getUserPlaylists().then((res) => {
  //         setIsLoading(false);
  //         setIsLodingPlaylist(false);
  //         setUserPlaylists(res.data.data);
  //         // console.log(res.data.data);
  //         setUserPublicPlaylists(res.data.data);
  //       });
  //     } else {
  //       PlaylistService.getPublicPlaylistByUserId(user?.id).then((res) => {
  //         setIsLoading(false);
  //         setIsLodingPlaylist(false);
  //         // setUserPlaylists(res.data.data);
  //         // console.log(res.data.data);
  //         setUserPublicPlaylists(res.data.data);
  //       });
  //     }
  //   }
  // }, [user]);

  // useEffect(() => {
  //   if (user) {
  //     if (user.id !== loggedInUser.id) {
  //       PlaylistService.getPublicPlaylists()
  //         .then((res: any) => {
  //           // console.log(res.data.data);
  //           const filteredData = res.data.data.filter(
  //             (item: any) =>
  //               item?.visibility === 1 && item?.user?.id === user?.id
  //           );
  //           setUserPublicPlaylists(filteredData);
  //         })
  //         .catch((e) => {
  //           // setIsLoding(false);
  //           // console.log(e);
  //         });
  //     }
  //   }
  // }, [user]);

  const favoriteMoviesIds = favorite?.favorite_movies?.map(
    (item: any) => item.id
  );
  const favoriteTvSeriesIds = favorite?.favorite_tv_series?.map(
    (item: any) => item.id
  );
  const favoriteEpisodesIds = favorite?.favorite_publication_videos?.map(
    (item: any) => item.id
  );
  const favoriteMusicIds = favorite?.loved?.data?.map((item: any) => item.id);
  const favoritePodcastsIds = favorite?.favorite_podcasts?.map(
    (item: any) => item.id
  );
  const favoriteRadioIds = favorite?.favorite_radio_stations?.map(
    (item: any) => item.id
  );
  const favoriteArtistIds = favorite?.favorite_artists?.map(
    (item: any) => item.id
  );
  const favoritePpvEventsIds = favorite?.favorite_ppv_events?.map(
    (item: any) => item.id
  );

  return (
    <div>
      {/* <HeroBoosterSection follow="257 Followers" /> */}
      <div className="flex my-3 ml-6 max-sm:grid max-sm:grid-cols-3 max-md:grid max-md:grid-cols-4 max-lg:grid-cols-4 max-sm:mx-0 max-sm:gap-2">
        <>
          {user &&
          user?.id !== loggedInUser?.id &&
          user?.activity_privacy === 1 ? (
            <>
              <UserPageNavButton
                pages={pageName[3]}
                setIsSelected={setIsSelected}
                isSelected={isSelected}
                selected={true}
                setComponents={setComponents}
              />
            </>
          ) : (
            <>
              {enabled === "1" ? (
                <>
                  {pageName?.map((pages: any, i: number) => (
                    <div key={i} className="mr-2">
                      <UserPageNavButton
                        pages={pages}
                        setIsSelected={setIsSelected}
                        isSelected={isSelected}
                        selected={pages?.id === isSelected ? true : false}
                        setComponents={setComponents}
                      />
                    </div>
                  ))}
                </>
              ) : (
                <>
                  {pageName.slice(0, -2)?.map((pages: any, i: number) => (
                    <div key={i} className="mr-2">
                      <UserPageNavButton
                        pages={pages}
                        setIsSelected={setIsSelected}
                        isSelected={isSelected}
                        selected={pages?.id === isSelected ? true : false}
                        setComponents={setComponents}
                      />
                    </div>
                  ))}
                </>
              )}
            </>
          )}
        </>
      </div>
      <div id="section1" className=" text-white mb-[150px]">
        {
          <components.component
            user={user}
            favoriteData={favorite}
            favoriteMoviesIds={favoriteMoviesIds}
            favoriteTvSeriesIds={favoriteTvSeriesIds}
            favoriteEpisodesIds={favoriteEpisodesIds}
            favoriteMusicIds={favoriteMusicIds}
            favoritePodcastsIds={favoritePodcastsIds}
            favoriteRadioIds={favoriteRadioIds}
            favoriteArtistIds={favoriteArtistIds}
            userPlaylists={userPlaylists}
            setUserPlaylists={setUserPlaylists}
            isLoading={isLoading}
            userPublicPlaylists={userPublicPlaylists}
            userPlaylist={userPlaylist}
            isLoadingPlaylist={isLoadingPlaylist}
            purchased={ppvEvent}
            favoritePpvIds={favoritePpvEventsIds}
            // haveData={haveData}
          />
        }
      </div>
    </div>
  );
}

const pageName = [
  {
    id: 1,
    component: UserPageOverView,
    name: "Overview",
  },
  {
    id: 2,
    component: UserVideoPage,
    name: "Videos",
  },
  {
    id: 3,
    component: UserMusicPage,
    name: "Music",
  },
  {
    id: 4,
    component: UserPlaylistPage,
    name: "Playlists",
  },
  {
    id: 5,
    component: UserPodcastPage,
    name: "Podcast",
  },
  {
    id: 6,
    component: UserRadioPage,
    name: "Radio",
  },
  {
    id: 7,
    component: UserPayperviewPage,
    name: "PPV",
  },
  {
    id: 8,
    component: PaidSubscription,
    name: "Paid Subscription",
  },
];

export default UserProfileButtonSection;
