import React from 'react';

const VideoCategorySkeleton = () => {
  const arr = [1, 2, 3, 4, 5];

  return (
    <>
      <div className="grid mt-5 lg:grid-cols-5 md:grid-cols-3 sm:grid-col-1">
        {arr.map((item: any, i: number) => (
          <div className="" key={i}>
            <div className="w-[210px] h-[60px] rounded-[40px] bg-[#1a1919] animate-pulse"></div>
          </div>
        ))}
      </div>
    </>
  );
};

export default VideoCategorySkeleton;
