import React from 'react'

const EpisodicPagePaginationSkeleton = () => {
    return (
        <div className="lg:ml-[100px]">
            <div className="flex flex-col gap-10">
                <div className="flex flex-row gap-1">
                    <div>
                        <div className="lg:w-[450px] max-sm:w-[250px] h-[30px] bg-[#1a1919]  animate-pulse mt-4"></div>
                        <div className="lg:w-[250px] max-sm:w-[150px] h-[30px] bg-[#1a1919]  animate-pulse mt-4"></div>
                        <div className="lg:w-[550px] max-sm:w-[300px] h-[15px] bg-[#1a1919]  animate-pulse mt-4"></div>
                        <div className="lg:w-[550px] max-sm:w-[300px] h-[15px] bg-[#1a1919]  animate-pulse mt-4"></div>
                    </div>
                    <div className="lg:ml-[200px] max-sm:ml-[70px] mt-[50px]">
                        <div className="lg:w-[30px] max-sm:w-[30px] h-[30px] bg-[#1a1919]  animate-pulse mt-4"></div>
                    </div>
                </div>
                <div className="flex flex-row">
                    <div>
                        <div className="lg:w-[450px] max-sm:w-[250px] h-[30px] bg-[#1a1919]  animate-pulse mt-4"></div>
                        <div className="lg:w-[250px] max-sm:w-[150px] h-[30px] bg-[#1a1919]  animate-pulse mt-4"></div>
                        <div className="lg:w-[550px] max-sm:w-[300px] h-[15px] bg-[#1a1919]  animate-pulse mt-4"></div>
                        <div className="lg:w-[550px] max-sm:w-[300px] h-[15px] bg-[#1a1919]  animate-pulse mt-4"></div>
                    </div>
                    <div className="lg:ml-[200px] max-sm:ml-[70px] mt-[50px]">
                        <div className="lg:w-[30px] max-sm:w-[30px] h-[30px] bg-[#1a1919]  animate-pulse mt-4"></div>
                    </div>
                </div>

                <div className="flex flex-row">
                    <div>
                        <div className="lg:w-[450px] max-sm:w-[250px] h-[30px] bg-[#1a1919]  animate-pulse mt-4"></div>
                        <div className="lg:w-[250px] max-sm:w-[150px] h-[30px] bg-[#1a1919]  animate-pulse mt-4"></div>
                        <div className="lg:w-[550px] max-sm:w-[300px] h-[15px] bg-[#1a1919]  animate-pulse mt-4"></div>
                        <div className="lg:w-[550px] max-sm:w-[300px] h-[15px] bg-[#1a1919]  animate-pulse mt-4"></div>
                    </div>
                    <div className="lg:ml-[200px] max-sm:ml-[70px] mt-[50px]">
                        <div className="lg:w-[30px] max-sm:w-[30px] h-[30px] bg-[#1a1919]  animate-pulse mt-4"></div>
                    </div>
                </div>

                <div className="flex flex-row ">
                    <div>
                        <div className="lg:w-[450px] max-sm:w-[250px] h-[30px] bg-[#1a1919]  animate-pulse mt-4"></div>
                        <div className="lg:w-[250px] max-sm:w-[150px] h-[30px] bg-[#1a1919]  animate-pulse mt-4"></div>
                        <div className="lg:w-[550px] max-sm:w-[300px] h-[15px] bg-[#1a1919]  animate-pulse mt-4"></div>
                        <div className="lg:w-[550px] max-sm:w-[300px] h-[15px] bg-[#1a1919]  animate-pulse mt-4"></div>
                    </div>
                    <div className="ml-[200px] max-sm:ml-[70px] mt-[50px]">
                        <div className="lg:w-[30px] max-sm:w-[30px] h-[30px] bg-[#1a1919]  animate-pulse mt-4"></div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default EpisodicPagePaginationSkeleton