import axios from 'axios';
import authHeader from 'utils/auth-header';

axios.defaults.baseURL = process.env.REACT_APP_BACKEND_SERVER;
// const VIUZAT_URL = process.env.REACT_APP_VIUZAT_SERVER;
const BASE_URL = process.env.REACT_APP_BACKEND_SERVER;

const getFollowersById = async (id: any) => {
  const response = await axios({
    method: 'get',
    url: `${BASE_URL}/user/${id}/followers`,
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
  });
  return response.data;
};

const getFollowingById = async (id: any) => {
  const response = await axios({
    method: 'get',
    url: `${BASE_URL}/user/${id}/following`,
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
  });
  return response.data;
};

const getUserById = async (id: any) => {
  const response = await axios({
    method: 'get',
    url: `${BASE_URL}/user/${id}`,
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
  });
  return response.data;
};
const userFollow = async (data: any) => {
  const response = await axios({
    method: 'post',
    data: data,
    url: `${BASE_URL}/user/follow`,
    headers: authHeader(),
  });
  return response.data;
};

const userUnFollow = async (data: any) => {
  const response = await axios({
    method: 'post',
    data: data,
    url: `${BASE_URL}/user/unfollow`,
    headers: authHeader(),
  });
  return response.data;
};

const editProfile = async (data: any) => {
  const response = await axios({
    method: 'post',
    data: data,
    url: `${BASE_URL}/users/settings/profile`,
    headers: authHeader(),
  });

  return response;
};
const editUserName = async (data: any) => {
  const response = await axios({
    method: 'post',
    data: data,
    url: `${BASE_URL}/users/settings/change-username`,
    headers: authHeader(),
  });

  return response;
};

const changePassword = async (token: string, data: any) => {
  const output = new FormData();
  output.append('current_password', data.current_password);
  output.append('new_password', data.new_password);
  output.append('new_password_confirmation', data.new_password_confirmation);
  const response = await axios({
    method: 'post',
    data: data,
    url: `${BASE_URL}/users/settings/change-password`,
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'multipart/form-data',
    },
  });

  return response;
};

const getUserByUserName = async (username: any) => {
  const response = await axios({
    method: 'get',
    url: `${BASE_URL}/users/${username}`,
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
  });
  return response.data;
};

const getNotificationByUserId = async (user_id: any) => {
  const response = await axios({
    method: 'get',
    url: `${BASE_URL}/user/${user_id}/notifications`,
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
  });
  return response.data;
};

const UserProfileService = {
  getFollowersById,
  getFollowingById,
  getUserById,
  userFollow,
  userUnFollow,
  editProfile,
  getUserByUserName,
  editUserName,
  getNotificationByUserId,
  changePassword,
};

export default UserProfileService;
