import React, { useState } from "react";
import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import PayPerViewCategoryCard from "./PayPerViewCategoryCard";
import { Categories } from "constant/PayPerViewCategories";
import PayPerViewCard from "./PayPerViewCard";
import PayperviewCategoryskeleton from "components/skeleton/PayperviewCategoryskeleton";

const PayPerViewCategoryCarousal = ({ categories, isLoading }: any) => {
  return (
    <>
      {!isLoading && (
        <div>
          {categories && (
            <>
              {/* coursel */}
              <div className="">
                <Swiper
                  slidesPerView={5}
                  breakpoints={{
                    0: {
                      slidesPerView: 1,
                    },
                    350: {
                      slidesPerView: 2,
                    },
                    550: {
                      slidesPerView: 4,
                    },
                    1000: {
                      slidesPerView: 4,
                    },
                    1100: {
                      slidesPerView: 5,
                    },
                    1200: {
                      slidesPerView: 6,
                    },
                    1400: {
                      slidesPerView: 7,
                    },
                  }}
                  modules={[Pagination]}
                  className="mt-4 mb-5 mySwiper"
                >
                  {categories.map((category: any, i: number) => (
                    <SwiperSlide className="" key={i}>
                      <PayPerViewCategoryCard category={category} />
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </>
          )}
        </div>
      )}
      {isLoading && <PayperviewCategoryskeleton />}
    </>
  );
};

export default PayPerViewCategoryCarousal;
