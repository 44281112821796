import { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import SubText from "components/shared/SubText";
import "swiper/css";
import "swiper/css/pagination";
import "styles/card.css";
import "swiper/css/navigation";
import MovieCarouselCard from "../shared/MovieCarouselCard";
import { Link } from "react-router-dom";
import { RouteName } from "constant/routeNames";
import MovieCardSkeleton from "components/skeleton/MovieCardSkeleton";

function VideoPageSeriesCoursel({
  user,
  title,
  series,
  favorite,
  isLoading,
  dontShowSeeAll,
}: any) {
  // const [cardDetails, setCardDetails] = useState([]);
  // const [loading, setLoading] = useState(false);
  // console.log(series);
  return (
    <div>
      {!isLoading && (
        <>
          <div className="flex justify-between w-[95%] max-sm:w-full">
            {series && series?.length > 0 && (
              <SubText
                text={title ? title : "Series picked for you"}
                className="font-bold max-sm:ml-4"
              />
            )}
            {!dontShowSeeAll ? (
              <Link to={RouteName.SeeAllSeriesRoute + "?page=1"}>
                <p className="text-sm font-bold cursor-pointer red-text place-self-end">
                  See all
                </p>
              </Link>
            ) : (
              <></>
            )}
          </div>

          <div className="">
            {!isLoading ? (
              <Swiper
                slidesPerView={7}
                breakpoints={{
                  0: {
                    slidesPerView: 2,
                  },
                  380: {
                    slidesPerView: 2,
                  },
                  700: {
                    slidesPerView: 3,
                  },
                  1000: {
                    slidesPerView: 4,
                  },
                  1200: {
                    slidesPerView: 5,
                  },
                  1400: {
                    slidesPerView: 6,
                  },
                  1600: {
                    slidesPerView: 7,
                  },
                }}
                modules={[Pagination, Navigation]}
                navigation={true}
                className="mt-8 mb-10 mySwiper"
              >
                {series &&
                  series?.map((card: any, i: number) => (
                    <SwiperSlide key={i}>
                      <div className="mx-3 max-sm:mx-1 ">
                        <MovieCarouselCard
                          id={card?.id}
                          // time={card.runtime}
                          title={card?.title}
                          // info={card.description}
                          // year={card.release}
                          // type={card.video_type}
                          img={card?.thumbnail}
                          seasons={card?.no_of_seasons}
                          slug={card?.slug}
                          tv={true}
                          favoriteTvSeries={favorite}
                          tvSeries={card}
                          first_episode={card?.first_episode}
                        />
                      </div>
                    </SwiperSlide>
                  ))}
              </Swiper>
            ) : (
              <></>
            )}
          </div>
        </>
      )}
      {isLoading && <MovieCardSkeleton />}
    </div>
  );
}

export default VideoPageSeriesCoursel;
