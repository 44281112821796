import { useState } from "react";
import { FaPlay } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { addMusicToPlayer } from "redux/AudioPlayerRedux";
import { formatDuration } from "utils/timeFunctions";
import CreatePlaylistIcon from "../audioPlayer/AudioPlayer3/CreatePlaylistIcon/CreatePlaylistIcon";
import { RouteName } from "constant/routeNames";
import { Link } from "react-router-dom";
import AddSongToQueue from "../shared/AddSongToQueue";
import AddSongToFav from "../shared/AddSongToFav";
import LazyLoadImg from "components/LazyLoadImg";
import TableRowImage from "../shared/TableRowImage";

function CategoryTrackRow({
  track,
  userPlaylists,
  setUserPlaylists,
  favorite,
}: any) {
  const dispatch = useDispatch<any>();
  const [hover, setHover] = useState<boolean>(false);

  const handleMusicPlayer = () => {
    dispatch(addMusicToPlayer(track));
  };

  return (
    <div
      className={`flex w-full !h-[60px] top-0 items-center justify-between pr-8 my-3 max-sm:px-3 cursor-pointer !px-2 !py-10 hover:bg-slate-800 hover:shadow-xl rounded-md text-white hover:text-slate-300 ease-in-out duration-150`}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {/* thumbnail and text */}
      <div className="flex items-center w-[50%] gap-3">
        {/* image */}
        <TableRowImage
          executionFunc={handleMusicPlayer}
          imgSrc={track.artwork_url}
          imgAlt={"song"}
          imgHeight={"60px"}
          imgWidth={"60px"}
          hover={hover}
        />
        {/* text */}
        <div className="flex flex-col w-[50%] justify-between ml-2 text-[#7B9DB4] max-sm:block max-sm:w-[70%] ">
          <div>
            <p className="text-[15px]  font-semibold max-sm:text-xs">
              {track.title.length > 20
                ? `${track.title.slice(0, 20)}...`
                : track.title}
            </p>
            <p className="text-xs font-normal">
              {formatDuration(track.duration)}
            </p>
          </div>
        </div>
      </div>
      {/* artist */}
      <div className="flex items-center justify-center w-[40%]">
        <Link
          to={RouteName.MusicArtistPage.replace(
            ":slug",
            track?.artists[0]?.slug
          )}
        >
          <p className="text-[14px] font-normal text-white max-sm:w-[90%] hover:text-slate-400">
            {track.artists.length > 20
              ? `${track.artists.slice(0, 20).replace(/&amp;/g, '&')}...`
              : track.artists && track.artists[0]?.name.replace(/&amp;/g, '&')}
          </p>
        </Link>
      </div>
      {/* icon set */}
      <div className="flex items-center justify-end gap-5 pl-10 text-xl text-[#7B9DB4] w-[20%]">
        {/* {loggedInUser ? (
          <div onClick={handleFavorite}>
            {addFav ? (
              <div className="text-red-600 ">
                <AiFillHeart />
              </div>
            ) : (
              <AiOutlineHeart />
            )}
          </div>
        ) : (
          ""
        )} */}
        <AddSongToFav song={track} favorite={favorite} />

        {/* <div
          onClick={addToQueueSong}
          ref={popRef}
          data-tooltip-id="my-tooltip-1"
        >
          <RiPlayListAddLine />

          <div className="text-sm">
            <ReactTooltip
              id="my-tooltip-1"
              place="bottom"
              content="Add to queue"
            />
          </div>
        </div> */}
        <AddSongToQueue song={track} toolTiId={`category-page`} />

        <div>
          {/* {loggedInUser && ( */}
          <CreatePlaylistIcon
            userPlaylists={userPlaylists}
            setUserPlaylists={setUserPlaylists}
            mediaId={track.id}
          />
          {/* )} */}
        </div>
      </div>
    </div>
  );
}

export default CategoryTrackRow;
