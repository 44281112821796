import { TwitterShareButton } from "react-share";
import { Input } from "antd";
import { useState } from "react";
import SocialMediaShareBtn from "../SocialMediaShareBtn";
import CopySharebleLink from "../CopySharebleLink";

function Twitter({ shareUrl }: any) {
  const [title, setTitle] = useState();
  return (
    <div className="text-white">
      <p className="mb-3">Add your Title</p>
      <Input
        type={"text"}
        onChange={(e: any) => {
          setTitle(e.target.value);
        }}
      />
      {/* <TextArea
        showCount
        maxLength={100}
        style={{ height: 150 }}
        onChange={(e: any) => {
          setQuote(e.target.value);
        }}
      /> */}

      <div className="social-media-pop-up-btn-section">
        <CopySharebleLink shareUrl={shareUrl} />
        <TwitterShareButton url={shareUrl} title={title}>
          <SocialMediaShareBtn />
        </TwitterShareButton>
      </div>
    </div>
  );
}

export default Twitter;
