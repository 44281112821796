import CreatorQueueListSkeleton from "components/skeleton/CreatorQueueListSkeleton";
import PlaylistQueueCard from "./PlaylistQueueCard";

function QueueList({
  episodes,
  seasonId,
  slug,
  setVideoDetails,
  setEpisodeDetails,
  isLoading,
}: any) {
  const arr = [1, 2, 3, 4, 5, 6, 7];
  return (
    <div className="w-full mx-4">
      {isLoading &&
        episodes &&
        episodes?.map((episode: any, i: number) => (
          <div key={i} className="block mb-2">
            <PlaylistQueueCard
              // isLoading={isLoading}
              video={episode}
              season={seasonId}
              id={episode?.id}
              slug={slug}
              setVideoDetails={setVideoDetails}
              setEpisodeDetails={setEpisodeDetails}
            />
          </div>
        ))}
      {!isLoading && (
        <>
          {arr.map((item: any) => (
            <div key={item} className="w-[90%]">
              <CreatorQueueListSkeleton />
            </div>
          ))}
        </>
      )}
    </div>
  );
}

export default QueueList;
