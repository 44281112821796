import { useSelector } from "react-redux";
import RadioHomeCardSection from "./home/RadioHomeCardSection";
import StationCoursel from "./home/StationCoursel";
import Categories from "./shared/Categories";
import MightLikeCoursel from "./shared/MightLikeCoursel";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useEffect, useState } from "react";
import RadioService from "services/radio/RadioService";
import { Adsense } from "@ctrl/react-adsense";
import GeneralServices from "services/General/GeneralServices";

function RadioPageHome() {
  const loggedInUser = useSelector((state: any) => state?.user?.currentUser);
  const [channels, setChannels] = useState<any[]>([]);
  const [stations, setStations] = useState<any>([]);
  const [isLoadingMostTuned, setIsLoadingMostTuned] = useState<any>(false);

  const [randomStations, setRandomStations] = useState([]);
  const [isLoading, setIsLoading] = useState<any>(false);
  const [gAdSlot, setgAdSlot] = useState<any>();

  const FE_URL = process.env.REACT_APP_FRONTEND_URL;

  useEffect(() => {
    GeneralServices.getAllConfigData()
      .then((res) => {
        const PageStatus = res?.data?.filter(
          (item: any) => item?.title === "is_radio_gAd" && item?.value !== null
        );
        if (PageStatus[0]?.value === "1") {
          const filteredData = res?.data?.filter(
            (item: any) => item?.title === "gAdSlot" && item?.value !== null
          );
          setgAdSlot(filteredData[0]?.value);
          console.log("setgAdStatus", filteredData[0]?.value);
        }
      })
      .catch((e) => {});
  }, []);

  // console.log(anyPlayerActive);
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    setIsLoadingMostTuned(true);
    RadioService.getMostTunedRadioStations().then((res) => {
      // console.log(res.mostTunedRadioStations);
      setStations(res.mostTunedRadioStations);
      if (res.mostTunedRadioStations.length < 3) {
        // setIsLoadingMostTuned(false);
        setChannels(res.mostTunedRadioStations);
      } else {
        setIsLoadingMostTuned(false);
        setChannels(res.mostTunedRadioStations.slice(0, 3));
      }
    });
  }, []);

  useEffect(() => {
    setIsLoading(true);
    RadioService.getRandomRadioStations().then((res) => {
      // console.log(res.slides);
      setIsLoading(false);
      setRandomStations(res.randomRadioStations);
    });
  }, []);

  return (
    <>
        <Helmet>
          <title>AYOZAT Radio</title>

          <meta
          name="description"
          content="AYOZAT Streaming Platform - Watch free movies and TV shows online in HD on any device anytime. Free movie website search is over."
        />
        <link rel="canonical" href={`${FE_URL}/radio`} />
        <meta property="og:title" content="AYOZAT Radio" />
        <meta
          property="og:description"
          content="AYOZAT Streaming Platform - Watch free movies and TV shows online in HD on any device anytime. Free movie website search is over."
        />
        <meta property="og:image" content="https://ayozat.co.uk/logo192.png" />
        <meta property="og:url" content={`${FE_URL}/radio`} />
        <meta property="og:type" content="website" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="AYOZAT Radio" />
        <meta
          name="twitter:description"
          content="AYOZAT Streaming Platform - Watch free movies and TV shows online in HD on any device anytime. Free movie website search is over."
        />
        <meta name="twitter:image" content="https://ayozat.co.uk/logo192.png" />
        <meta name="twitter:site" content="AYOZAT Radio" />
        <meta name="twitter:url" content={`${FE_URL}/radio`} />
        </Helmet>

      <div>
        <h1 className="hidden">AYOZAT Radio</h1>
        <RadioHomeCardSection
          channels={channels}
          isLoadingMostTuned={isLoadingMostTuned}
        />
        {gAdSlot && (
          <Adsense
            client="ca-pub-5773929890067376"
            slot={gAdSlot}
            style={{
              display: "flex",
              marginBottom: "30px",
              height: "90px",
              justifyContent: "center",
            }}
            format="horizontal"
            data-full-width-responsive="true"
            // format="fluid"
          />
        )}
        {/* <LiveProgrammeCoursel liveProgrammes={programmes} /> */}
        <StationCoursel
          stations={stations}
          isLoadingStation={isLoadingMostTuned}
        />
        <Categories />
        <MightLikeCoursel
          text="You might like"
          user={loggedInUser}
          isLoading={isLoading}
          randomStations={randomStations}
        />
      </div>
    </>
  );
}

export default RadioPageHome;
