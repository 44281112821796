import { YearData } from "constant/Years";
import { useState } from "react";

function YearSelector({ selectedYear, setSelectedYear }: any) {
  const [years] = useState<any>(YearData);

  const onChange = (e: any) => {
    setSelectedYear(e.target.value);
  };

  return (
    <div className="flex justify-between max-sm:pr-4 max-sm:ml-2 max-sm:w-full">
      <label htmlFor="watched" className="text-white ">
        Years{" "}
      </label>
      <select
        name="cars"
        id="cars"
        className="p-1  ml-2 text-white rounded-md outline-none searchbar max-h-[60px] overflow-y-auto"
        onChange={onChange}
        value={selectedYear}
      >
        <>
          <option value={""}>All</option>
          {years &&
            years?.map((c: any, i: number) => (
              <option value={c.year} key={i}>
                {c.year}
              </option>
            ))}
        </>
      </select>
    </div>
  );
}

export default YearSelector;
