import { useEffect, useRef, useState } from "react";
import SidebarIcon from "./shared/SidebarIcon";

function WelcomePageSidebar({ isActive, navData, setIsActive }: any) {
  const [iconClicked, setIconClicked] = useState<Boolean>(false);
  const [selectedIcon, setSelectedIcon] = useState();
  const enabled = localStorage.getItem("ppv_enabled");

  // hide side bar in mobile mode
  const windowWidth = window.innerWidth;
  const popRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    if (windowWidth < 500) {
      const handleClickOutside = (event: MouseEvent) => {
        if (popRef.current && !popRef.current.contains(event.target as Node)) {
          setIsActive(false);
        }
      };
      if (isActive) {
        window.addEventListener("mousedown", handleClickOutside);
      }
      return () => {
        window.removeEventListener("mousedown", handleClickOutside);
      };
    }
  }, [isActive]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.innerWidth < 768) {
        setIsActive(false);
      }
    };
    // Add the event listener for scroll
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
 

  return (
    <>
      {/* side bar shows on isactive variable */}
      <div
        className={`duration-500 sidebar z-40 navbar fixed overflow-y-auto text-center shadow mt-2 h-full max-sm:z-[100] max-sm:mt-0 ${
          isActive
            ? "left-0 p-2  w-[95px] !overflow-visible max-sm:w-[100px] "
            : " md:left-[-100px] left-[-60px]  p-2  w-[60px] "
        }`}
        ref={popRef}
      >
        {/* icon bar */}
        <div className={`items-center text-gray-100 `}>
          {iconClicked ? (
            <>
              <SidebarIcon
                data={selectedIcon}
                setSelectedIcon={setSelectedIcon}
                iconClicked={iconClicked}
                setIconClicked={setIconClicked}
              />
            </>
          ) : (
            <>
            {enabled === "1" ?(<>
              {navData.map((data: any, i: number) => (
                <SidebarIcon
                  data={data}
                  key={i}
                  setSelectedIcon={setSelectedIcon}
                  iconClicked={iconClicked}
                  setIconClicked={setIconClicked}
                />
              ))}
            </>):(<>
              {navData.slice(0, -1).map((data: any, i: number) => (
                <SidebarIcon
                  data={data}
                  key={i}
                  setSelectedIcon={setSelectedIcon}
                  iconClicked={iconClicked}
                  setIconClicked={setIconClicked}
                />
              ))}
            </>)}
              
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default WelcomePageSidebar;
