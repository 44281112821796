import React from "react";
import { HiArrowLeft } from "react-icons/hi";
import CustomProgressBar from "components/AudioPlayer/Components/CustomProgressBar";
import { formatDurationHr_Min_Sec } from "utils/timeFunctions";

function PlayerSection({
  currentSong,
  fullScreen,
  setFullScreen,
  music,
  radio,
  podcast,
  progress,
  onSeek,
  currentTime,
  duration,
  controlSection,
}: any) {
  const handleFullScreen = () => {
    setFullScreen(!fullScreen);
  };

  const screenHeight = window.innerHeight - 110;
  const getHeight = (part: number) => {
    return `${screenHeight * part}px`;
  };

  return (
    <>
      {/* header part */}
      <div
        className="flex justify-evenly items-center w-full p-[1%]"
        style={{
          height: `${getHeight(0.15)}`,
        }}
      >
        <div className="w-full flex justify-start">
          <div
            className={`flex items-center justify-center w-10 h-10 text-2xl bg-white text-black rounded-full cursor-pointer`}
            onClick={handleFullScreen}
          >
            <HiArrowLeft />
          </div>
        </div>
        <div className="w-full flex justify-center text-xl text-center">
          Playing Now
        </div>
        <div className="w-full flex justify-end"></div>
      </div>

      {/* audio detail */}
      <div
        className="flex justify-center items-center"
        style={{
          height: `${getHeight(0.4)}`,
        }}
      >
        <img
          src={
            music
              ? currentSong?.artwork_url
              : podcast
              ? currentSong?.podcast?.artwork_url
              : ``
          }
          width={getHeight(0.4)}
          height={getHeight(0.4)}
          alt=""
          className={`rounded-full drop-shadow-2xl`}
        />
      </div>

      {/* song details */}
      {(music || podcast) && (
        <div
          style={{
            height: `${getHeight(0.2)}`,
          }}
          className="flex justify-center items-center"
        >
          <div>
            <p className={`text-center text-2xl font-bold text-white`}>
              {currentSong?.title}
            </p>
            {/* artist */}

            <p
              className={`text-center text-[#7B9DB4] mt-2 text-lg max-sm:text-base`}
            >
              {music && currentSong?.artists && currentSong?.artists[0]?.name}
              {podcast && currentSong?.artist && currentSong?.artist?.name}
            </p>
          </div>
        </div>
      )}

      {/* progress bar */}
      <div
        className="w-full flex justify-between gap-2 items-center"
        style={{
          height: `${getHeight(0.1)}`,
        }}
      >
        <p className={`max-md:hidden min-w-[50px]  text-center`}>
          {currentTime ? formatDurationHr_Min_Sec(currentTime) : "00:00"}
        </p>
        <CustomProgressBar
          progress={progress}
          onSeek={onSeek}
          fullScreen={fullScreen}
        />
        <p className={`max-md:hidden min-w-[50px]  text-center`}>
          {currentTime ? formatDurationHr_Min_Sec(duration) : "00:00"}
        </p>
      </div>

      {/* control section */}
      <div
        className="flex justify-center items-center gap-3 md:w-full scale-[120%] "
        style={{
          height: `${getHeight(0.1)}`,
        }}
      >
        {controlSection}
      </div>

      <hr className="border-solid border-[2px] border-[#313533]" />
    </>
  );
}

export default PlayerSection;
