import React from "react";
import { AiOutlinePlus } from "react-icons/ai";

function BoosterButton() {
  return (
    <button
      className={`flex items-center w-[120px] h-[40px] p-3 text-center border-solid border-2 border-white text-white rounded-full max-sm:p-2 max-sm:ml-2`}
    >
      <AiOutlinePlus className="mr-4" /> Follow
    </button>
  );
}

export default BoosterButton;
