import { RouteName } from "constant/routeNames";
import React from "react";
import { Link } from "react-router-dom";

function PodcastCategories({ genre }: any) {
  // console.log(genre.alt_name);
  return (
    <Link
      // to={RouteName.PodcastDiscoverByGenreId.replace(":slug", genre.alt_name)}
      to={RouteName.Podcast.replace(
        "*",
        RouteName.PodcastDiscoverByGenreId.replace(":slug", genre.alt_name)
      )}
    >
      <div className="h-16 w-[70%] relative cursor-pointer bg-gradient-to-r from-[#272424] to-[#f30543] opacity-50 hover:opacity-100 rounded-lg max-sm:w-[95%]">
        <div className="absolute inset-0 flex items-center justify-center mx-auto text-center">
          <p className="text-white font-[800] text-md">{genre.name.replace(/&amp;/g, '&')}</p>
        </div>
        {/* <img
          className="h-20 w-[97%] object-cover opacity-30 hover:opacity-70"
          src={`${"https://townsquare.media/site/366/files/2022/02/attachment-slash-20202.jpg"}`}
          alt="/"
        /> */}
      </div>
      {/* <div className="flex h-[52px] bg-[#1E1E1E] cursor-pointer rounded-[50px] max-sm:w-[90%] mx-3">
        <div>
          <img
            className="h-[48px] w-[48px] object-cover mt-[2px] rounded-full"
            src={
              "https://rachelcorbett.com.au/wp-content/uploads/2018/07/How-to-design-a-great-podcast-logo.jpg"
            }
            alt="/"
          />
        </div>
        <div className="inset-0 flex items-center justify-center mx-auto text-center">
          <p className="text-white  font-[600] text-xl max-sm:text-sm max-sm:ml-0">
            {genre.name}
          </p>
        </div>
      </div> */}
    </Link>
  );
}

export default PodcastCategories;
