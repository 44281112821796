const CreatorAllVideosSkeleton = () => {
  const arr = [1, 2, 3, 4];

  return (
    <div className="mt-5">
      <div className={"flex flex-row justify-between w-full mt-5 mr-12"}>
        {arr.map((item: any) => (
          <div key={item} className="flex flex-col items-start justify-center">
            <div className="lg:w-[320px] h-[270px] w-[320px] bg-[#1a1919] animate-pulse mt-2"></div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CreatorAllVideosSkeleton;
