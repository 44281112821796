import React from "react";

const NotificationSkeleton = () => {
  return (
    <>
      <div className="flex items-center justify-between pl-[5px]  rounded-md    animate-pulse">
        <div className="w-[20%] relative  animate-pulse">
          <div className="w-[45px] h-[45px] rounded-full  animate-pulse bg-[#1a1919]"></div>
        </div>
        <div className="ml-3 w-[80%] relative animate-pulse">
          <div className=" bg-[#1a1919] mt-1 animate-pulse w-full h-[15px]"></div>
          <div className=" bg-[#1a1919] mt-1 animate-pulse w-full h-[15px]"></div>  
          <div className=" bg-[#1a1919] mt-1 animate-pulse w-[40%] h-[15px]"></div>       
        </div>
      </div>
      <div className="flex items-center justify-between pl-[5px]  rounded-md  mt-5  animate-pulse">
        <div className="w-[20%] relative  animate-pulse">
          <div className="w-[45px] h-[45px] rounded-full  animate-pulse bg-[#1a1919]"></div>
        </div>
        <div className="ml-3 w-[80%] relative animate-pulse">
          <div className=" bg-[#1a1919] mt-1 animate-pulse w-full h-[15px]"></div>
          <div className=" bg-[#1a1919] mt-1 animate-pulse w-full h-[15px]"></div>  
          <div className=" bg-[#1a1919] mt-1 animate-pulse w-[40%] h-[15px]"></div>       
        </div>
      </div>
      <div className="flex items-center justify-between pl-[5px]  rounded-md  mt-5  animate-pulse">
        <div className="w-[20%] relative  animate-pulse">
          <div className="w-[45px] h-[45px] rounded-full  animate-pulse bg-[#1a1919]"></div>
        </div>
        <div className="ml-3 w-[80%] relative animate-pulse">
          <div className=" bg-[#1a1919] mt-1 animate-pulse w-full h-[15px]"></div>
          <div className=" bg-[#1a1919] mt-1 animate-pulse w-full h-[15px]"></div>  
          <div className=" bg-[#1a1919] mt-1 animate-pulse w-[40%] h-[15px]"></div>       
        </div>
      </div>
    </>
  );
};

export default NotificationSkeleton;
