import RecentVideosGrid from "./shared/RecentVideosGrid";

function HomePage({ isLoading, creatorData }: any) {
  // console.log(isLoading);
  return (
    <>
      <RecentVideosGrid isLoading={isLoading} creatorData={creatorData} />
      {/* <PopularVideosCoursel isLoading={isLoading} creatorData={creatorData} /> */}
    </>
  );
}

export default HomePage;
