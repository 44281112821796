import { Routes, Route } from 'react-router-dom';
import LiveTvHistory from 'pages/liveTvPage/LiveTvHistory';
import LiveTvDiscover from 'pages/liveTvPage/LiveTvDiscover';
import FeaturePages from 'layout/FeaturePages';
import { RouteName } from 'constant/routeNames';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { removeAllPlayer } from 'redux/AudioPlayerRedux';
import SeeAllChannels from './liveTvPage/SeeAllChannels';
import LiveTvHomeWithPlayer from './liveTvPage/LiveTvHomeWithPlayer';

function LiveTvPage({
  handleClick,
  isActive,
  setIsActive,
  // setPlayerIsActive,
  anyPlayerActive,
}: any) {
  const dispatch = useDispatch<any>();
  useEffect(() => {
    dispatch(removeAllPlayer());
  }, []);

  // useEffect(() => {
  //   setPlayerIsActive(isActive);
  // }, []);
  return (
    <div className={`${anyPlayerActive ? `mb-[140px]` : `mb-30`}`}>
      <Routes>
        {/* <Route
          path="/"
          element={
            <FeaturePages
              Content={LiveTvHomeWithPlayer}
              title={"LiveTV"}
              handleClick={handleClick}
              isActive={isActive}
              setIsActive={setIsActive}
            />
          }
        /> */}
        <Route
          path={RouteName.LiveTVPlayerWithSlug}
          element={
            <FeaturePages
              Content={LiveTvHomeWithPlayer}
              title={'LiveTV'}
              handleClick={handleClick}
              isActive={isActive}
              setIsActive={setIsActive}
            />
          }
        />
        <Route
          path={RouteName.LiveTVHistory}
          element={
            <FeaturePages
              Content={LiveTvHistory}
              title={'LiveTV'}
              handleClick={handleClick}
              isActive={isActive}
              setIsActive={setIsActive}
            />
          }
        />
        <Route
          path={RouteName.LiveTVDiscover}
          element={
            <FeaturePages
              Content={LiveTvDiscover}
              handleClick={handleClick}
              isActive={isActive}
              setIsActive={setIsActive}
              title={'LiveTV'}
            />
          }
        />
        <Route
          path={RouteName.SeeAllChannels}
          element={
            <FeaturePages
              Content={SeeAllChannels}
              handleClick={handleClick}
              isActive={isActive}
              setIsActive={setIsActive}
              title={'LiveTV'}
            />
          }
        />
      </Routes>
    </div>
  );
}

export default LiveTvPage;
