import React from "react";
import Podcastcard from "./Podcastcard";

function PodcastHistoryCardSection({ gridCard }: any) {
  return (
    <div>
      {/* <Link to={RouteName.PodcastOpenEpisode}> */}
      <div className="mt-4 mb-5 w-[95%] grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-5 2xl:grid-cols-6 max-sm:gap-5">
        {gridCard.map((card: any, i: number) => (
          <div key={i}>
            <Podcastcard podcast={card} />
          </div>
        ))}
      </div>
      {/* </Link> */}
    </div>
  );
}

export default PodcastHistoryCardSection;
