import React from "react";

function VideoDetailsSkeleton() {
  return (
    <div>
      {/* Skeleton */}
      <div className="flex w-full">
        <div className="w-full">
          <div className="ml-5 mb-5 animate-pulse w-[80%] h-[30px] bg-[#1a1919]"></div>
          <div className="ml-5 animate-pulse w-[60%] h-[30px] bg-[#1a1919]"></div>
        </div>
        <div className="flex w-full justify-end mr-10 max-sm:mr-0">
          <div className="ml-5 mr-10 animate-pulse w-[20%] py-3 px-5 h-[50px] rounded-full bg-[#1a1919] mb-2"></div>
          <div className="ml-5 animate-pulse w-[20%] py-3 px-5 h-[50px] rounded-full bg-[#1a1919] mb-2"></div>
        </div>
      </div>
    </div>
  );
}

export default VideoDetailsSkeleton;
