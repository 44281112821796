import { useEffect, useState } from "react";
import HomeTopHero from "./shared/HomeTopHero";
import PayPerViewCategories from "./shared/PayPerViewCategories";
import { Helmet } from "react-helmet-async";
import UpcomingCoursel from "./shared/UpcomingCoursel";
import PastEventCoursel from "./shared/PastEventCoursel";
import Purchased from "./shared/Purchased";
import PayperviewService from "services/payperview/PayperviewServie";
import { useSelector } from "react-redux";
import GeneralServices from "services/General/GeneralServices";
import { Adsense } from "@ctrl/react-adsense";
import Accodion from "pages/home/Accodion";

const PayPerViewHome = () => {
  const [events, setEvents] = useState<any>([]);
  const [streamingEvents, setStreamingEvents] = useState<any>([]);
  const [pastEvents, setpastEvents] = useState<any>([]);
  const loggedInUser = useSelector((state: any) => state?.user?.currentUser);
  const [isLoading, setIsLoding] = useState<any>(false);
  const FE_URL = process.env.REACT_APP_FRONTEND_URL;
  const [gAdSlot, setgAdSlot] = useState<any>();
  const [categoryLength, setCategoryLength] = useState<any>();

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }, 1);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  useEffect(() => {
    setIsLoding(true);

    PayperviewService.getAllPayperviewEvents()
      .then((res) => {
        console.log("event", res.data);
        setIsLoding(false);
        setEvents(res?.data);

        const streamingEvents = res.data.filter(
          (payperviewEvent: any) =>
            payperviewEvent?.event_status === "streaming"
        );
        setStreamingEvents(streamingEvents);
        const pastEvents = res.data.filter(
          (payperviewEvent: any) => payperviewEvent?.event_status === "ended"
        );
        setpastEvents(pastEvents);
      })
      .catch((e: any) => {
        console.log(e);
      });
  }, []);

  useEffect(() => {
    GeneralServices.getAllConfigData()
      .then((res) => {
        const PageStatus = res?.data?.filter(
          (item: any) => item?.title === "is_ppv_gAd" && item?.value !== null
        );
        if (PageStatus[0]?.value === "1") {
          const filteredData = res?.data?.filter(
            (item: any) => item?.title === "gAdSlot" && item?.value !== null
          );
          setgAdSlot(filteredData[0]?.value);
          console.log("setgAdStatus", filteredData[0]?.value);
        }
      })
      .catch((e) => {});
  }, []);

  const [purchased, setPurchased] = useState<any>([]);
  //  const [isLoading, setIsLoding] = useState<any>(false);

  useEffect(() => {
    setIsLoding(true);
    PayperviewService.getPurchasedEvents(loggedInUser?.id)
      .then((res) => {
        console.log("event", res.data);
        setIsLoding(false);
        setPurchased(res.data);
      })
      .catch((e: any) => {
        console.log("getPurchasedEvents ", e);
      });
  }, []);

  const [payPerViewCategory, setPayPerViewCategory] = useState<any>([]);

  useEffect(() => {
    setIsLoding(true);
    PayperviewService.getAllPayperviewCategories()
      .then((res) => {
        // console.log("category", res.ppv_categories);
        setIsLoding(false);
        setPayPerViewCategory(res.ppv_categories);
        setCategoryLength(res.ppv_categories.length);
      })
      .catch((e: any) => {
        console.log(e);
      });
  }, []);

  // console.log(purchased.length);

  return (
    <>
      <Helmet>
        <title>AYOZAT Pay-Per-View</title>
        <meta
          name="description"
          content="Experience exclusive content and events on demand with our Pay-Per-View service."
        />
        <link rel="canonical" href={`${FE_URL}/ppv`} />
        <meta property="og:title" content="AYOZAT Pay-Per-View" />
        <meta
          property="og:description"
          content="Experience exclusive content and events on demand with our Pay-Per-View service."
        />
        {/* <meta property="og:image:secure_url" content="https://ayozat.co.uk/logo192.png" /> */}
        <meta property="og:image" content="https://ayozat.co.uk/logo192.png" />
        <meta property="og:url" content={`${FE_URL}/ppv`} />
        <meta property="og:type" content="website" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="AYOZAT Pay-Per-View" />
        <meta
          name="twitter:description"
          content="Experience exclusive content and events on demand with our Pay-Per-View service."
        />
        <meta name="twitter:image" content="https://ayozat.co.uk/logo192.png" />
        <meta name="twitter:site" content="AYOZAT Pay-Per-View" />
        <meta name="twitter:url" content={`${FE_URL}/ppv`} />
      </Helmet>

      <div>
        <HomeTopHero />
        {gAdSlot && (
          <Adsense
            client="ca-pub-5773929890067376"
            slot={gAdSlot}
            style={{
              display: "flex",
              marginBottom: "30px",
              height: "90px",
              justifyContent: "center",
            }}
            format="horizontal"
            data-full-width-responsive="true"
            // format="fluid"
          />
        )}

        <UpcomingCoursel
          events={events}
          isLoading={isLoading}
          purchased={purchased}
          user={loggedInUser}
        />
        {categoryLength >= 4 || purchased.length >= 3 ? (
          <>
            {/* <PopularRightNow
              events={events}
              isLoading={isLoading}
              purchased={purchased}
              user={loggedInUser}
            /> */}
            <Purchased
              purchased={purchased}
              isLoading={isLoading}
              user={loggedInUser}
            />
            <PayPerViewCategories
              categories={payPerViewCategory}
              isLoading={isLoading}
              categoryLength={categoryLength}
            />
          </>
        ) : (
          <>
            <div className={`grid w-full grid-cols-2 max-sm:block`}>
              <div className="">
                <PayPerViewCategories
                  categories={payPerViewCategory}
                  isLoading={isLoading}
                  categoryLength={categoryLength}
                />
              </div>
              {purchased && (
                <div className="">
                  <Purchased
                    purchased={purchased}
                    isLoading={isLoading}
                    user={loggedInUser}
                  />
                </div>
              )}
            </div>
          </>
        )}
        {/* {categoryLength >= 3 || pastEvents.length >= 4 ? (
          <>
            <PayPerViewCategories setCategoryLength={setCategoryLength} /> */}
        {/* <PickedOutForYou event={events} isLoading={isLoading} /> */}

        {gAdSlot && (
          <Adsense
            client="ca-pub-5773929890067376"
            slot={gAdSlot}
            style={{
              display: "flex",
              marginBottom: "30px",
              height: "90px",
              justifyContent: "center",
            }}
            format="horizontal"
            data-full-width-responsive="true"
            // format="fluid"
          />
        )}

        <PastEventCoursel
          events={events}
          isLoading={isLoading}
          user={loggedInUser}
        />
        {/* </>
        ) : (
          ""
        )} */}
      </div>
      <Accodion />
    </>
  );
};

export default PayPerViewHome;
