import FeaturePages from "layout/FeaturePages";
import { Route, Routes } from "react-router-dom";
import BoosterHome from "./boosterPage/BoosterHome";

function BoosterPage({
  handleClick,
  isActive,
  setIsActive,
  anyPlayerActive,
}: any) {
  return (
    <div className={`${anyPlayerActive ? `mb-[140px]` : `mb-30`}`}>
      <Routes>
        <Route
          path=""
          element={
            <FeaturePages
              Content={BoosterHome}
              handleClick={handleClick}
              isActive={isActive}
              setIsActive={setIsActive}
              title={""}
            />
          }
        />
      </Routes>
    </div>
  );
}

export default BoosterPage;
