import { useEffect, useRef, useState } from "react";
import { RiVolumeMuteLine, RiVolumeUpLine } from "react-icons/ri";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { useDispatch, useSelector } from "react-redux";
import { adjustVolume } from "redux/AudioPlayerRedux";
import { adjustVolumeVideo } from "redux/VideoPlayerRedux";

function VolumeButton({ isMuted, toggleMute, audioRef, setIsMuted }: any) {
  const dispatch = useDispatch<any>();
  const { volume } = useSelector((state: any) => state?.audio);

  const popRef = useRef<HTMLDivElement | null>(null);
  const [playerVolume, setPlayerVolume] = useState<number>(volume);
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    if (
      audioRef.current &&
      audioRef.current.audio &&
      audioRef.current.audio.current
    ) {
      audioRef.current.audio.current.volume = parseFloat(volume);
    }
  }, []);

  // handle volume
  const handleVolume = (value: any) => {
    if (
      audioRef.current &&
      audioRef.current.audio &&
      audioRef.current.audio.current
    ) {
      audioRef.current.audio.current.volume = parseFloat(value);
      setPlayerVolume(value);
      dispatch(adjustVolume(value));
      dispatch(adjustVolumeVideo(value));
    }
  };

  useEffect(() => {
    if (playerVolume === 0) {
      setIsMuted(true);
    } else {
      setIsMuted(false);
    }
  }, [playerVolume]);

  // Function to handle clicks outside the component
  const handleClickOutside = (e: MouseEvent) => {
    if (
      popRef.current &&
      !popRef.current.contains(e.target as Node) &&
      !(e.target instanceof HTMLElement && e.target.closest(".volume-control"))
    ) {
      setIsActive(false);
    }
  };

  // Add a click event listener to the document body when the component mounts
  useEffect(() => {
    document.body.addEventListener("click", handleClickOutside);
    return () => {
      // Remove the event listener when the component unmounts
      document.body.removeEventListener("click", handleClickOutside);
    };
  }, []);

  // Toggle dropdown
  const dropDown = () => {
    setIsActive(!isActive);
  };

  return (
    <div className="relative volume-control max-sm:hidden">
      <div className="relative">
        <div>
          <div
            className="text-xl text-white duration-300 ease-in-out cursor-pointer hover:text-red-600"
            onClick={isMuted ? toggleMute : dropDown}
            ref={popRef}
            data-tooltip-id={`audio-player-volume`}
          >
            {isMuted ? <RiVolumeMuteLine /> : <RiVolumeUpLine />}
            {!isActive && (
              <div className="text-sm">
                <ReactTooltip
                  id={`audio-player-volume`}
                  place="top"
                  content="Volume"
                />
              </div>
            )}
          </div>
        </div>

        {isActive && (
          <div
            className={`block text-center absolute bottom-[40px] left-[-15px] bg-[#343434] p-4 `}
          >
            <div className="flex items-center justify-center w-full">
              <Slider
                vertical
                min={0}
                max={1}
                step={0.01}
                value={playerVolume}
                disabled={isMuted}
                onChange={handleVolume}
                style={{
                  width: "20px",
                  height: "150px", // Increase the height as desired
                }}
                trackStyle={{ backgroundColor: "red" }}
                handleStyle={{
                  borderColor: "red",
                  backgroundColor: "red",
                  boxShadow: "0 0 5px 2px rgba(255, 0, 0, 0.5)",
                }}
                activeDotStyle={{
                  boxShadow: "0 0 5px 2px rgba(255, 0, 0, 0.5)",
                }}
              />
            </div>

            <button
              className="mt-4 volumeButton "
              onClick={toggleMute}
              data-tooltip-id={`audio-player-volume-mute/unmute`}
            >
              {isMuted ? <RiVolumeMuteLine /> : <RiVolumeUpLine />}
              <div className="text-sm">
                <ReactTooltip
                  id={`audio-player-volume-mute/unmute`}
                  place="left"
                  content={isMuted ? "Unmute" : "Mute"}
                />
              </div>
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default VolumeButton;
