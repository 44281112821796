import React from 'react'
import MusicCardSkeleton from './MusicCardSkeleton'

const NewReleaseSeeAllSkeleton = () => {

    const arr = [1, 2, 3, 4, 5, 6];

    return (
        <div>
            <div className='w-[50px] h-[50px] rounded-full bg-[#1a1919] animate-pulse'></div>
            <div className='w-[300px] h-[40px] bg-[#1a1919] animate-pulse mt-5'></div>
            <div className='flex flex-col mt-10'>
                <div className='grid lg:grid-cols-6 max-md:grid-cols-3 max-sm:grid-cols-2'>
                    {arr.map((item: any) => (
                        <div key={item}>
                            <MusicCardSkeleton />
                        </div>
                    ))
                    }
                </div>
                <div className='grid lg:grid-cols-6 max-md:grid-cols-3 max-sm:grid-cols-2 mt-[100px]'>
                    {arr.map((item: any) => (
                        <div key={item}>
                            <MusicCardSkeleton />
                        </div>
                    ))
                    }
                </div>
            </div>
        </div>
    )
}

export default NewReleaseSeeAllSkeleton