import React from "react";
import PodcastMusicLeftSide from "./podcastMusicPlayer/PodcastMusicLeftSide";

function PodcastMusicPlayer() {
  return (
    <div className="flex justify-between">
      <div className="w-[50%]"></div>
      <div className="w-[50%]">
        <PodcastMusicLeftSide />
      </div>
    </div>
  );
}

export default PodcastMusicPlayer;
