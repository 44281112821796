import FullScreenNextSong from "components/AudioPlayer/Components/FullScreenNextSong";
import FullScreenQueue from "components/AudioPlayer/Components/FullScreenQueue";
import SubText from "components/shared/SubText";
import React from "react";

function QueueSection({
  nextSong,
  music,
  radio,
  podcast,
  currentSong,
  playlist,
}: any) {
  const height = `${window.innerHeight - 230}px`;
  return (
    <>
      <div className="h-[120px]">
        <SubText text={"Playing Next"} className={"mb-3"} />
        <FullScreenNextSong
          nextSong={nextSong}
          music={music}
          radio={radio}
          podcast={podcast}
        />
      </div>

      {(music || podcast) && (
        <div
          className=" w-full"
          style={{
            height: `${height}`,
          }}
        >
          <FullScreenQueue
            musics={playlist}
            currentSong={currentSong}
            music={music}
            radio={radio}
            podcast={podcast}
          />
        </div>
      )}
    </>
  );
}

export default QueueSection;
