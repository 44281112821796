const GenreSkeleton = () => {
  return (
    <div>
      <div className="flex flex-col items-start justify-center">
        <div className="flex justify-between gap-[220px]">
          <div className="flex-1 w-[80px] h-[30px] bg-[#1a1919] animate-pulse mt-2"></div>
          <div className="flex-1 items-end w-[80px] h-[30px] bg-[#1a1919] animate-pulse mt-2"></div>
        </div>
        <div className="lg:w-[380px] w-[190px] h-[500px] bg-[#1a1919]  animate-pulse mt-2"></div>
      </div>
    </div>
  );
};

export default GenreSkeleton;
