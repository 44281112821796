import { Notification } from "constant/notifications";
import React from "react";
import { FaPlay } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { playLatestPodcasts } from "redux/AudioPlayerRedux";
import {
  triggerSuccessNotification,
  triggerWarningNotification,
} from "utils/notification";

function PlayFromRecent({ episodes }: any) {
  const dispatch = useDispatch<any>();

  const addPodcasts = async () => {
    if (episodes.length > 0) {
      dispatch(playLatestPodcasts(episodes));
      triggerSuccessNotification(Notification.PodcastsAddedToQueue);
    } else {
      triggerWarningNotification(Notification.NoPodcasts);
    }
  };
  return (
    <button
      className={`flex items-center mx-auto w-auto p-3 text-center text-white rounded-full button-red`}
      onClick={addPodcasts}
    >
      <FaPlay className="mr-3" /> Play latest
    </button>
  );
}

export default PlayFromRecent;
