import { RouteName } from "constant/routeNames";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

function GenreSelector({ slug, categories }: any) {
  const navigate = useNavigate();
  // const [categories, setCategories] = useState([]);
  const [selected, setSelected] = useState<any>(slug);

  useEffect(() => {
    navigate(
      RouteName.Radio.replace(
        "*",
        RouteName.RadioChannelsByCategory.replace(":slug", selected)
      )
    );
    // setGenre(selected);
  }, [selected]);

  const onChange = (e: any) => {
    setSelected(e.target.value);
  };

  return (
    <div className="mr-5">
      <label
        htmlFor="watched"
        className="text-white max-sm:hidden max-md:hidden"
      >
        Genres{" "}
      </label>
      <select
        name="cars"
        id="cars"
        className="p-1 ml-2 text-white rounded-md outline-none searchbar"
        onChange={onChange}
        value={selected}
      >
        {categories?.map((c: any, i: number) => (
          <option value={c.alt_name} key={i}>
            {c.name.replace(/&amp;/g, '&')}
          </option>
        ))}
      </select>
    </div>
  );
}

export default GenreSelector;
