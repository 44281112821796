export const YearData = [
  {
    id: 1,
    year: 2000,
  },
  {
    id: 2,
    year: 2001,
  },
  {
    id: 3,
    year: 2002,
  },
  {
    id: 4,
    year: 2003,
  },
  {
    id: 5,
    year: 2004,
  },
  {
    id: 6,
    year: 2005,
  },
  {
    id: 7,
    year: 2006,
  },
  {
    id: 8,
    year: 2007,
  },
  {
    id: 9,
    year: 2008,
  },
  {
    id: 10,
    year: 2009,
  },
  {
    id: 11,
    year: 2010,
  },
  {
    id: 12,
    year: 2011,
  },
  {
    id: 13,
    year: 2012,
  },
  {
    id: 14,
    year: 2013,
  },
  {
    id: 15,
    year: 2014,
  },
  {
    id: 16,
    year: 2015,
  },
  {
    id: 17,
    year: 2016,
  },
  {
    id: 18,
    year: 2017,
  },
  {
    id: 19,
    year: 2018,
  },
  {
    id: 20,
    year: 2019,
  },
  {
    id: 21,
    year: 2020,
  },
  {
    id: 22,
    year: 2021,
  },
  {
    id: 23,
    year: 2022,
  },
  {
    id: 24,
    year: 2023,
  },
  {
    id: 24,
    year: 2024,
  },
];
