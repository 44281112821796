//import logo
import Logo from 'Assets/Images/a_logo.png';
import ConfirmationPopUp from 'components/Pop-Ups/ConfirmationPopUp';
import PopUpModel from 'components/PopUpModel';
import ChangePasswordOldUser from 'components/shared/ChangePasswordOldUser';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { clearDataWhenLogout } from 'redux/AudioPlayerRedux';
import { logout } from 'redux/userRedux';
import AuthService from 'services/auth/AuthService';
import CoverPoster from 'Assets/Images/CoverPoster.jpg';

function UpdateOldUserPassword() {
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogout = () => {
    AuthService.logout()
      .then((response: any) => {
        if (response.status === 200) {
          // console.log("200");
          dispatch(logout());
          // console.log("logout()");
          navigate('/login');
          // console.log("navigate");
          dispatch(clearDataWhenLogout());
          // console.log("clearDataWhenLogout");
        }
      })
      .catch((e: Error) => {
        // console.log("log out user error ==> ", e);
      });
  };

  // skip function
  // delete playlist confirmation
  const [showModal, setShowModal] = useState(false);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <div
        className="overflow-x-hidden max-sm:w-[100%] max-sm:h-screen overflow-scroll "
        // main back image
        style={{
          color: '#fff',
          backgroundImage: `url(${CoverPoster})`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          height: '100vh',
        }}
      >
        {/* red dot top left */}
        <div className=" loginpage-back">
          <div
            className="absolute h-[800px] w-[800px] -left-[350px] z-[0] -top-[400px] max-sm:hidden"
            style={{
              color: '#fff',
              backgroundImage: `url('https://i.stack.imgur.com/G7vn7.png')`,
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
            }}
          ></div>
          <div className="justify-start sm:pt-20 update-password ">
            {/* <div
              className={`!z-50 flex items-center justify-center w-10 h-10 text-2xl bg-white rounded-full cursor-pointer mb-4`}
            >
              <HiArrowLeft className="text-black" onClick={openModal} />
            </div> */}

            {/* ayosat logo part */}
            <div className="  bg-white rounded-full w-[100px] h-[100px] flex items-center mb-10 mt-[-50px] max-sm:mt-0">
              <img src={Logo} alt="logo" className="w-16 h-16 mx-auto mb-2" />
            </div>

            {/* main topic section */}
          </div>
          <div className="relative mt-[80px] ml-[100px]  flex items-center w-[50%] max-sm:w-full max-sm:mx-[0px] z-20 top-[-10px] max-sm:top-10 ">
            <ChangePasswordOldUser
              handleLogout={handleLogout}
              openModal={openModal}
            />
          </div>
        </div>
      </div>
      {showModal && (
        <div className="z-50">
          <PopUpModel isOpen={showModal} onClose={closeModal} width={'800px'}>
            <ConfirmationPopUp
              title={'Skip Password Change'}
              text={`Are you sure you want to skip the process? You will be logged out.`}
              executableFunction={handleLogout}
              onClose={closeModal}
              executableFunctionButtonText={'Confirm'}
            />
          </PopUpModel>
        </div>
      )}
    </>
  );
}

export default UpdateOldUserPassword;
