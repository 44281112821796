import React from "react";
import RadioCardGridSection from "./shared/RadioCardGridSection";
import RadioProgrammesCardSection from "./shared/RadioProgrammesCardSection";
import { radios } from "constant/radios";
import { programmes } from "constant/programmes";

function RadioPageFavorite() {
  return (
    <div>
      {/* top section */}
      <div className="mb-8 w-[98%]">
        <div>
          <h1 className="text-2xl font-medium text-white lg:text-2xl md:text-2xl">
            Favorite stations
          </h1>
        </div>
      </div>
      {/* card section */}
      <RadioCardGridSection radios={radios} />

      {/* bottom section */}
      <div className="mb-8 w-[98%]">
        <div>
          <h1 className="text-2xl font-medium text-white lg:text-2xl md:text-2xl">
            Favorite programmes
          </h1>
        </div>
      </div>
      {/* card section */}
      <RadioProgrammesCardSection programmes={programmes} />
    </div>
  );
}

export default RadioPageFavorite;
