import React from "react";
import NoSearch from "./NoSearch";
import { ChannelCard } from "pages/liveTvPage/shared/ChannelCard";
import TvChannelSkeleton from "components/skeleton/TvChannelSkeleton";

function SearchLiveTvSection({ data, isLoading }: any) {
  const channels = data?.tv_channels || [];
  // const [channels, setChanels] = useState<any>([]);

  // useEffect(() => {
  //   if (data) {
  //     setChanels(data.tv_channels);
  //   }
  // }, [data]);

  return (
    <>
      {!isLoading && (
        <div>
          {/* top section */}
          <div className="mb-8 w-[98%] ml-2 mt-8 max-sm:w-full max-sm:mx-0">
            <div>
              <h1 className="text-xl font-semibold text-white lg:text-xl md:text-xl max-sm:ml-3">
                {/* search channels */}
                Live TV
              </h1>
            </div>
          </div>
          {/* card section */}
          {channels.length === 0 && channels ? (
            <NoSearch text="Live TV Channels" />
          ) : (
            <div>
              <div className="mt-4 mb-5 w-[95%] grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 2xl:grid-cols-7 max-sm:mx-auto max-sm:w-full max-sm:gap-4">
                {channels.length > 0 &&
                  channels.map((channel: any, i: any) => (
                    <div key={i}>
                      <div className="mx-3 max-sm:mx-1">
                        <ChannelCard
                          data={channel}
                          img={channel.thumbnail_url}
                        />
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          )}
        </div>
      )}
      {isLoading && <TvChannelSkeleton />}
    </>
  );
}

export default SearchLiveTvSection;
