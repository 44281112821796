import React, { useEffect, useState } from "react";
import HeroCardPaperView from "./HeroCardPaperView";
import SubText from "components/shared/SubText";
import PayperviewService from "services/payperview/PayperviewServie";
import PayperviewFeatured from "components/skeleton/PayperviewFeatured";

const HomeTopHero = () => {
  const [isLoading, setIsLoding] = useState<any>(false);
  const [event, setEvent] = useState<any>([]);

  useEffect(() => {
    setIsLoding(true);
    PayperviewService.getAllPayperviewFeatured()
      .then((res: { data: any }) => {
        console.log("Hero-event", res.data);
        setIsLoding(false);
        setEvent(res.data);
      })
      .catch((e: any) => {
        console.log(e);
      });
  }, []);

  // console.log("Hero-event", event.length);

  return (
    <div>
      {!isLoading && (
        <>
          <SubText text={"Featured Event"} className="font-bold" />
          <div className="flex mt-5 flex-row justify-between w-[98%] max-sm:block max-sm:w-full max-md:block max-lg:block">
            {event?.[0] && (
              <div className="w-[55%] max-sm:w-full max-md:w-full max-lg:w-full max-sm:mb-4">
                <HeroCardPaperView value={event?.[0]} />
              </div>
            )}

            {event?.[1] && (
              <div className="w-[40%] max-sm:w-full  max-md:w-full max-lg:w-full">
                <HeroCardPaperView value={event?.[1]} />
              </div>
            )}
          </div>
          {event.length >= 2 ? (
            <>
              <div className="flex mt-5 flex-row justify-between w-[98%] max-sm:block max-sm:w-full max-md:block max-lg:block">
                {event?.[2] && (
                  <div className="w-[40%] max-sm:w-full  max-md:w-full max-lg:w-full">
                    <HeroCardPaperView value={event?.[2]} />
                  </div>
                )}

                {event?.[3] && (
                  <div className="w-[55%] max-sm:w-full max-sm:mb-4 max-md:w-full max-lg:w-full ">
                    <HeroCardPaperView value={event?.[3]} />
                  </div>
                )}
              </div>
            </>
          ) : (
            <></>
          )}
        </>
      )}
      {isLoading && <PayperviewFeatured />}
    </div>
  );
};

export default HomeTopHero;
